<template>
  <b-input-group>
    <b-form-textarea
        type="text"
        :id="`input-${itemId}`"
        :max-rows="20"
        @input="saveRequest()"
        :disabled="locked"
        v-model="model"
        :placeholder="toggle ? $t('voiceCaptureActive') : placeholder"
        class="voiceInput__textbox"
        :class="activeInput ? 'invoice__activeInput' : null"
        v-on:keydown.13.stop
        v-on:keydown.shift.13="enterPressed"
    />
    <b-btn
        v-if="recognition"
        @click.stop="toggle ? endSpeechRecognition() : startSpeechRecognition()"
        class="voiceInput__button">
      <b-icon
          :icon="toggle ? 'mic-mute' : 'mic'"
      />
    </b-btn>
  </b-input-group>
</template>

<script>
import totalCalculators from '@/mixins/totalCalculators';

export default {
  // title() {
  //   return ` | ${this.$t('project.brand')}`;
  // },
  mounted() {
    this.checkCompatibility();
    if (this.SpeechRecognition) {
      this.recognition = new this.SpeechRecognition();
    }
  },
  props: [
    'activeInput',
    'enableEnterKey',
    'itemId',
    'modelProp',
    'locked',
    'placeholder',
  ],
  mixins: [
    totalCalculators,
  ],
  computed: {
    model: {
      get() {
        return this.modelProp;
      },
      set(input) {
        this.$emit('textInput', input);
      },
    },
    SpeechRecognition() {
      return window.SpeechRecognition || window.webkitSpeechRecognition;
    },
  },
  data() {
    return {
      error: false,
      speaking: false,
      toggle: false,
      recognition: false,
      runtimeTranscription: '',
      sentences: [],
      lang: {
        type: String,
        default: 'en-US',
      },
      text: {
        type: [String, null],
        required: true,
      },
    };
  },
  methods: {
    checkCompatibility() {
      if (!this.recognition) {
        this.error = this.$t('error.speechRecognitionBrowser');
      }
    },
    endSpeechRecognition() {
      this.toggle = false;
      this.$emit('speechend');
      this.recognition.stop();
      // this.recognition = new this.SpeechRecognition();
    },
    enterPressed() {
      this.$emit('enterKeyPressed');
      this.endSpeechRecognition();
    },
    startSpeechRecognition() {
      this.$emit('startingRecognition');
      this.$emit('textInput', '');
      if (!this.recognition) {
        this.error = this.$t('error.speechRecognitionBrowser');
      } else {
        this.sentences = [];
        this.toggle = true;
        this.recognition.lang = this.lang;
        this.recognition.interimResults = true;
        this.recognition.addEventListener('speechstart', () => {
          this.speaking = true;
        });
        this.recognition.addEventListener('speechend', () => {
          this.speaking = false;
          this.recognition.stop();
        });
        this.recognition.addEventListener('result', (event) => {
          console.log('speech captured');
          const text = Array.from(event.results).map(result => result[0]).map(result => result.transcript).join('');
          this.runtimeTranscription = text;
        });
        this.recognition.addEventListener('onerror', (e) => {
          console.log(e);
        });
        this.recognition.addEventListener('end', () => {
          if (this.runtimeTranscription !== '') {
            this.sentences.push(this.capitalizeFirstLetter(this.runtimeTranscription));
            this.$emit('update:text', `${this.text}${this.sentences.slice(-1)[0]}. `);
          }
          this.runtimeTranscription = '';
          this.recognition.stop();
          let sentences = this.sentences.join('. ');
          sentences += '.';
          if (sentences && sentences !== '.') {
            this.$emit('textInput', sentences);
          }
          if (this.toggle) {
            try {
              this.recognition.start();
            } catch (error) {
              this.recognition.stop();
            }
          }
        });
        try {
          this.recognition.start();
        } catch (error) {
          this.recognition.stop();
        }
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    speechEnd({ sentences }) {
      this.$emit('textInput', sentences);
    },
  },
  watch: {},
};
</script>
