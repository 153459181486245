<template>
  <b-btn-group
      :id="`availableOrNot-${id}`">
    <b-btn
        :id="`available-${id}`"
        :variant="!available ? 'success-outline' : 'success'"
        @click="buttonClicked(true)"
        class="px-4 my-2 mr-1">
      <b-icon-calendar-check
        class="mr-1"
      />
      <span
          v-text="$t('scheduler.available')"
      />
    </b-btn>
    <b-btn
        :id="`notAvailable-${id}`"
        @click="buttonClicked(false)"
        :variant="available ? 'warning-outline' : 'warning'"
        class="px-4 my-2 ml-1">
      <b-icon-x-circle
          class="mr-1"
      />
      <span
          v-text="$t('scheduler.notAvailable')"
      />
    </b-btn>
  </b-btn-group>
</template>

<script>

export default {
  props: ['id', 'available'],
  computed: {},
  data() {
    return {
      model: {},
    };
  },
  methods: {
    buttonClicked(choice) {
      this.$emit('buttonClicked', choice);
    },
  },
  watch: {},
};
</script>
