<template>
  <div
      id="timeSheetAdmin"
      v-if="$store.state.initialLoad">
    <div
        id="viewExistingEntries"
        v-if="!addingTimecardEntry">
      <b-row
          align-h="center"
          class="mb-3">
        <!-- Date Range Selection -->
        <b-col
            align="center"
            cols="*"
            class="workDay__container__dark ml-auto mr-2">
          <b-row
              align-h="center">
            <b-col
                cols="*">
              <label
                  for="startDateInput"
                  v-text="tableType === 'byEmlpoyee' ? $t('workDay.weekOf') : $t('workDay.dateStart')"
              />
              <b-form-input
                  id="startDateInput"
                  v-model="startDateInput"
                  type="date"
                  class="form__input medium"
              />
            </b-col>
            <b-col
                cols="*">
              <label
                  for="endDateInput"
                  v-text="tableType === 'byEmployee' ? $t('workDay.weekEnding') : $t('workDay.dateEnd')"
              />
              <b-form-input
                  :disabled="tableType === 'byEmployee'"
                  id="endDateInput"
                  v-model="dateEnd"
                  type="date"
                  class="form__input medium"
              />
            </b-col>
          </b-row>
          <b-row
              align-h="center">
            <b-col
                cols="*"
                align="center">
              <b-btn
                  id="backWeekButton"
                  @click="moveWeek()">
                <b-icon-arrow-left
                    :aria-label="$t('aria.weekBack')"/>
              </b-btn>
            </b-col>
            <b-col
                cols="*"
                align="center">
              <b-btn
                  id="forwardWeekButton"
                  @click="moveWeek(true)">
                <b-icon-arrow-right
                    :aria-label="$t('aria.weekForward')"/>
              </b-btn>
            </b-col>
          </b-row>
          <!-- <b-row
              id="weekOf"
              v-if="tableType === 'byEmployee'"
              align-h="center"
              class="mt-3">
            <b-col
                cols="*"
                align="center">
            </b-col>
          </b-row> -->
        </b-col>
        <!-- View Mode / Table Type Toolbar -->
        <b-col
            v-if="employees"
            align="center"
            cols="*"
            class="workDay__container__dark mr-auto ml-2 my-auto">
          <b-btn-group
              id="viewType">
            <b-btn
                v-for="(option, index) in tableOptions"
                :key="index"
                @click.stop="changeTableView(option.key)"
                :variant="option.key === tableType ? 'primary' : 'secondary'">
              {{option.text}}
            </b-btn>
          </b-btn-group>
          <transition
              name="fade"
              mode="out-in">
            <div
                v-if="tableType === 'byEmployee'"
                class="mt-3">
              <b-dropdown
                  id="employeeSelection"
                  variant="info"
                  :text="activeEmployee._id ? displayEmployeeData(activeEmployee._id) : $t('workDay.selectEmployee')">
                <b-dropdown-item
                    :id="`emp-${employee._id}`"
                    v-for="(employee, index) in employees"
                    :key="index"
                    @click="selectActiveEmployee(employee)">
                  {{displayEmployeeData(employee._id)}}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </transition>
        </b-col>
      </b-row>
      <transition
          name="fade"
          mode="out-in">
        <div
            key="recent"
            v-if="!hideTable"
            class="workDay__container__dark">
          <b-table
              id="item-table"
              striped
              responsive
              :items="shiftsWorkedPaginated"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              :busy="this.$store.state.showLoader"
              primary-key="_id"
              class="workDay__table">
            <template
                v-slot:cell(date)="row">
              <div
                  :id="`dates-${row.item._id}`"
                  v-if="!row.item.total && !row.item.hideDate"
                  class="workDay__list__info">
                <span
                    v-html="displayDate(row.item.clockIn)"
                />
              </div>
            </template>
            <template
                v-slot:cell(start)="row">
              <transition
                  name="fade"
                  mode="out-in">
                <div
                    key="display"
                    v-if="quickEdit !== row.item._id && !row.item.total"
                    :id="`startTime-${row.item._id}`"
                    :class="!row.item.conflictIn ? 'workDay__list__info' : 'workDay__list__info__warning'">
                  <b
                      v-text="displayTime(row.item.clockIn)"
                  />
                  <div
                      v-if="row.item.clockInClicked">
                    <small>
                      {{$t('workDay.clockedInAdvanced')}} {{displayTime(row.item.clockInClicked)}}
                    </small>
                  </div>
                  <div
                      v-if="row.item.clockInNotes"
                      :id="`notes-${row.item._id}`">
                    {{row.item.clockInNotes}}
                  </div>
                  <div
                      v-if="row.item.conflictIn">
                    <small
                        v-text="$t('workDay.clockInConflict')"
                    />
                  </div>
                </div>
                <div
                    v-else-if="!row.item.total"
                    key="editing"
                    :id="`startTime-${row.item._id}`"
                    class="workDay__list__info">
                  <b-input-group>
                    <b-form-timepicker
                        v-model="model.clockIn"
                        :id="`startTimeInput-${row.item._id}`"
                        :placeholder="$t('scheduler._timePlaceholder')"
                        size="lg"
                        :locale="$t('project.timeLocale')"
                        class="scheduler__availability__input"
                    />
                  </b-input-group>
                  <b-form-textarea
                      v-model="model.clockInNotes"
                      :id="`clockInNotesInput-${row.item._id}`"
                  />
                </div>
              </transition>
            </template>
            <template
                v-slot:cell(end)="row">
              <transition
                  name="fade"
                  mode="out-in">
                <div
                    v-if="quickEdit !== row.item._id && !row.item.total"
                    key="viewing"
                    :id="`endTime-${row.item._id}`"
                    :class="row.item.clockOut ? !row.item.conflictOut ? 'workDay__list__info' : 'workDay__list__info__warning' : ''">
                  <b
                      v-if="row.item.clockOut"
                      v-text="displayTime(row.item.clockOut)"
                  />
                  <div
                      v-if="row.item.clockOutNotes"
                      :id="`notes-${row.item._id}`">
                    {{row.item.clockOutNotes}}
                  </div>
                  <div
                      v-if="row.item.conflictOut">
                    <small
                        v-text="$t('workDay.clockInConflict')"
                    />
                  </div>
                </div>
                <div
                    v-else-if="!row.item.total"
                    key="editing"
                    :id="`endTime-${row.item._id}`"
                    class="workDay__list__info">
                  <b-input-group>
                    <b-form-timepicker
                        v-model="model.clockOut"
                        :id="`endTimeInput-${row.item._id}`"
                        :placeholder="$t('scheduler._timePlaceholder')"
                        size="lg"
                        :locale="$t('project.timeLocale')"
                        class="scheduler__availability__input"
                    />
                    <b-btn
                        :id="`clearTimeOut-${row.item._id}`"
                        :aria-label="$t('aria.clearTime')"
                        variant="danger-outline"
                        @click="clearClockOut()">
                      <b-icon-x-circle />
                    </b-btn>
                  </b-input-group>
                  <b-form-textarea
                      v-model="model.clockOutNotes"
                      :id="`clockOutNotesInput-${row.item._id}`"
                  />
                </div>
              </transition>
            </template>
            <template
                v-slot:cell(hours)="row">
              <div
                  :id="`entryHours-${row.item._id}`"
                  v-if="row.item.clockIn || row.item.total"
                  :class="warningCheck(row.item) ? 'workDay__list__info__warning' : !row.item.total ? 'workDay__list__info' : 'workDay__list__info__total'">
                <span
                    v-html="displayHoursWorked(row.item)"
                />
                <div
                    v-if="row.item.noLunch">
                    {{$t('workDay.noLunch')}}
                </div>
                <div
                    v-if="row.item.log && row.item.log.length"
                    :id="`displayLogs-${row.item._id}`">
                  <b-btn
                      v-text="displayLogs.indexOf(row.item._id) === -1 ? $t('workDay.logShow') : $t('workDay.logHide')"
                      size="sm"
                      @click="displayLogClick(row.item._id)"
                  />
                  <transition
                      mode="out-in"
                      name="fade">

                    <div
                        v-if="displayLogs.indexOf(row.item._id) > -1">
                      <b-row
                          v-for="log in row.item.log"
                          :key="log._id"
                          align-h="center"
                          :id="`logDisplay-${log._id}`">
                        <b-col
                            cols="*"
                            align="center">
                          <b-row
                              v-for="(text, index) in log.text"
                              :key="index">
                            <b-col
                                cols="*">
                              <small
                                  v-text="text"
                              />
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                  </transition>
                </div>
              </div>
            </template>
            <template
                v-slot:cell(project)="row">
              <div
                  :id="`projectInfo-${row.item._id}`">
                <display-project-info
                  :shiftInfo="row.item"
                />
              </div>
            </template>
            <template
                v-slot:cell(employee)="row">
              <div
                  :id="`employees-${row.item._id}`"
                  v-if="displayEmployeeData(row.item.employee)"
                  class="workDay__list__info">
                <span
                    v-text="displayEmployeeData(row.item.employee)"
                />
              </div>
            </template>
            <template
                v-slot:cell(buttons)="row">
              <div
                  :id="`actionButtons-${row.item._id}`"
                  v-if="!row.item.total">
                <b-btn-group
                    id="editAndDelete"
                    v-if="quickEdit !== row.item._id && deleteRequest !== row.item._id">
                  <b-btn
                      :id="`edit-${row.item._id}`"
                      :aria-label="$t('aria.editWorkDay')"
                      @click="editTimecardEntry(row.item._id)"
                      variant="info"
                      class="mr-2">
                    <b-icon-pencil
                    />
                  </b-btn>
                  <b-btn
                      :id="`delete-${row.item._id}`"
                      v-if="quickEdit !== row.item._id"
                      :aria-label="$t('aria.deleteWorkDay')"
                      @click="deleteTimecardEntryReq(row.item._id)"
                      variant="danger">
                    <b-icon-trash
                    />
                  </b-btn>
                </b-btn-group>
                <b-btn
                    v-else-if="quickEdit === row.item._id"
                    variant="primary"
                    @click="editTimecardEntryConfirm()"
                    class="px-3">
                  <b-icon-check-square /> {{$t('save')}}
                </b-btn>
                <div
                    v-else-if="!$store.state.showSpinner">
                  <small
                      v-text="$t('areYouSure')"
                  />
                  <br />
                  <b-btn-group
                      id="confirmDelete">
                    <b-btn
                        :aria-label="$t('aria.deleteWorkDayConfirm')"
                        :id="`delete-${row.item._id}`"
                        @click="deleteTimecardEntry(row.item._id)"
                        variant="danger">
                      <b-icon-trash
                      />
                    </b-btn>
                    <b-btn
                        :id="`edit-${row.item._id}`"
                        :aria-label="$t('aria.cancel')"
                        @click="deleteRequest = null"
                        variant="info"
                        class="ml-2">
                      <b-icon-arrow-left
                      />
                    </b-btn>
                  </b-btn-group>
                </div>
                <div
                    v-else
                    class="mx-auto">
                  <b-btn
                      :disabled="true"
                      variant="secondary">
                    <loader
                        key="loader"
                        color="white"
                        :h="25"
                        :w="80"
                        :fullPage="false"
                    />
                  </b-btn>
                </div>
              </div>
            </template>
          </b-table>
          <b-row
              align-h="center"
              v-if="shiftsWorked.length > perPage">
            <b-col
                cols="*"
                align="center"
                class="workDay__container__dark">
              <b-pagination
                  v-model="page"
                  :total-rows="shiftsWorked.length"
                  :per-page="perPage"
                  aria-controls="my-table"
                  class="my-0"
              />
            </b-col>
          </b-row>
        </div>
        <!-- <div
            key="byEmployee"
            v-if="tableType === 'byEmployee'">
          <b-btn
              @click="activeEmployee = employees[0]._id"
          />
          {{shiftsWorked}}
        </div> -->
      </transition>
      <b-row
          id="addEntryButtonRow"
          v-if="employees"
          class="mt-3 mx-2">
        <b-col
            class="workDay__container__dark mr-auto ml-2"
            cols="*">
          <b-btn
              id="payrollReportButton"
              size="lg"
              @click="runPayrollReport()"
              variant="primary">
            {{$t('workDay.runPayroll')}} <b-icon-file-earmark-check-fill />
          </b-btn>
        </b-col>
        <b-col
            class="workDay__container__dark ml-auto mr-2"
            cols="*">
          <b-btn
              id="addEntryButton"
              size="lg"
              @click="addTimecardEntry()"
              variant="primary">
            <b-icon-plus /> {{$t('workDay.addTimecardEntry')}}
          </b-btn>
        </b-col>
      </b-row>
    </div>
    <!-- Adding new Timecard -->
    <div
        id="addNewTimecardEntry"
        v-if="addingTimecardEntry">
      <b-row
          id="employeeSelection"
          v-if="activeView === 0"
          align-h="center">
        <b-col
            align="center"
            cols="*"
            class="workDay__container">
          <h2
              class="workday__timecard__header"
              v-text="$t('workDay.chooseEmployee')"
          />
          <b-list-group
              id="employees">
            <b-list-group-item
                v-for="employee in employees"
                :key="employee._id"
                @mouseover="hoverEmployee = employee._id"
                @mouseleave="hoverEmployee = null"
                :variant="hoverEmployee === employee._id ? 'success' : 'secondary'"
                style="cursor: pointer"
                @click="clickEmployee(employee._id)"
                class="px-5 py-3">
              <span
                  v-html="displayEmployeeData(employee._id)"
              />
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row
          id="dateSelection"
          v-if="activeView === 1"
          align-h="center">
        <b-col
            align="center"
            cols="*"
            class="workDay__container">
          <h2
              class="workday__timecard__header"
              v-text="$t('workDay.chooseDate')"
          />
          <span
              v-text="$t('workDay.addTimecardEntryFor')"
          />
          <br />
          <b
              v-html="displayEmployeeData(model.employee)"
          />
          <date-picker
              :disableSelectType="true"
              :startingSelectType="0"
              @datesChosen="dateSelected"
          />
        </b-col>
      </b-row>
      <div
          v-if="activeView === 2">
        <!-- Time in and Time out for new timecard entry -->
        <b-row
            id="timeSelection"
            align-h="center">
          <b-col
              align="center"
              cols="*"
              id="startTime"
              class="workDay__container ml-auto mr-3">
            <h3
                v-text="$t('workDay.clockedIn')"
            />
            <b-input-group>
              <b-form-timepicker
                  v-model="model.clockIn"
                  id="startTimeInput"
                  :placeholder="$t('scheduler._timePlaceholder')"
                  size="lg"
                  :locale="$t('project.timeLocale')"
                  class="scheduler__availability__input"
              />
            </b-input-group>
            <b-form-textarea
                id="clockInNotesInput"
                v-model="model.clockInNotesAdmin"
                :placeholder="$t('workDay.clockInNotes')"
            />
            <div
                v-if="model.clockOutNotes">
              <small
                  v-text="$t('workDay.employeeNotes')"
              />
              <br />
              <span
                  v-text="model.clockOutNotes"
              />
            </div>
          </b-col>
          <b-col
              align="center"
              cols="*"
              id="endTime"
              class="workDay__container ml-3 mr-auto">
            <h3
                v-text="$t('workDay.clockedOut')"
            />
            <b-input-group>
              <b-form-timepicker
                  v-model="model.clockOut"
                  id="endTimeInput"
                  :placeholder="$t('scheduler._timePlaceholder')"
                  size="lg"
                  :locale="$t('project.timeLocale')"
                  class="scheduler__availability__input"
              />
            </b-input-group>
            <b-form-textarea
                id="clockOutNotesInput"
                v-model="model.clockOutNotesAdmin"
                :placeholder="$t('workDay.clockInNotes')"
            />
            <div
                v-if="model.clockOutNotes">
              <small
                  v-text="$t('workDay.employeeNotes')"
              />
              <br />
              <span
                  v-text="model.clockOutNotes"
              />
            </div>
          </b-col>
        </b-row>
        <b-row
            id="autoPunchForLunch"
            align-h="center"
            class="mt-3">
          <b-col
              cols="*"
              align="center"
              class="workDay__container">
            <b-input-group>
              <b-form-checkbox
                  id="punchForLunchToggle"
                  switch
                  size="lg"
                  class="ml-auto mr-1"
                  v-model="autoPunchLunch"
              />
              <b-input-group-append>
                {{$t('workDay.autoPunchLunch')}}
              </b-input-group-append>
            </b-input-group>
            <div
                v-if="autoPunchLunch">
                {{$t('start')}}:
                <b-form-timepicker
                    v-model="autoPunchedLunch.start"
                />
                {{$t('end')}}:
                <b-form-timepicker
                    v-model="autoPunchedLunch.end"
                />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row
          id="controlButtons"
          class="mt-4">
        <b-col
            align="center"
            class="workDay__container">
          <b-btn
              size="lg"
              @click="cancelTimecardEntry()"
              variant="warning"
              class="mx-2">
            <b-icon-x-circle /> {{$t('cancel')}}
          </b-btn>
          <transition
              name="fade"
              mode="out-in">
            <b-btn
                size="lg"
                v-if="model.clockIn && activeView > 1"
                @click="saveTimecardEntry()"
                variant="primary"
                class="mx-2 px-4">
              {{addEntryLabel}}
              <b-icon-check />
            </b-btn>
          </transition>
        </b-col>
      </b-row>
    </div>
    <b-modal
        :id="`emailModal_${model._id}`"
        centered
        class="invoice__emailModal"
        size="lg"
        :title="$t('emailHeader')"
        header-class="invoice__emailModal__header"
        body-class="invoice__emailModal"
        footer-class="invoice__emailModal__footer"
        :modal-ok="$t('send')"
        @ok="sendReport()">
      <div>
        <label
            for="emailAddressToSend"
            id="emailModalAddressLabel"
            v-text="$t('emailSendTo')"
        />
        <b-form-input
            id="emailAddressToSend"
            name="email"
            type="text"
            v-model="email.to"
            class="invoice__emailModal__addressField"
        />
        <label
            for="emailSubjectLine"
            id="emailModalSubjectLabel"
            v-text="$t('emailSubject')"
        />
        <b-form-input
            id="emailSubjectLine"
            name="subject"
            type="text"
            v-model="email.subjectLine"
            class="invoice__emailModal__subjectLine"
        />
        <label
            for="emailModalMessageEntry"
            id="emailModalMessageLabel"
            v-text="$t('emailMessage')"
        />
        <b-form-textarea
            id="emailModalMessageEntry"
            rows="12"
            size="lg"
            name="email"
            type="text"
            v-model="email.message"
            class="invoice__emailModal__textField"
        />
      </div>
      <template v-slot:modal-footer>
        <b-btn
            v-text="$t('cancel')"
            size="sm"
            variant="info"
            @click="payrollReportCancel()"
        />
        <b-btn
            size="lg"
            variant="primary"
            @click="payrollReportSend()">
          <span
              v-text="$t('send')"
          />
        </b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import totalCalculators from '@/mixins/totalCalculators';
import DatePicker from '@/components/data/DatePicker';
import DisplayProjectInfo from '@/components/singlePurpose/DisplayWorkDayProjectInfo';

export default {
  title() {
    return `${this.$t('workDay.titles.timeSheetsAdmin')} | ${this.$t('project.brand')}`;
  },
  created() {
    if (this.employee) {
      this.activeEmployee = this.employee;
      this.tableType = 'byEmployee';
      const dayOfWeek = moment().format('e');
      let currentWeek;
      if (dayOfWeek > this.preferences.weekStartsOn) {
        currentWeek = moment().day(`${this.preferences.weekStartsOn}`);
      } else {
        currentWeek = moment().subtract(7, 'days').day(`${this.preferences.weekStartsOn}`);
      }
      this.changeStartDate(currentWeek.format('YYYY-MM-DD'));
      this.setEndDate();
    }
  },
  components: {
    DatePicker,
    DisplayProjectInfo,
  },
  props: [
    'timecards',
    'employees',
    'employee',
  ],
  mixins: [
    totalCalculators,
  ],
  computed: {
    addEntryLabel() {
      if (this.activeView === 0) {
        return this.$t('next');
      }
      return this.$t('workDay.addTimecardEntrySave');
    },
    initialLoad() {
      return this.$store.state.initialLoad;
    },
    startDateInput: {
      get() {
        return this.weekOf;
      },
      set(newVal) {
        this.changeStartDate(newVal);
      },
    },
    fields() {
      if (this.employee) {
        return [
          'date',
          'start',
          'end',
          'hours',
          'project',
        ];
      }
      if (this.tableType === 'byEmployee') {
        return [
          'date',
          'start',
          'end',
          'hours',
          'project',
          'buttons',
        ];
      }
      return [
        'date',
        'employee',
        'start',
        'end',
        'hours',
        'project',
        'buttons',
      ];
    },
    preferences() {
      return this.$store.getters.userPreferences;
    },
    shiftsWorked() {
      const timecards = [];
      let data = this.timecards;
      // Check for overlapping logins
      data = [...data].sort((a, b) => ((a.clockIn > b.clockIn) ? 1 : -1));
      data.forEach((_timecard) => {
        const timecard = _timecard;
        timecard.conflictIn = false;
        timecard.conflictOut = false;
        timecard.noLunch = false;
        const start = timecard.clockIn;
        const end = timecard.clockOut;
        if (moment(end).diff(start, 'minutes') > 300) {
          timecard.noLunch = true;
        }
        timecards.forEach((otherCard) => {
          if (otherCard.employee === timecard.employee) {
            if (moment(start).format('L') === moment(otherCard.clockIn).format('L')) {
              // Two shifts on same day
              timecard.hideDate = true;
              if (moment(start) < moment(otherCard.clockIn) || moment(start) < moment(otherCard.clockOut)) {
                timecard.conflictIn = true;
              }
              if (moment(end) && (moment(end) < moment(otherCard.clockOut) || moment(end) < moment(otherCard.clockIn))) {
                timecard.conflictOut = true;
              }
            }
          }
        });
        if ((this.dateStart && (new Date(moment(this.dateStart).format('L')) <= new Date(moment(start).format('L'))))
        && (this.dateEnd && (new Date(moment(this.dateEnd).format('L')) >= new Date(moment(start).format('L'))))) {
          timecards.push(timecard);
        }
      });
      if (this.tableType === 'byEmployee') {
        if (this.activeEmployee._id) {
          // Add Totals Row
          timecards.push({
            _id: 'totals',
            employee: this.activeEmployee._id,
            total: this.calculateHoursWorked(timecards),
          });
        }
        return timecards.filter(tc => tc.employee === this.activeEmployee._id);
      }
      return timecards;
    },
    shiftsWorkedPaginated() {
      return this.shiftsWorked.slice(((this.page - 1) * this.perPage), this.page * this.perPage);
    },
    viewType() {
      switch (this.tableType) {
        default: {
          return null;
        }
        case ('byEmployee'): {
          return this.$t('workDay.tables.byEmployee');
        }
        case ('recent'): {
          return this.$t('workDay.tables.recent');
        }
      }
    },
  },
  data() {
    return {
      activeEmployee: {},
      activeView: 0,
      addingTimecardEntry: false,
      autoPunchLunch: false,
      autoPunchedLunch: {
        start: null,
        end: null,
      },
      currentPage: 1,
      dateEnd: moment().format('YYYY-MM-DD'),
      dateStart: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      deleteRequest: null,
      displayLogs: [],
      email: {
        customerId: null,
        invoiceId: null,
        to: null,
        subjectLine: null,
        message: null,
        from: null,
        replyTo: null,
      },
      hideTable: false,
      hoverEmployee: null,
      model: {
        clockInNotes: null,
        clockOutNotes: null,
        clockInNotesAdmin: null,
        clockOutNotesAdmin: null,
        clockIn: null,
        clockOut: null,
        adminLogin: true,
        log: [],
      },
      modelDefault: {
        clockInNotes: null,
        clockOutNotes: null,
        clockInNotesAdmin: null,
        clockOutNotesAdmin: null,
        clockIn: null,
        clockOut: null,
        adminLogin: true,
        log: [],
      },
      page: 1,
      perPage: 10,
      quickEdit: null,
      tableOptions: [
        {
          key: 'byEmployee',
          text: this.$t('workDay.tables.byEmployee'),
        },
        {
          key: 'recent',
          text: this.$t('workDay.tables.recent'),
        },
      ],
      tableType: this.employees ? 'recent' : 'byEmployee',
      weekOf: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    };
  },
  methods: {
    addTimecardEntry() {
      this.addingTimecardEntry = true;
      if (this.preferences.autoStartTime) {
        this.model.clockIn = this.preferences.autoStartTime;
      }
      if (this.preferences.autoEndTime) {
        this.model.clockOut = this.preferences.autoEndTime;
      }
    },
    addTimecardNextButton() {
      this.activeView += 1;
    },
    calculateHoursWorked(entries) {
      let hours = 0;
      let overtime = 0;
      let doubletime = 0;
      const timecards = [...entries].sort((a, b) => (a.clockIn > b.clockIn ? 1 : -1));
      const weeksWorked = [];
      // let currentWeek = 0;
      for (let a = 0; a < timecards.length; a += 1) {
        // Organize shifts by week & day
        const card = timecards[a];
        if (card.clockOut) {
          const currentDay = moment(card.clockIn).format('L');
          const dayOfWeek = moment(card.clockIn).format('e');
          let currentWeek;
          if (dayOfWeek > this.preferences.weekStartsOn) {
            currentWeek = moment(card.clockIn).day(`${this.preferences.weekStartsOn}`).format('L');
          } else {
            currentWeek = moment(card.clockIn).subtract(1, 'week').day(`${this.preferences.weekStartsOn}`).format('L');
          }
          let weeksIndex = weeksWorked.findIndex(w => w.starting === currentWeek);
          if (weeksIndex === -1) {
            weeksWorked.push({
              starting: currentWeek,
              hours: 0,
              overtime: 0,
              days: [],
            });
            weeksIndex = weeksWorked.findIndex(w => w.starting === currentWeek);
          }
          let daysIndex = weeksWorked[weeksIndex].days.findIndex(d => d.date === currentDay);
          if (daysIndex === -1) {
            weeksWorked[weeksIndex].days.push({
              date: currentDay,
              hours: 0,
              overtime: 0,
              doubletime: 0,
            });
            daysIndex = weeksWorked[weeksIndex].days.findIndex(d => d.date === currentDay);
          }
          const hoursWorked = Number((moment(card.clockOut).diff(card.clockIn, 'minutes') / 60).toFixed(2));
          weeksWorked[weeksIndex].hours += hoursWorked;
          weeksWorked[weeksIndex].days[daysIndex].hours += hoursWorked;
        }
      }
      for (let w = 0; w < weeksWorked.length; w += 1) {
        // After processing raw hours work, calculate overtime occuring in each week
        // Using CA rules (more than 8 hours per day, more than 40 hours per week)
        // More than 12 hours is doubletime
        const week = weeksWorked[w];
        if (week.hours > 40) {
          week.overtime = week.hours - 40;
        }
        for (let d = 0; d < week.days.length; d += 1) {
          const day = week.days[d];
          if (d === 6) {
            // ie employee has worked 7 consecutive days. First 8 are overtime, subsequent hours are doubletime
            if (day.hours > 8) {
              day.doubletime = day.hours - 8;
              day.overtime = 8;
            }
          } else if (day.hours > 12) {
            day.overtime = 4;
            day.doubletime = day.hours - 12;
          } else if (day.hours > 8) {
            day.overtime = day.hours - 8;
          }
        }
      }
      // Total all overtime for selected date range
      weeksWorked.forEach((week) => {
        let overtimeByDay = 0;
        week.days.forEach((day) => {
          if (day.overtime) {
            overtime += day.overtime;
            overtimeByDay += day.overtime;
          }
          if (day.doubletime) {
            doubletime += day.doubletime;
            overtimeByDay += day.doubletime; // Must count doubletime in the overtime count, for comparing it against weekly OT total
          }
          hours += day.hours;
        });
        if (week.overtime > overtimeByDay) {
          overtime += (week.overtime - overtimeByDay);
        }
      });
      hours = Number(hours).toFixed(2);
      overtime = Number(overtime).toFixed(2);
      doubletime = Number(doubletime).toFixed(2);
      return { hours, overtime, doubletime };
    },
    cancelTimecardEntry() {
      this.model = { ...this.modelDefault };
      this.activeView = 0;
      this.addingTimecardEntry = false;
      this.hoverEmployee = null;
    },
    changeStartDate(input) {
      if (this.tableType === 'byEmployee') {
        if (moment(input).format('e') >= this.preferences.weekStartsOn) {
          this.dateStart = moment(input).day(this.preferences.weekStartsOn).format('YYYY-MM-DD');
        } else {
          this.dateStart = moment(input).subtract(1, 'week').day(this.preferences.weekStartsOn).format('YYYY-MM-DD');
        }
        this.setEndDate();
      } else {
        this.dateStart = input;
      }
      if (this.dateStart !== this.weekOf) {
        this.weekOf = this.dateStart;
      }
    },
    changeTableView(type) {
      this.hideTable = true;
      this.tableType = type;
      if (this.tableType === 'byEmployee') {
        this.changeStartDate(moment().day(this.preferences.weekStartsOn).format('YYYY-MM-DD'));
        this.setEndDate();
      }
      setTimeout(() => { // This creates a transition effect when table data switches
        this.hideTable = false;
      }, 150);
    },
    clearClockOut() {
      this.model.clockOut = null;
      this.model.clockOutNotes = null;
      this.model.clearClockOut = true;
    },
    clickEmployee(id) {
      this.model.employee = id;
      this.activeView += 1;
    },
    dateSelected(date) {
      // Selected a date while adding timecard entry
      this.model.date = date[0];
      this.activeView += 1;
    },
    displayEmployeeData(employeeId) {
      const fields = this.$store.state.account.employeesFields;
      let string = '';
      const employee = this.$store.getters.employee(employeeId);
      if (employee) {
        if (this.preferences.showEmployeeId && employee[fields.employeeId]) {
          string += `${employee[fields.employeeId]} - `;
        }
        if (employee[fields.name]) {
          string += employee[fields.name];
        }
      }
      return string;
    },
    displayHoursWorked(signIn) {
      if (signIn.total) {
        if (signIn.overtime === 0) {
          return `${this.$t('workDay.periodTotal')}
            <h4><b>${signIn.total.hours}</b> ${this.$t('hours')}</h4>`;
        }
        if (signIn.doubletime === 0) {
          return `${this.$t('workDay.periodTotal')}
            <h4>${this.$t('workDay.hoursTotal')}:<b>${signIn.total.hours}</b></h4>
            <h4>${this.$t('workDay.hoursRegular')}:<b>${signIn.total.hours - signIn.total.overtime}</b></h4>
            <h4>${this.$t('workDay.hoursOvertime')}: <b>${signIn.total.overtime}</b></h4>`;
        }
        return `${this.$t('workDay.periodTotal')}
          <h4>
            ${this.$t('workDay.hoursTotal')}:
            <b>
              ${signIn.total.hours}
            </b>
          </h4>
          <h4>
            ${this.$t('workDay.hoursRegular')}:
            <b>
              ${Number(parseFloat(signIn.total.hours - (Number(signIn.total.overtime) + Number(signIn.total.doubletime)))).toFixed(2)}
            </b>
          </h4>
          <h4>
            ${this.$t('workDay.hoursOvertime')}:
            <b>
              ${signIn.total.overtime}
            </b>
          </h4>
          <h4>
            ${this.$t('workDay.hoursDoubletime')}:
            <b>
              ${signIn.total.doubletime}
            </b>
          </h4>
        `;
      }
      if (!signIn.clockOut) {
        // Return 0
        return `<b>${(moment(signIn.clockIn).diff(signIn.clockIn, 'minutes') / 60).toFixed(2)}</b> ${this.$t('hours')}`;
      }
      return `<b>${(moment(signIn.clockOut).diff(signIn.clockIn, 'minutes') / 60).toFixed(2)}</b> ${this.$t('hours')}`;
    },
    displayLogClick(id) {
      const index = this.displayLogs.indexOf(id);
      if (index > -1) {
        this.displayLogs.splice(index, 1);
      } else {
        this.displayLogs.push(id);
      }
    },
    deleteTimecardEntryReq(id) {
      this.deleteRequest = id;
    },
    deleteTimecardEntry(id) {
      this.$store.dispatch('removeTimecardEntry', id);
    },
    editTimecardEntry(id) {
      const timecard = this.shiftsWorked.find(shift => shift._id === id);
      this.model._id = id;
      if (timecard.clockIn) {
        this.model.clockIn = moment(timecard.clockIn).format('HH:mm:ss');
      }
      if (timecard.clockOut) {
        this.model.clockOut = moment(timecard.clockOut).format('HH:mm:ss');
      }
      if (timecard.clockInNotes) {
        this.model.clockInNotes = timecard.clockInNotes;
      }
      if (timecard.clockOutNotes) {
        this.model.clockOutNotes = timecard.clockOutNotes;
      }
      if (timecard.clockInNotesAdmin) {
        this.model.clockInNotesAdmin = timecard.clockInNotesAdmin;
      }
      if (timecard.clockOutNotesAdmin) {
        this.model.clockOutNotesAdmin = timecard.clockOutNotesAdmin;
      }
      this.quickEdit = id;
    },
    editTimecardEntryConfirm() {
      this.updateTimecardEntry();
      this.model = this.modelDefault;
      this.quickEdit = null;
    },
    moveWeek(forward) {
      let week;
      if (forward) {
        week = moment(this.weekOf).add(1, 'week').format('YYYY-MM-DD');
      } else {
        week = moment(this.weekOf).subtract(1, 'week').format('YYYY-MM-DD');
      }
      this.changeStartDate(week);
      this.setEndDate();
    },
    runPayrollReport() {
      this.email.to = this.preferences.payrollEmail;
      this.email.subjectLine = this.payrollReportSubject();
      this.email.replyTo = this.preferences.payrollEmailReplyTo;
      this.email.from = this.preferences.defaultEmailFrom;
      this.email.message = this.payrollReportText();
      this.$root.$emit('bv::show::modal', `emailModal_${this.model._id}`);
    },
    payrollReportCancel() {
      this.$bvModal.hide(`emailModal_${this.model._id}`);
    },
    payrollReportSubject() {
      let subject = '';
      if (this.preferences.payrollEmailSubjectLine) {
        subject += this.preferences.payrollEmailSubjectLine;
        subject += '  ';
      }
      subject += moment(this.dateStart).format('L');
      subject += ' - ';
      subject += moment(this.dateEnd).format('L');
      return subject;
    },
    payrollReportText() {
      let message = '';
      if (this.preferences.payrollEmailMessage) {
        message = this.preferences.payrollEmailMessage;
        message += '\n';
      }
      const employees = [];
      this.shiftsWorked.forEach((shift) => {
        const index = employees.findIndex(e => e._id === shift.employee);
        if (index === -1) {
          employees.push({ _id: shift.employee, timecards: [shift] });
        } else {
          employees[index].timecards.push(shift);
        }
      });
      employees.forEach((employee) => {
        if (message) {
          message += '\n\n';
        }
        const employeeText = 'David';
        message += employeeText;
        message += '\n';
        const time = this.calculateHoursWorked(employee.timecards);
        if (time.overtime > 0) {
          message += 'Regular: ';
          message += (time.hours - time.overtime - time.doubletime);
          message += '\nOvertime: ';
          message += time.overtime;
          if (time.doubletime > 0) {
            message += '\nDoubletime: ';
            message += time.doubletime;
          }
          message += '\n';
          message += 'TOTAL HOURS: ';
        } else {
          message += 'Hours: ';
        }
        message += time.hours;
      });
      return message;
    },
    payrollReportSend() {
      this.$store.dispatch('sendPayrollReport', this.email);
      this.$bvModal.hide(`emailModal_${this.model._id}`);
    },
    saveTimecardEntry() {
      const Start = this.setTime(this.model.date, this.model.clockIn);
      this.model.clockIn = new Date(Start);
      if (this.model.clockOut) {
        const End = this.setTime(this.model.date, this.model.clockOut);
        this.model.clockOut = new Date(End);
      }
      if (this.autoPunchLunch) {
        const start = this.setTime(this.model.date, this.autoPunchedLunch.start);
        const end = this.setTime(this.model.date, this.autoPunchedLunch.end);
        this.model.autoPunchedLunch = {
          start: new Date(start),
          end: new Date(end),
        };
        this.autoPunchedLunch = {
          start: null,
          end: null,
        };
        this.autoPunchLunch = false;
      }
      this.model.log.push({
        _id: this.$guid(),
        text: [
          this.$t('workDay.timecardLog.adminClockIn'),
        ],
        date: new Date(),
      });
      this.$store.dispatch('clockIn', this.model);
      this.model = this.modelDefault;
      this.activeView = 0;
      this.addingTimecardEntry = false;
    },
    selectActiveEmployee(employee) {
      this.activeEmployee = employee;
      this.hideTable = true;
      setTimeout(() => {
        this.hideTable = false;
      }, 150);
    },
    setEndDate() {
      this.dateEnd = moment(this.dateStart).add(6, 'days').format('YYYY-MM-DD');
    },
    updateTimecardEntry() {
      const shift = this.$store.state.data.workDayTimecards.find(_shift => _shift._id === this.model._id);
      let Start;
      const log = {
        _id: this.$guid(),
        text: [
          this.$t('workDay.timecardLog.adminUpdate'),
        ],
        date: new Date(),
      };
      shift.adminLogin = true;
      if (shift.clockIn) {
        log.text.push(`${this.$t('workDay.timecardLog.shiftStartOriginal')} ${moment(shift.clockIn).format('hh:mma')}`);
      }
      if (shift.clockOut) {
        log.text.push(`${this.$t('workDay.timecardLog.shiftEndOriginal')} ${moment(shift.clockOut).format('hh:mma')}`);
      }
      if (this.model.clearClockIn) {
        shift.clockIn = null;
        shift.clockInNotes = null;
        log.text.push(`${this.$t('workDay.timecardLog.clearClockIn')}`);
      } else if (this.model.clockIn) {
        Start = this.setTime(shift.clockIn, this.model.clockIn);
        shift.clockIn = new Date(Start);
        shift.clockInNotes = this.model.clockInNotesAdmin;
        log.text.push(`${this.$t('workDay.timecardLog.shiftStartUpdated')} ${moment(shift.clockIn).format('hh:mma')}`);
      }
      if (this.model.clearClockOut) {
        shift.clockOut = null;
        shift.clockOutNotes = null;
        log.text.push(`${this.$t('workDay.timecardLog.clearClockIn')}`);
      } else if (this.model.clockOut) {
        const End = this.setTime(shift.clockOut ? moment(shift.clockOut) : moment(shift.clockIn), this.model.clockOut);
        if (Start < End) {
          shift.clockOut = new Date(End);
        } else {
          // This is to compensate for when a shift ends after midnight, but implementation is buggy.
          // TODO - Improve this. Not a high priority at the moment, unless deploying to clients with regular overnight shifts.
          End.add(1, 'days');
          shift.clockOut = new Date(End);
        }
        shift.clockOutNotes = this.model.clockOutNotesAdmin;
        log.text.push(`${this.$t('workDay.timecardLog.shiftEndUpdated')} ${moment(shift.clockOut).format('hh:mma')}`);
      }
      shift._updated = new Date();
      shift.log.push(log);
      this.$store.dispatch('updateTimecardEntry', shift);
      this.model = this.model.default;
    },
    warningCheck(signIn) {
      // Return True if cell should be highlighted for review
      if (signIn.noLunch) {
        return true;
      }
      if (signIn.total) {
        return false;
      }
      if (!signIn.clockOut) {
        return true;
      }
      return (moment(signIn.clockOut).diff(signIn.clockIn, 'minutes') > 480 || moment(signIn.clockOut).diff(signIn.clockIn, 'minutes') < 1);
    },
  },
  watch: {
    autoPunchLunch(newVal) {
      if (newVal && this.model.date && this.model.clockIn) {
        const clockIn = this.setTime(this.model.date, this.model.clockIn);
        this.autoPunchedLunch.start = moment(clockIn).add(240, 'minutes').format('HH:mm:ss');
        this.autoPunchedLunch.end = moment(clockIn).add(270, 'minutes').format('HH:mm:ss');
      }
    },
    initialLoad(newVal) {
      if (newVal) {
        const dayOfWeek = moment().format('e');
        if (dayOfWeek > this.preferences.weekStartsOn) {
          this.weekOf = moment().day(`${this.preferences.weekStartsOn}`).format('YYYY-MM-DD');
        } else {
          this.weekOf = moment().subtract(1, 'week').day(`${this.preferences.weekStartsOn}`).format('YYYY-MM-DD');
        }
        this.changeStartDate(this.weekOf);
        this.setEndDate();
      }
    },
  },
};
</script>
