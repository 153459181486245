import moment from 'moment';
import { ses } from '@/store/aws';

export default {
  data() {
    return {
      autoSaveCancel: false,
      autoSaveTimer: null,
      autoSaveTiming: [
        2.75,
        1500,
        4000,
      ],
      lastChanged: moment(),
      lastChangedBefore: moment(),
      lastSaved: moment(),
    };
  },
  computed: {
    allItems() {
      return this.model.lineItems;
    },
    dueDate() {
      if (this.model.dateDue) {
        return moment(this.model.dateDue);
      }
      return moment(this.model.date).add(this.preferences.defaultTerms, 'days');
    },
    expirationDate() {
      if (this.model.dateProposalExpires) {
        return moment(this.model.dateProposalExpires);
      }
      return moment(this.model.dateProposal).add(this.preferences.defaultTermsProposal, 'days');
    },
    invoiceNumber() {
      // Creates the next invoice number on new documents
      let prefs = { ...this.$store.state.preferences };
      if (this.customerDetails
        && this.customerDetails.preferences
        && this.customerDetails.preferences.masterNumbering === false) {
        prefs = {
          ...prefs,
          ...this.customerDetails.preferences,
        };
      }
      let prefix = null;

      if (prefs.defaultPrefix) {
        prefix = prefs.defaultPrefix;
      // } else {
      //   prefix = this.$t('invoice.defaultType');
      }
      const leadingZeros = '0000000000';
      let docNumber;
      if (!prefs.masterNumbering && this.customerDetails && this.customerDetails.preferences) {
        docNumber = this.customerDetails.preferences.documentNumber * 1;
      } else {
        docNumber = this.$store.state.preferences.documentNumber * 1;
      }
      if (!docNumber || typeof docNumber !== 'number') {
        docNumber = 1;
      }
      let size = prefs.documentNumberLength;
      if (size === 1 && docNumber > 9) {
        size += 1;
      }
      if (size === 2 && docNumber > 99) {
        size += 1;
      }
      if (size === 3 && docNumber > 999) {
        size += 1;
      }
      if (size === 4 && docNumber > 9999) {
        size += 1;
      }
      if (size === 5 && docNumber > 99999) {
        size += 1;
      }
      if (size === 6 && docNumber > 999999) {
        size += 1;
      }
      if (size === 7 && docNumber > 9999999) {
        size += 1;
      }
      if (size === 8 && docNumber > 99999999) {
        size += 1;
      }
      if (size === 9 && docNumber > 999999999) {
        size += 1;
      }
      return `${prefix}-${(leadingZeros + docNumber).substr(-size)}`;
    },
    forceAutosave: {
      get() {
        return this.$store.state.autosaveNow;
      },
      set(newVal) {
        this.$store.state.autosaveNow = newVal;
      },
    },
    readyToRun() {
      return this.$store.state.initialLoad;
    },
    totalOutstanding() {
      let total = 0;
      const outstandingToClear = [];
      for (let a = 0; a < this.model.outstandingItems.length; a += 1) {
        const outstanding = this.$store.getters.invoice(this.model.outstandingItems[a].originalId);
        const invTotal = outstanding.totalCharges - outstanding.totalPayments;
        if (invTotal === 0) {
          outstandingToClear.push(this.model.outstandingItems[a]._id);
        }
        total += invTotal;
      }
      // const total = this.paymentsCalculator(items) * 1;
      return total;
    },
    totalOutstandingDisplay() {
      return this.displayAmount(this.totalOutstanding);
    },
  },
  methods: {
    accountDue(customer, returnType) {
      let charges = 0;
      let payments = 0;
      const invoices = customer.invoices;
      function addTo(c, p) {
        charges += c;
        payments += p;
      }
      invoices.forEach((invoiceId) => {
        const inv = this.$store.getters.invoice(invoiceId);
        addTo(inv.totalCharges, inv.totalPayments);
      });
      if (returnType === 'display') {
        return this.displayAmount(charges - payments);
      }
      if (returnType === 'raw') {
        return charges - payments;
      }
      return 0;
    },
    addressTemplate(type) {
      if (type === 'international') {
        return {
          attn: '',
          street: '',
          streetSecond: null,
          streetSecondShow: false,
          city: null,
          country: null,
          postal: null,
          notes: null,
        };
      }
      // if (type === 'usaFormat') {
      return {
        attn: '',
        street: '',
        streetSecond: null,
        streetSecondShow: false,
        city: null,
        state: null,
        zip: null,
        notes: null,
      };
      // }
    },
    calendarDate(date) {
      return moment(date).calendar();
    },
    currencySymbol(type) {
      let symbol;
      if (type === 'usd') {
        symbol = '$';
      }
      if (type === 'eur') {
        symbol = '€';
      }
      return symbol;
    },
    customerIdentifier({
      name, contact, email, address, phone, fax,
    }) {
      let identifier = this.$t('invoice.customerNoId');
      let needToKeepGoing = true;
      if (name) {
        identifier = name;
        needToKeepGoing = false;
      }
      if (contact && needToKeepGoing) {
        identifier = contact;
        needToKeepGoing = false;
      }
      if (email && needToKeepGoing) {
        identifier = email;
        needToKeepGoing = false;
      }
      if (address
        && address.street
        && needToKeepGoing) {
        identifier = address.street;
        needToKeepGoing = false;
      }
      if (phone
        && needToKeepGoing) {
        identifier = this.displayPhone(phone);
        needToKeepGoing = false;
      }
      if (fax
        && needToKeepGoing) {
        identifier = this.displayPhone(fax);
      }
      if (needToKeepGoing) {
        identifier = this.$t('customer.noName');
        needToKeepGoing = false;
      }
      return identifier;
    },
    dateDisplay(model) {
      if (moment().diff(moment(model), 'months') > 6) {
        return moment(model).format('MMM Do YYYY');
      }
      return moment(model).format('MMM Do');
    },
    dateDisplayWithTime(model) {
      if (moment().diff(moment(model), 'months') > 6) {
        return moment(model).format('MMM Do YYYY h:mma');
      }
      return moment(model).format('l h:mma');
    },
    dateSince(date) {
      return moment(date).calendar();
    },
    dateOfInvoice({ date, dateSent }) {
      if (this.preferences.dueDateIsAutomatic) {
        if (dateSent) {
          return moment(dateSent).format('l');
        }
        return moment().format('l');
      }
      return moment(date).format('l');
    },
    dateReturn(model) {
      if (model.status === 'proposal' || model.status === 'proposalSent') {
        if (model.dateProposalSent) {
          return moment(model.dateProposalSent).format();
        }
        if (model.dateUpdated) {
          return moment(model.dateUpdated).format();
        }
        if (model.dateProposal) {
          return moment(model.dateProposal).format();
        }
        return moment(model.dateCreated).format();
      }
      if (model.dateSent) {
        return moment(model.dateSent).format();
      }
      if (model.dateUpdated) {
        return moment(model.dateUpdated).format();
      }
      if (model.date) {
        return moment(model.date).format();
      }
      return moment(model.dateCreated).format();
    },
    dateReturnType(model) {
      if ((model.status === 'proposal' || model.status === 'proposalSent') && model.dateProposalSent) {
        return this.$t('invoice.dateSentProposal');
      }
      if (model.dateSent && (model.status === 'sent' || model.status === 'pastDue')) {
        return this.$t('invoice.dateSent');
      }
      if ((model.date && (model.date !== model.dateCreated)) || model.dateUpdated > model.date) {
        return this.$t('invoice.dateUpdated');
      }
      return this.$t('invoice.dateCreated');
    },
    discountCalculator(items) {
      let discounts = 0;
      for (let i = 0; i < items.length; i += 1) {
        if (items[i].price < 0) {
          discounts += this.itemPrice(items[i]);
        }
      }
      return discounts;
    },
    displayAddress(data, display) {
      switch (display) {
        default:
        case ('whole'): {
          let address = '';
          const ad = data.address;
          if (ad.street) {
            address = ad.street;
          }
          if (ad.streetSecond && ad.streetSecondShow) {
            address += `<br /><small>${ad.streetSecond}</small>`;
          }
          if (address && (ad.city || ad.state || ad.zip || ad.country || ad.postal)) {
            address += '<br />';
          }
          if (ad.city) {
            address += ad.city;
          }
          if (ad.city && (ad.state || ad.zip || ad.country || ad.postal)) {
            address += '<small>,</small> ';
          }
          if (data.type === 'usaFormat') {
            address += `${ad.state ? `<small>${ad.state}</small>` : ''} ${ad.zip ? `<small>${ad.zip}</small>` : ''}`;
          } else {
            address += `${ad.country ? `<small>${ad.country}</small>` : ''} ${ad.postal ? `<small>${ad.postal}</small>` : ''}`;
          }
          return address;
        }
        case ('first'): {
          if (!data.address.streetSecondShow || !data.address.streetSecond) {
            return data.address.street;
          }
          return `${data.address.street}<br /> ${data.address.streetSecond}`;
        }
        case ('cityState'): {
          const ad = data.address;
          if (ad.city && ad.state) {
            return `${ad.city}, ${ad.state}`;
          }
          if (ad.city && ad.country) {
            return `${ad.city}, ${ad.country}`;
          }
          if (ad.city) {
            return ad.city;
          }
          if (ad.state) {
            return ad.state;
          }
          return null;
        }
        case ('state'): {
          if (data.address.state) {
            return data.address.state;
          }
          return null;
        }
        case ('zip'): {
          if (data.address.zip) {
            return data.address.zip;
          }
          if (data.address.postal) {
            return data.address.postal;
          }
          return null;
        }
        case ('country'): {
          if (data.type === 'usaFormat') {
            return 'USA';
          }
          if (data.address.country) {
            return data.address.country;
          }
          return null;
        }
        case ('cityCountry'): {
          const ad = data.address;
          let address = '';
          if (data.type === 'usaFormat') {
            if (ad.city) {
              address += ad.city;
            }
            if (ad.state) {
              address += ', ';
              address += ad.state;
            }
            if (address) {
              address += ' ';
            }
            address += this.$t('countries.usa');
            return address;
          }
          if (data.address.country) {
            let cityCountry = '';
            if (data.address.city) {
              cityCountry = data.address.city;
            }
            if (data.address.country) {
              if (cityCountry) {
                cityCountry += ', ';
              }
              cityCountry += data.address.country;
            }
            return cityCountry;
          }
          return null;
        }
      }
    },
    displayAmount(amt) {
      const amount = amt * 1;
      if (amount < 0) {
        return `-$${(amt * -1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      }
      if (amount === 0 || amount === null) {
        return '$0.00';
      }
      return `$${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    },
    displayCurrency(amt, type) {
      let symbol;
      if (type === 'usd') {
        symbol = '$';
      }
      if (type === 'eur') {
        symbol = '€';
      }
      const amount = amt * 1;
      if (amount < 0) {
        return `-${symbol}${(amt * -1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      }
      if (amount === 0) {
        return `${symbol}0.00`;
      }
      return `${symbol}${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    },
    displayDate(date) {
      return moment(date).format('L').substr(0, 10);
    },
    // displayDiscounts(items) {
    //   const discounts = this.discountCalculator(items) * -1;
    //   return `-$${discounts.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    // },
    displayDocumentNumberWithPrefix(model, preferences) {
      let docNumber;
      if (model.status === 'proposal') {
        if (preferences.defaultLabelProposal) {
          docNumber = preferences.defaultLabelProposal;
        }
      } else if (preferences.defaultLabelInvoice) {
        docNumber = preferences.defaultLabelInvoice;
      }
      if (docNumber) {
        docNumber += ' # ';
      } else {
        docNumber = '# ';
      }
      docNumber += model.number;
      return docNumber;
    },
    displayInvoiceTotal(charges, outstanding, payments) {
      return this.displayAmount((charges + outstanding) - payments);
    },
    displayInvoiceTotalAlt(charges, payments) {
      const totalCharges = this.totalCalculator(charges) * 1;
      const totalPayments = this.paymentsCalculator(payments) * 1;
      const total = totalCharges - totalPayments;
      return this.displayAmount(total);
    },
    displayItemPrice(item) {
      const price = Number(item.price);
      return this.displayAmount(price);
    },
    // displayWorkDayProjectInfo(projectData) {
    //   let string = '';
    //   if (projectData) {
    //     console.log(projectData);
    //     const project = projectData._id ? projectData : this.$store.getters.project(projectData);
    //     console.log(project);
    //     const customer = this.$store.getters.customer(project.customer);
    //     if (project.title) {
    //       string += `<h5>${project.title}</h5>`;
    //     }
    //     if (customer.name) {
    //       string += `<b>${customer.name}</b><br/>`;
    //     }
    //     if (!project.address) {
    //       string += this.displayAddress({ address: customer.address, type: this.$t('project.defaultAddressType') }, 'whole');
    //     } else {
    //       string += `${this.displayAddress({ address: project.address, type: this.$t('project.defaultAddressType') }, 'whole')}`;
    //     }
    //   }
    //   return string;
    // },
    displayPhone(number) {
      if (number) {
        return number.replace(/(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?(\S{1,})?/, (_, p1, p2, p3, p4, p5) => {
          let output = '';
          if (p1) { output = `(${p1}`; }
          if (p2) { output += `${p2})`; }
          if (p3) { output += ` ${p3}`; }
          if (p4) { output += `-${p4}`; }
          if (p5) { output += ` ${p5}`; }
          return output;
        });
      }
      return null;
    },
    displaySubtotal(items) {
      const subtotal = this.subtotalCalculator(items);
      return this.displayAmount(subtotal);
    },
    displayTime(date, time) {
      if (date && time) {
        let day = new Date(date).setHours(time.slice(0, 2), time.slice(3, 5));
        day = moment(day).format('h:mm a');
        return day;
      }
      if (date) {
        return moment(date).format('h:mm a');
      }
      return moment(new Date()).format('h:mm a');
      // return moment(new Date(time)).format('hh:mm a');
    },
    displayTotal(items, tax) {
      const total = this.totalCalculator(items, tax) * 1;
      return this.displayAmount(total);
    },
    displayTotalPayments(items) {
      const total = this.paymentsCalculator(items) * 1;
      return this.displayAmount(total);
    },
    displayTotalTax(items, tax) {
      const totalTax = this.totalTaxCalculator(items, tax);
      return this.displayAmount(totalTax);
    },
    dueDateCalc({ dateSent, dateDue, date }, format, bypass) {
      if (this.preferences.dueDateIsAutomatic && !bypass) {
        if (dateSent) {
          return moment(dateSent).add(this.preferences.defaultTerms, 'days').format(format);
        }
        return moment().add(this.preferences.defaultTerms, 'days').format(format);
      }
      if (dateDue) {
        return moment(dateDue).format(format);
      }
      return moment(date).add(this.preferences.defaultTerms, 'days').format(format);
    },
    dueDateCalcProposal({
      dateProposalSent,
      dateProposalExpires,
      dateProposal,
      dateCreated,
    }, format, bypass) {
      if (this.preferences.proposalExpirationDateIsAutomatic && !bypass) {
        if (dateProposalSent) {
          return moment(dateProposalSent).add(this.preferences.defaultTermsProposal, 'days').format(format);
        }
        return moment().add(this.preferences.defaultTermsProposal, 'days').format(format);
      }
      if (dateProposalExpires) {
        return moment(dateProposalExpires).format(format);
      }
      if (dateProposal) {
        return moment(dateProposal).add(this.preferences.defaultTermsProposal, 'days').format(format);
      }
      return moment(dateCreated).add(this.preferences.defaultTermsProposal, 'days').format(format);
    },
    dueDateInformal(model, bypass) {
      return this.dueDateCalc(model, 'MMM Do', bypass);
    },
    dueDateInformalPrint(model) {
      return this.dueDateCalc(model, 'MMMM Do');
    },
    dueDateInformalPrintProposal(model) {
      return this.dueDateCalcProposal(model, 'MMMM Do');
    },
    dueDateFormal(model) {
      return this.dueDateCalc(model, 'l');
    },
    dateString(date, humanReadable) {
      if (date && humanReadable) {
        return moment(new Date(date)).format('l');
      }
      if (date) {
        return moment(new Date(date)).format('YYYY-MM-DD');
      }
      return null;
    },
    formatPhoneForSns(phone) {
      let phoneFormatted = '';
      if (phone) {
        if (phone.length === 14) {
          phoneFormatted = '+1';
          phoneFormatted += phone[1];
          phoneFormatted += phone[2];
          phoneFormatted += phone[3];
          phoneFormatted += phone[6];
          phoneFormatted += phone[7];
          phoneFormatted += phone[8];
          phoneFormatted += phone[10];
          phoneFormatted += phone[11];
          phoneFormatted += phone[12];
          phoneFormatted += phone[13];
        }
        if (phone.length === 10) {
          phoneFormatted = `+1${phone}`;
        } else if (phone.length === 11 && phone[0] === 1) {
          phoneFormatted = `+${phone}`;
        } else if (phone.length === 12 && phone[0] === '+') {
          phoneFormatted = phone;
        }
      }
      return phoneFormatted;
    },
    isBlank(input) {
      return input === null || input.match(/^\s*$/) !== null;
    },
    itemPrice(item) {
      return (item.price * item.qty) * 1;
    },
    lineTotal(item, tax, rawData) {
      let qty = item.qty;
      let price = item.price;
      if (item.qty === (0 || null)) {
        qty = 1;
      }
      if (item.taxable && tax && tax.enable) {
        const totalTax = (price * tax.rate) * 0.01;
        price = (item.price * 1) + (totalTax * 1);
      }
      if (!rawData) {
        return this.displayAmount(price * qty);
      }
      return price * qty;
    },
    multiDocDue(invoices, returnType) {
      // Does not count proposal or hold items
      let charges = 0;
      let payments = 0;
      for (let z = 0; z < invoices.length; z += 1) {
        if (invoices[z].status !== 'proposal' && invoices[z].status !== 'hold') {
          let docCharges = 0;
          const tax = {
            enable: invoices[z].preferences.taxOption,
            rate: invoices[z].preferences.taxRate,
          };
          if (invoices[z].lineItems.length > 0) {
            for (let y = 0; y < invoices[z].lineItems.length; y += 1) {
              docCharges += this.lineTotal(invoices[z].lineItems[y], tax, true) * 1;
            }
          }
          let docPayments = 0;
          if (invoices[z].payments.length > 0) {
            for (let x = 0; x < invoices[z].payments.length; x += 1) {
              docPayments += invoices[z].payments[x].amount * 1;
            }
          }
          charges += docCharges;
          payments += docPayments;
        }
      }
      if (returnType === 'display') {
        return this.displayAmount(charges - payments);
      }
      if (returnType === 'raw') {
        return charges - payments;
      }
      return 0;
    },
    multiDocProposal(invoices, returnType) {
      let proposed = 0;
      for (let z = 0; z < invoices.length; z += 1) {
        if (invoices[z].status === 'proposal') {
          let docCharges = 0;
          const tax = {
            enable: invoices[z].preferences.taxOption,
            rate: invoices[z].preferences.taxRate,
          };
          if (invoices[z].lineItems.length > 0) {
            for (let y = 0; y < invoices[z].lineItems.length; y += 1) {
              docCharges += this.lineTotal(invoices[z].lineItems[y], tax, true) * 1;
            }
          }
          proposed += docCharges;
        }
      }
      if (returnType === 'display') {
        return this.displayAmount(proposed);
      }
      if (returnType === 'raw') {
        return proposed;
      }
      return 0;
    },
    multiDocTotal(invoices, returnType) {
      // Does not return proposal or hold items
      let charges = 0;
      for (let z = 0; z < invoices.length; z += 1) {
        if (invoices[z].status !== 'proposal' && invoices[z].status !== 'hold') {
          let docCharges = 0;
          const tax = {
            enable: invoices[z].preferences.taxOption,
            rate: invoices[z].preferences.taxRate,
          };
          if (invoices[z].lineItems.length > 0) {
            for (let y = 0; y < invoices[z].lineItems.length; y += 1) {
              docCharges += this.lineTotal(invoices[z].lineItems[y], tax, true) * 1;
            }
          }
          charges += docCharges;
        }
      }
      if (returnType === 'display') {
        return this.displayAmount(charges);
      }
      if (returnType === 'raw') {
        return charges;
      }
      return 0;
    },
    pastDueAmount(model) {
      return model.outstandingItems.length > 0
        ? this.subtotalCalculator(model.outstandingItems) : 0;
    },
    pastDueCalc() {
      let pastDue = null;
      let pastDueText = null;
      if (this.model.dateDue) {
        pastDue = moment().diff(this.model.dateDue, 'days');
      } else {
        const dueDate = moment(this.model.date).add(this.$store.state.preferences.defaultTerms, 'days');
        pastDue = moment().diff(dueDate, 'days');
      }
      if (pastDue > 1) {
        pastDueText = `(${this.$t('invoice.due')} ${pastDue} ${this.$t(
          'invoice.daysAgo',
        )})`;
      }
      if (pastDue === 1) {
        pastDueText = `(${this.$t('invoice.dueYesterday')})`;
      }
      if (pastDue === 0) {
        pastDueText = `(${this.$t('invoice.dueToday')})`;
      }
      return pastDueText;
    },
    paymentsCalculator(items) {
      let payments = 0;
      for (let i = 0; i < items.length; i += 1) {
        payments += items[i].amount * 1;
      }
      return payments;
    },
    outstandingCalculator(items) {
      let subtotal = 0;
      for (let i = 0; i < items.length; i += 1) {
        if (items[i].due > 0) {
          subtotal += (items[i].due * 1);
        }
      }
      return subtotal;
    },
    readyCheck() {
      setTimeout(() => {
        if (!this.readyToRun) {
          this.$bvToast.show('error-toast');
          const logData = {
            type: 'error',
            when: moment().format('l HH:mm'),
            project: this.$t('project.url'),
            where: this.$route.path,
            systemInfo: window.navigator,
            errorLog: this.$store.state.errors,
            toastData: 'Failed initial load',
          };
          this.$store.dispatch('sendLogToAdmin', logData);
        }
      }, 10000);
    },
    sanitizeFileKey(key) {
      return key.replace(/ /g, '+');
    },
    saveRequest() {
      this.$store.commit('AUTOSAVE_REQUEST');
      this.lastChangedBefore = this.lastChanged;
      this.lastChanged = moment();
    },
    searchCustomers(customers, term) {
      const results = [];
      const searchTerm = term.toLowerCase();
      for (let a = 0; a < customers.length; a += 1) {
        let match = false;
        if (customers[a].name) {
          if (customers[a].name.toLowerCase().includes(searchTerm)) {
            match = true;
          }
        }
        if (!match && customers[a].contact) {
          if (customers[a].contact.toLowerCase().includes(searchTerm)) {
            match = true;
          }
        }
        if (!match && customers[a].invoiceNumberPrefix) {
          if (customers[a].invoiceNumberPrefix.toLowerCase().includes(searchTerm)) {
            match = true;
          }
        }
        if (!match && customers[a].invoices.length) {
          // search invoice number
          for (let z = 0; z < customers[a].invoices.length; z += 1) {
            if (!match && this.$store.getters.invoice(customers[a].invoices[z]).number.toLowerCase().includes(searchTerm)) {
              match = true;
            }
          }
        }
        if (!match && customers[a].address.street) {
          if (customers[a].address.street.toLowerCase().includes(searchTerm)) {
            match = true;
          }
        }
        if (!match && customers[a].address.city) {
          if (customers[a].address.city.toLowerCase().includes(searchTerm)) {
            match = true;
          }
        }
        if (!match && customers[a].address.zip) {
          if (customers[a].address.zip.toLowerCase().includes(searchTerm)) {
            match = true;
          }
        }
        if (customers[a].addressForBilling) {
          if (!match && customers[a].billingAddress.street) {
            if (customers[a].billingAddress.street.toLowerCase().includes(searchTerm)) {
              match = true;
            }
          }
          if (!match && customers[a].billingAddress.city) {
            if (customers[a].billingAddress.city.toLowerCase().includes(searchTerm)) {
              match = true;
            }
          }
          if (!match && customers[a].billingAddress.zip) {
            if (customers[a].billingAddress.zip.toLowerCase().includes(searchTerm)) {
              match = true;
            }
          }
        }
        if (!match && customers[a].recurringLineItems.length > 0) {
          for (let z = 0; z < customers[a].recurringLineItems.length; z += 1) {
            if (!match && customers[a].recurringLineItems[z].item
                && customers[a].recurringLineItems[z].item.toLowerCase().includes(searchTerm)) {
              match = true;
            }
          }
        }
        if (!match && customers[a].notes.length > 0) {
          for (let z = 0; z < customers[a].notes.length; z += 1) {
            if (!match && customers[a].notes[z].text && customers[a].notes[z].text.toLowerCase().includes(searchTerm)) {
              match = true;
            }
          }
        }
        if (match) {
          results.push(customers[a]);
        }
      }
      return results;
    },
    sendSesEmail(req) {
      const params = {
        Destination: {
          BccAddresses: [
          ],
          CcAddresses: [
            this.$t('project.brandEmailNotifications'),
          ],
          ToAddresses: [
            req.to,
          ],
        },
        Message: {
          Body: {
            Html: {
              Charset: 'UTF-8',
              Data: req.msg,
            },
            Text: {
              Charset: 'UTF-8',
              Data: req.msg,
            },
          },
          Subject: {
            Charset: 'UTF-8',
            Data: req.subject,
          },
        },
        ReplyToAddresses: [
          this.$t('project.brandEmailReplyTo'),
        ],
        ReturnPath: '',
        ReturnPathArn: '',
        Source: this.$t('project.brandEmail'),
        SourceArn: '',
      };
      ses.sendEmail(params, (err /* data */) => {
        if (err) {
          console.log(err);
          return false;
        }
        return true;
      });
    },
    setTime(date, time) {
      // Return moment object with correct time
      let dateTime;
      if (time[2] !== ':') {
        dateTime = moment(time);
      } else {
        dateTime = moment(date);
        dateTime.hour(time.split(':')[0]);
        dateTime.minute(time.split(':')[1]);
        dateTime.second('00');
        dateTime.millisecond('0');
      }
      return dateTime;
    },
    subtotalCalculator(items) {
      let subtotal = 0;
      for (let i = 0; i < items.length; i += 1) {
        if (items[i].price > 0) {
          subtotal += this.itemPrice(items[i]);
        }
      }
      return subtotal;
    },
    titleCase(input) {
      if (input) {
        let title = input.replace(/([A-Z])/g, '$1').trim().toLowerCase();
        title = title.split(' ');
        for (let a = 0; a < title.length; a += 1) {
          title[a] = `${title[a].charAt(0).toUpperCase()}${title[a].slice(1)}`;
        }
        return title.filter(i => i.length).join(' ');
      }
      return '';
    },
    totalCalculator(items, tax) {
      const subtotal = this.subtotalCalculator(items);
      /* only captures line items with a negative value */
      const discounts = this.discountCalculator(items);
      const totalTax = this.totalTaxCalculator(items, tax);
      return subtotal + totalTax + discounts;
    },
    totalDue(charge, payment, tax) {
      const charges = this.totalCalculator(charge, tax);
      const payments = this.paymentsCalculator(payment);
      return charges - payments;
    },
    totalDueSimple(charges, payments) {
      return charges - payments;
    },
    totalTaxCalculator(items, { enable, rate }) {
      if (enable) {
        let totalTax = 0;
        for (let i = 0; i < items.length; i += 1) {
          if (items[i].taxable) {
            totalTax += (this.itemPrice(items[i]) * rate) * 0.01;
          }
        }
        return totalTax;
      }
      return 0;
    },
    totalOutstandingCalc(items, display) {
      // TODO - may be a good idea to add a routine that clears the outstanding items once the calculator determines the amount due is 0.
      let total = 0;
      for (let a = 0; a < items.length; a += 1) {
        const outstanding = this.$store.getters.invoice(items[a].originalId);
        const invTotal = outstanding.totalCharges - outstanding.totalPayments;
        total += invTotal;
      }
      if (display) {
        return this.displayAmount(total);
      }
      return total;
    },
    updateTotalCharges(charges, taxRate) {
      return this.totalCalculator(charges, taxRate);
    },
    updateTotalPayments(payments) {
      return this.paymentsCalculator(payments);
    },
  },
  watch: {
    forceAutosave(newVal) {
      console.log('forcing autosave');
      if (newVal) {
        this.autoSaveCancel = true;
        this.forceAutosave = false;
        this.saveNow();
      }
    },
    lastChanged() {
      const timer = this.autoSaveTiming;
      // Initiates an autosave when value is updated
      this.autoSaveTimer = setInterval(() => {
        if ((this.lastSaved < this.lastChanged)
          && ((this.lastChangedBefore < moment(this.lastChanged).subtract(timer[0], 'seconds'))
            || (this.lastChanged < moment().subtract(timer[0], 'seconds'))
          )) {
          this.lastSaved = moment();
          if (!this.autoSaveCancel) {
            this.saveNow();
          } else {
            clearInterval(this.autoSaveTimer);
            this.autoSaveCancel = false;
          }
        }
      }, timer[1]);
      // eslint-disable-next-line no-unused-expressions
      this.autoSaveTimer;
      setTimeout(() => {
        clearInterval(this.autoSaveTimer);
      }, timer[2]);
    },
  },
};
