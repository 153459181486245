<template>
  <span
      v-if="model"
      class="dataViewer__data"
      v-text="model.counter"
  />
</template>
<script>

export default {
  props: ['model'],
};
</script>
