<template>
  <div
      class="record__cell">
    <div
        v-if="fieldType === 'Address'"
        class="record__cell__address">
      <div
          v-if="!showQuickEntry">
        <display-address
            v-if="cellData"
            :addressDisplayType="schemaObj[field.key].displayType"
            :model="cellData"
        />
      </div>
      <div
          v-else
          class="record__cell__quickEdit__address">
        <label
            :for="`${field.key}-input`"
            class="form__label__small"
            v-text="addressesInCollection[0] === field.key && addressesInCollection.length > 1 ? field.default.labelMulti : field.label"
        />
        <small
            v-if="model[field.key].expanded && !field.default.expanded"
            v-text="`(${$t('collections.address.remove')})`"
            @click="model[field.key].expanded = !model[field.key].expanded"
            class="form__textButton"
        />
        <b-btn
            v-if="!model[field.key].expanded"
            @click="model[field.key].expanded = !model[field.key].expanded"
            v-text="`${$t('collections.address.add')} ${model[field.key].labelMulti}`"
        />
        <div
            v-if="model[field.key].expanded">
          <label
              :for="`${field.key}-streetEntry`"
              v-text="$t('collections.address.labels.street')"
              class="form__label__small ml-0 mr-auto"
          />
          <small
              v-if="!model[field.key].address.streetSecondShow"
              v-text="$t('collections.address.labels.addSecondStreet')"
              @click="model[field.key].address.streetSecondShow = !model[field.key].address.streetSecondShow"
              class="ml-4 mr-0 form__textButton"
          />
          <b-input-group
              class="form__input__small mx-auto">
            <b-form-input
                :id="`${field.key}-streetEntry`"
                v-model="model[field.key].address.street"
                class="form__input__small ml-auto"
                type="text"
            />
            <b-form-select
                v-model="model[field.key].type"
                :options="[
                  { text: $t('collections.address.formatUSA'), value: 'usaFormat' },
                  { text: $t('collections.address.formatInt'), value: 'international' }
                ]"
                @change="model[field.key].address = addressTemplates(model[field.key].type)"
                class="smallSelect p-1"
            />
          </b-input-group>
          <label
              v-text="$t('collections.address.labels.streetSecond')"
              v-if="model[field.key].address.streetSecondShow"
              :for="`${field.key}-streetSecondEntry`"
              class="form__label__small ml-0 mr-auto"
          />
          <small
              v-if="model[field.key].address.streetSecondShow"
              v-text="$t('collections.address.labels.removeSecondStreet')"
              @click="model[field.key].address.streetSecondShow = !model[field.key].address.streetSecondShow"
              class=" ml-4 mr-0 form__textButton"
          />
          <b-form-input
              :id="`${field.key}-streetSecondEntry`"
              v-if="model[field.key].address.streetSecondShow"
              v-model="model[field.key].address.streetSecond"
              class="form__input__small mx-auto"
              type="text"
          />
          <b-row
              v-if="model[field.key].type === 'usaFormat'">
            <b-col
                cols="auto"
                class="p-0 ml-auto mr-1">
              <label
                  v-text="$t('collections.address.labels.city')"
                  :for="`${field.key}-cityEntry`"
                  class="form__label__small ml-0 mr-auto"
              />
              <b-form-input
                  :id="`${field.key}-cityEntry`"
                  type="text"
                  v-model="model[field.key].address.city"
                  class="ml-auto form__input__small cityInput"
              />
            </b-col>
            <b-col
                cols="auto"
                class="p-0">
              <label
                  v-text="$t('collections.address.labels.state')"
                  :for="`${field.key}-stateEntry`"
                  class="form__label__small ml-0 mr-auto"
              />
              <b-form-input
                  :id="`${field.key}-stateEntry`"
                  type="text"
                  v-model="model[field.key].address.state"
                  class="form__input__small stateInput"
              />
            </b-col>
            <b-col
                cols="auto"
                class="p-0 mr-auto">
              <label
                  v-text="$t('collections.address.labels.zip')"
                  :for="`${field.key}-zipEntry`"
                  class="form__label__small ml-0 mr-auto"
              />
              <b-form-input
                  :id="`${field.key}-zipEntry`"
                  type="text"
                  v-model="model[field.key].address.zip"
                  @input="zipLookup(model[field.key].address.zip)"
                  class="form__input__small xsmallInput"
              />
            </b-col>
          </b-row>
          <div
              v-else>
            <label
                v-text="$t('collections.address.labels.city')"
                :for="`${field.key}-cityEntry`"
                class="form__label__small ml-0 mr-auto"
            />
            <b-form-input
                :id="`${field.key}-cityEntry`"
                type="text"
                v-model="model[field.key].address.city"
                class="form__input__small xl"
            />
            <b-row>
              <b-col
                  cols="auto">
                <label
                    v-text="$t('collections.address.labels.country')"
                    :for="`${field.key}-countryEntry`"
                    class="form__label__small ml-0 mr-auto"
                />
                <b-form-input
                    :id="`${field.key}-countryEntry`"
                    type="text"
                    v-model="model[field.key].address.country"
                    class="form__input__small large"
                />
              </b-col>
              <b-col
                  cols="auto">
                <label
                    v-text="$t('collections.address.labels.postal')"
                    :for="`${field.key}-postalEntry`"
                    class="form__label__small ml-0 mr-auto"
                />
                <b-form-input
                    :id="`${field.key}-postalEntry`"
                    type="text"
                    v-model="model[field.key].address.postal"
                    class="form__input__small medium"
                />
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <div
        v-else-if="fieldType === 'Color'"
        class="record__cell">
      <div
          v-if="!showQuickEntry">
        <display-color
            v-if="cellData"
            :model="cellData"
        />
      </div>
      <div
          v-else>
        <!-- <b-form-input
            id="backgroundColorSelection"
            type="color"
            debounce="500"
            v-model="model[field.key]"
            class="preferences__colorScheme__selector"
        /> -->
      </div>
    </div>
    <div
        v-else-if="fieldType === 'ColorBadge'"
        class="record__cell">
      <div
          v-if="!showQuickEntry">
        <display-colorbadge
            v-if="cellData"
            :model="cellData"
        />
      </div>
      <div
          v-else>
        <!-- <b-form-input
            id="backgroundColorSelection"
            type="color"
            debounce="500"
            v-model="model[field.key]"
            class="preferences__colorScheme__selector"
        /> -->
      </div>
    </div>
    <div
        v-else-if="fieldType === 'Email'"
        class="record__cell__email">
      <div
          v-if="!showQuickEntry">
        <display-email
            v-if="cellData"
            :model="cellData"
        />
      </div>
      <div
          v-else>
        <b-form-input
            :id="`${field.key}-input`"
            class="record__cell__quickEdit"
            type="text"
            v-model="model[field.key]"
        />
      </div>
    </div>
    <div
        v-else-if="fieldType === 'Inventory'"
        class="record__cell__inventory">
      <div
          v-if="!showQuickEntry">
        <display-inventory
            :model="cellData"
        />
      </div>
      <b-row
          v-else>
          <b-col>
            <b-btn
                @click="cellData.counter = Number(cellData.counter) + 1"
                class="record__cell__counterButton">
              <b-icon-arrow-up />
            </b-btn>
            <b-form-input
                :id="`${this.field.key}-input`"
                v-model.number="cellData.counter"
                class="form__input"
                type="number"
            />
            <b-btn
                @click="cellData.counter = Number(cellData.counter) - 1"
                class="record__cell__counterButton">
              <b-icon-arrow-down />
            </b-btn>
          </b-col>
      </b-row>
    </div>
    <div
        v-else-if="fieldType === 'Link'"
        class="record__cell__linkedData">
      <div>
        <!-- Selected Links Display -->
        <b-row
            v-for="(linkId, index) in model[field.key].linksChosen"
            :class="index % 2 !== 0 ? 'record__cell__linkedData__evenRow' : 'record__cell__linkedData__row'"
            @mouseover="hoverLinkedItem = linkId"
            @mouseleave="hoverLinkedItem = null"
            :key="linkId">
          <b-col
              class="record__cell__linkedData__id__container"
              cols="*">
            <b-btn
                id="seeDataLink"
                v-if="hovered && hoverLinkedItem === linkId"
                @click="seeDataLink(schemaObj[field.key].default.linkTo, linkId)"
                variant="tertiary"
                :aria-label="$t('aria.view')"
                size="sm"
                class="mr-auto ml-1">
              <b-icon-search
                  scale="0.8"
              />
            </b-btn>
            <div
                v-if="linkedCollection(schemaObj[field.key].default.linkTo).idType === 'guid'
                  || linkedCollection(schemaObj[field.key].default.linkTo).idType === 'humanId'">
              <span
                  :class="linkedCollection(schemaObj[field.key].default.linkTo).idType === 'humanId'
                    ? 'record__cell__linkedData__id' : 'record__cell__linkedData__id smallText'"
                  v-text="linkedCollection(schemaObj[field.key].default.linkTo).idType === 'humanId'
                    ? Object.entries(linkedRecord([ field.key, { default: schemaObj[field.key].default } ], linkId))[0][1].humanId
                    : Object.entries(linkedRecord([ field.key, { default: schemaObj[field.key].default } ], linkId))[0][0]"
              />
              <br
                  v-if="showQuickEntry"
              />
              <small
                  v-if="showQuickEntry"
                  v-text="$t('sys.linkQuickEditNotAvailable')"
                  class="mx-auto"
              />
            </div>
          </b-col>
          <b-col
              class="my-auto pb-1 ml-1 mr-auto record__cell__linkedData__container"
              cols="auto">
            <div
                v-for="linkedField in Object.entries(
                  linkedRecord([ field.key, { default: schemaObj[field.key].default } ], linkId))"
                :key="linkedField[0]">
              <data-viewer
                  :addressDisplayType="schemaObj[field.key] && schemaObj[field.key].default.linkedFields.find(i => i._id === linkedField[0])
                    ? schemaObj[field.key].default.linkedFields.find(i => i._id === linkedField[0]).addressDisplayType : null"
                  :collection="$store.getters.collection(schemaObj[field.key].default.linkTo)"
                  :model="linkedField"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div
        v-else-if="fieldType === 'MultiSelect' || fieldType === 'SelectOne'"
        :class="fieldType === 'MultiSelect' ? 'record__cell__multiSelect' : 'record__cell__selectOne'">
      <div
          v-if="!showQuickEntry">
        <display-select
            v-if="selectedOptions.length"
            :model="selectedOptions"
        />
      </div>
      <div
          v-else-if="fieldType === 'SelectOne'">
        <b-list-group>
          <b-list-group-item
              v-for="(option, index) in model[field.key]"
              :key="index"
              :id="`${index}-toggle`"
              button
              :variant="model[field.key][index].value ? 'primary' : 'light'"
              @click="selectOneClick(field.key, index)">
            {{ option.label }}
          </b-list-group-item>
        </b-list-group>
      </div>
      <div
          v-else>
        <b-list-group>
          <b-list-group-item
              v-for="(option, index) in model[field.key]"
              :key="index"
              :id="`${index}-toggle`"
              button
              :variant="model[field.key][index].value ? 'primary' : 'light'"
              @click="model[field.key][index].value = !model[field.key][index].value">
            {{ option.label }}
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <div
        v-else-if="fieldType === 'Notes'">
      <div
          v-if="quickNote !== model._id"
          class="record__cell__notes">
        <b-row
            v-for="(note, index) in latestNotes"
            :key="index"
            class="mb-3">
          <b-col
              class="text-left">
            <span
                style="white-space: normal">
              {{note}}
            </span>
          </b-col>
        </b-row>
        <small
            @click="addQuickNote(model._id)"
            :class="latestNotes.length ? 'mt-2 form__textButton' : 'form__textButton'">
            <b-icon-plus /> {{$t('note.add')}}
        </small>
      </div>
      <div
          v-else-if="quickNote === model._id"
          class="record__cell__notes">
        <small
            v-text="$t('note.adding')"
        />
        <b-form-textarea
            :id="`${quickNote}-entry`"
            class="record__cell__notes__add"
            v-model="quickNoteContent.text"
            :rows="5"
            v-on:keydown.13.stop
            v-on:keydown.shift.13="addQuickNoteConfirm"
        />
        <b-input-group
            class="mt-1">
          <b-btn
              class="py-0 ml-auto mr-1"
              @click="addQuickNoteCancel()"
              variant="danger">
            <small
                v-text="$t('cancel')"
            />
          </b-btn>
          <b-btn
              class="py-0 ml-1 mr-0"
              @click="addQuickNoteConfirm()"
              variant="success">
            <b-icon-plus
                scale="1.42"
            />
          </b-btn>
        </b-input-group>
      </div>
    </div>
    <div
        v-else-if="fieldType === 'Status'"
        class="record__cell__status">
      <div
          v-if="cellData">
        <display-status
            v-if="changingStatus !== record._id"
            :model="cellData"
        />
        <div
            v-else>
          <b-form-textarea
              v-model="model[field.key].text"
              :id="`${model._id}-statusInput`"
              @click.stop
              class="record__cell__quickEdit__status large mx-auto"
              :placeholder="$t('collections.status.placeholder')"
          />
          <urgent-button
              :classes="'mr-auto ml-0 px-3 py-0'"
              @urgent="model[field.key].urgent = !model[field.key].urgent"
              :active="model[field.key].urgent"
          />
          <span
              class="form__textButton ml-5 mr-1"
              @click.stop="changeStatusCancel()">
              {{$t('cancel')}}
          </span>
          <span
              @click.stop="changeStatusSave()"
              class="form__textButton ml-1 mr-auto">
              {{$t('save')}}
          </span>
        </div>
      </div>
      <span
          v-if="changingStatus !== model._id"
          @click.stop="changeStatus()"
          class="form__textButton"
          v-text="model[field.key].text ? $t('collections.status.update') : $t('collections.status.add')"
      />
    </div>
    <div
        v-else-if="fieldType === 'Tags'">
      <div
          v-if="!showQuickEntry"
          class="record__cell__tags">
        <display-tags
            v-if="cellData && cellData.length"
            :model="cellData"
        />
      </div>
      <div
          v-else>
        <b-form-tags
            v-model="model[field.key]"
            no-outer-focus
            class="record__cell__tags__quickEdit">
          <template
              v-slot="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
            <b-input-group
                aria-controls="my-custom-tags-list">
              <input
                  v-bind="inputAttrs"
                  v-on="inputHandlers"
                  v-on:keydown.13.stop.exact
                  v-on:keydown.shift.13="$emit('quickSave')"
                  :placeholder="$t('tagNew')"
                  class="form-control">
              <b-input-group-append>
                <b-button
                    @click="addTag()"
                    variant="primary"
                    class="py-0">
                  {{$t('add')}}
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <ul
                id="my-custom-tags-list"
                class="record__cell__tags__container"
                aria-live="polite"
                aria-atomic="false"
                aria-relevant="additions removals">
              <b-card
                  v-for="tag in tags"
                  :key="tag"
                  :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                  tag="li"
                  class="mt-1 mr-1"
                  body-class="record__cell__tags__tag">
                <strong
                    class="record__cell__tags__text">
                  {{ tag }}
                </strong>
                <b-button
                    @click="removeTag(tag)"
                    variant="danger-outline"
                    size="sm"
                    class="px-1 py-0"
                    :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`">
                  <b-icon-x-circle
                      :scale="0.8"
                  />
                </b-button>
              </b-card>
            </ul>
          </template>
        </b-form-tags>
      </div>
    </div>
    <div
        v-else-if="fieldType === 'Phone'">
      <div
          v-if="!showQuickEntry"
          class="record__cell__phone">
        <display-phone
            v-if="cellData && cellData.number"
            :model="cellData"
        />
      </div>
      <div
          v-else>
        <b-input-group
            class="record__cell__quickEdit__phone">
          <b-form-input
              :id="`${field.key}-input`"
              class="record__cell__quickEdit"
              type="text"
              v-model="model[field.key].number"
              @input="formatPhone([field.key])"
          />
          <b-form-select
              v-model="model[field.key].format"
              :options="phoneFormatOptions"
              class="form__input smallSelect"
          />
        </b-input-group>
      </div>
    </div>
    <div
        v-else-if="fieldType === 'Price'">
      <div
          v-if="!showQuickEntry">
        <display-price
            v-if="cellData && cellData.price"
            :model="cellData"
        />
      </div>
      <div
          v-else>
        <b-input-group
            class="record__cell__quickEdit__price">
          <b-input-group-prepend
              class="ml-auto"
              v-text="currencySymbol(model[field.key].type)">
          </b-input-group-prepend>
          <b-form-input
              :id="`${field.key}-input`"
              class="form__input smallish"
              type="number"
              :step="0.01"
              v-model.number="model[field.key].price"
          />
          <b-form-select
              v-model="model[field.key].type"
              :options="currencyTypes"
              class="form__input smallSelect mr-auto"
          />
        </b-input-group>
      </div>
    </div>
    <div
        v-else-if="fieldType === 'Toggle'">
      <div
          v-if="!showQuickEntry">
        <display-toggle
            :fieldDefault="collection.schemaObj[field.key].default"
            :model="cellData"
            class="record__cell__toggle"
        />
      </div>
      <div
          v-else>
        {{model[field.key] && model[field.key].value ? field.default.labelOn : (field.default.labelOff ? field.default.labelOff : null)}}
        <b-form-checkbox
            :id="`toggle-${model._id}-${field.key}`"
            switch
            size="lg"
            v-model="model[field.key].value"
        />
      </div>
    </div>
    <div
        v-else-if="fieldType === 'Number'">
      <div
          v-if="!showQuickEntry"
          class="record__cell__number">
        <display-default
            v-if="cellData"
            :model="cellData"
        />
      </div>
      <div
          v-else>
        <b-form-input
            :id="`${field.key}-input`"
            v-model.number="model[field.key]"
            type="number"
            class="record__cell__number__entry"
        />
      </div>
    </div>
    <div
        v-else>
      <div
          v-if="!showQuickEntry"
          class="record__cell__default">
        <display-default
            v-if="cellData"
            :model="cellData"
        />
      </div>
      <div
          v-else>
        <b-form-input
            v-if="fieldType === 'String'"
            :id="`${field.key}-input`"
            v-model="model[field.key]"
            type="text"
            class="record__cell__quickEdit__text"
        />
        <voice-input
              v-if="fieldType === 'Textarea'"
              :id="`${field.key}-input`"
              :itemId="field.key"
              :modelProp="model[field.key]"
              @textInput="updateTextInput"
              @startingRecognition="activeField = field.key"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dataViewer from '@/components/data/DataViewer';
import displayDefault from '@/components/metaType/display/Default';
import displayColor from '@/components/metaType/display/Color';
import displayColorbadge from '@/components/metaType/display/ColorBadge';
import displayPhone from '@/components/metaType/display/Phone';
import displayAddress from '@/components/metaType/display/Address';
import displayEmail from '@/components/metaType/display/Email';
import displayInventory from '@/components/metaType/display/Inventory';
import displaySelect from '@/components/metaType/display/Select';
import displayPrice from '@/components/metaType/display/Price';
import displayStatus from '@/components/metaType/display/Status';
import displayTags from '@/components/metaType/display/Tags';
import displayToggle from '@/components/metaType/display/Toggle';
import totalCalculators from '@/mixins/totalCalculators';
import urgentButton from '@/components/singlePurpose/UrgentButton';
import voiceInput from '@/components/data/VoiceInput';

export default {
  mixins: [
    totalCalculators,
  ],
  components: {
    dataViewer,
    displayDefault,
    displayAddress,
    displayPhone,
    displayColor,
    displayColorbadge,
    displayEmail,
    displayInventory,
    displaySelect,
    displayPrice,
    displayStatus,
    displayTags,
    displayToggle,
    urgentButton,
    voiceInput,
  },
  props: [
    'collectionId',
    'field',
    'hovered',
    'modelId',
    'modelOverride',
    'quickEntry',
    'quickEditRow',
    'schemaObj',
  ],
  created() {
    if (!this.model[this.field.key]) {
      if (this.field.default) {
        this.model[this.field.key] = JSON.parse(JSON.stringify(this.field.default));
      } else {
        this.model[this.field.key] = null;
      }
    }
  },
  computed: {
    addressesInCollection() {
      const ids = [];
      if (this.schemaObj) {
        const entries = Object.entries(this.schemaObj);
        entries.forEach((e) => {
          if (e[1].metaType === 'Address') {
            if (!ids.length) {
              ids.push(e[0]);
            } else if (this.model[e[0]].expanded) {
              ids.push(e[0]);
            }
          }
        });
      }
      return ids;
    },
    cellData() {
      return this.model[this.field.key];
    },
    collection: {
      get() {
        return this.$store.getters.collection(this.collectionId);
      },
      set(newVal) {
        this.store.state.data.collections[this.collectionId] = newVal;
      },
    },
    currencyTypes() {
      return [
        'usd',
        'eur',
      ];
    },
    fieldType() {
      return this.field.metaType;
    },
    latestNotes() {
      const incNotes = this.model[this.field.key];
      const notes = [];
      if (incNotes && incNotes.length) {
        incNotes.sort((a, b) => ((a.date < b.date) ? 1 : -1));
        for (let a = 0; a < incNotes.length; a += 1) {
          if (incNotes[a].text) {
            let note = incNotes[a].text;
            if (this.schemaObj[this.field.key].notePreviewLength === 'sm' && note.length > 48) {
              note = `${note.slice(0, 48)}...`;
            }
            if (this.schemaObj[this.field.key].notePreviewLength === 'md' && note.length > 84) {
              note = `${note.slice(0, 84)}...`;
            }
            if (this.schemaObj[this.field.key].notePreviewLength === 'lg' && note.length > 184) {
              note = `${note.slice(0, 184)}...`;
            }
            notes.push(note);
            if (notes.length >= this.schemaObj[this.field.key].notesToShow) {
              break;
            }
          }
        }
      }
      return notes;
    },
    model: {
      get() {
        if (this.modelOverride) {
          return this.modelOverride;
        }
        return this.$store.getters.record(this.collectionId, this.modelId);
      },
      set(newVal) {
        if (this.modelOverride) {
          this.modelOverride = newVal;
        } else {
          this.$store.state.data.records[this.collectionId][this.modelId] = newVal;
        }
      },
    },
    phoneFormatOptions() {
      return [
        {
          value: 'usaFormat',
          text: this.$t('collections.phoneTypes.usForm'),
        },
        {
          value: 'noFormat',
          text: this.$t('collections.phoneTypes.noForm'),
        },
      ];
    },
    record() {
      return this.$store.getters.record(this.collectionId, this.model._id);
    },
    selectedOptions() {
      const options = [];
      if (this.cellData) {
        Object.entries(this.cellData).forEach((item) => {
          if (item[1].value) {
            options.push(item[1].label);
          }
        });
      }
      return options;
    },
    showQuickEntry() {
      if (this.quickEntry && (this.quickEditRow === this.model._id || this.modelOverride)) {
        return true;
      }
      return false;
    },
    zipResults() {
      return this.$store.state.zipLookup;
    },
  },
  data() {
    return {
      activeField: null,
      changingStatus: false,
      changingStatusRevert: null,
      hoverLinkedItem: null,
      quickNote: null,
      quickNoteContent: null,
      zipWatcher: {
        field: null,
        id: null,
        type: null,
      },
    };
  },
  methods: {
    addQuickNote(id) {
      this.quickNote = id;
      this.quickNoteContent = {
        _id: this.$uuid.v4(),
        date: new Date(),
        addedBy: 'Dev Text', // TODO - enable this this.$store.state.user.attributes.email
        text: null,
      };
      setTimeout(() => {
        document.getElementById(`${this.quickNote}-entry`).focus();
      }, 150);
    },
    addQuickNoteConfirm() {
      this.quickNoteContent.text.trim();
      if (!this.quickEntry) {
        this.$emit('quickNote', this.model._id, this.field.key, this.quickNoteContent);
      } else {
        this.model[this.field.key].push(this.quickNoteContent);
      }
      this.quickNote = null;
      this.quickNoteContent = null;
    },
    addQuickNoteCancel() {
      this.quickNote = null;
      this.quickNoteContent = null;
    },
    changeStatus() {
      this.model[this.field.key].dateOfStatus = new Date();
      if (this.changingStatus) {
        if (this.model[this.field.key].text !== this.changingStatusRevert.text
        || this.model[this.field.key].urgent !== this.changingStatusRevert.urgent) {
          this.$store.dispatch('updateRecord', { collection: this.collectionId, record: this.model });
          this.changingStatusRevert = null;
        }
      }
      this.changingStatusRevert = {
        _id: this.model._id,
        text: this.model[this.field.key].text,
        urgent: this.model[this.field.key].statusUrgent,
      };
      this.changingStatus = this.model._id;
      // this.$nextTick(() => {
      //   document.getElementById(`${this.model._id}-statusInput`).focus();
      //   document.getElementById(`${this.model._id}-statusInput`).select();
      // });
    },
    changeStatusCancel() {
      this.cellData.status = this.changingStatusRevert.text;
      this.cellData.statusUrgent = this.changingStatusRevert.urgent;
      this.changingStatus = null;
      this.changingStatusRevert = null;
    },
    changeStatusSave() {
      this.$store.dispatch('updateRecord', { collection: this.collectionId, record: this.model });
      this.changingStatus = null;
      this.changingStatusRevert = null;
    },
    dataOverride(data) {
      const fields = [];
      Object.entries(data).forEach((field) => {
        if (field[0] !== '_id'
        && field[0] !== 'humanId'
        && field[0] !== 'dateAdded'
        && field[0] !== 'dateUpdated') {
          fields.push(field);
        }
      });

      return fields;
    },
    displayAddress({
      street, streetSecond, streetSecondShow, city, state, zip, country, postal,
    }, type) {
      let address = '';
      if (street) {
        address = street;
      }
      if (streetSecond && streetSecondShow) {
        address += `<br /><small>${streetSecond}</small>`;
      }
      if (address && (city || state || zip || country || postal)) {
        address += '<br />';
      }
      if (city) {
        address += city;
      }
      if (city && (state || zip || country || postal)) {
        address += '<small>,</small> ';
      }
      if (type === 'usaFormat') {
        address += `${state ? `<small>${state}</small>` : ''} ${zip ? `<small>${zip}</small>` : ''}`;
      } else {
        address += `${country ? `<small>${country}</small>` : ''} ${postal ? `<small>${postal}</small>` : ''}`;
      }
      return address;
    },
    formatPhone(fieldKey) {
      if (this.model[fieldKey].format === 'usaFormat') {
        this.model[fieldKey].number = this.model[fieldKey].number.replace(/[^A-Z0-9]+/ig, '');
        this.model[fieldKey].number = this.displayPhone(this.model[fieldKey].number);
      }
    },
    linkedCollection(linkTo) {
      if (linkTo && this.$store.getters.collection(linkTo)) {
        return this.$store.getters.collection(linkTo);
      }
      return {};
    },
    linkedRecord(field, recId) {
      const collId = field[1].default.linkTo;
      const fieldKey = field[0];
      const fields = field[1].default.linkedFields;
      let item = recId;
      const tempCheck = recId.split('__');
      if (tempCheck[0] === 'temp') {
        item = this.model[fieldKey].tempData.find(i => i._id === tempCheck[1]);
      }
      return this.$store.getters.recordLink(collId, item, fields);
    },
    selectOneClick(fieldKey, id) {
      const options = Object.entries(this.model[fieldKey]);
      for (let a = 0; a < options.length; a += 1) {
        if (options[a][0] === id) {
          options[a][1].value = !options[a][1].value;
        } else {
          options[a][1].value = false;
        }
      }
    },
    seeDataLink(collection, record) {
      this.$emit('switchToRecord', { collection, record });
    },
    updateTextInput(input) {
      this.model[this.activeField] = input;
    },
    zipLookup(zip) {
      if (!this.$_pref.zipAutofill || this.model[this.field.key].type !== 'usaFormat') {
        return;
      }
      if (zip.length === 5) {
        const lookup = {
          id: this.model._id,
          zip,
        };
        this.zipWatcher.id = this.model._id;
        this.zipWatcher.type = this.field.key;
        this.$store.dispatch('zipLookup', lookup);
      } else {
        this.zipWatcher.id = null;
        this.zipWatcher.type = null;
      }
    },
  },
  watch: {
    'zipResults.id': {
      handler(newVal) {
        if (newVal
        && newVal !== 'err') {
          if (this.zipResults.id === this.model._id
          && this.zipWatcher.type === this.field.key) {
            console.log('successful Lookup');
            if (this.$store.state.zipLookup.city) {
              this.model[this.field.key].address.city = this.titleCase(this.zipResults.city);
              this.model[this.field.key].address.state = this.zipResults.state;
            }
          }
        }
        if (newVal === 'err' && this.zipWatcher.type === this.field.key) {
          this.model[this.field.key].address.city = null;
          this.model[this.field.key].address.state = null;
          this.zipWatcher = {
            id: null,
            type: null,
          };
          if (this.zipWatcher.type === 'field' && this.zipWatcher.record === this.model._id) {
            this.$store.dispatch('zipLookupClear');
          }
        }
      },
    },
  },
};
</script>
