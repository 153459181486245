<template>
  <div>
  <h5
      v-text="project && project.title ? project.title : null"
  />
  <span
      v-text="customer ? customer.name : (project && project.name) ? project.name : null"
  />
  <div
      v-html="address"
  />
  </div>
</template>

<script>
import totalCalculators from '@/mixins/totalCalculators';

export default {
  // title() {
  //   return ` | ${this.$t('project.brand')}`;
  // },
  mixins: [
    totalCalculators,
  ],
  computed: {
    address() {
      if (this.customer) {
        return this.displayAddress({ address: this.customer.address, type: this.$t('project.defaultAddressType') }, 'whole');
      }
      if (this.project && this.project.address) {
        return this.displayAddress({ address: this.project.address, type: this.$t('project.defaultAddressType') }, 'whole');
      }
      return null;
    },
    customer() {
      if (this.project && this.project.customer) {
        return this.$store.getters.customer(this.project.customer);
      }
      return null;
    },
    project() {
      if (this.projectData) {
        return this.projectData;
      }
      if (this.shiftInfo) {
        if (this.shiftInfo.project) {
          return this.$store.getters.project(this.shiftInfo.project);
        }
        return this.shiftInfo.projectData;
      }
      return null;
    },
  },
  props: [
    'shiftInfo',
    'projectData',
  ],
  data() {
    return {
      model: {},
    };
  },
  methods: {},
  watch: {},
};
</script>
