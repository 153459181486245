<template>
  <div
      id="invoiceButtons">
    <transition
        name="fadeQuick"
        mode="out-in">
        <b-btn-group
            id="pdfActions"
            key="fileActions"
            class="p-2">
          <b-btn
              id="cancelPdfCancel"
              variant="warning"
              class="mr-2"
              @click="pdfCancel()">
            <icon
                icon="window-close"
                size="sm"
            />
          </b-btn>
          <b-btn
              :id="`preview-${model._id}`"
              :variant="buttonSpinner.target === 'preview' ? 'primary' : 'tertiary'"
              @click="previewPdf()"
              class="invoice__buttonBar__pdfOptions"
              :aria-label="$t('aria.filePreview')">
            <loader
              v-if="buttonSpinnerActive
                  && buttonSpinner.target !== 'preview'"
              :color="buttonSpinner.color"
              :type="buttonSpinner.type"
              :h="buttonSpinner.h"
              :w="buttonSpinner.w"
              :fullPage="false"
            />
            <div
                v-else>
              <icon
                  :icon="['fa', 'eye']"
                  :aria-label="$t('aria.preview')"
                  size="lg"
                />
                {{$t('preview')}}
            </div>
          </b-btn>
          <b-btn
              :id="`email-${model._id}`"
              :variant="buttonSpinner.target === 'email' ? 'primary' : 'secondary'"
              @click="emailPdf()"
              class="invoice__buttonBar__pdfOptions"
              :aria-label="$t('aria.fileEmail')">
            <loader
              v-if="buttonSpinnerActive
                  && buttonSpinner.target !== 'email'"
              :color="buttonSpinner.color"
              :type="buttonSpinner.type"
              :h="buttonSpinner.h"
              :w="buttonSpinner.w"
              :fullPage="false"
            />
            <div
                v-else>
              <icon
                  icon="at"
                  :aria-label="$t('aria.email')"
                  size="lg"
                />
                {{$t('email')}}
            </div>
          </b-btn>
          <b-btn
              :id="`download-${model._id}`"
              :variant="buttonSpinner.target === 'download' ? 'primary' : 'secondary'"
              @click="downloadPdf()"
              class="invoice__buttonBar__pdfOptions"
              :aria-label="$t('fileDownload')">
            <loader
                v-if="buttonSpinnerActive
                    && buttonSpinner.target !== 'download'"
                :color="buttonSpinner.color"
                :type="buttonSpinner.type"
                :h="buttonSpinner.h"
                :w="buttonSpinner.w"
                :fullPage="false"
            />
            <div
                v-else>
              <icon
                  icon="file-download"
                  :aria-label="$t('aria.download')"
                  size="lg"
              />
              {{$t('download')}}
            </div>
          </b-btn>
          <b-btn
              :id="`print-${model._id}`"
              :variant="buttonSpinner.target === 'print' ? 'primary' : 'secondary'"
              @click="printPdf()"
              class="invoice__buttonBar__pdfOptions"
              :aria-label="$t('aria.filePrint')">
            <loader
                v-if="buttonSpinnerActive
                    && buttonSpinner.target !== 'print'"
                :color="buttonSpinner.color"
                :type="buttonSpinner.type"
                :h="buttonSpinner.h"
                :w="buttonSpinner.w"
                :fullPage="false"
            />
            <div
                v-else>
              <icon
                  icon="print"
                  :aria-label="$t('aria.filePrint')"
                  size="lg"
              />
              {{$t('print')}}
            </div>
          </b-btn>
        </b-btn-group>
    </transition>
    <div>
      <b-modal
          :id="`previewModal_${model._id}`"
          ref="previewModal"
          centered
          size="xl"
          :title="$t('invoice.preview')"
          header-class="invoice__emailModal__header"
          body-class="invoice__previewModal"
          footer-class="invoice__emailModal__footer"
          ok-only
          :ok-title="$t('close')"
          @ok="$bvModal.hide(`previewModal_${model._id}`)"
          @hidden="modalHidden">
        <b-pagination
            v-if="pageCount > 1"
            per-page="1"
            :total-rows="pageCount"
            align="center"
            v-model="pageView"
            :hide-goto-end-buttons="pageCount < 5 ? true : false"
        />
        <pdf
            :src="previewFile.data"
            :page="pageView"
            class="invoice__previewModal__file"
        />
      </b-modal>
      <b-modal
          :id="`emailModal_${model._id}`"
          centered
          class="invoice__emailModal"
          size="lg"
          :title="$t('emailHeader')"
          header-class="invoice__emailModal__header"
          body-class="invoice__emailModal"
          footer-class="invoice__emailModal__footer"
          :modal-ok="$t('send')"
          @ok="sendFile()">
        <div>
          <label
              for="emailAddressToSend"
              id="emailModalAddressLabel"
              v-text="$t('emailSendTo')"
          />
          <b-form-input
              id="emailAddressToSend"
              name="email"
              type="text"
              v-model="email.to"
              class="invoice__emailModal__addressField"
          />
          <label
              for="emailSubjectLine"
              id="emailModalSubjectLabel"
              v-text="$t('emailSubject')"
          />
          <b-form-input
              id="emailSubjectLine"
              name="subject"
              type="text"
              v-model="email.subjectLine"
              class="invoice__emailModal__subjectLine"
          />
          <label
              for="emailModalMessageEntry"
              id="emailModalMessageLabel"
              v-text="$t('emailMessage')"
          />
          <b-form-textarea
              id="emailModalMessageEntry"
              rows="12"
              size="lg"
              name="email"
              type="text"
              v-model="email.message"
              class="invoice__emailModal__textField"
          />
          <p
              class="invoice__emailModal__attachedFilesText">
            {{$t('fileAttached')}}
            <br />
            <icon
                icon="search"
                size="sm"
                v-if="!previewFileInsideEmailModal"
                :aria-label="$t('aria.preview')"
                @click="previewPdfInsideEmailModal()"
                class="mr-2"
                style="cursor: pointer"
            />
            <icon
                icon="window-close"
                size="sm"
                v-else
                :aria-label="$t('aria.previewClose')"
                style="cursor: pointer"
                @click="previewFileInsideEmailModal = false"
            />
            {{this.email.fileName}}
          </p>
          <div
              v-if="previewFileInsideEmailModal">
            <b-pagination
                v-if="pageCount > 1"
                per-page="1"
                :total-rows="pageCount"
                align="center"
                v-model="pageView"
                :hide-goto-end-buttons="pageCount < 5 ? true : false"
            />
            <pdf
                :src="previewFile.data"
                style="width: 99%"
            />
          </div>
        </div>
        <template v-slot:modal-footer>
          <b-btn
              v-text="$t('cancel')"
              size="sm"
              variant="info"
              @click="emailModalCancel()"
          />
          <b-btn
              size="lg"
              :variant="readyToSendFile ? 'primary' : 'tertiary'"
              :disabled="readyToSendFile ? false : true"
              @click="sendFile()">
            <span
                v-text="$t('send')"
                v-if="readyToSendFile"
            />
            <span
                v-if="!readyToSendFile"
                v-text="$t('invoice.processing')"
            />
            <loader
                v-if="!readyToSendFile"
                color="azure"
                :type="buttonSpinner.type"
                :h="10"
                :w="20"
                :fullPage="false"
              />
          </b-btn>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import image2base64 from 'image-to-base64';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdf from 'vue-pdf';
import { s3 } from '@/store/aws';
import totalCalculators from '@/mixins/totalCalculators';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: 'fileOptionsButton',
  props: ['model', 'hideAddButton', 'preferences'],
  mixins: [
    totalCalculators,
  ],
  components: {
    pdf,
  },
  computed: {
    colors() {
      const colors = {
        colorAccent: null,
        colorText: null,
        colorBackground: null,
        colorImpact: null,
      };
      if (this.model.status === 'proposal' || this.model.status === 'proposalSent') {
        colors.colorAccent = this.preferences.colorAccentProposal;
        colors.colorText = this.preferences.colorTextProposal;
        colors.colorBackground = this.preferences.colorBackgroundProposal;
        colors.colorImpact = this.preferences.colorImpactProposal;
      } else {
        colors.colorAccent = this.preferences.colorAccent;
        colors.colorText = this.preferences.colorText;
        colors.colorBackground = this.preferences.colorBackground;
        colors.colorImpact = this.preferences.colorImpact;
      }
      return colors;
    },
    docLabel() {
      let label;
      if ((this.model.status === 'proposal' || this.model.status === 'proposalSent')
        && this.preferences.defaultLabelProposal) {
        label = `${this.preferences.defaultLabelProposal} #`;
      } else if (this.preferences.defaultLabelInvoice) {
        label = `${this.preferences.defaultLabelInvoice} #`;
      } else {
        label = '#';
      }
      return label;
    },
    fileActionResult() {
      return this.$store.state.fileAction;
    },
    launchPreview() {
      return this.$store.state.launchPreview;
    },
    logo: {
      get() {
        return this.$store.state.logo;
      },
      set(newVal) {
        this.$store.state.logo = newVal;
      },
    },
    printData() {
      return this.$store.state.data.printData;
    },
    sendSuccess: {
      get() {
        return this.$store.state.sendFileSuccess;
      },
      set(newVal) {
        this.$store.state.sendFileSuccess = newVal;
      },
    },
    userAddressBottomLine() {
      // returns properly formatted bottom line of address on PDF invoice. Necessary to prevent 'null' being printed if elements not present
      const city = this.preferences.userCity;
      const state = this.preferences.userState;
      const zip = this.preferences.userZip;
      if (city && state && zip) {
        return `${city}, ${state}  ${zip}`;
      }
      if (city && state) {
        return `${city}, ${state}`;
      }
      if (city && zip) {
        return `${city}, ${zip}`;
      }
      if (state && zip) {
        return `${state}  ${zip}`;
      }
      if (city) {
        return city;
      }
      if (state) {
        return state;
      }
      if (zip) {
        return zip;
      }
      return '';
    },
  },
  data() {
    return {
      buttonSpinnerActive: false,
      buttonSpinner: {
        color: 'snow',
        h: 20,
        w: 20,
        type: 'dots',
        target: null,
      },
      changeDate: false,
      displayEditBar: false,
      fileAction: null,
      fileToUpload: null,
      email: {
        customerId: null,
        invoiceId: null,
        to: null,
        fileUrl: null,
        subjectLine: null,
        message: null,
        from: null,
        replyTo: null,
      },
      pageCount: null,
      pageView: 1,
      paymentTypes: this.$_app.invoice.paymentOptions,
      previewFile: { data: null },
      previewFileInsideEmailModal: false,
      printDoc: {},
      readyToSendFile: false,
      showPdfActions: false,
      unitTypes: [
        '',
        'hours',
        'minutes',
        'days',
        'weeks',
        'months',
      ],
      useBigInput: false,
      waitForLogo: false,
    };
  },
  methods: {
    docHeader() {
      if (this.model.status === 'proposal' || this.model.status === 'proposalSent') {
        if (this.preferences.enableHeaderProposal && this.preferences.defaultHeaderProposal) {
          return this.preferences.defaultHeaderProposal;
        }
      }
      if (this.preferences.enableHeader && this.preferences.defaultHeader) {
        return this.preferences.defaultHeader;
      }
      return '';
    },
    docMemo() {
      if (this.model.status === 'proposal' || this.model.status === 'proposalSent') {
        if (this.preferences.enableMemoProposal && this.preferences.defaultMemoProposal) {
          return this.preferences.defaultMemoProposal;
        }
      }
      if (this.preferences.enableMemo && this.preferences.defaultMemo) {
        return this.preferences.defaultMemo;
      }
      return null;
    },
    docSubheader() {
      if (this.model.status === 'proposal' || this.model.status === 'proposalSent') {
        if (this.preferences.enableSubheaderProposal && this.preferences.defaultSubheaderProposal) {
          return this.preferences.defaultSubheaderProposal;
        }
      }
      if (this.preferences.enableSubheader && this.preferences.defaultSubheader) {
        return this.preferences.defaultSubheader;
      }
      return '';
    },
    docTitle(doc) {
      if (doc.title && this.preferences.displayTitle) {
        return doc.title;
      }
      return '';
    },
    downloadPdf() {
      this.fileAction = {
        cmd: 'download',
        _id: this.model._id,
      };
      this.buttonSpinner.target = 'download';
      this.buttonSpinnerActive = true;
      this.getPrintData();
    },
    emailPdf() {
      this.fileAction = {
        cmd: 'email',
        _id: this.model._id,
      };
      this.buttonSpinner.target = 'email';
      this.buttonSpinnerActive = true;
      this.email.customerId = this.model.customer;
      this.email.invoiceID = this.model._id;
      this.getPrintData();
    },
    async prepareEmailAttachment() {
      const file = pdfMake.createPdf(this.printDoc);
      await file.getBase64((fileData) => {
        console.log('starting upload');
        const fileBuffer = Buffer.from(fileData.replace(/^data:application\/\w+;pdf,/, ''), 'base64');
        s3.upload({
          Key: this.printDoc.info.title,
          Body: fileBuffer,
          ContentEncoding: 'base64',
          ContentType: 'application/pdf',
          ACL: 'public-read',
        }, (err, data) => {
          if (err) {
            console.log('failed', err.message);
          } else {
            console.log('upload complete', data.Location);
            this.email.fileUrl = data.Location;
            this.readyToSendFile = true;
            this.$store.dispatch('fileUploaded', this.printDoc.info.title);
          }
        });
      });
    },
    emailModalLauncher() {
      this.email.fileName = this.printDoc.info.title;
      this.email.to = this.printData.customer.email;
      this.email.subjectLine = this.preferences.defaultEmailSubjectLine;
      this.email.replyTo = this.preferences.defaultEmailReplyTo;
      this.email.from = this.preferences.defaultEmailFrom;
      this.email.message = this.preferences.defaultEmailMessage;
      this.$root.$emit('bv::show::modal', `emailModal_${this.model._id}`);
      this.prepareEmailAttachment();
    },
    emailModalCancel() {
      this.readyToSendFile = false;
      this.$store.dispatch('fileUploadCancel');
      this.$bvModal.hide(`emailModal_${this.model._id}`);
    },
    getPrintData() {
      if (this.preferences.userLogo !== this.logo.location) {
        console.log('Need to re-process logo');
        this.processLogo();
      }
      this.$store.dispatch('invoicePrintData', this.model._id);
    },
    makePrintDoc(incData) {
      const that = this;
      const printData = incData;
      const currentlyOutstandingItems = [];
      printData.outstandingItems.forEach((i) => {
        const invoice = this.$store.getters.invoice(i.originalId);
        if (invoice.totalCharges - invoice.totalPayments > 0) {
          currentlyOutstandingItems.push(i);
        }
      });
      printData.outstandingItems = currentlyOutstandingItems;
      const document = {
        info: {
          title: this.nameFile(),
          author: this.preferences.userName,
          subject: `${this.docLabel} ${printData.number}`,
          keywords: `${this.preferences.userName}, ${this.docLabel}, ${this.docMemo()} ${printData.date}`,
        },
        footer(currentPage, pageCount) {
          if (pageCount > 1) {
            return [
              that.makeFooter(pageCount),
              {
                text: `${that.$t('invoice.page')} ${currentPage.toString()} of ${pageCount}`,
                alignment: 'center',
              }];
          }
          return [
            that.makeFooter(),
          ];
        },
        header: [
          {
            layout: 'noBorders',
            table: {
              alignment: 'center',
              widths: ['*'],
              margin: [5, 15, 5, 5],
              body: [[{
                alignment: 'left',
                columns: [
                  this.logo.data ? {
                    image: `data:image/png;base64,${this.logo.data}`,
                    fit: [100, 100],
                    width: 'auto',
                  } : {
                    text: '',
                    width: 0,
                  },
                  this.preferences.userName ? {
                    fontSize: 15,
                    color: this.colors.colorImpact.toLowerCase(),
                    text: this.preferences.userName,
                    alignment: 'center',
                    margin: !this.preferences.userAddressSecondLine && !this.preferences.userFax
                      ? [20, 25, 0, 5] : [20, 30, 0, 5],
                    bold: true,
                    width: '*',
                  } : {},
                  [
                    {
                      text: this.preferences.userAddress ? this.preferences.userAddress : '',
                      alignment: 'center',
                      margin: [0, 20, 0, 0],
                      color: this.colors.colorAccent.toLowerCase(),
                    },
                    this.preferences.userAddressSecondLine ? {
                      text: this.preferences.userAddressSecondLine,
                      alignment: 'center',
                      color: this.colors.colorAccent.toLowerCase(),
                    } : {},
                    {
                      text: this.userAddressBottomLine,
                      alignment: 'center',
                      color: this.colors.colorAccent.toLowerCase(),
                    },
                  ],
                  [
                    {
                      text: !this.preferences.userFax
                        ? this.displayPhone(this.preferences.userPhone)
                        : `${this.$t('prefs.userPhone')}: ${this.displayPhone(this.preferences.userPhone)}`,
                      alignment: 'center',
                      margin: [0, 20, 20, 0],
                      color: this.colors.colorAccent.toLowerCase(),
                    },
                    this.preferences.userFax ? {
                      text: `${this.$t('prefs.userFax')}: ${this.displayPhone(this.preferences.userFax)}`,
                      alignment: 'center',
                      margin: [0, 0, 20, 0],
                      color: this.colors.colorAccent.toLowerCase(),
                    } : {},
                    this.preferences.userEmail ? {
                      text: this.preferences.userEmail,
                      alignment: 'center',
                      color: this.colors.colorAccent.toLowerCase(),
                    } : {},
                  ],
                ],
              }]],
            },
          },
          {
            table: {
              widths: ['*'],
              alignment: 'center',
              body: [[{
                fillColor: that.colors.colorBackground.toLowerCase(),
                alignment: 'left',
                color: that.colors.colorText.toLowerCase(),
                columns: [
                  this.preferences.headerAppearsOnLeft
                    ? this.makeHeader(true) : this.makeHeaderDetails(true),
                  this.preferences.headerAppearsOnLeft
                    ? this.makeHeaderDetails() : this.makeHeader(),
                ],
              }]],
            },
            margin: [5, 5, 5, 5],
            layout: {
              hLineColor() {
                return 'grey';
              },
              vLineColor() {
                return 'grey';
              },
            },
          },
        ],
        pageMargins: [30, 130, 30, 60],
        // eslint-disable-next-line no-unused-vars
        background(currentPage) {
          return [
            {
              canvas: [
                {
                  type: 'line',
                  x1: 15,
                  y1: 15,
                  x2: 15,
                  y2: 825,
                  lineWidth: 1.5,
                  lineColor: 'darkgrey',
                },
                {
                  type: 'line',
                  x1: 15,
                  y1: 15,
                  x2: 580,
                  y2: 15,
                  lineWidth: 1.5,
                  lineColor: 'darkgrey',
                },
                {
                  type: 'line',
                  x1: 580,
                  y1: 15,
                  x2: 580,
                  y2: 825,
                  lineWidth: 1.5,
                  lineColor: 'darkgrey',
                },
                {
                  type: 'line',
                  x1: 15,
                  y1: 825,
                  x2: 580,
                  y2: 825,
                  lineWidth: 1.5,
                  lineColor: 'darkgrey',
                },
              ],
            },
          ];
        },
        content: [
          this.docTitle(printData) ? {
            text: this.docTitle(printData),
            fontSize: 15,
            margin: [0, 0, 0, 0],
            alignment: 'center',
          } : {},
          this.makeCustomerInfo(),
          this.makeMemo(),
          this.makeNoteItems(printData.notes),
          this.makeLineItems(printData.lineItems),
          // this.preferences.displayDiscounts && (this.discountCalculator(printData.lineItems) !== 0) ? {
          //   text: `${this.$t('invoice.discounts')}:   ${this.displayDiscounts(printData.lineItems)}`,
          //   margin: [0, 2, 11, 10],
          //   alignment: 'right',
          // } : {},
          this.totalTaxCalculator(printData.lineItems, { enable: this.preferences.taxOption, rate: this.preferences.taxRate }) ? [
            {
              columns: [
                {
                  text: '',
                  width: '11%',
                },
                {
                  text: this.$t('invoice.taxLabel'),
                  alignment: 'center',
                  fontSize: 11,
                  italics: true,
                  margin: [0, 5, 0, 0],
                  width: '15%',
                },
                {
                  text: `${this.$t('invoice.subtotal')}:   ${this.displaySubtotal(printData.lineItems)}`,
                  alignment: 'right',
                  fontSize: 14,
                  bold: true,
                  margin: [0, 5, 11, 10],
                },
              ],
            },
            {
              // eslint-disable-next-line max-len
              text: `${this.$t('invoice.totalTax')} (${(this.preferences.taxRate)}%):   ${this.displayTotalTax(printData.lineItems, { enable: this.preferences.taxOption, rate: this.preferences.taxRate })}`,
              margin: [0, 2, 11, 10],
              alignment: 'right',
              fontSize: 14,
              width: '*',
            },
          ] : {},
          (printData.totalCharges + this.totalOutstandingCalc(printData.outstandingItems)) > 0 ? {
            text: printData.totalPayments > 0 || this.totalOutstandingCalc(printData.outstandingItems) > 0
              ? `${this.$t('invoice.totalCharges')}:   ${this.displayTotal(printData.lineItems, { enable: this.preferences.taxOption, rate: this.preferences.taxRate })}`
              : `${this.$t('invoice.total')}:   ${this.displayTotal(printData.lineItems, { enable: this.preferences.taxOption, rate: this.preferences.taxRate })}`,
            alignment: 'right',
            bold: true,
            fontSize: 14,
            margin: [0, 8, 11, 10],
          } : {},
          printData.outstandingItems.length ? this.makeOutstandingItems(printData.outstandingItems) : '',
          printData.outstandingItems.length ? {
            text: `${this.$t('invoice.totalOutstanding')}:   ${this.totalOutstandingCalc(printData.outstandingItems, 'display')}`,
            alignment: 'right',
            bold: true,
            fontSize: 14,
            margin: [0, 8, 11, 10],
          } : {},
          // this.preferences.showPayments
          //   &&
          printData.payments.length > 0
            ? this.makePaymentItems(printData.payments) : {
              // table: {
              //   widths: [520],
              //   alignment: 'center',
              //   margin: [5, 5],
              //   body: [[{
              //     fillColor: that.colors.colorBackground.toLowerCase(),
              //     alignment: 'left',
              //     color: that.colors.colorText.toLowerCase(),
              //     columns: [
              //       {
              //         text: 'printData.payments',
              //       },
              //     ],
              //   }]],
              // },
            },
          // 'Total Due: $amount'
          ((printData.status !== 'proposal' && printData.status !== 'proposalSent')
          && (printData.totalPayments > 0
              || printData.totalCharges > 0
              || this.totalOutstandingCalc(printData.outstandingItems) > 0))
            ? {
              text: [
                {
                  text: `${this.$t('invoice.totalDue')}  `,
                  color: this.colors.colorImpact.toLowerCase(),
                },
                {
                  text: this.displayInvoiceTotal(printData.totalCharges, this.totalOutstandingCalc(printData.outstandingItems), printData.totalPayments),
                  color: this.colors.colorImpact.toLowerCase(),
                },
              ],
              alignment: 'right',
              bold: true,
              fontSize: 20,
              margin: [0, 10, 5, 5],
            } : {},
          // Due on dd/mm/yy
          (printData.status !== 'proposal' && printData.status !== 'proposalSent')
            && printData.totalPayments < (printData.totalCharges + this.totalOutstandingCalc(printData.outstandingItems))
            ? {
              text: (this.preferences.dueDateEnabled || printData.dateDue) ? [
                {
                  text: `${this.$t('invoice.dueOn')}  `,
                  color: this.colors.colorImpact.toLowerCase(),
                },
                {
                  text: this.dueDateFormal(printData),
                },
              ] : [],
              bold: true,
              alignment: 'right',
              margin: [0, 10, 5, 5],
              fontSize: 16,
            } : {
              text: printData.totalPayments > 0
                && printData.totalPayments >= (printData.totalCharges + this.totalOutstandingCalc(printData.outstandingItems))
                ? [
                  {
                    text: `${this.$t('invoice.paidOn')} `,
                  },
                  {
                    text: moment(printData.payments[printData.payments.length - 1].date).format('l'),
                  },
                ]
                : {},
              bold: true,
              alignment: 'right',
              margin: [0, 10, 5, 5],
              fontSize: 16,
            },
          // Payment Instructions
          this.preferences.showPaymentsMemo
            && (printData.status !== 'proposal' && printData.status !== 'proposalSent')
            && printData.totalCharges > 0
            && printData.totalPayments < (printData.totalCharges + this.totalOutstandingCalc(printData.outstandingItems))
            && this.preferences.paymentMemo
            ? [
              this.preferences.paymentMemoHeader ? {
                text: this.preferences.paymentMemoHeader,
                alignment: 'center',
                fontSize: 17,
                italics: true,
                margin: [0, 42, 0, 0],
                color: this.colors.colorImpact.toLowerCase(),
              } : null,
              {
                text: this.preferences.paymentMemo,
                alignment: 'center',
                fontSize: 14,
                italics: true,
                margin: [0, 10, 0, 0],
                color: this.colors.colorImpact.toLowerCase(),
              },
            ] : {},
          // Proposal Retainer Instructions
          this.preferences.showPaymentsMemoProposal
            && (printData.status === 'proposal' || printData.status === 'proposalSent')
            ? [
              // header
              this.preferences.paymentMemoProposalHeader ? {
                text: this.preferences.paymentMemoProposalHeader,
                alignment: 'center',
                fontSize: 17,
                italics: true,
                margin: [0, 42, 0, 0],
                color: this.colors.colorImpact.toLowerCase(),
              } : null,
              // Retainer amount: $amount
              (this.preferences.proposalRetainer.type === 'percentage'
                  && this.preferences.proposalRetainer.percent)
              || (this.preferences.proposalRetainer.type === 'setAmount'
                  && this.preferences.proposalRetainer.setAmount)
                ? {
                  alignment: 'center',
                  fontSize: 15,
                  margin: [0, 10, 0, 0],
                  text: [
                    {
                      text: `${this.preferences.proposalRetainer.prefix}  $`,
                      color: this.colors.colorImpact.toLowerCase(),
                    },
                    {
                      text: this.retainerDue(printData),
                    },
                  ],
                } : {},
              {
                // instructions
                text: this.preferences.paymentMemoProposal,
                alignment: 'center',
                fontSize: 14,
                italics: true,
                margin: [0, 10, 0, 0],
                color: this.colors.colorImpact.toLowerCase(),
              },
            ] : {},
        ],
        // eslint-disable-next-line
          pageBreakBefore(currentNode, nodesOnNextPage) {
          return (currentNode.headlineLevel === 1
              && nodesOnNextPage.length > 0);
        },
      };
      return document;
    },
    makeCustomerInfo() {
      const alignLeft = this.preferences.customerInfoAppearsOnLeft;
      const primaryAddress = [
        {
          text: this.printData.customer.address.street
              && this.customerIdentifier(this.printData.customer) !== this.printData.customer.address.street
            ? `${this.printData.customer.address.street}` : '',
          margin: alignLeft || this.printData.customer.addressForBilling ? [10, 5, 0, 0] : [0, 5, 20, 0],
          alignment: alignLeft || this.printData.customer.addressForBilling ? 'left' : 'right',
        },
        this.printData.customer.address.secondLine
          ? {
            text: `${this.printData.customer.address.secondLine}`,
            alignment: alignLeft || this.printData.customer.addressForBilling ? 'left' : 'right',
            margin: alignLeft || this.printData.customer.addressForBilling ? [10, 5, 0, 0] : [0, 5, 20, 0],
          } : {},
        {
          // eslint-disable-next-line max-len
          text: `${this.printData.customer.address.city ? this.printData.customer.address.city : ''}${this.printData.customer.address.city && this.printData.customer.address.state ? ',' : ''} ${this.printData.customer.address.state ? this.printData.customer.address.state : ''} ${this.printData.customer.address.zip ? this.printData.customer.address.zip : ''}`,
          margin: alignLeft || this.printData.customer.addressForBilling ? [10, 5, 0, 0] : [0, 5, 20, 0],
          alignment: alignLeft || this.printData.customer.addressForBilling ? 'left' : 'right',
        },
      ];
      const secondaryAddress = [
        {
          text: this.printData.customer.billingAddress.street
              && this.customerIdentifier(this.printData.customer) !== this.printData.customer.billingAddress.street
            ? `${this.printData.customer.billingAddress.street}` : '',
          margin: [0, 5, 20, 0],
          alignment: 'left',
        },
        this.printData.customer.billingAddress.secondLine
          ? {
            text: `${this.printData.customer.billingAddress.secondLine}`,
            alignment: 'left',
            margin: [0, 5, 20, 0],
          } : {},
        {
          // eslint-disable-next-line max-len
          text: `${this.printData.customer.billingAddress.city ? this.printData.customer.billingAddress.city : ''}${this.printData.customer.billingAddress.city && this.printData.customer.billingAddress.state ? ',' : ''} ${this.printData.customer.billingAddress.state ? this.printData.customer.billingAddress.state : ''} ${this.printData.customer.billingAddress.zip ? this.printData.customer.billingAddress.zip : ''}`,
          margin: [0, 5, 20, 0],
          alignment: 'left',
        },
      ];
      return [
        this.customerIdentifier(this.printData.customer) ? {
          text: {
            text: this.customerIdentifier(this.printData.customer),
            fontSize: 18,
          },
          margin: alignLeft ? [0, 2, 0, 2] : [0, 2, 20, 2],
          color: this.colors.colorImpact.toLowerCase(),
          bold: true,
          alignment: alignLeft ? 'left' : 'right',
        } : {},
        this.printData.customer.addressForBilling
          ? {
            table: {
              widths: ['*'],
              alignment: 'center',
              body: [[{
                columns: [
                  [
                    {
                      text: `${this.preferences.addressLabel}: `,
                      alignment: 'left',
                      margin: [0, 5, 0, 0],
                      bold: true,
                      fontSize: 12,
                    },
                    primaryAddress,
                  ],
                  [
                    {
                      text: `${this.preferences.addressSecondaryLabel}: `,
                      alignment: 'left',
                      margin: [0, 5, 0, 0],
                      bold: true,
                      fontSize: 12,
                    },
                    secondaryAddress,
                  ],
                ],
              }]],
            },
            layout: 'noBorders',
          }
          : {
            table: {
              widths: ['*'],
              alignment: 'center',
              body: [[{
                columns: [
                  [
                    primaryAddress,
                  ],
                ],
              }]],
            },
            layout: 'noBorders',
          },
        // [
        // ] : [
        //   this.printData.customer.address.street
        //     && this.customerIdentifier(this.printData.customer) !== this.printData.customer.address.street
        //     ? {
        //       text: `${this.printData.customer.address.street}`,
        //       alignment: alignLeft ? 'left' : 'right',
        //       margin: alignLeft ? [20, 5, 0, 0] : [0, 5, 20, 0],
        //     } : {},
        //   this.printData.customer.address.secondLine
        //     ? {
        //       text: `${this.printData.customer.address.secondLine}`,
        //       alignment: alignLeft ? 'left' : 'right',
        //       margin: alignLeft ? [20, 5, 0, 0] : [0, 5, 20, 0],
        //     } : {},
        //   {
        //     // eslint-disable-next-line max-len
        // eslint-disable-next-line max-len
        //     text: `${this.printData.customer.address.city ? this.printData.customer.address.city : ''}${this.printData.customer.address.city && this.printData.customer.address.state ? ',' : ''} ${this.printData.customer.address.state ? this.printData.customer.address.state : ''} ${this.printData.customer.address.zip ? this.printData.customer.address.zip : ''}`,
        //     alignment: alignLeft ? 'left' : 'right',
        //     margin: alignLeft ? [20, 5, 0, 0] : [0, 5, 20, 0],
        //   },
        // ],
      ];
    },
    makeFooter(pages) {
      if (pages) {
        this.pageCount = pages;
      } else {
        this.pageCount = 1;
      }
      return {
        table: {
          widths: ['*'],
          alignment: 'center',
          body: [[{
            fillColor: this.colors.colorBackground.toLowerCase(),
            alignment: 'center',
            color: this.colors.colorText.toLowerCase(),
            // eslint-disable-next-line no-nested-ternary
            columns: (this.model.status !== 'proposal' && this.model.status !== 'proposalSent')
              ? this.preferences.enableFooter
                ? [
                  {
                    text: this.preferences.defaultFooter,
                    fontSize: 12,
                    bold: true,
                  },
                ] : []
              : this.preferences.enableFooterProposal
                ? [
                  {
                    text: this.preferences.defaultFooterProposal,
                    fontSize: 12,
                    bold: true,
                  },
                ] : [],
          }]],
        },
        margin: [5, 5, 5, 5],
        layout: {
          hLineColor() {
            return 'grey';
          },
          vLineColor() {
            return 'grey';
          },
        },
      };
    },
    makeHeader(alignLeft) {
      return [
        {
          fontSize: this.docHeader().length < 22 ? 24 : 20,
          text: [
            {
              text: this.docHeader(),
            },
          ],
          margin: alignLeft ? [20, 5, 0, 2] : [0, 5, 10, 2],
          bold: true,
          alignment: alignLeft ? 'left' : 'right',
        },
        {
          text: this.docSubheader(),
          fontSize: 14,
          italics: true,
          margin: alignLeft ? [35, 2, 0, 5] : [0, 2, 15, 5],
          alignment: alignLeft ? 'left' : 'right',
        },
      ];
    },
    makeHeaderDetails(alignLeft) {
      return [
        {
          text: [
            {
              text: `${this.$t('invoice.date')}  `,
            },
            {
              text: this.dateOfInvoice(this.printData),
              bold: true,
              fontSize: 13,
            },
          ],
          alignment: alignLeft ? 'left' : 'right',
          margin: alignLeft ? [20, 5, 0, 2] : [0, 5, 20, 2],
        },
        {
          text: [
            {
              text: this.docLabel,
            },
            {
              text: `${this.printData.number}`,
              bold: true,
              fontSize: 13,
            },
          ],
          alignment: alignLeft ? 'left' : 'right',
          margin: alignLeft ? [20, 0, 0, 0] : [0, 0, 20, 0],
        },
        // Display due date for Invoices
        (this.printData.status !== 'proposal' && this.printData.status !== 'proposalSent' && this.preferences.dueDateEnabled) ? {
          text: [
            {
              text: `${this.$t('invoice.due')}  `,
            },
            {
              text: this.dueDateInformalPrint(this.printData),
              bold: true,
              fontSize: 13,
            },
          ],
          alignment: alignLeft ? 'left' : 'right',
          margin: alignLeft ? [20, 2, 0, 5] : [0, 2, 20, 5],
        } : {},
        // Display expiration date for Proposals
        ((this.printData.status === 'proposal' || this.printData.status === 'proposalSent') && this.preferences.proposalExpirationDateEnabled) ? {
          text: [
            {
              text: `${this.$t('invoice.expires')}  `,
            },
            {
              text: this.dueDateInformalPrintProposal(this.printData),
              bold: true,
              fontSize: 13,
            },
          ],
          alignment: alignLeft ? 'left' : 'right',
          margin: alignLeft ? [20, 2, 0, 5] : [0, 2, 20, 5],
        } : {},
      ];
    },
    makeLineItems(items) {
      const allItems = [];
      const chargesHeader = {
        layout: {
          hLineColor() {
            return 'grey';
          },
          vLineColor() {
            return 'grey';
          },
        },
        margin: [0, 10, 0, 0],
        table: {
          widths: [520],
          alignment: 'center',
          body: [[{
            fillColor: this.colors.colorBackground.toLowerCase(),
            color: this.colors.colorText.toLowerCase(),
            columns: [
              {
                text: [
                  (this.model.status !== 'proposal'
                    && this.model.status !== 'proposalSent') ? {
                      text: this.preferences.chargesHeader ? this.preferences.chargesHeader : this.$t('invoice.lineItems'),
                      fontSize: 16,
                      bold: true,
                    } : {
                      text: this.preferences.chargesHeaderProposal ? this.preferences.chargesHeaderProposal : this.$t('invoice.lineItems'),
                      fontSize: 16,
                      bold: true,
                    },
                ],
                margin: [15, 5, 0, 5],
                width: '*',
              },
            ],
          }]],
        },
      };
      // Print Line Item Headers
      const headerColumns = [];
      if (this.preferences.dateLineItemOption) {
        headerColumns.push({
          text: this.$t('date'),
          width: '14%',
          alignment: 'center',
          bold: true,
        });
      }
      if (this.preferences.qtyOption) {
        headerColumns.push({
          text: `${this.$t('invoice.qty')}`,
          width: '8%',
          alignment: 'center',
          bold: true,
        });
        headerColumns.push({
          text: `${this.$t('invoice.priceEach')}`,
          width: '10%',
          alignment: 'center',
          bold: true,
        });
      }
      headerColumns.push({
        text: this.$t('invoice.desc'),
        width: '*',
        alignment: 'center',
        bold: true,
      });
      headerColumns.push({
        text: this.$t('invoice.total'),
        width: '10%',
        alignment: 'right',
        bold: true,
      });
      const chargesLabel = {
        columns: headerColumns,
        columnGap: 10,
        fontSize: 16,
        color: this.colors.colorImpact.toLowerCase(),
        decoration: 'underline',
        margin: [15, 10, 10, 3],
      };
      if (items.length) {
        allItems.push(chargesHeader);
        allItems.push(chargesLabel);
      }
      // Print Each Line Item
      for (let i = 0; i < items.length; i += 1) {
        const lineItemColumns = [];
        if (this.preferences.dateLineItemOption) {
          lineItemColumns.push({
            text: moment(items[i].date).format('L'),
            width: '14%',
            alignment: 'center',
          });
        }
        if (this.preferences.qtyOption) {
          lineItemColumns.push({
            text: items[i].qty,
            width: '8%',
            alignment: 'center',
          });
          lineItemColumns.push({
            text: items[i].taxable
              && this.preferences.taxOption
              && this.preferences.taxRate > 0
              ? `${this.displayItemPrice(items[i])} *` : this.displayItemPrice(items[i]),
            width: '10%',
            alignment: 'center',
          });
        }
        lineItemColumns.push({
          text: items[i].item,
          alignment: 'center',
          width: '*',
        });
        lineItemColumns.push({
          text: this.lineTotal(items[i]),
          width: '10%',
          alignment: 'right',
        });
        const item = {
          columns: lineItemColumns,
          margin: [5, 4, 10, 3],
          columnGap: 10,
        };
        allItems.push(item);
      }
      return allItems;
    },
    makeNoteItems(items) {
      const allItems = [];
      const notesToShow = items.filter(i => i.showToCustomer);
      if (notesToShow.length) {
        const notesHeader = {
          layout: {
            hLineColor() {
              return 'grey';
            },
            vLineColor() {
              return 'grey';
            },
          },
          table: {
            widths: [520],
            alignment: 'center',
            body: [[{
              fillColor: this.colors.colorBackground.toLowerCase(),
              color: this.colors.colorText.toLowerCase(),
              columns: [
                {
                  text: [
                    (this.model.status !== 'proposal'
                      && this.model.status !== 'proposalSent') ? {
                        text: this.preferences.noteHeader ? this.preferences.noteHeader : this.$t('invoice.note'),
                        fontSize: 16,
                        bold: true,
                      } : {
                        text: this.preferences.noteHeaderProposal ? this.preferences.noteHeaderProposal : this.$t('invoice.note'),
                        fontSize: 16,
                        bold: true,
                      },
                  ],
                  margin: [15, 5, 0, 5],
                  width: '*',
                },
              ],
            }]],
          },
        };
        allItems.push(notesHeader);
        for (let i = 0; i < notesToShow.length; i += 1) {
          const item = {
            columns: [
              {
                text: this.preferences.showNoteDate ? this.dateDisplay(notesToShow[i].date) : '',
                width: '*',
                alignment: 'left',
              },
              {
                text: notesToShow[i].text,
                width: this.preferences.showNoteDate ? '85%' : '97%',
              },
            ],
            margin: [5, 4, 10, 3],
          };
          allItems.push(item);
        }
      }
      return allItems;
    },
    makeOutstandingItems(items) {
      const allItems = [];
      const outstandingHeader = {
        layout: {
          hLineColor() {
            return 'grey';
          },
          vLineColor() {
            return 'grey';
          },
        },
        table: {
          widths: [520],
          alignment: 'center',
          body: [[{
            fillColor: this.colors.colorBackground.toLowerCase(),
            color: this.colors.colorText.toLowerCase(),
            columns: [
              {
                text: [
                  {
                    text: `${this.$t('invoice.outstandingItemsHeader')}`,
                    fontSize: 16,
                    bold: true,
                  },
                ],
                margin: [15, 5, 0, 5],
                width: '*',
              },
            ],
          }]],
        },
      };
      const outstandingLabel = {
        columns: [
          {
            text: this.$t('invoice.dateDueHeader'),
            alignment: 'center',
            width: '15%',
          },
          {
            text: this.$t('invoice.number'),
            alignment: 'center',
            width: '*',
          },
          (this.model.status !== 'proposal'
          && this.model.status !== 'proposalSent') ? {
              text: this.preferences.chargesHeader ? this.preferences.chargesHeader : this.$t('invoice.lineItems'),
              width: '20%',
              alignment: 'right',
            } : {
              text: this.preferences.chargesHeaderProposal ? this.preferences.chargesHeaderProposal : this.$t('invoice.lineItems'),
              width: '20%',
              alignment: 'right',
            },
          (this.model.status !== 'proposal' && this.model.status !== 'proposalSent') ? {
            text: this.preferences.paymentsHeader ? this.preferences.paymentsHeader : this.$t('invoice.payments'),
            width: '20%',
            alignment: 'right',
          } : {
            text: this.preferences.paymentsHeaderProposal ? this.preferences.paymentsHeaderProposal : this.$t('invoice.payments'),
            width: '20%',
            alignment: 'right',
          },
          {
            text: this.$t('invoice.totalDue'),
            width: '25%',
            alignment: 'right',
            bold: true,
          },
        ],
        columnGap: 10,
        fontSize: 16,
        color: this.colors.colorImpact.toLowerCase(),
        decoration: 'underline',
        margin: [5, 10, 10, 3],
      };
      if (items.length) {
        allItems.push(outstandingHeader);
        allItems.push(outstandingLabel);
      }
      for (let i = 0; i < items.length; i += 1) {
        const invoice = this.$store.getters.invoice(items[i].originalId);
        const item = {
          columns: [
            {
              text: this.dueDateCalc(invoice, 'l'),
              width: '15%',
              alignment: 'center',
            },
            {
              text: invoice.number,
              alignment: 'center',
              width: '*',
            },
            {
              text: this.displayAmount(invoice.totalCharges),
              width: '20%',
              alignment: 'right',
            },
            {
              text: this.displayAmount(invoice.totalPayments),
              width: '20%',
              alignment: 'right',
            },
            {
              text: this.displayAmount(invoice.totalCharges - invoice.totalPayments),
              width: '25%',
              alignment: 'right',
            },
          ],
          margin: [5, 4, 10, 3],
        };
        allItems.push(item);
      }
      return allItems;
    },
    makeMemo() {
      return [
        this.docMemo() ? {
          text: this.docMemo(),
          alignment: 'left',
          bold: true,
          fontSize: 14,
          fillColor: '#dedede',
          color: this.colors.colorImpact.toLowerCase(),
          margin: [0, 15, 0, 8],
        } : {},
      ];
    },
    makePaymentItems(items) {
      const allItems = [];
      const paymentsHeader = {
        layout: {
          hLineColor() {
            return 'grey';
          },
          vLineColor() {
            return 'grey';
          },
        },
        table: {
          widths: [520],
          alignment: 'center',
          body: [[{
            fillColor: this.colors.colorBackground.toLowerCase(),
            color: this.colors.colorText.toLowerCase(),
            columns: [
              {
                text: [
                  (this.model.status !== 'proposal' && this.model.status !== 'proposalSent') ? {
                    text: this.preferences.paymentsHeader ? this.preferences.paymentsHeader : this.$t('invoice.payments'),
                    fontSize: 16,
                    bold: true,
                  } : {
                    text: this.preferences.paymentsHeaderProposal ? this.preferences.paymentsHeaderProposal : this.$t('invoice.payments'),
                    fontSize: 16,
                    bold: true,
                  },
                ],
                margin: [15, 5, 0, 5],
                width: '*',
              },
            ],
          }]],
        },
      };
      // Print payment headers
      const paymentsLabelColumns = [];
      if (this.preferences.datePaymentOption) {
        paymentsLabelColumns.push({
          text: this.$t('date'),
          width: '14%',
          alignment: 'center',
          bold: true,
        });
      }
      paymentsLabelColumns.push({
        text: this.$t('invoice.paymentDetails'),
        width: '*',
        alignment: 'center',
        bold: true,
      });
      paymentsLabelColumns.push({
        text: this.$t('invoice.amount'),
        width: '15%',
        alignment: 'right',
        bold: true,
      });
      const paymentsLabel = {
        columns: paymentsLabelColumns,
        columnGap: 10,
        fontSize: 16,
        color: this.colors.colorImpact.toLowerCase(),
        decoration: 'underline',
        margin: [15, 10, 10, 3],
      };
      allItems.push(paymentsHeader);
      allItems.push(paymentsLabel);
      for (let i = 0; i < items.length; i += 1) {
        const item = items[i];
        const paymentDetails = [];
        if (this.preferences.datePaymentOption) {
          paymentDetails.push({
            text: moment(item.date).format('L'),
            width: '14%',
            alignment: 'center',
          });
        }
        let text = '';
        if (item.type) {
          text += item.type;
          text += '    ';
        }
        if (item.notes) {
          text += item.notes;
        } else {
          text += this.$t('invoice.noPaymentDesc');
        }
        paymentDetails.push({
          text,
          width: '*',
          alignment: 'center',
        });
        paymentDetails.push({
          text: this.displayAmount(item.amount),
          width: '15%',
          alignment: 'right',
        });
        const paymentData = {
          columns: paymentDetails,
          margin: [5, 4, 10, 3],
          columnGap: 10,
        };
        allItems.push(paymentData);
      }
      if (allItems.length > 3) {
        allItems.push({
          text: [
            {
              text: `${this.$t('invoice.totalPayments')}:   `,
            },
            {
              text: this.displayAmount(this.printData.totalPayments),
            },
          ],
          alignment: 'right',
          bold: true,
          fontSize: 14,
          margin: [0, 5, 5, 3],
        });
      }
      return allItems;
    },
    markAsSent(deliveryMethod) {
      let setToSent = false;
      const isProposal = this.model.status === 'proposal' || this.model.status === 'proposalSent';
      if (deliveryMethod === this.$_app.invoice.deliveryMethods[0]
          && this.preferences.markAsSentEmail
          && this.preferences.statusAutoUpdate) {
        setToSent = true;
      }
      if (deliveryMethod === this.$_app.invoice.deliveryMethods[1]
          && this.preferences.markAsSentPrint
          && this.preferences.statusAutoUpdate) {
        setToSent = true;
      }
      if (deliveryMethod === this.$_app.invoice.deliveryMethods[2]
          && this.preferences.markAsSentDownload
          && this.preferences.statusAutoUpdate) {
        setToSent = true;
      }
      if (setToSent) {
        if (isProposal) {
          this.model.deliveryMethod.proposal = deliveryMethod;
        } else {
          this.model.deliveryMethod.invoice = deliveryMethod;
        }
        if (isProposal) {
          this.model.status = 'proposalSent';
        } else {
          this.model.status = null;
          this.model.status = 'sent';
        }
        this.model.notes.push({
          _id: this.$guid(),
          date: new Date(),
          text: !isProposal ? this.$t('invoice.hasBeenSent') : this.$t('invoice.hasBeenSentProposal'),
          showToCustomer: false,
          systemGenerated: true,
        });
        if (deliveryMethod !== this.$_app.invoice.deliveryMethods[0]) {
          this.model.notes.push({
            _id: this.$guid(),
            date: new Date(),
            text: `${this.$t('invoice.hasBeenSentMethod')} ${deliveryMethod}`,
            showToCustomer: false,
            systemGenerated: true,
          });
        } else {
          this.model.notes.push({
            _id: this.$guid(),
            date: new Date(),
            text: `${this.$t('invoice.hasBeenSentMessageCopy')} ${this.email.message}`,
            showToCustomer: false,
            systemGenerated: true,
          });
          this.model.notes.push({
            _id: this.$guid(),
            date: new Date(),
            text: `${this.$t('invoice.hasBeenSentEmail')} ${this.email.to}`,
            showToCustomer: false,
            systemGenerated: true,
          });
        }
      }
      this.$store.dispatch('updateInvoice', this.model);
    },
    modalHidden() {
      if (this.launchPreview) {
        this.$emit('close-preview');
        this.$store.commit('LAUNCH_PREVIEW_CLEAR');
      }
    },
    nameFile() {
      return this.docLabel ? `${this.docLabel}-${this.model.number}.pdf` : `${this.model.number}.pdf`;
    },
    pdfCancel() {
      this.$emit('clear');
    },
    previewPdf() {
      this.fileAction = {
        cmd: 'open',
        _id: this.model._id,
      };
      this.buttonSpinner.target = 'preview';
      this.buttonSpinnerActive = true;
      this.getPrintData();
    },
    async previewPdfReady() {
      const file = pdfMake.createPdf(this.printDoc);
      await file.getDataUrl((data) => {
        this.previewFile.data = data;
        this.$root.$emit('bv::show::modal', `previewModal_${this.printData._id}`);
      });
    },
    async previewPdfInsideEmailModal() {
      const file = pdfMake.createPdf(this.printDoc);
      await file.getDataUrl((data) => {
        this.previewFile.data = data;
        this.previewFileInsideEmailModal = true;
      });
    },
    printPdf() {
      this.fileAction = {
        cmd: 'print',
        _id: this.model._id,
      };
      this.buttonSpinner.target = 'print';
      this.buttonSpinnerActive = true;
      this.getPrintData();
    },
    processPrintData() {
      console.log('Processing print data.');
      this.buttonSpinner.target = null;
      this.buttonSpinnerActive = false;
      this.printData.customer = this.$store.getters.customer(this.printData.customer);
      this.printDoc = this.makePrintDoc(this.printData);
      if (this.fileAction.cmd === 'open') {
        this.previewPdfReady();
      }
      if (this.fileAction.cmd === 'print') {
        this.markAsSent(this.$_app.invoice.deliveryMethods[1]);
        pdfMake.createPdf(this.printDoc).print(this.printDoc.info.title);
      }
      if (this.fileAction.cmd === 'download') {
        this.markAsSent(this.$_app.invoice.deliveryMethods[2]);
        pdfMake.createPdf(this.printDoc).download(this.printDoc.info.title);
      }
      if (this.fileAction.cmd === 'email') {
        this.emailModalLauncher();
      }
    },
    async processLogo() {
      console.log('re-processing logo');
      if (this.preferences.userLogo) {
        this.waitForLogo = true;
        await image2base64(this.preferences.userLogo).then((imgData) => {
          console.log('logo has been processed');
          this.logo.data = imgData;
          this.logo.location = this.preferences.userLogo;
          this.waitForLogo = false;
        }).catch((err) => {
          console.log(err);
          this.waitForLogo = false;
        });
      } else {
        console.log('clearing logo');
        this.$store.dispatch('clearLogo');
      }
    },
    retainerDue(model) {
      let retainerDue = 0;
      const total = model.totalCharges * 1;
      if (this.preferences.proposalRetainer.type === 'percentage') {
        retainerDue = (total * (this.preferences.proposalRetainer.percent / 100)) * 1;
      }
      if (this.preferences.proposalRetainer.type === 'setAmount') {
        retainerDue = this.preferences.proposalRetainer.setAmount * 1;
      }
      return retainerDue.toFixed(2);
    },
    sendFile() {
      console.log('sending', this.email.customerId);
      this.$store.dispatch('sendFile', this.email);
      this.readyToSendFile = false;
      this.$bvModal.hide(`emailModal_${this.model._id}`);
    },
  },
  watch: {
    launchPreview(newVal) {
      if (newVal === this.model._id) {
        this.previewPdf();
      }
    },
    printData(newVal) {
      if (!this.preferences.userName) {
        this.$store.commit('NO_NAME');
        return;
      }
      if (newVal._id === this.model._id) {
        if (!this.waitForLogo) {
          this.processPrintData();
        } else {
          console.log('must wait for logo to load');
          const logoWatcher = setInterval(() => {
            if (!this.waitForLogo) {
              console.log('Logo ready.');
              this.processPrintData();
              clearInterval(logoWatcher);
            } else {
              console.log('still waiting for logo...');
            }
          }, 250);
          // eslint-disable-next-line no-unused-expressions
          logoWatcher;
        }
      }
    },
    sendSuccess(confirmation) {
      if (confirmation) {
        this.markAsSent(this.$_app.invoice.deliveryMethods[0]);
        this.sendSuccess = null;
      }
    },
  },
};
</script>
