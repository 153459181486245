<template>
  <div
      class="scheduler__calendar__container">
    <transition
        name="slide-fade"
        mode="out-in">
      <!-- Regular weekly schedule -->
      <div
          id="regularAvailabilityContainer"
          key="regularAvailabilty"
          v-if="!addingException">
        <h2
            v-text="$t('scheduler.regularSchedule')"
        />
        <b-row
            align-h="center">
          <b-col
              align="center"
              cols="*"
              id="mondayAvailability"
              class="scheduler__segment__container">
            <availability-controls
                :dayOfWeek="1"
                :showDate="false"
                class="mx-auto"
            />
          </b-col>
        </b-row>
        <b-row
            align-h="center">
          <b-col
              align="center"
              cols="*"
              id="tuesdayAvailability"
              class="scheduler__segment__container">
            <availability-controls
                :dayOfWeek="2"
                :showDate="false"
                class="mx-auto"
            />
          </b-col>
        </b-row>
        <b-row
            align-h="center">
          <b-col
              align="center"
              cols="*"
              id="wednedsayAvailability"
              class="scheduler__segment__container">
            <availability-controls
                :dayOfWeek="3"
                :showDate="false"
                class="mx-auto"
            />
          </b-col>
        </b-row>
        <b-row
            align-h="center">
          <b-col
              align="center"
              cols="*"
              id="thursdayAvailability"
              class="scheduler__segment__container">
            <availability-controls
                :dayOfWeek="4"
                :showDate="false"
                class="mx-auto"
            />
          </b-col>
        </b-row>
        <b-row
            align-h="center">
          <b-col
              align="center"
              cols="*"
              id="fridayAvailability"
              class="scheduler__segment__container">
            <availability-controls
                :dayOfWeek="5"
                :showDate="false"
                class="mx-auto"
            />
          </b-col>
        </b-row>
        <b-row
            align-h="center">
          <b-col
              align="center"
              cols="*"
              id="saturdayAvailability"
              class="scheduler__segment__container">
            <availability-controls
                :dayOfWeek="6"
                :showDate="false"
                class="mx-auto"
            />
          </b-col>
        </b-row>
        <b-row
            align-h="center">
          <b-col
              align="center"
              cols="*"
              id="sundayAvailability"
              class="scheduler__segment__container">
            <availability-controls
                :dayOfWeek="0"
                :showDate="false"
                class="mx-auto"
            />
          </b-col>
        </b-row>
        Exceptions
        Exceptions in exceptions
        <b-btn
            id="addExceptionButton"
            @click="addingException = true;"
            v-text="$t('scheduler.addException')"
        />
        Add Exception
      </div>
      <!-- Adding schedule exception -->
      <div
          key="addingException"
          id="addingExceptionDiv"
          v-else>
      Adding Exception
        <date-picker
            key="noDatesChosen"
            v-if="!model.length"
            :disableSelectType="false"
            :startingSelectType="0"
            @datesChosen="datesSelected"
        />
        <div
            key="datesChosen"
            v-else>
          <b-row
              id="headerAndAllSameOptions">
            <b-col
                cols="*"
                align="center"
                class="form__header__small mx-auto">
              <div
                  class="mx-4 my-2"
                  id="multipleDatesSelected"
                  v-if="model.length > 1">
                <h2
                    class="px-4">
                  <b-btn
                      id="clearSelection"
                      @click="model = []"
                      variant="warning-outline"
                      class="px-3 my-2">
                    <b-icon-x-circle
                        :scale="0.8"
                    />
                    <span
                        v-text="$t('scheduler.clearSelection')"
                    />
                  </b-btn>
                  <b
                      v-text="model.length"
                      class="ml-3"
                  />
                  <span
                      v-text="$t('scheduler.datesSelected')"
                      class="mr-3"
                  />
                  <b-btn
                      id="showSelectedDates"
                      v-if="selectedDates.length > 1"
                      size="sm"
                      :aria-label="showSelectedDates ? $t('aria.hideSelectedDates') : $t('aria.showSelectedDates')"
                      @click="showSelectedDates = !showSelectedDates">
                    <b-icon
                        :icon="showSelectedDates ? 'eye-slash-fill' : 'eye-fill'"
                    />
                  </b-btn>
                </h2>
                <div
                    v-if="showSelectedDates">
                  <b-row
                      v-for="(i, index) in Math.ceil(selectedDates.length / 2)"
                      :key="index"
                      class="my-1">
                    <b-col
                        v-for="(date, index) in selectedDates.slice((i - 1) * 2, i * 2)"
                        :key="index"
                        style="white-space:nowrap">
                      {{date}}
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div
                  class="mx-2 my-1"
                  id="oneDateSelected"
                  v-else>
                <h2
                    class="my-auto">
                  <b
                      v-text="dateString(model[0], true)"
                  />
                  <b-btn
                      id="clearSelection"
                      @click="model = []"
                      variant="warning-outline"
                      class="px-1 ml-4"
                      size="sm">
                    <b-icon-x-circle
                        :scale="0.7"
                    />
                    <span
                        v-text="$t('scheduler.clearSelection')"
                    />
                  </b-btn>
                </h2>
              </div>
              <b-btn-group
                  id="allSameOptions"
                  v-if="model.length > 1"
                  class="my-2">
                <b-btn
                    id="allSameAvailability"
                    @click="allSame = true"
                    :variant="allSame ? 'secondary' : 'secondary-outline'"
                    v-text="$t('scheduler.allSameOptions[0]')"
                />
                <b-btn
                    id="differentAvailability"
                    @click="allSame = false"
                    :variant="allSame ? 'secondary-outline' : 'secondary'"
                    v-text="$t('scheduler.allSameOptions[1]')"
                />
              </b-btn-group>
            </b-col>
          </b-row>
          <transition
              mode="out-in"
              name="slide-fade">
            <b-row
                v-if="allSame && model.length !== 1"
                key="allSame"
                align-h="center">
              <b-col
                  align="center"
                  cols="*">
                <availability-controls
                    :dateObj="model[0]"
                    :showDate="false"
                    class="mx-auto"
                />
              </b-col>
            </b-row>
            <div
                key="allDifferent"
                v-else>
              <b-row
                  v-for="date in model"
                  :key="date.getTime()"
                  align-h="center">
                <b-col
                    align="center"
                    cols="*">
                  <availability-controls
                      :dateObj="date"
                      :showDate="model.length > 1"
                      class="mx-auto"
                  />
                </b-col>
              </b-row>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import totalCalculators from '@/mixins/totalCalculators';
import DatePicker from '@/components/data/DatePicker';
import AvailabilityControls from '@/components/data/AvailabilityControls';

export default {
  name: 'GeneralAvailability',
  title() {
    return ` | ${this.$t('scheduler.generalAvailabilityHeader')}`;
  },
  components: {
    AvailabilityControls,
    DatePicker,
  },
  mixins: [
    totalCalculators,
  ],
  data() {
    return {
      addingException: false,
      allSame: true,
      model: [],
      showSelectedDates: false,
    };
  },
  computed: {
    selectedDates() {
      // List adjacent dates as 11/1 - 11/3
      const list = [];
      const dates = this.model;
      dates.sort((a, b) => ((a > b) ? 1 : -1));
      for (let a = 0; a < dates.length; a += 1) {
        let string = this.dateString(this.model[a], true);
        if (this.model[a + 1]
        && new Date(moment(this.model[a]).add(1, 'days')).getTime() === this.model[a + 1].getTime()) {
          while (this.model[a + 1]
          && new Date(moment(this.model[a]).add(1, 'days')).getTime() === this.model[a + 1].getTime()) {
            a += 1;
          }
          string += `  -  ${this.dateString(this.model[a], true)}`;
        }
        list.push(string);
      }
      return list;
    },
  },
  methods: {
    datesSelected(dates) {
      dates.forEach(d => console.log(d));
      this.model = dates;
      if (this.selectedDates.length === 1) {
        this.showSelectedDates = true;
      }
    },
  },
  watch: {},
};
</script>
