<template>
  <b-container
      fluid
      class="align-self-end text-left">
    <small
        v-if="$_app.switch.foot.createdBy"
        class="p-1">
      ©{{returnYear()}} {{$t('project.footerName')}}
      <span class="px-1"> | </span>
      <a
          style="cursor: pointer"
          @click="$router.push('/privacy')">
        <span
            v-text="$t('privacyPolicy')"
        />
      </a>
      <span class="px-1"> | </span>
      <span
          v-html="$t('project.footerInfo')"
      />
    </small>
  </b-container>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Footer',
  methods: {
    returnYear() {
      return moment().format('YYYY');
    },
  },
};
</script>
