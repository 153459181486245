export default {
  data() {
    return {
      sysBreakpoint: this.sysBreakpointCalc(),
      sysBreakpointEnable: false,
      windowWidth: this.getWindowWidth(),
    };
  },
  mounted() {
    // eslint-disable-next-line func-names
    if (this.sysBreakpointEnable) {
      window.addEventListener('resize', this.customDebounce(this.getWindowWidth, 450));
      this.getWindowWidth();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.customDebounce(this.getWindowWidth, 450));
  },
  methods: {
    sysBreakpointCalc() {
      if (this.windowWidth <= 571) {
        this.sysBreakpoint = 'sm';
      } else if (this.windowWidth <= 991) {
        this.sysBreakpoint = 'md';
      } else {
        this.sysBreakpoint = 'lg';
      }
    },
    customDebounce(fn, delay) {
      let timeoutID = null;
      // eslint-disable-next-line func-names
      return function () {
        clearTimeout(timeoutID);
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        const that = this;
        timeoutID = setTimeout(() => {
          fn.apply(that, args);
        }, delay);
      };
    },
    getWindowWidth() {
      this.windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth);
      this.sysBreakpointCalc();
    },
  },
};
