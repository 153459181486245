<template>
  <div>
    <span
        v-for="(option, index) in model"
        v-html="index === 0 ? option : `, ${option}`"
        class="dataViewer__data"
        :key="index"
    />
  </div>
</template>
<script>
export default {
  props: ['model'],
};
</script>
