<template>
  <a
      v-if="model"
      :href="`mailto:${model}`"
      style="text-decoration: none">
    {{model}}
  </a>
</template>
<script>

export default {
  props: ['model'],
};
</script>
