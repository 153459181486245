<template>
  <div
      key="badge"
      :id="badgeId"
      :class="!showThis
              ? 'invoice__fileBadge invoice__fileBadge__container'
              : 'invoice__fileBadge__selected invoice__fileBadge__container'"
      @click="activeToggle()">
    <transition-group
        name="fade"
        mode="out-in">
      <b-row
          key="primary"
          align-v="center">
        <b-col
            id="invoiceNumberContainer"
            sm="6"
            md="6"
            lg="2"
            :class="showThis
                    ? 'invoice__fileBadge__invoiceNumber__container__selected'
                    : 'invoice__fileBadge__invoiceNumber__container'">
          <transition
              name="fade"
              mode="out-in">
            <div
                key="invoiceNumber"
                v-if="!showThis">
              <span
                  :id="`invoiceNumber-defaultView-${model.number}`"
                  :class="model.number.length < 13 ? 'invoice__fileBadge__invoiceNumber' : 'invoice__fileBadge__invoiceNumber__small'"
                  v-text="model.number"
              />
              <br />
              <span
                  :id="`${model.number}-customerName-defaultView`"
                  class="invoice__fileBadge__text__customerIdentifier"
                  v-text="customer && customerIdentifier(customer) !== $t('invoice.customerNoId') ? customerIdentifier(customer) : ''"
              />
            </div>
            <div
                key="invoiceNumberEmphasis"
                v-else>
              <small
                v-if="displayPrefix"
                v-text="displayPrefix">
              </small>
              <span>
                #
              </span>
              <span
                  class="invoice__fileBadge__text__emphasis"
                  v-text="model.number"
              />
            </div>
          </transition>
        </b-col>
        <b-col
            id="status"
            sm="6"
            md="6"
            lg="2"
            class="mr-auto">
          <div
              :id="`statusDisplay-${model._id}`"
              class="invoice__fileBadge__status__container">
            <b-btn-group>
              <b-btn
                  id="flagButton"
                  @click.stop="flagThis()"
                  :aria-label="$t('aria.flagInvoice')"
                  :variant="model.flagged ? 'secondary' : 'tertiary'"
                  class="invoice__buttons__flagged">
                <icon
                    :icon="model.flagged ? ['fas', 'bookmark'] : ['far', 'bookmark']"
                />
              </b-btn>
              <b-dropdown
                  :id="`changeStatusDropdown-${model._id}`"
                  ref="dropdown"
                  :text="statusOption(model.status)"
                  :variant="statusDropdownColor(model.status)"
                  no-caret
                  class="invoice__fileBadge__status">
                <b-dropdown-item-button
                    v-for="(option, index) in statusOptions"
                    :key="index"
                    class="invoice__fileBadge__status__options"
                    @click.stop="updateStatus(option.key)">
                  {{option.text}}
                </b-dropdown-item-button>
              </b-dropdown>
            </b-btn-group>
          </div>
        </b-col>
        <b-col
            id="dueDateAndTitleContainer"
            sm="6"
            md="4"
            class="invoice__fileBadge__contentContainer">
           <div
              id="invoiceTitle"
              v-if="model.title">
            <span
                v-text="model.title"
                class="invoice__fileBadge__text__medium"
            />
          </div>
          <div
              id="dateDisplay">
            <span
                :id="`dateType-${model._id}`"
                v-text="dateReturnType(model)"
                v-if="preferences.showDateLabels"
                class="invoice__fileBadge__text__small"
            />
            <span
                :id="`dateText-${model._id}`"
                v-text="dateDisplay(dateReturn(model))"
                class="invoice__fileBadge__text__large mr-3"
            />
            <span
                v-if="!preferences.dueDateEnabled"
            />
            <span
                v-else-if="preferences.dueDateEnabled
                    && (subtotalCalculator(model.lineItems) > 0
                    && totalDue(model.lineItems, model.payments, { enable: preferences.taxOption, rate: preferences.taxRate }) > 0)"
                class="invoice__fileBadge__text__small">
              {{$t('invoice.due')}}
              <span
                  :id="`dateDueText-${model._id}`"
                  v-text="dueDateInformal(model)"
                  class="invoice__fileBadge__text__big"
              />
            </span>
            <span
                v-else-if="subtotalCalculator(model.lineItems) === 0"
                v-text="$t('invoice.noDue')"
                class="invoice__fileBadge__text__lowKey"
            />
            <span
                v-else-if="preferences.dueDateEnabled
                    && (subtotalCalculator(model.lineItems) > 0
                    && totalDue(model.lineItems, model.payments, { enable: preferences.taxOption, rate: preferences.taxRate }) <= 0)"
                class="invoice__fileBadge__text__large">
              {{$t('invoice.paid')}}
              <span
                  :id="`dateOfLastPayment-${model._id}`"
                  v-if="model.payments.length > 0"
                  v-text="lastPaymentDate"
                  class="invoice__fileBadge__text__lowKey"
              />
            </span>
          </div>
        </b-col>
        <b-col
            id="financeCol-FirstLine"
            sm="6"
            md="6"
            lg="4"
            align="center"
            class="invoice__fileBadge__contentContainer"
            v-if="!showThis">
          <transition
              name="fadeQuick"
              mode="out-in">
            <div
                key="balanceDue"
                v-if="!showThis"
                id="balance">
              <span
                  class="invoice__fileBadge__text__small"
                  v-text="`${totalOutstanding < 1 ? $t('invoice.total') : $t('invoice.lineItems')} ${displayAmount(model.totalCharges)}`"
              />
              <div
                  v-if="totalOutstanding > 0">
                <span
                    class="invoice__fileBadge__text__small ml-3"
                    v-text="$t('invoice.pastDue')"
                />
                <span
                    class="invoice__fileBadge__text_small"
                    v-text="totalOutstandingDisplay"
                />
              </div>
              <div
                  v-if="!spinnerById"
                  :class="(totalDueSimple(model.totalCharges, model.totalPayments) === 0 && totalOutstanding === 0)
                          ? 'invoice__fileBadge__text__lowKey' :''">
                <span
                    class="invoice__fileBadge__text__small"
                    v-text="$t('invoice.bal')"
                />
                <span
                    v-text="displayInvoiceTotal(model.totalCharges, totalOutstanding, model.totalPayments)"
                    :class="totalDueSimple(model.totalCharges, model.totalPayments) === 0
                            && totalOutstanding === 0
                            ? 'invoice__fileBadge__text__small' : 'invoice__fileBadge__text__big'"
                />
              </div>
              <div
                  v-else
                  id="loader">
                <loader
                    color="white"
                    type="bars"
                    :h="42"
                    :w="102"
                    :fullPage="false"
                />
              </div>
            </div>
          </transition>
        </b-col>
        <b-col
            id="fileOptionsButtons-SecondLine"
            sm="6"
            md="4"
            lg="4"
            align="left"
            class="my-auto invoice__fileBadge__contentContainer"
            v-else>
          <div
              key="fileOptionButtons">
            <b-btn-group>
              <b-btn
                  id="fileDeliveryActionsButton"
                  @click.stop="activeItemDeliver()"
                  :variant="activeItem === 5 ? 'secondary' : 'tertiary'"
                  v-text="$t('invoice.fileDeliver')"
                  size="lg"
                  class="invoice__buttons__options"
              />
              <b-btn
                  id="showNotesButton"
                  @click.stop="activeItemNotes()"
                  :variant="activeItem === 3 ? 'secondary' : 'tertiary'"
                  class="invoice__buttons__options">
                <span
                    v-text="$t('invoice.notes')"
                />
                ({{model.notes.length}})
              </b-btn>
              <b-btn
                  id="showOptionsButton"
                  @click.stop="activeItemOptions()"
                  :variant="activeItem === 4 ? 'secondary' : 'tertiary'"
                  v-text="$t('invoice.options')"
                  class="invoice__buttons__options"
              />
              <b-btn
                  id="previewPdf"
                  variant="secondary"
                  @click.stop="previewPdf()"
                  size="sm"
                  class="invoice__buttonBar__pdfOptions"
                  :aria-label="$t('aria.filePreview')">
                <loader
                    v-if="buttonSpinnerActive
                        && buttonSpinner.target !== 'preview'"
                    :color="buttonSpinner.color"
                    :type="buttonSpinner.type"
                    :h="buttonSpinner.h"
                    :w="buttonSpinner.w"
                    :fullPage="false"
                />
                <div
                    v-else>
                  <icon
                      :icon="['fa', 'eye']"
                      :aria-label="$t('aria.preview')"
                    />
                </div>
              </b-btn>
            </b-btn-group>
          </div>
        </b-col>
      </b-row>
      <b-row
          key="secondLine"
          v-if="showThis"
          class="mt-3"
          align-v="center"
          @click.stop>
        <b-col
            id="fileOptionButtonsContainer"
            sm="6"
            md="4"
            lg="4"
            class="invoice__fileBadge__customerName__container"
            @click="seeThis()">
          <span
              class="invoice__fileBadge__customerName"
              :style="customerIdentifier(customer) !== $t('invoice.customerNoId') ? '' : 'font-size: 0.85em'"
              v-text="customerIdentifier(customer)"
          />
        </b-col>
        <b-col
            id="financeCol-totalChargesAndPayments"
            sm="6"
            md="4"
            lg="4"
            align-self="center"
            class="invoice__fileBadge__contentContainer">
          <transition
              name="fadeQuick"
              mode="out-in">
            <div
                key="chargesAndPayments"
                id="balance">
              <b-btn-group>
                <b-btn
                    :id="`showLineItems${model._id}`"
                    @click.stop="activeItemCharges()"
                    class="invoice__fileBadge__buttons__openDetails"
                    :variant="activeItem === 1 ? 'primary' : 'tertiary'"
                    :aria-label="$t('aria.showLineItems')">
                  {{$t('invoice.lineItems')}}
                  <br />
                  <span
                      v-text="displayTotal(model.lineItems, { enable: preferences.taxOption, rate: preferences.taxRate })"
                      class="invoice__fileBadge__buttons__openDetails__amount"
                  />
                </b-btn>
                <b-btn
                    :id="`showPayments${model._id}`"
                    @click.stop="activeItemPayments()"
                    class="invoice__fileBadge__buttons__openDetails"
                    :variant="activeItem === 2 ? 'primary' : 'tertiary'"
                    :aria-label="$t('aria.showPayments')">
                  {{$t('invoice.payments')}}
                  <br />
                  <span
                      v-text="displayTotalPayments(model.payments)"
                      class="invoice__fileBadge__buttons__openDetails__amount"
                  />
                </b-btn>
                <b-btn
                    :id="`showOutstanding${model._id}`"
                    v-if="totalOutstanding > 0"
                    @click.stop="activeItemOutstanding()"
                    class="invoice__fileBadge__buttons__openDetails"
                    :variant="activeItem === 6 ? 'primary' : 'tertiary'"
                    :aria-label="$t('aria.showOutstanding')">
                  {{$t('invoice.outstandingItems')}}
                  <br />
                  <span
                      v-text="totalOutstandingDisplay"
                      class="invoice__fileBadge__buttons__openDetails__amount"
                  />
                </b-btn>
              </b-btn-group>
            </div>
          </transition>
        </b-col>
        <b-col
            id="financeCol-balance"
            sm="6"
            md="4"
            lg="4"
            class="invoice__fileBadge__balance__container">
          <div
              v-if="!spinnerById">
            <!-- <div
                v-if="totalOutstanding(model.outstandingItems) > 0">
              <span
                  class="invoice__fileBadge__text__small ml-3"
                  v-text="$t('invoice.pastDue')"
              />
              <span
                  :class="totalOutstanding(model.outstandingItems) > totalDueSimple(model.totalCharges, model.totalPayments)
                          ? 'invoice__fileBadge__text__big' : 'invoice__fileBadge__text_small'"
                  v-text="totalOutstanding(model.outstandingItems, 'display')"
              />
            </div> -->
            <span
                v-text="$t('invoice.bal')"
                class="invoice__fileBadge__text__small"
            />
            <span
                v-text="displayInvoiceTotal(model.totalCharges, totalOutstanding, model.totalPayments)"
                class="invoice__fileBadge__text__emphasis"
            />
          </div>
          <div
              v-else>
            <loader
              color="white"
              type="bars"
              :h="40"
              :w="100"
              :fullPage="false"
            />
          </div>
        </b-col>
      </b-row>
      <div
          key="details"
          id="middleRowContentContainer"
          v-if="showThis && activeItem > 0"
          @click.stop
          class="invoice__fileBadge__addItems__container">
        <transition
            name="fadeQuick"
            mode="out-in">
          <b-row
              key="charges"
              v-if="activeItem === 1"
              @click.stop>
            <!-- Line Item Table Display -->
            <b-col
                id="lineItemsTableContainer"
                v-if="!addingLinkedItem">
              <transition
                  name="fade"
                  mode="out-in">
                <b-table
                    id="lineItemTable"
                    v-if="model.lineItems.length !== 0"
                    :fields="lineItemTableFields"
                    :items="model.lineItems"
                    :per-page="perPage"
                    stacked="md"
                    class="invoice__fileBadge__addItems__table"
                    @row-hovered="tableLineItemRowHover"
                    @row-unhovered="tableLineItemRowUnhover"
                    @row-clicked="tableLineItemRowClicked"
                    :tbody-transition-props="{name: 'fade'}">
                  <template
                      v-slot:row-details="row">
                    <div
                        id="linkedLineItemInfo"
                        v-if="row.item.link
                          && row.item.link._record
                          && deleteChargeRequest !== row.item._id"
                        class="invoice__fileBadge__linkedData__linkInfo">
                      <b-row
                          align-h="center">
                        <b-col
                            id="linkedBanner"
                            cols="auto"
                            class="mx-4">
                          <span
                              style="font-size: 1.3em"
                              class="my-auto">
                            <b-icon-link
                                :scale="1.7"
                                class="mr-3"
                            />
                            {{$t('invoice.linked.linkedFrom')}}
                            <b>
                                {{$store.getters.collection(row.item.link._collection).name}}
                            </b>
                          </span>
                          <br />
                          <b-btn
                              @click="seeLinkedData(row.item.link)"
                              size="sm"
                              variant="info-outline"
                              class="my-2 mx-2">
                            <b-icon-eye />
                            {{$t('invoice.linked.seeLinked')}}
                          </b-btn>
                          <small>
                            {{$t('invoice.linked.linkedOn')}} {{dateDisplay(row.item.link.linkedOn)}}
                          </small>
                        </b-col>
                        <b-col
                            id="syncQty"
                            cols="3"
                            class="my-auto mx-3">
                          <b-input-group
                              v-if="row.item.link.qty.field">
                            <b-form-checkbox
                                :id="`syncQty-${row.item.link.qty.field}`"
                                switch
                                size="lg"
                                class="ml-auto mr-1"
                                @input="saveRequest()"
                                v-model="row.item.link.qty.sync"
                            />
                            <b-input-group-append
                                class="ml-1 mr-auto mt-1">
                              {{$t('invoice.linked.qtySync[0]')}}
                              <b-icon-question-circle-fill
                                  id="syncQtyTooltip"
                                  class="ml-2 mt-1"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <span
                              v-if="deleteLinkRequest === row.item._id
                              && row.item.link.qty && row.item.link.qty.sync">
                            {{row.item.qty}} {{$t('invoice.linked.toBeReturned[0]')}}
                            {{$store.getters.collection(row.item.link._collection).schemaObj[row.item.link.qty.field].label}}
                            {{$t('invoice.linked.toBeReturned[1]')}} {{$store.getters.collection(row.item.link._collection).name}}
                          </span>
                          <b-tooltip
                              target="syncQtyTooltip">
                            <span>
                              {{$t('invoice.linked.qtySync[1]')}}
                              <b>
                                '{{$store.getters.collection(row.item.link._collection).schemaObj[row.item.link.qty.field].label}}'
                              </b>
                              {{$t('invoice.linked.qtySync[2]')}}
                              <p />
                              <i>
                                {{$t('invoice.linked.qtySync[3]')}}
                              </i>
                            </span>
                          </b-tooltip>
                        </b-col>
                        <b-col
                            cols="4"
                            class="my-auto"
                            align="right">
                          <transition
                              mode="out-in"
                              name="fadeQuick">
                            <div
                                id="refreshAndDeleteLinkOptions"
                                key="defaultView"
                                v-if="$store.state.spinnerById !== row.item._id
                                && deleteLinkRequest !== row.item._id">
                              <b-input-group
                                  id="refreshLinkedData"
                                  class="ml-auto mr-1">
                                <b-btn
                                    class="ml-auto mr-1"
                                    @click="refreshLinkedData(row.item)"
                                    size="sm"
                                    v-if="row.item.link.price || row.item.link.text.length">
                                  <b-icon-arrow-repeat />
                                  {{$t('invoice.linked.refresh')}}
                                </b-btn>
                                <b-input-group-append
                                    id="refreshDataTooltip"
                                    class="ml-1 mr-1 mt-2">
                                  <b-icon-question-circle-fill />
                                </b-input-group-append>
                              </b-input-group>
                              <b-tooltip
                                  target="refreshDataTooltip"
                                  class="ml-1 mr-1">
                                <h5>
                                  {{$t('invoice.linked.refresh')}}
                                </h5>
                                {{$t('tooltips.linkedItemRefresh[0]')}} {{$store.getters.collection(row.item.link._collection).name}} {{$t('tooltips.linkedItemRefresh[1]')}}
                              </b-tooltip>
                              <b-input-group
                                  id="removeLinkReceipt"
                                  class="ml-auto mr-1 mt-3">
                                <b-btn
                                    @click="deleteLinkRequest = row.item._id"
                                    class="ml-auto mr-1"
                                    size="sm"
                                    variant="warning">
                                  <b-icon-x />
                                  {{$t('invoice.linked.remove')}}
                                </b-btn>
                                <b-input-group-append
                                    id="removeDataLinkTooltip"
                                    class="ml-1 mr-1 mt-2">
                                  <b-icon-question-circle-fill />
                                </b-input-group-append>
                              </b-input-group>
                              <b-tooltip
                                  target="removeDataLinkTooltip">
                                <h5>
                                  {{$t('invoice.linked.removeHeader')}}
                                </h5>
                                {{$t('invoice.linked.removeText[0]')}} {{$store.getters.collection(row.item.link._collection).name}}.
                                <br />
                                {{$t('invoice.linked.removeText[1]')}}
                              </b-tooltip>
                            </div>
                            <div
                                key="loadingSpinner"
                                v-else-if="$store.state.spinnerById === row.item._id">
                              <loader
                                  color="black"
                                  type="bars"
                                  :h="50"
                                  :w="222"
                                  :fullPage="false"
                              />
                            </div>
                            <div
                                key="deleteConfirm"
                                v-else>
                              <h3>
                                {{$t('invoice.linked.removeText[2]')}}
                              </h3>
                              <b-btn-toolbar
                                  id="confirmDelete">
                                <b-btn
                                    id="cancelDeleteLink"
                                    class="ml-auto mr-2"
                                    variant="tertiary"
                                    @click="deleteLinkRequest = null">
                                  <b-icon-arrow-left /> {{$t('cancel')}}
                                </b-btn>
                                <b-btn
                                    id="confirmDeleteLink"
                                    class="ml-2 mr-1"
                                    variant="danger"
                                    @click="removeLinkReceipt(row.item._id)">
                                  <b-icon-check /> {{$t('invoice.linked.removeHeader')}}
                                </b-btn>
                              </b-btn-toolbar>
                              <span>
                                {{$t('invoice.linked.removeText[1]')}}
                              </span>
                            </div>
                          </transition>
                        </b-col>
                      </b-row>
                    </div>
                    <div
                        v-if="row.item.link
                          && row.item.link._record
                          && deleteChargeRequest === row.item._id
                          && row.item.link.qty
                          && row.item.link.qty.field
                          && row.item.link.qty.sync"
                        class="invoice__fileBadge__linkedData__linkInfo">
                      <h4>
                        <b-icon-link
                            :scale="1.4"
                            class="mr-1"
                        />
                        {{$t('invoice.linked.linked')}}
                      </h4>
                      <b-input-group>
                        <b-form-checkbox
                            id="qtySyncConfirmRemoval"
                            v-model="row.item.link.qty.sync"
                            size="lg"
                            class="ml-auto mr-1"
                        />
                        <b-input-group-append
                            class="mr-auto ml-1">
                          <label
                              for="qtySyncConfirmRemoval">
                            {{$t('invoice.linked.syncQtyRemoval[0]')}} {{row.item.qty}}
                            {{$t('invoice.linked.syncQtyRemoval[1]')}} <b>{{$store.getters.collection(row.item.link._collection).schemaObj[row.item.link.qty.field].label}}</b>
                            {{$t('invoice.linked.syncQtyRemoval[2]')}} {{$store.getters.collection(row.item.link._collection).name}}
                          </label>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </template>
                  <template
                      v-slot:cell(date)="row">
                    <transition
                        mode="out-in"
                        name="slide-fade-alt">
                      <b-btn
                          id="chargeDateDisplay"
                          @click="dateConvertCharge(row.index)"
                          v-text="dateDisplayCharge(row.index)"
                          variant="info"
                          v-if="convertDate !== row.item._id"
                          :aria-label="$t('sys.clickDate')"
                      />
                      <b-form-input
                          id="changeLineItemDateInput"
                          class="invoice__fileBadge__inputs__date"
                          v-else
                          type="date"
                          @change="dateChangeCharge(row.index)"
                          v-model="model.lineItems[`${row.index}`].date"
                      />
                    </transition>
                  </template>
                  <template
                      v-slot:cell(price)="row">
                    <div
                        class="invoice__fileBadge__inputs__price">
                      <transition
                          mode="out-in"
                          name="slide-fade-alt">
                        <span
                            v-if="changeLineItem !== row.item._id"
                            key="display">
                          <span
                              v-if="model.lineItems[`${row.index}`].price"
                              v-text="`$${model.lineItems[`${row.index}`].price.toFixed(2)}`"
                          />
                        </span>
                        <b-input-group
                            v-else
                            key="input"
                            prepend="$">
                          <b-form-input
                              :id="`priceInput-${row.item._id}`"
                              type="number"
                              :step="0.01"
                              min="0"
                              v-model.number="model.lineItems[`${row.index}`].price"
                              @input="saveRequest('charge')"
                              :disabled="lockedItem ? true: false"
                              @focus="activeInput = `priceInput-${row.item._id}`"
                              @blur="activeInput = null;"
                              :class="activeInput === `priceInput-${row.item._id}` ? 'invoice__activeInput' : null"
                          />
                        </b-input-group>
                      </transition>
                    </div>
                  </template>
                  <template
                      v-slot:cell(qty)="row">
                    <transition
                        mode="out-in"
                        name="slide-fade-alt">
                      <span
                          v-if="changeLineItem !== row.item._id"
                          v-text="model.lineItems[`${row.index}`].qty"
                      />
                      <b-form-input
                          :id="`qtyInput-${row.item._id}`"
                          class="invoice__fileBadge__inputs__qty"
                          v-else
                          type="number"
                          :step="0.01"
                          :value="model.lineItems[`${row.index}`].qty"
                          @input="updateQty(row.item, $event)"
                          :disabled="lockedItem ? true : false"
                          @focus="activeInput = `qtyInput-${row.item._id}`"
                          @blur="activeInput = null;"
                          :class="activeInput === `qtyInput-${row.item._id}` ? 'invoice__activeInput' : null"
                      />
                    </transition>
                  </template>
                  <template
                      v-slot:cell(item)="row">
                    <transition
                        mode="out-in"
                        name="slide-fade-alt">
                      <div
                          key="plainText"
                          v-if="changeLineItem !== row.item._id">
                        <span
                            v-text="model.lineItems[`${row.index}`].item"
                            class="invoice__description"
                        />
                        <transition
                            mode="in-out"
                            name="fadeQuick">
                          <icon
                              icon="pencil-alt"
                              v-show="changeHover === row.item._id"
                          />
                        </transition>
                      </div>
                      <voice-input
                          :id="`lineItemDescription-${row.item._id}`"
                          key="formArea"
                          :itemId="row.item._id"
                          :locked="lockedItem"
                          :activeInput="activeInput === `lineItemDescription-${row.item._id}`"
                          :modelProp="model.lineItems[`${row.index}`].item"
                          :placeholder="$t('invoice.lineItemDesc')"
                          @textInput="updateTextInputLineItem"
                          @focus="activeInput = `lineItemDescription-${row.item._id}`"
                          @blur="activeInput = null;"
                          v-else
                      />
                    </transition>
                  </template>
                  <template
                      v-slot:cell(taxable)="row">
                    <b-form-checkbox
                        class="invoice__fileBadge__inputs__taxable"
                        v-model="model.lineItems[`${row.i}`].taxable"
                        @input="saveRequest('charge')"
                        :disabled="lockedItem ? true : false"
                    />
                  </template>
                  <template
                      v-slot:cell(total)="row">
                    <span
                      class="invoice__fileBadge__text__medium"
                      v-text="lineTotal(model.lineItems[`${row.index}`], { enable: preferences.taxOption, rate: preferences.taxRate })"
                    />
                  </template>
                  <template
                      v-slot:cell(delete)="row">
                    <transition
                        name="fadeQuick"
                        mode="out-in">
                      <b-btn
                          v-if="changeLineItem === row.item._id"
                          key="changeLineItemConfirm"
                          id="confirmChangeLineItem"
                          variant="secondary"
                          @click="changeLineItem = null"
                          class="invoice__fileBadge__buttons__confirm">
                        <icon
                            icon="check-circle"
                            size="lg"
                        />
                      </b-btn>
                      <b-btn-group
                          key="deleteCharge"
                          v-else-if="deleteChargeRequest !== model.lineItems[row.index]._id">
                        <b-btn
                            :id="`removeCharge-${row.index}`"
                            name="RemoveCharge"
                            :aria-label="$t('delete')"
                            variant="danger"
                            @click="deleteLineItemRequest(row.index)"
                            class="invoice__fileBadge__buttons__delete"
                            size="md">
                          <icon
                              icon="trash-alt"
                              size="lg"
                          />
                        </b-btn>
                      </b-btn-group>
                      <b-btn-group
                          v-else
                          key="deleteConfirm">
                        <b-btn
                            id="deleteCancel"
                            key="deleteCancel"
                            :aria-label="$t('deleteCancel')"
                            @click="deleteChargeRequest = null"
                            class="invoice__fileBadge__buttons__delete__cancel"
                            variant="secondary">
                          <icon
                              icon="arrow-circle-left"
                          />
                        </b-btn>
                        <b-btn
                            key="deleteConfirm"
                            id="deleteConfirmLineItem"
                            :aria-label="$t('deleteConfirm')"
                            @click="deleteLineItemConfirm(row.index)"
                            class="invoice__fileBadge__buttons__delete__confirm"
                            variant="warning">
                          <icon
                              icon="trash-alt"
                          />
                          <span
                              v-if="deleteChargeRequest === model.lineItems[row.index]._id"
                              v-text="$t('sys.confirm')"
                              class="invoice__fileBadge__text__small"
                          />
                        </b-btn>
                      </b-btn-group>
                    </transition>
                  </template>
                </b-table>
              </transition>
              <b-row>
                <b-col
                    id="addLineItemCol"
                    align="center">
                  <b-btn
                      id="addLineItem"
                      :aria-label="!lockedItem ? $t('aria.lineItemAdd') : $t('aria.lineItemLocked')"
                      @click="itemAdd()"
                      :disabled="lockedItem ? true : false"
                      class="invoice__fileBadge__buttons__addItem"
                      variant="primary"
                      v-b-hover="lockedItem ? lockedWarning : null">
                    <icon
                        :icon="!lockedItem ? 'plus' : 'lock'"
                    />
                    <span
                        v-text="!lockedItem ? $t('invoice.lineItemAdd') : $t('invoice.locked')"
                    />
                  </b-btn>
                  <b-btn
                      v-if="!lockedItem"
                      id="addLinkedLineItem"
                      @click="itemAddLinked()"
                      class="invoice__fileBadge__buttons__addItem"
                      variant="secondary">
                    <b-icon-link /> {{$t('invoice.linked.addLinked')}}
                  </b-btn>
                  <transition
                      name="fadeQuick"
                      mode="out-in">
                    <div
                        key="lockedItemExplanation"
                        v-if="lockedItemHover">
                      <small
                          v-text="$t('invoice.unlockToChange')"
                          class="font-italic"
                      />
                    </div>
                  </transition>
                </b-col>
              </b-row>
            </b-col>
            <!-- Adding Linked Item -->
            <b-col
                id="addingLinkedItemContainer"
                class="my-5"
                v-if="addingLinkedItem">
              <div>
                {{$t('invoice.linked.selectColl')}}
                <b-form-select
                    id="linkedToSelection"
                    v-model="linkedItem._collection"
                    @change="linkedItem._record = null"
                    :options="collectionOptions"
                    placeholder="Select one"
                    class="form__input large ml-auto"
                />
              </div>
              <div
                  class="my-4"
                  v-if="linkedItem._collection && !linkedItem._record">
                <b-form-tags
                    id="linkedRecordSearch"
                    type="text"
                    input-id="searchInput"
                    :add-on-change="true"
                    @tag-state="searchTermEntry"
                    remove-on-delete
                    v-model="searchTerms"
                    :placeholder="$t('searchFor')"
                    class="invoice__fileBadge__linkedData__search"
                />
                <!-- Display linkedRecord data -->
                <b-row
                    v-for="(record, index) in linkedRecordsPaginated"
                    :key="record[0]"
                    class="invoice__fileBadge__linkedData__field__container"
                    :class="index % 2 !== 0 ? 'linkedItem__evenRow' : 'linkedItem__row'"
                    @click="linkedItemSelect(record)">
                  <b-col
                      v-if="linkedCollection(linkedItem._collection)
                          && (linkedCollection(linkedItem._collection).idType === 'guid'
                            || linkedCollection(linkedItem._collection).idType === 'humanId')"
                      class="linkedItem__id__container"
                      cols="*">
                    <span
                        v-text="linkedCollection(linkedItem._collection).idType === 'humanId'
                          ? Object.entries(record[1])[0][1].humanId
                          : Object.entries(record[1])[0][0]"
                        :class="linkedCollection(linkedItem._collection).idType === 'humanId'
                          ? 'linkedItem__id' : 'linkedItem__id smallText'"
                    />
                  </b-col>
                  <b-col
                      v-for="linkedField in Object.entries(record[1])"
                      :key="linkedField[0]"
                      cols="auto"
                      align="center">
                    <data-viewer
                        :addressDisplayType="'whole'"
                        :model="linkedField"
                        :collection="$store.getters.collection(linkedItem._collection)"
                    />
                  </b-col>
                </b-row>
              </div>
              <div
                  class="mt-4"
                  v-if="linkedItem._record">
                <div
                    id="linkedText"
                    class="my-4">
                  <h4
                      v-text="$t('invoice.linked.textOption')"
                  />
                  <b-list-group
                      id="textOptions">
                    <b-list-group-item
                        v-for="linkedText in linkedTextFields"
                        :key="linkedText[0]"
                        :id="`textOption-${linkedText[0]}`"
                        button
                        :variant="linkedItem.text.findIndex(i => i[0] === linkedText[0]) > -1 ? 'primary' : 'light'"
                        class="invoice__fileBadge__linkedData__selection"
                        @click="linkedTextClick(linkedText)">
                      {{linkedText[1].data}}
                      <b-input-group
                          @click.stop
                          v-if="linkedItem.text.findIndex(i => i[0] === linkedText[0]) > -1">
                        <b-input-group-prepend
                            class="ml-auto mr-1"
                            v-text="`${$t('invoice.linked.showLabel')} '${linkedText[1].label}:'`"
                        />
                        <b-form-checkbox
                            :id="`showLabelToggle-${linkedText[0]}`"
                            switch
                            size="lg"
                            class="ml-2 mr-auto"
                            v-model="linkedItem.text.find(i => i[0] === linkedText[0])[1].showLabel"
                        />
                        <b-input-group-append>
                        </b-input-group-append>
                      </b-input-group>
                    </b-list-group-item>
                    <b-list-group-item
                        id="customText"
                        button
                        :variant="!linkedItem.text.length ? 'primary' : 'light'"
                        class="invoice__fileBadge__linkedData__selection"
                        @click="linkedTextClick(null)">
                      {{$t('invoice.linked.customText')}}
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div
                    id="linkedPrice"
                    class="my-2">
                  <h4
                      v-text="$t('invoice.linked.priceOption')"
                  />
                  <b-list-group
                      id="priceOptions">
                    <b-list-group-item
                        v-for="linkedPrice in linkedPriceFields"
                        :key="linkedPrice[0]"
                        :id="`priceOption-${linkedPrice[0]}`"
                        button
                        class="invoice__fileBadge__linkedData__selection"
                        :variant="linkedItem.price[0] === linkedPrice[0] ? 'primary' : 'light'"
                        @click="linkedPriceClick(linkedPrice)">
                      {{linkedPrice[1].label}}: {{displayCurrency(linkedPrice[1].data.price, linkedPrice[1].data.type)}}
                    </b-list-group-item>
                    <b-list-group-item
                        id="customPrice"
                        button
                        class="invoice__fileBadge__linkedData__selection"
                        :variant="linkedItem.price[0] === null ? 'primary' : 'light'"
                        @click="linkedPriceClick(null)">
                      {{$t('invoice.linked.customPrice')}}
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div
                    id="linkedQty"
                    class="my-2">
                  <h4
                      v-text="$t('invoice.linked.qtyOption')"
                  />
                  <b-list-group
                      id="qtyOptions">
                    <b-list-group-item
                        v-for="linkedQty in linkedQtyFields"
                        :key="linkedQty[0]"
                        :id="`qtyOption-${linkedQty[0]}`"
                        button
                        class="invoice__fileBadge__linkedData__selection"
                        :variant="linkedItem.qty[0] === linkedQty[0] ? 'primary' : 'light'"
                        @click="linkedQtyClick(linkedQty)">
                      {{$t('invoice.linked.syncQty')}} {{linkedQty[1].label}}: {{linkedQty[1].data.counter}} {{$t('invoice.linked.syncAvailable')}}
                    </b-list-group-item>
                    <b-list-group-item
                        id="customQty"
                        button
                        class="invoice__fileBadge__linkedData__selection"
                        :variant="linkedItem.qty[0] === null ? 'primary' : 'light'"
                        @click="linkedQtyClick(null)">
                      {{$t('invoice.linked.customQty')}}
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>
              <b-btn-group
                  class="mt-3">
                <b-btn
                    @click="itemAddLinkedCancel()"
                    variant="danger">
                  {{$t('cancel')}}
                </b-btn>
                <b-btn
                    v-if="linkedItem._record"
                    @click="itemAddLinkedConfirm()"
                    variant="primary">
                  {{$t('invoice.linked.confirm')}}
                </b-btn>
              </b-btn-group>
            </b-col>
          </b-row>
          <b-row
              key="payments"
              v-if="activeItem === 2">
            <b-col
                id="paymentsTableContainer">
              <transition
                  name="fade"
                  mode="out-in">
                <b-table
                    id="paymentsTable"
                    v-if="model.payments.length > 0"
                    :fields="paymentsTableFields"
                    :items="model.payments"
                    :per-page="perPage"
                    stacked="md"
                    class="invoice__fileBadge__addItems__table"
                    @row-hovered="tablePaymentsRowHover"
                    @row-unhovered="tablePaymentsRowUnhover"
                    @row-clicked="tablePaymentsRowClicked"
                    :tbody-transition-props="{name: 'fade'}">
                  <template
                      v-slot:cell(date)="row">
                    <b-btn
                        id="paymentDateDisplay"
                        @click="dateConvertPayment(row.index)"
                        v-text="dateDisplayPayment(row.index)"
                        variant="info"
                        v-if="convertDate !== row.item._id"
                        :aria-label="$t('sys.clickDate')"
                    />
                    <b-form-input
                        class="invoice__fileBadge__inputs__date"
                        v-else
                        type="date"
                        @change="dateChangePayment(row.index)"
                        v-model="model.payments[`${row.index}`].date"
                    />
                  </template>
                  <template
                      v-slot:cell(type)="row">
                    <transition
                        mode="out-in"
                        name="slide-fade-alt">
                      <span
                          v-if="changePayment !== row.item._id"
                          key="dataView"
                          v-text="model.payments[`${row.index}`].type"
                      />
                      <b-form-select
                        id="paymentType"
                        v-else
                        key="dataEntry"
                        v-model="model.payments[`${row.index}`].type"
                        :text="model.payments[`${row.index}`].type"
                        :options="model.payments[`${row.index}`].type === $_app.invoice.paymentOptions[6] ? paymentTypes : paymentTypesNoSystem"
                        @change="saveRequest('payment')"
                        class="invoice__fileBadge__inputs__paymentType"
                      />
                    </transition>
                  </template>
                  <template
                      v-slot:cell(amount)="row">
                      <div
                          class="invoice__fileBadge__inputs__price">
                      <transition
                          mode="out-in"
                          name="slide-fade-alt">
                        <span
                            key="dataView"
                            v-if="changePayment !== row.item._id"
                            v-text="model.payments[row.index].amount ? `$${model.payments[`${row.index}`].amount.toFixed(2)}` : null"
                        />
                        <b-input-group
                            key="dataEntry"
                            v-else
                            prepend="$">
                          <b-form-input
                              type="number"
                              :step="0.01"
                              min="0"
                              @input="saveRequest('payment')"
                              v-model.number="model.payments[`${row.index}`].amount"
                              @focus="activeInput = `priceInput-${row.item._id}`"
                              @blur="activeInput = null;"
                              :class="activeInput === `priceInput-${row.item._id}` ? 'invoice__activeInput' : null"
                          />
                        </b-input-group>
                      </transition>
                    </div>
                  </template>
                  <template
                      v-slot:cell(notes)="row">
                    <transition
                        mode="out-in"
                        name="slide-fade-alt">
                      <div
                          :id="`notePaymentContainer-${row.index}`"
                          key="viewData"
                          v-if="changePayment !== row.item._id">
                        <span
                            v-text="model.payments[`${row.index}`].notes"
                        />
                        <transition
                            mode="in-out"
                            name="fadeQuick">
                          <icon
                              icon="pencil-alt"
                              v-show="changeHover === row.item._id"
                          />
                        </transition>
                      </div>
                      <voice-input
                          :id="`paymentDescription-${row.item._id}`"
                          key="formArea"
                          :itemId="row.item._id"
                          :activeInput="activeInput === `paymentDescription-${row.item._id}`"
                          :modelProp="model.payments[`${row.index}`].notes"
                          :placeholder="$t('invoice.paymentDescription')"
                          @textInput="updateTextInputPayment"
                          @focus="activeInput = `paymentDescription-${row.item._id}`"
                          @blur="activeInput = null;"
                          v-else
                      />
                    </transition>
                  </template>
                  <template
                      v-slot:cell(delete)="row">
                    <transition
                        name="fadeQuick"
                        mode="out-in">
                      <b-btn
                          id="confirmChangePayment"
                          v-if="changePayment === row.item._id"
                          key="changePaymentConfirm"
                          @click="changePayment = null"
                          variant="secondary"
                          class="invoice__fileBadge__buttons__confirm">
                        <icon
                            icon="check-circle"
                            size="lg"
                        />
                      </b-btn>
                      <b-btn
                          key="deleteRequest"
                          :id="`removePayment-${row.index}`"
                          name="RemovePayment"
                          v-else-if="deletePaymentRequest !== model.payments[row.index]._id"
                          :aria-label="$t('delete')"
                          variant="danger"
                          @click="paymentRemReq(row.index)"
                          class="invoice__fileBadge__buttons__delete"
                          size="md">
                        <icon
                            icon="trash-alt"
                            size="lg"
                        />
                      </b-btn>
                      <b-btn-group
                          v-else
                          key="deleteConfirm">
                        <b-btn
                            id="deleteCancelButton"
                            key="deleteCancel"
                            :aria-label="$t('deleteCancel')"
                            @click="deletePaymentRequest = null"
                            class="invoice__fileBadge__buttons__delete__cancel"
                            variant="secondary">
                          <icon
                              icon="arrow-circle-left"
                          />
                        </b-btn>
                        <b-btn
                            id="deletePaymentConfirmButton"
                            key="deleteConfirm"
                            :aria-label="$t('deleteConfirm')"
                            class="invoice__fileBadge__buttons__delete__confirm"
                            @click="paymentRem(row.index)"
                            variant="warning">
                          <icon
                              icon="trash-alt"
                              size="lg"
                          />
                          <small
                              v-if="deletePaymentRequest === model.payments[row.index]._id"
                              v-text="$t('sys.confirm')"
                          />
                        </b-btn>
                      </b-btn-group>
                    </transition>
                  </template>
                </b-table>
              </transition>
              <b-row>
                <b-col
                    id="addPaymentCol"
                    align="center">
                  <b-btn
                      id="addPayment"
                      variant="secondary"
                      class="invoice__fileBadge__buttons__addItem"
                      @click="paymentAdd()">
                    <icon
                        icon="plus"
                        size="lg"
                    />
                    <span
                        v-text="$t('invoice.paymentAdd')"
                    />
                  </b-btn>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row
              key="notes"
              v-if="activeItem === 3">
            <b-col>
              <b-row
                  id="notesOptionsTopRow"
                  v-if="notesToShow.length > 10">
                <b-col
                    v-if="systemNotesCount" />
                <b-col
                    id="newNoteCol"
                    align="center">
                  <b-btn
                      id="addNote"
                      variant="secondary"
                      class="invoice__fileBadge__buttons__addItem"
                      @click="noteAdd()">
                    <icon
                        icon="plus"
                        size="lg"
                        class="admin__buttonContent"
                    />
                    <span
                        v-text="$t('invoice.noteAdd')"
                    />
                  </b-btn>
                </b-col>
                <b-col
                    v-if="systemNotesCount">
                  <b-form-checkbox
                      id="hideSystemGeneratedNotes"
                      v-model="hideSystemNotes"
                      class="invoice__fileBadge__inputs__checkbox">
                    {{$t('invoice.hideSystemNotes')}} ({{systemNotesCount }})
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <transition
                  name="fade"
                  mode="out-in">
                <b-table
                    v-if="notesToShow.length > 0"
                    :fields="notesTableFields"
                    :items="notesToShow"
                    :per-page="perPage"
                    sort-by="date"
                    stacked="md"
                    class="invoice__fileBadge__addItems__table"
                    :tbody-transition-props="{name: 'fadeQuick'}">
                  <template
                      v-slot:cell(date)="row">
                    <b-btn
                        id="displayNoteDate"
                        @click="row.item.systemGenerated ? '' : editDateOfNote(row.item._id, row.item.date)"
                        v-text="dateDisplayNoteInput(row.item.date)"
                        v-if="editNoteDate !== row.item._id"
                        class="invoice__fileBadge__buttons__date"
                        :aria-label="$t('sys.clickDate')"
                        variant="info"
                    />
                    <b-form-input
                        id="changeNoteDateInput"
                        v-else
                        v-model="row.item.date"
                        type="date"
                        @change="checkNoteDate(row.item.date, row.item._id)"
                        class="invoice__fileBadge__inputs__date"
                    />
                  </template>
                  <template
                      v-slot:cell(showToCustomer)="row">
                    <b-form-checkbox
                        v-model="row.item.showToCustomer"
                        name="showToCustomer"
                        @input="saveRequest()"
                        :aria-label="$t('aria.showNoteToCustomer')"
                        switch>
                    </b-form-checkbox>
                  </template>
                  <template
                      v-slot:cell(text)="row">
                    <div
                        @click="row.item.systemGenerated ? null : noteEditText(row.item._id)">
                      <transition
                          name="fadeQuick"
                          mode="out-in">
                        <span
                            key="plainText"
                            v-if="editNoteText !== row.item._id"
                            v-text="row.item.text"
                        />
                        <b-form-textarea
                            key="formArea"
                            v-else
                            :id="`note-${row.item._id}`"
                            :max-rows="10"
                            @input="saveRequest()"
                            :disabled="row.item.systemGenerated ? true : false"
                            v-model="row.item.text"
                            :placeholder="editNoteText === row.item._id ? `${$t('note.new')}` : ''"
                        />
                      </transition>
                    </div>
                  </template>
                  <template
                      v-slot:cell(delete)="row"
                      style="width: 100px">
                    <transition
                        name="fadeQuick"
                        mode="out-in">
                      <b-input-group
                          key="noteDeleteandSystemButtoms"
                          v-if="noteDeleteConfirm !== row.item._id">
                        <b-btn
                            id="systemGeneratedLabel"
                            v-if="row.item.systemGenerated"
                            disabled
                            v-text="$t('invoice.systemGenerated')"
                            variant="info"
                            class="invoice__fileBadge__buttons__systemGeneratedLabel"
                        />
                        <b-btn
                            id="noteDelete"
                            v-if="editNoteDate !== row.item._id
                              && !row.item.systemGenerated"
                            @click="noteDeleteRequest(row.item._id)"
                            :aria-label="$t('deleteNote')"
                            class="invoice__fileBadge__buttons__delete"
                            variant="danger">
                          <icon
                              icon="trash-alt"
                              size="lg"
                          />
                        </b-btn>
                      </b-input-group>
                      <b-input-group
                          key="deleteNoteConfirmation"
                          v-else>
                        <b-btn
                            id="cancelNoteDelete"
                            @click="noteDeleteCancel()"
                            class="invoice__fileBadge__buttons__delete__cancel"
                            :aria-label="$t('deleteCancel')"
                            variant="secondary">
                          <icon
                              icon="arrow-circle-left"
                          />
                        </b-btn>
                        <b-btn
                            id="confirmNoteDelete"
                            @click="noteDeleteConfirmed(row.item._id)"
                            :aria-label="$t('deleteConfirm')"
                            class="invoice__fileBadge__buttons__delete__confirm"
                            variant="warning">
                          <icon
                              icon="trash-alt"
                              size="lg"
                          />
                          <small
                              v-text="$t('sys.confirm')"
                          />
                        </b-btn>
                      </b-input-group>
                    </transition>
                  </template>
                </b-table>
              </transition>
              <b-row
                  id="notesOptionsBottomRow">
                <b-col
                    v-if="systemNotesCount" />
                <b-col
                    id="newNoteCol"
                    align="center">
                  <b-btn
                      id="addNote"
                      variant="secondary"
                      class="invoice__fileBadge__buttons__addItem"
                      @click="noteAdd()">
                    <icon
                        icon="plus"
                        size="lg"
                        class="admin__buttonContent"
                    />
                    <span
                        v-text="$t('invoice.noteAdd')"
                    />
                  </b-btn>
                </b-col>
                <b-col
                    v-if="systemNotesCount">
                  <b-form-checkbox
                      id="hideSystemGeneratedNotes"
                      v-model="hideSystemNotes"
                      class="invoice__fileBadge__inputs__checkbox">
                    {{$t('invoice.hideSystemNotes')}} ({{systemNotesCount }})
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div
              key="options"
              v-if="activeItem === 4"
              class="mt-4">
            <preference-pane
                v-if="!closePreferences"
                :customerDetails="customer"
                :model="model"
                :enable="prefsToEnable"
                @change-date="updateDate"
                @duplicateDocument="duplicateInvoice"
                @update-model="saveRequest()"
                @update-taxes="updateTaxes"
                @save-now="savePreferences"
            />
          </div>
          <div
              key="fileActions"
              v-if="activeItem === 5">
            <file-options
                  :id="`fileOptions-${model._id}`"
                  @click.native.stop
                  @clear="clearDetailsView"
                  @close-preview="previewPdfDone()"
                  :model="model"
                  :preferences="preferences"
              />
          </div>
          <b-row
              key="outstandingItems"
              v-if="activeItem === 6">
            <b-col
                id="outstandingItemsContainer">
              <div
                  v-for="item in model.outstandingItems"
                  :key="item._id">
                <b-row
                    align-h="center"
                    v-if="$store.getters.invoice(item.originalId).totalCharges - $store.getters.invoice(item.originalId).totalPayments > 0">
                  <b-col
                      cols="auto"
                      align="center"
                      class="invoice__outstandingItem__container">
                    <b-btn
                        :id="`removeLink-${item._id}`"
                        :aria-label="$t('aria.removeLink')"
                        @click="removeOutstandingLink(item._id)"
                        variant="danger"
                        class="mr-3">
                      <b-icon-trash />
                    </b-btn>
                    <span>
                      {{$t('invoice.number')}}
                      <b>
                        {{$store.getters.invoice(item.originalId).number}}
                      </b>
                    </span>
                    <span
                        class="mx-4">
                      {{$t('invoice.dueOn')}} {{dueDateCalc($store.getters.invoice(item.originalId), 'l')}}
                    </span>
                    <span>
                      {{$t('invoice.outstandingDue')}}
                      {{displayAmount($store.getters.invoice(item.originalId).totalCharges - $store.getters.invoice(item.originalId).totalPayments)}}
                    </span>
                    <b-btn
                        :id="`seeLink-${item._id}`"
                        :aria-label="$t('aria.view')"
                        @click="seeOutstandingItem(item.originalId)"
                        class="ml-3">
                      <b-icon-search />
                    </b-btn>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </transition>
      </div>
      <b-row
          key="bottom"
          v-if="showThis && (activeItem === 1 && model.lineItems.length > 4) || (activeItem === 2 && model.payments.length > 5)">
        <b-col
            sm="0"
            md="4"
        />
        <b-col
            id="financeCol-totalChargesAndPayments-SecondOccurrence"
            sm="6"
            md="4"
            class="invoice__fileBadge__contentContainer">
          <b-btn-group>
            <b-btn
                :id="`showLineItems${model._id}-secondOccurrence`"
                @click.stop="activeItemCharges()"
                :variant="activeItem === 1 ? 'primary' : 'tertiary'"
                class="invoice__fileBadge__buttons__openDetails"
                :aria-label="$t('aria.showLineItems')">
              <span
                  v-text="displayTotal(model.lineItems, { enable: preferences.taxOption, rate: preferences.taxRate})"
                  class="invoice__fileBadge__buttons__openDetails__amount"
              />
              <br />
              {{$t('invoice.lineItems')}}
            </b-btn>
            <b-btn
                :id="`showPayments${model._id}-secondOccurrence`"
                @click.stop="activeItemPayments()"
                :variant="activeItem === 2 ? 'primary' : 'tertiary'"
                class="invoice__fileBadge__buttons__openDetails"
                :aria-label="$t('aria.showPayments')">
              <span
                  v-text="displayTotalPayments(model.payments)"
                  class="invoice__fileBadge__buttons__openDetails__amount"
              />
              <br />
              {{$t('invoice.payments')}}
            </b-btn>
          </b-btn-group>
        </b-col>
        <b-col
            id="financeCol-bottom"
            key="bottomTotalContainer"
            sm="6"
            md="4"
            class="invoice__fileBadge__balance__container">
          <div
              v-if="!spinnerById">
            <small
                v-text="$t('invoice.bal')"
            />
            <span
                v-text="displayInvoiceTotal(model.totalCharges, totalOutstanding, model.totalPayments)"
                class="invoice__fileBadge__text__emphasis"
            />
          </div>
          <div
              v-else>
            <loader
              color="white"
              type="bars"
              :h="40"
              :w="100"
              :fullPage="false"
            />
          </div>
        </b-col>
      </b-row>
    </transition-group>
  </div>
</template>

<script>
import moment from 'moment';
import dataViewer from '@/components/data/DataViewer';
import fileOptions from '@/components/util/pdf/FileOptionsButton';
import preferencePane from '@/components/util/Preferences';
import totalCalculators from '@/mixins/totalCalculators';
import voiceInput from '@/components/data/VoiceInput';

export default {
  name: 'FileBadge',
  mixins: [
    totalCalculators,
  ],
  components: {
    dataViewer,
    fileOptions,
    preferencePane,
    voiceInput,
  },
  props: ['model', 'hideShowButton'],
  computed: {
    activeItem: {
      get() {
        return this.$store.state.activeItem;
      },
      set(newVal) {
        this.$store.state.activeItem = newVal;
      },
    },
    closePreferences() {
      return this.$store.state.needToClosePreferences;
    },
    collectionOptions() {
      const collections = [];
      this.$store.getters.collections.forEach((coll) => {
        if (coll._id !== this.model._id) {
          collections.push({
            value: coll._id,
            text: coll.name,
          });
        }
      });
      return collections;
    },
    customer() {
      if (this.model.customer._id) {
        return this.$store.getters.customer(this.model.customer._id);
      }
      if (this.model.customer) {
        return this.$store.getters.customer(this.model.customer);
      }
      return {};
    },
    deleteRequest: {
      get() {
        return this.$store.state.deleteRequest;
      },
      set(newVal) {
        this.$store.state.deleteRequest = newVal;
      },
    },
    displayPrefix() {
      let prefix;
      switch (this.model.status) {
        case 'proposal': {
          if (this.preferences.defaultLabelProposal) {
            prefix = this.preferences.defaultLabelProposal;
          }
          break;
        }
        default: {
          if (this.preferences.defaultLabelInvoice) {
            prefix = this.preferences.defaultLabelInvoice;
          }
          break;
        }
      }
      return prefix;
    },
    dueDate() {
      return this.dueDateCalc(this.model);
    },
    justViewed: {
      get() {
        return this.$store.state.justViewed;
      },
      set(newVal) {
        this.$store.state.justViewed = newVal;
      },
    },
    lastPaymentDate() {
      if (this.model.payments.length > 0) {
        return moment(this.model.payments[this.model.payments.length - 1].date).format('MMM Do');
      }
      return null;
    },
    lineItemTableFields() {
      let mustShowQty = false;
      if (!this.preferences.taxOption && !this.preferences.qtyOption) {
        for (let a = 0; a < this.model.lineItems.length; a += 1) {
          if (this.model.lineItems[a].qty !== 1) {
            mustShowQty = true;
          }
        }
      }
      return [
        this.preferences.dateLineItemOption
          ? { key: 'date', label: this.$t('date') }
          : {},
        this.preferences.taxOption || this.preferences.qtyOption || mustShowQty
          ? { key: 'price', label: this.$t('invoice.priceEach') }
          : {},
        this.preferences.qtyOption || mustShowQty
          ? { key: 'qty', label: this.$t('invoice.qty') }
          : {},
        { key: 'item', label: this.$t('invoice.desc') },
        this.preferences.taxOption
          ? {
            key: 'taxable',
            label: this.$t('invoice.taxable'),
            class: 'text-center',
          }
          : {},
        this.preferences.taxOption || this.preferences.qtyOption || mustShowQty
          ? { key: 'total', label: this.$t('invoice.total') }
          : { key: 'price', label: this.$t('invoice.priceEach') },
        { key: 'delete', label: '' },
      ];
    },
    linkedRecords() {
      // Populate linked records.
      if (this.linkedItem._collection) {
        const records = {};
        const recs = this.$store.getters.records(this.linkedItem._collection);
        if (recs) {
          for (let a = 0; a < recs.length; a += 1) {
            if (this.linkedRecord(this.linkedItem._collection, recs[a]._id)) {
              records[recs[a]._id] = this.linkedRecord(this.linkedItem._collection, recs[a]._id);
            }
          }
          if (this.linkedItemSearchNeeded) {
            const items = this.linkedItemsToSearch(records, this.linkedItem._collection);
            const terms = this.linkedItemSearchTerms;
            for (let a = 0; a < items.length; a += 1) {
              let matchCount = 0;
              for (let t = 0; t < terms.length; t += 1) {
                for (let b = 0; b < items[a].data.length; b += 1) {
                  if (items[a].data[b].includes(terms[t])) {
                    matchCount += 1;
                    break;
                  }
                }
                if (matchCount >= terms.length) {
                  break;
                }
              }
              if (matchCount < terms.length) {
                delete records[items[a]._id];
              }
            }
          }
          return Object.entries(records);
        }
      }
      return [];
    },
    linkedItemSearchNeeded() {
      if ((this.searchTerms && this.searchTerms.length) || this.searchTermPartial) {
        return true;
      }
      return false;
    },
    linkedItemSearchTerms() {
      const terms = [];
      if (this.searchTermPartial) {
        terms.push(this.searchTermPartial.toLowerCase());
      }
      if (this.searchTerms && this.searchTerms.length) {
        this.searchTerms.forEach(i => terms.push(i.toLowerCase()));
      }
      return terms;
    },
    linkedRecordsPaginated() {
      const records = this.linkedRecords;
      const perPage = this.linkedRecordsPerPage;
      const page = this.linkedRecordsPage;
      return records.slice(((page - 1) * perPage), page * perPage);
    },
    linkedPriceFields() {
      const fields = [];
      const selectedMetatypes = [
        'Price',
      ];
      const fieldData = Object.entries(this.linkedRecord(this.linkedItem._collection, this.linkedItem._record));
      fieldData.forEach((field) => {
        if (selectedMetatypes.indexOf(field[1].metaType) > -1) {
          fields.push(field);
        }
      });
      return fields;
    },
    linkedQtyFields() {
      const fields = [];
      const selectedMetatypes = [
        'Inventory',
      ];
      const fieldData = Object.entries(this.linkedRecord(this.linkedItem._collection, this.linkedItem._record));
      fieldData.forEach((field) => {
        if (selectedMetatypes.indexOf(field[1].metaType) > -1) {
          fields.push(field);
        }
      });
      return fields;
    },
    linkedTextFields() {
      const fields = [];
      const selectedMetatypes = [
        'String',
        'Textarea',
      ];
      const fieldData = Object.entries(this.linkedRecord(this.linkedItem._collection, this.linkedItem._record));
      fieldData.forEach((field) => {
        if (selectedMetatypes.indexOf(field[1].metaType) > -1) {
          const fieldToReturn = field;
          fieldToReturn[1].showLabel = false;
          fields.push(fieldToReturn);
        }
      });
      return fields;
    },
    lockedItem() {
      if (this.preferences.autoLock
          && (this.model.status === 'sent'
          || this.model.status === 'paid'
          || this.model.status === 'pastDue'
          || this.model.status === 'hold')) {
        return true;
      }
      return false;
    },
    paymentsTableFields() {
      return [
        this.preferences.datePaymentOption
          ? { key: 'date', label: this.$t('invoice.date') } : {},
        { key: 'type', label: this.$t('invoice.paymentType') },
        { key: 'amount', label: this.$t('invoice.paymentAmount') },
        { key: 'notes', label: this.$t('invoice.desc') },
        { key: 'delete', label: '' },
      ];
    },
    paymentTypes() {
      return this.$_app.invoice.paymentOptions;
    },
    paymentTypesNoSystem() {
      const payarray = this.$_app.invoice.paymentOptions;
      payarray.splice(6, 1);
      return payarray;
    },
    preferences() {
      let prefsObject = { ...this.$store.state.preferences };
      const customerPrefs = { ...this.$store.getters.customerPreferences(this.customer._id) };
      const documentPrefs = { ...this.model.preferences };
      if (this.customer && customerPrefs) {
        prefsObject = { ...prefsObject, ...customerPrefs };
      }
      if (documentPrefs) {
        prefsObject = { ...prefsObject, ...documentPrefs };
      }
      return prefsObject;
    },
    showThis() {
      return this.$store.state.showItem === this.model._id;
    },
    spinnerById() {
      return this.$store.getters.spinnerById === this.model._id;
    },
    sortBy() {
      return this.$store.state.preferences.defaultSort;
    },
    statusOptions() {
      const statusOptions = [];
      const options = Object.entries(this.$t('invoice.statusOptions'));
      for (let a = 0; a < options.length; a += 1) {
        statusOptions.push({ key: options[a][0], text: options[a][1] });
      }
      return statusOptions;
    },
    systemNotesCount() {
      let count = 0;
      function countUp() { count += 1; }
      this.model.notes.filter(note => note.systemGenerated).forEach(() => countUp());
      return count;
    },
    notesToShow() {
      if (!this.hideSystemNotes) {
        return this.model.notes;
      }
      return this.model.notes.filter(note => !note.systemGenerated);
    },
  },
  data() {
    return {
      activeInput: null,
      activeItemBefore: 0,
      addingLinkedItem: null,
      badgeId: this.$guid(),
      buttonSpinnerActive: false,
      buttonSpinner: {
        color: 'snow',
        h: 20,
        w: 20,
        type: 'dots',
        target: null,
      },
      convertDate: null,
      changeDate: null,
      changeDateDue: null,
      changeHover: null,
      changeLineItem: null,
      changePayment: null,
      deleteChargeRequest: null,
      deleteInvoiceRequest: null,
      deleteLinkRequest: null,
      deletePaymentRequest: null,
      editNoteDate: null,
      editNoteDateOriginal: null,
      editNoteText: null,
      hideSystemNotes: true,
      linkedItem: {
        _collection: null,
        _record: null,
        price: [null, {}],
        qty: [null, {}],
        text: [],
      },
      lockedItemHover: false,
      linkedRecordsPerPage: 10,
      linkedRecordsPage: 1,
      needToUpdateQty: {},
      noteDeleteConfirm: null,
      notesTableFields: [
        { key: 'date', label: this.$t('invoice.date'), sortable: true },
        { key: 'showToCustomer', label: this.$t('invoice.noteShowToCustomer'), sortable: true },
        { key: 'text', label: this.$t('invoice.note') },
        { key: 'delete', label: '' },
      ],
      perPage: 0,
      prefsToEnable: {
        account: false,
        actions: true,
        customer: false,
        default: true,
        document: true,
        system: false,
        help: false,
        user: true,
      },
      searchTerms: [],
      searchTermPartial: null,
      updatePayload: [],
    };
  },
  methods: {
    activeToggle() {
      if (!this.showThis) {
        this.$store.dispatch('showItem', this.model._id);
        this.$emit('scrollTo');
      } else {
        this.$store.dispatch('showItemClear');
      }
    },
    activeItemCharges() {
      this.activeItem = this.activeItem === 1 ? 0 : 1;
    },
    activeItemPayments() {
      this.activeItem = this.activeItem === 2 ? 0 : 2;
    },
    activeItemNotes() {
      this.activeItem = this.activeItem === 3 ? 0 : 3;
    },
    activeItemOptions() {
      this.activeItem = this.activeItem === 4 ? 0 : 4;
    },
    activeItemDeliver() {
      if (moment(this.lastChanged).format > this.model.dateUpdated) {
        this.saveNow();
      }
      this.activeItem = this.activeItem === 5 ? 0 : 5;
    },
    activeItemOutstanding() {
      this.activeItem = this.activeItem === 6 ? 0 : 6;
    },
    changeDateButton() {
      this.changeDate = moment(this.model.date).format();
      this.model.date = moment(this.model.date).format();
    },
    changeDateDueButton() {
      this.changeDateDue = moment(this.dueDate).format();
      this.model.dateDue = moment(this.dueDate).format();
    },
    checkInvoiceDate() {
      if (!this.model.date) {
        this.model.date = moment(this.changeDate).format();
        this.changeDate = null;
        return;
      }
      this.model.date = moment(this.model.date).format();
      this.changeDate = null;
      this.saveRequest('Date');
    },
    checkInvoiceDateDue() {
      if (!this.model.dateDue) {
        this.model.dateDue = moment(this.changeDateDue).format();
        this.changeDateDue = null;
        return;
      }
      this.model.dateDue = moment(this.model.dateDue).format();
      this.changeDateDue = null;
      this.saveNow('Date');
    },
    checkNoteDate(date, id) {
      if (!date) {
        for (let a = 0; a < this.model.notes.length; a += 1) {
          if (this.model.notes[a]._id === id) {
            this.model.notes[a].date = moment(this.editNoteDateOriginal).format();
          }
        }
      }
      this.editNoteDate = null;
      this.editNoteDateOriginal = null;
      this.saveRequest();
    },
    editDateOfNote(id, date) {
      this.editNoteDate = id;
      this.editNoteDateOriginal = date;
    },
    clearDetails() {
      this.$emit('clear');
    },
    clearDetailsView() {
      this.activeItem = 0;
    },
    dateChange() {
      if (!this.model.date) {
        this.model.date = moment().format();
      }
      this.editDate = null;
      this.saveRequest('Date');
      this.$store.dispatch('itemRefreshDone');
    },
    dateDueChange() {
      if (!this.model.dateDue) {
        this.model.dateDue = moment(this.model.date).add(this.preferences.defaultTerms, 'days').format();
      }
      this.editDueDate = null;
      this.saveRequest('Date');
      this.$store.dispatch('itemRefreshDone');
    },
    dateChangeCharge(i) {
      console.log(this.model.lineItems[i]);
      if (!this.model.lineItems[i].date) {
        this.model.lineItems[i].date = moment().format();
      }
      this.convertDate = null;
      this.saveRequest();
    },
    dateChangePayment(i) {
      if (!this.model.payments[i].date) {
        this.model.payments[i].date = moment().format();
      }
      this.convertDate = null;
      this.saveRequest();
    },
    dateChangeNote(i) {
      if (!this.model.notes[i].date) {
        this.model.notes[i].date = moment().format();
      }
      this.editNoteDate = null;
      this.saveRequest();
    },
    dateConvert() {
      this.editDate = true;
      this.model.date = moment(this.model.date)
        .format()
        .substring(0, 10);
      this.saveRequest('Date');
    },
    dateDueConvert() {
      this.editDueDate = true;
      this.model.dateDue = moment(this.model.dateDue)
        .format()
        .substring(0, 10);
      this.saveRequest('Date');
    },
    dateConvertCharge(i) {
      this.convertDate = this.model.lineItems[i]._id;
      this.model.lineItems[i].date = moment(this.model.lineItems[i].date)
        .format()
        .substring(0, 10);
    },
    dateConvertPayment(i) {
      this.convertDate = this.model.payments[i]._id;
      this.model.payments[i].date = moment(this.model.payments[i].date)
        .format()
        .substring(0, 10);
    },
    dateDisplay(date) {
      return this.preferences.showTimes ? `${moment(date).format('l hh:mma')}` : `${moment(date).format('l')}`;
    },
    dateDisplayCharge(i) {
      return moment(this.model.lineItems[i].date).format('l');
    },
    dateDisplayFormInput(date) {
      return moment(date).format('L').substr(0, 10);
    },
    dateDisplayNoteInput(date) {
      return moment(date).format('l').substr(0, 10);
    },
    dateDisplayPayment(i) {
      return moment(this.model.payments[i].date).format('l');
    },
    deleteInvoiceCancel() {
      this.deleteInvoiceRequest = false;
    },
    deleteInvoice(id) {
      // TODO CRITICAL - properly remove 'outstandingOn' reference when invoice is deleted This implementation below doesn't work/needs testing.
      this.updatePayload = [];
      this.deleteInvoiceRequest = false;
      this.deleteRequest = null;
      for (let a = 0; a < this.model.lineItems.length; a += 1) {
        // Resolve any synced Qty amounts on linked records, and remove invoicedOn
        const lineItem = this.model.lineItems[a];
        if (lineItem.link) {
          if (lineItem.link.qty && lineItem.link.qty.sync) {
            this.$store.dispatch('updateQty', {
              removeLink: true,
              record: lineItem.link._record,
              collection: lineItem.link._collection,
              field: lineItem.link.qty.field,
              newQty: 0,
              originalQty: lineItem.qty,
              invoice: this.model._id,
              lineItem: lineItem._id,
            });
          } else {
            this.$store.dispatch('invoicedOnRemove', {
              invoice: this.model._id,
              collection: lineItem.link._collection,
              record: lineItem.link._record,
              lineItem: lineItem._id,
            });
          }
        }
      }
      if (this.model.outstandingOn.length) {
        const deletedNote = `${this.model.number} ${this.$t('invoice.documentDeletedNote')}`;
        this.removeOutstandingOn(deletedNote);
      }
      if (this.model.outstandingItems.length) {
        const deletedNote = `${this.model.number} ${this.$t('invoice.documentDeletedNote')}`;
        this.removeOutstandingItems(deletedNote);
      }
      if (this.updatePayload.length) {
        this.$store.dispatch('removeInvoiceAndModifyOthers', { toDelete: id, toUpdate: this.updatePayload });
      } else {
        this.$store.dispatch('removeInvoice', id);
      }
    },
    deleteLineItemConfirm(i) {
      this.deleteChargeRequest = null;
      const lineItem = this.model.lineItems[i];
      if (lineItem.link && (lineItem.link._record && lineItem.link._collection)) {
        if (lineItem.link.qty && lineItem.link.qty.field && lineItem.link.qty.sync) {
          this.$store.dispatch('updateQty', {
            removeLink: true,
            record: lineItem.link._record,
            collection: lineItem.link._collection,
            field: lineItem.link.qty.field,
            newQty: 0,
            originalQty: lineItem.qty,
            invoice: this.model._id,
            lineItem: lineItem._id,
          });
        } else {
          this.$store.dispatch('invoicedOnRemove', {
            invoice: this.model._id,
            collection: lineItem.link._collection,
            record: lineItem.link._record,
            lineItem: lineItem._id,
          });
        }
      }
      this.model.lineItems.splice(i, 1);
      this.saveRequest('charge');
    },
    deleteLineItemRequest(i) {
      if (this.model.lineItems[i].link && this.model.lineItems[i].link.qty && this.model.lineItems[i].link.qty.field) {
        this.model.lineItems[i]._showDetails = true;
      }
      this.deleteChargeRequest = this.model.lineItems[i]._id;
    },
    duplicateInvoice(model) {
      this.$emit('duplicateDocument', model);
    },
    fileOptionsCancel() {
      this.activeItem = 0;
    },
    flagThis() {
      this.model.flagged = !this.model.flagged;
      this.saveNow();
    },
    inputBlur() {
      this.activeInput = null;
    },
    inputFocus(id) {
      this.activeInput = id;
    },
    itemAdd() {
      const lineItemId = this.$guid();
      this.model.lineItems.push({
        _id: lineItemId,
        date: moment().format(),
        qty: 1,
        item: '',
        price: null,
        taxable: false,
        recur: false,
      });
      this.changeLineItem = lineItemId;
      setTimeout(() => {
        document.getElementById(`priceInput-${lineItemId}`).focus();
        document.getElementById(`priceInput-${lineItemId}`).select();
      }, 270);
    },
    itemAddLinked() {
      this.addingLinkedItem = true;
    },
    itemAddLinkedText(text) {
      let itemText = '';
      for (let a = 0; a < text.length; a += 1) {
        const item = text[a][1];
        let lineItem = '';
        if (item.showLabel) {
          lineItem += `${item.label}: `;
        }
        lineItem += item.data;
        if (a > 0) {
          itemText += `\n${lineItem}`;
        } else {
          itemText += lineItem;
        }
      }
      return itemText;
    },
    itemAddLinkedConfirm() {
      const lineItemId = this.$guid();
      const itemText = this.itemAddLinkedText(this.linkedItem.text);
      this.model.lineItems.push({
        _id: lineItemId,
        date: moment().format(),
        qty: 1,
        item: itemText,
        price: this.linkedItem.price[1].data ? this.linkedItem.price[1].data.price : 0,
        taxable: false,
        recur: false,
        link: {
          _record: this.linkedItem._record,
          _collection: this.linkedItem._collection,
          linkedOn: moment().format(),
          items: this.linkedItem.text,
          price: this.linkedItem.price[0],
          qty: {
            field: this.linkedItem.qty[0],
            sync: true,
          },
        },
      });
      this.addingLinkedItem = null;
      if (this.linkedItem.qty[0]) {
        this.$store.dispatch('updateQty', {
          addLink: true,
          record: this.linkedItem._record,
          collection: this.linkedItem._collection,
          field: this.linkedItem.qty[0],
          newQty: 1,
          originalQty: 0,
          invoice: this.model._id,
          lineItem: lineItemId,
        });
      } else {
        this.$store.dispatch('invoicedOn', {
          invoice: this.model._id,
          collection: this.linkedItem._collection,
          record: this.linkedItem._record,
          lineItem: lineItemId,
        });
      }
      this.linkedItem = {
        _collection: null,
        _record: null,
        price: [null, {}],
        qty: [null, {}],
        text: [],
      };
      this.saveRequest('charge');
    },
    itemAddLinkedCancel() {
      this.addingLinkedItem = null;
      this.linkedItem = {
        _collection: null,
        _record: null,
        price: [null, {}],
        qty: [null, {}],
        text: [],
      };
    },
    linkedCollection(linkTo) {
      if (linkTo && this.$store.getters.collection(linkTo)) {
        return this.$store.getters.collection(linkTo);
      }
      return {};
    },
    linkedItemSelect(record) {
      // If there's only one of a given type, then select it automatically when the record is chosen
      this.linkedItem._record = record[0];
      if (this.linkedTextFields.length === 1) {
        this.linkedItem.text.push(this.linkedTextFields[0]);
      }
      if (this.linkedPriceFields.length === 1) {
        this.linkedItem.price = this.linkedPriceFields[0];
      }
      if (this.linkedQtyFields.length === 1) {
        this.linkedItem.qty = this.linkedQtyFields[0];
      }
    },
    linkedItemsToSearch(inc, coll) {
      // Creates searchable objects based on metatype, pairs them with item id
      // This method is slightly different than itemsToSearch in RecordAdmin.vue. This is a reductive search (it deletes keys that do not match)
      const items = Object.entries(inc);
      const workingItems = [];
      const collection = this.$store.getters.collection(coll);
      for (let x = 0; x < items.length; x += 1) {
        const item = Object.entries(items[x][1]);
        const workingItem = [];
        for (let a = 0; a < item.length; a += 1) {
          const metaType = item[a][1].metaType;
          if (collection.schemaObj[item[a][0]]) {
            if (item[a][1]) {
              if (metaType === 'Address' && item[a][1].data.address) {
                const adr = item[a][1].data.address;
                if (adr.attn) {
                  workingItem.push(adr.attn.toLowerCase());
                }
                if (adr.street) {
                  workingItem.push(adr.street.toLowerCase());
                }
                if (adr.streetSecond && adr.streetSecondShow) {
                  workingItem.push(adr.streetSecond.toLowerCase());
                }
                if (adr.city) {
                  workingItem.push(adr.city.toLowerCase());
                }
                if (adr.state) {
                  workingItem.push(adr.state.toLowerCase());
                }
                if (adr.city && adr.state) {
                  workingItem.push(`${adr.city.toLowerCase()}, ${adr.state.toLowerCase()}`);
                }
                if (adr.zip) {
                  workingItem.push(adr.zip);
                }
                if (adr.country) {
                  workingItem.push(adr.country.toLowerCase());
                }
                if (adr.postal) {
                  workingItem.push(adr.postal.toLowerCase());
                }
              }
              if (metaType === 'Email') {
                workingItem.push(item[a][1].data.toLowerCase());
              }
              if (metaType === 'MultiSelect' || metaType === 'SelectOne') {
                item[a][1].data.forEach((entry) => {
                  workingItem.push(entry.toLowerCase());
                });
              }
              if (metaType === 'Number' && item[a][1]) {
                workingItem.push(item[a][1].data.toString());
              }
              if (metaType === 'Notes' && item[a][1].data.length) {
                item[a][1].data.forEach((note) => {
                  workingItem.push(note.text.toLowerCase());
                });
              }
              if (metaType === 'Phone') {
                if (item[a][1].data.number) {
                  workingItem.push(item[a][1].data.number.replace(/[^A-Z0-9]+/ig, ''));
                }
              }
              if (metaType === 'Price' && item[a][1].data.price) {
                workingItem.push(item[a][1].data.price.toString());
              }
              if (metaType === 'Status') {
                if (item[a][1].data.text) {
                  workingItem.push(item[a][1].data.text.toLowerCase());
                }
              }
              if (metaType === 'String' || metaType === 'Textarea') {
                workingItem.push(item[a][1].data.toLowerCase());
              }
              if (metaType === 'Tags') {
                item[a][1].data.forEach((tag) => {
                  workingItem.push(tag.toLowerCase());
                });
              }
            }
            if (metaType === 'Toggle') {
              if (item[a][1].data
              && collection.schemaObj[item[a][0]].default.labelOn) {
                workingItem.push(collection.schemaObj[item[a][0]].default.labelOn.toLowerCase());
              }
              if (!item[a][1].data
              && collection.schemaObj[item[a][0]].default.labelOff) {
                workingItem.push(collection.schemaObj[item[a][0]].default.labelOff.toLowerCase());
              }
            }
          }
        }
        if (workingItem.length) {
          workingItems.push({ _id: items[x][0], data: workingItem });
        }
      }
      return workingItems;
    },
    linkedRecord(collId, item) {
    // linkedRecord(field, recId) {
      // Display linked record data
      // const collId = field[1].default.linkTo;
      // const fieldKey = field[0];
      // const fields = field[1].default.linkedFields;
      return this.$store.getters.recordLink(collId, item, 'all');
    },
    linkedPriceClick(record) {
      if (this.linkedItem.price[0] === record[0]) {
        this.linkedItem.price = [null, {}];
      } else {
        this.linkedItem.price = record;
      }
    },
    linkedQtyClick(record) {
      if (this.linkedItem.qty[0] === record[0]) {
        this.linkedItem.qty = [null, {}];
      } else {
        this.linkedItem.qty = record;
      }
    },
    linkedTextClick(record) {
      if (record) {
        const index = this.linkedItem.text.findIndex(i => i[0] === record[0]);
        if (index > -1) {
          this.linkedItem.text.splice(index, 1);
        } else {
          this.linkedItem.text.push(record);
        }
      } else {
        this.linkedItem.text = [];
      }
    },
    lockedWarning(hovered) {
      if (hovered) {
        this.lockedItemHover = true;
      } else {
        this.lockedItemHover = false;
      }
    },
    noteAdd() {
      const newNoteId = this.$guid();
      this.model.notes.push({
        _id: newNoteId,
        date: moment().format(),
        text: null,
        showToCustomer: false,
        systemGenerated: false,
      });
      this.editNoteText = newNoteId;
    },
    noteDateCheck(i) {
      if (!this.model.notes[i].date) {
        this.model.notes[i].date = moment().format('L').substr(0, 10);
      }
      this.editNoteDate = this.model.notes[i]._id;
    },
    noteDateChoose(i) {
      this.editNoteDate = null;
      if (!this.model.notes[i].date) {
        this.model.notes[i].date = moment().format();
      }
      this.saveRequest();
    },
    noteDeleteCancel() {
      this.noteDeleteConfirm = null;
    },
    noteDeleteConfirmed(id) {
      const i = this.model.notes.findIndex(note => note._id === id);
      this.model.notes.splice(i, 1);
      this.noteDeleteConfirm = null;
      this.saveRequest();
    },
    noteDeleteRequest(id) {
      this.noteDeleteConfirm = id;
    },
    noteEditText(noteId) {
      this.editNoteText = noteId;
    },
    paymentAdd() {
      const payId = this.$guid();
      const newPayment = {
        _id: payId,
        date: moment().format(),
        amount: null,
        type: null,
        notes: null,
      };
      this.changePayment = payId;
      this.model.payments.push(newPayment);
    },
    paymentRem(i) {
      this.model.payments.splice(i, 1);
      this.deletePaymentRequest = null;
      this.saveRequest('payment');
    },
    paymentRemReq(i) {
      this.deletePaymentRequest = this.model.payments[i]._id;
    },
    previewPdf() {
      if (moment(this.lastChanged).format() > this.model.dateUpdated) {
        this.saveNow();
      }
      this.activeItemBefore = this.activeItem;
      this.activeItem = 5;
      setTimeout(() => {
        this.$store.commit('LAUNCH_PREVIEW', this.model._id);
      }, 250);
    },
    previewPdfDone() {
      this.activeItem = this.activeItemBefore;
    },
    processOutstanding() {
      const incomingInvoice = this.model;
      const totalPayments = incomingInvoice.totalPayments;
      if (incomingInvoice.outstandingItems.length
      && totalPayments > incomingInvoice.totalCharges) {
        const outstandingItemsToRemove = [];
        let overpayment = totalPayments - incomingInvoice.totalCharges;
        for (let a = 0; a < incomingInvoice.outstandingItems.length; a += 1) {
          if (overpayment > 0) {
            const outstandingInvoice = this.$store.getters.invoice(incomingInvoice.outstandingItems[a].originalId);
            const amountDue = outstandingInvoice.totalCharges - outstandingInvoice.totalPayments;
            if (amountDue > 0) {
              const amountToApply = amountDue > overpayment ? overpayment : amountDue;
              incomingInvoice.payments.push(
                {
                  _id: this.$guid(),
                  date: moment().format(),
                  amount: amountToApply * -1,
                  type: this.$t('invoice.systemPayment'),
                  notes: `${this.$t('invoice.paymentAppliedTo')} ${outstandingInvoice.number}`,
                },
              );
              incomingInvoice.totalPayments = this.updateTotalPayments(incomingInvoice.payments);
              outstandingInvoice.payments.push(
                {
                  _id: this.$guid(),
                  date: moment().format(),
                  amount: amountToApply,
                  type: this.$t('invoice.systemPayment'),
                  notes: `${this.$t('invoice.paymentAppliedFrom')} ${incomingInvoice.number}`,
                },
              );
              outstandingInvoice.totalPayments = this.updateTotalPayments(outstandingInvoice.payments);
              overpayment -= amountToApply;
              if (outstandingInvoice.totalPayments >= outstandingInvoice.totalCharges) {
                if (this.preferences.statusAutoUpdate) {
                  outstandingInvoice.status = 'paid';
                }
                outstandingItemsToRemove.push(incomingInvoice.outstandingItems[a].originalId);
                const index = outstandingInvoice.outstandingOn.indexOf(incomingInvoice._id);
                if (index > -1) {
                  outstandingInvoice.outstandingOn.splice(index, 1);
                }
              }
              // eslint-disable-next-line max-len
              const paymentNote = `${this.$t('invoice.paymentAppliedNote[0]')} ${this.$t('project.currencySymbol')}${amountToApply} ${this.$t('invoice.paymentAppliedNote[1]')} ${incomingInvoice.number} ${this.$t('invoice.paymentAppliedNote[2]')} ${outstandingInvoice.number}`;
              incomingInvoice.notes.push(paymentNote);
              outstandingInvoice.notes.push(paymentNote);
              outstandingInvoice.dateUpdated = new Date();
              this.updatePayload.push(outstandingInvoice);
            }
          }
        }
        if (this.updatePayload.length) {
          for (let a = 0; a < outstandingItemsToRemove.length; a += 1) {
            const index = this.model.outstandingItems.findIndex(i => i.originalId === outstandingItemsToRemove[a]);
            if (index > -1) {
              this.model.outstandingItems.splice(index, 1);
            }
          }
        }
        // TODO -- Add check if invoice has been paid, and remove items based on 'OutstandingOn' list
      }
    },
    removeOutstandingLink(link) {
      const i = this.model.outstandingItems.findIndex(x => x._id === link);
      this.model.outstandingItems.splice(i, 1);
      this.saveRequest();
      if (this.model.outstandingItems.length === 0) {
        this.activeItem = 0;
      }
    },
    removeOutstandingOn(note) {
      // When an invoice is paid or deleted, this removes references to the invoice in other documents
      for (let a = 0; a < this.model.outstandingOn.length; a += 1) {
        const invoice = this.$store.getters.invoice(this.model.outstandingOn[a]);
        const index = invoice.outstandingItems.findIndex(i => i.originalId === this.model.outstandingOn[a]);
        invoice.outstandingItems.splice(index, 1);
        if (note) {
          invoice.notes.push(note);
        }
        this.updatePayload.push(invoice);
      }
      for (let a = this.model.outstandingOn.length - 1; a > -1; a -= 1) {
        this.model.outstandingOn.splice(a, 1);
      }
    },
    removeOutstandingItems(note) {
      // When an invoice is paid or deleted, this removes references to the invoice in other documents
      for (let a = 0; a < this.model.outstandingItems.length; a += 1) {
        const id = this.model.outstandingItems[a].originalId;
        const invoice = this.$store.getters.invoice(id);
        const index = invoice.outstandingOn.indexOf(id);
        invoice.outstandingOn.splice(index, 1);
        if (note) {
          invoice.notes.push(note);
        }
        this.updatePayload.push(invoice);
      }
    },
    recalculatePayments() {
      this.model.totalPayments = this.updateTotalPayments(this.model.payments);
    },
    recalculateTotal() {
      this.model.totalCharges = this.updateTotalCharges(this.model.lineItems, { enable: this.preferences.taxOption, rate: this.preferences.taxRate });
    },
    saveRequest(recalculate) {
      this.$store.commit('AUTOSAVE_REQUEST');
      let refreshNeeded = false;
      let typeOfChange = null;
      if (recalculate === 'payment') {
        this.recalculatePayments();
        if (this.model.status !== 'proposal') {
          if (this.preferences.statusAutoUpdate
            && this.model.totalPayments >= (this.model.totalCharges + this.totalOutstanding)
            && this.model.status !== 'paid') {
            this.model.status = 'paid';
          }
          if (this.preferences.statusAutoUpdate
              && this.model.totalPayments < (this.model.totalCharges + this.totalOutstanding)
              && this.model.status === 'paid') {
            if (this.model.dateDue) {
              this.model.status = 'sent';
            } else {
              this.model.status = 'open';
            }
          }
        }
        typeOfChange = 'finance';
      }
      if (recalculate === 'charge') {
        this.recalculateTotal();
        if (this.model.status !== 'proposal') {
          if (this.preferences.statusAutoUpdate
            && this.model.status !== 'open') {
            this.model.status = 'open';
          }
        }
        typeOfChange = 'finance';
      }
      if (recalculate === 'proposal') {
        this.recalculatePayments();
        this.recalculateTotal();
        typeOfChange = 'finance';
      }
      if (recalculate === 'outstanding') {
        typeOfChange = 'finance';
      }
      if (typeOfChange === 'finance'
          && (this.sortBy === 'Total Charges'
          || this.sortBy === 'Balance Due')) {
        refreshNeeded = true;
      }
      if (recalculate === 'Date'
          && this.sortBy === 'Date') {
        refreshNeeded = true;
      }
      if (refreshNeeded) {
        this.model.needRefresh = true;
      }
      this.lastChangedBefore = this.lastChanged;
      this.lastChanged = moment();
    },
    saveNow() {
      this.$store.commit('AUTOSAVE_COMPLETE');
      this.updatePayload = [];
      this.model.dateUpdated = new Date();
      if (this.model.outstandingOn.length
      && this.model.totalPayments >= this.model.totalCharges) {
        this.removeOutstandingOn();
      }
      if (this.model.outstandingItems.length
      && this.model.totalPayments > this.model.totalCharges) {
        this.processOutstanding();
      }
      if (this.updatePayload.length) {
        this.updatePayload.push(this.model);
        this.$store.dispatch('updateMultipleInvoices', { customer: this.model.customer._id, invoices: this.updatePayload });
      } else {
        this.$store.dispatch('updateInvoice', this.model);
      }
      if (this.needToUpdateQty.record) {
        this.$store.dispatch('updateQty', this.needToUpdateQty);
        this.needToUpdateQty = {};
      }
      if (this.model.needRefresh) {
        this.$delete(this.model, 'needRefresh');
      }
    },
    savePreferences(incomingPref) {
      this.model.preferences = {
        ...this.model.preferences,
        ...incomingPref,
      };
      if (Object.entries(incomingPref)[0][0].slice(0, 5) !== 'color') {
        this.saveRequest();
      } else {
        this.lastSaved = moment();
        this.saveNow();
      }
    },
    searchTermEntry(e) {
      this.searchTermPartial = e[0];
    },


    seeLinkedData(link) {
      // Navigates to linked record
      setTimeout(() => {
        this.$store.state.viewItem.record = link._record;
        // this.$store.state.viewItem.collection = link._collection;
        this.justViewed.record = this.model._id;
        this.justViewed.collection = 'billing';
      }, 350);
      this.$router.push(`/dashboard/records/${link._collection}`);
    },

    refreshLinkedData(item) {
      // Replaces current line item info with updated info
      const index = this.model.lineItems.findIndex(i => i._id === item._id);
      const lineItem = this.model.lineItems[index];
      this.$store.commit('SHOW_SPINNER_BY_ID', lineItem._id);
      lineItem.link.linkedOn = moment().format();
      const record = this.$store.getters.record(lineItem.link._collection, lineItem.link._record);
      lineItem.price = record[lineItem.link.price].price;
      const fieldData = Object.entries(this.linkedRecord(lineItem.link._collection, lineItem.link._record));
      const fieldsToUse = [];
      lineItem.link.items.forEach(i => fieldsToUse.push(i[0]));
      lineItem.item = this.itemAddLinkedText(fieldData.filter(i => fieldsToUse.indexOf(i[0]) > -1));
      this.model.lineItems[index] = lineItem;
      this.saveRequest('charge');
      setTimeout(() => {
        this.$store.commit('HIDE_SPINNER_BY_ID');
      }, 650);
    },

    removeLinkReceipt(linkId) {
      // Removes link between Line Item and Record
      const index = this.model.lineItems.findIndex(i => i._id === linkId);
      const link = this.model.lineItems[index].link;
      const lineItem = this.model.lineItems[index];
      if (link.qty && link.qty.field && link.qty.sync) {
        this.$store.dispatch('updateQty', {
          removeLink: true,
          record: link._record,
          collection: link._collection,
          field: link.qty.field,
          newQty: 0,
          originalQty: lineItem.qty,
          invoice: this.model._id,
          lineItem: lineItem._id,
        });
      } else {
        this.$store.dispatch('invoicedOnRemove', {
          invoice: this.model._id,
          collection: link._collection,
          record: link._record,
          lineItem: lineItem._id,
        });
      }
      this.saveRequest();
      this.changeLineItem = null;
      this.model.lineItems[index].link = {};
      this.deleteLinkRequest = null;
    },

    updateQty(lineItem, input) {
      // Qty field uses :value, and must be updated manually
      // Also needs to check if Qty sync is on
      if (input || input === 0) {
        let newVal = input;
        if (newVal) {
          newVal = newVal.replace(/[^\d.-]/g, '');
          newVal = parseFloat(newVal);
        }
        if (lineItem.link && lineItem.link.qty && lineItem.link.qty.sync) {
          if (this.needToUpdateQty.record) {
            if (lineItem._id !== this.needToUpdateQty.lineItem._id) {
              // triggers if user has switched lineItems before old one has saved
              this.$store.dispatch('updateQty', this.needToUpdateQty);
              this.needToUpdateQty = {};
            } else if (typeof newVal === 'number') {
              // triggers on subsequent updates to same field in a single autosave period
              this.needToUpdateQty.newQty = newVal;
            }
          }
          if (!this.needToUpdateQty.record) {
            this.needToUpdateQty = {
              record: lineItem.link._record,
              collection: lineItem.link._collection,
              field: lineItem.link.qty.field,
              newQty: newVal,
              originalQty: lineItem.qty,
              invoice: this.model._id,
              lineItem: lineItem._id,
            };
          }
        }
        const index = this.model.lineItems.findIndex(i => i._id === lineItem._id);
        if (!newVal || typeof newVal !== 'number') {
          this.model.lineItems[index].qty = null;
        } else {
          this.model.lineItems[index].qty = newVal;
        }
      }
      this.saveRequest('charge');
    },

    updateTextInputLineItem(input) {
      for (let a = 0; a < this.model.lineItems.length; a += 1) {
        if (this.model.lineItems[a]._id === this.changeLineItem) {
          this.model.lineItems[a].item = input;
          this.saveRequest();
        }
      }
    },

    updateTextInputPayment(input) {
      for (let a = 0; a < this.model.payments.length; a += 1) {
        if (this.model.payments[a]._id === this.changePayment) {
          this.model.payments[a].notes = input;
          this.saveRequest();
        }
      }
    },

    seeOutstandingItem(link) {
      this.$store.dispatch('showItem', link);
      this.$emit('scroll-to');
    },
    seeThis() {
      this.$emit('loadCustomer', this.customer._id);
    },
    statusDropdownColor(status) {
      if (status === 'open') {
        return 'highlight';
      }
      if (status === 'sent') {
        return 'lowKey';
      }
      if (status === 'paid') {
        return 'success';
      }
      if (status === 'pastDue') {
        return 'danger';
      }
      if (status === 'hold') {
        return 'warning';
      }
      if (status === 'proposal') {
        return 'info';
      }
      if (status === 'proposalSent') {
        return 'info';
      }
      return 'highlight';
    },
    statusOption(key) {
      return this.$t(`invoice.statusOptions.${key}`);
    },
    tableLineItemRowHover(item) {
      if (!this.lockedItem) {
        this.changeHover = item._id;
      }
    },
    tableLineItemRowUnhover() {
      if (!this.lockedItem) {
        this.changeHover = null;
      } else {
        this.lockedWarning(false);
      }
    },
    tableLineItemRowClicked(item) {
      if (!this.lockedItem) {
        const newItem = this.changeLineItem !== item._id;
        if (this.changeLineItem && newItem) {
          document.getElementById('lineItemTable').focus();
        }
        this.changeLineItem = newItem ? item._id : null;
        if (newItem) {
          setTimeout(() => {
            document.getElementById(`input-${item._id}`).focus();
            document.getElementById(`input-${item._id}`).select();
          }, 270);
        }
      } else {
        this.lockedWarning(true);
      }
    },
    tablePaymentsRowHover(item) {
      this.changeHover = item._id;
    },
    tablePaymentsRowUnhover() {
      this.changeHover = null;
    },
    tablePaymentsRowClicked(item) {
      this.changePayment = item._id;
    },
    updateDate() {
      this.saveRequest('Date');
    },
    updateStatus(newStatus) {
      const wasNotProposal = !!(this.model.status !== 'proposal');
      this.model.status = newStatus;
      this.model.notes.push({
        _id: this.$guid(),
        date: moment().format(),
        text: `${this.$t('invoice.status.setTo')} '${newStatus}' ${this.$t('invoice.status.setToByUser')}.`,
        showToCustomer: false,
        systemGenerated: true,
      });
      if (this.model.status !== 'proposal' && wasNotProposal) {
        this.saveRequest();
      } else {
        this.saveRequest('proposal');
      }
    },
    updateTaxes() {
      this.saveRequest('charge');
    },
  },
  watch: {
    activeItem(newVal, oldVal) {
      if (oldVal === 1 && newVal !== 1) {
        for (let a = 0; a < this.model.lineItems.length; a += 1) {
          if (this.model.lineItems[a]._showDetails) {
            this.model.lineItems[a]._showDetails = false;
          }
        }
      }
    },
    changeLineItem(newVal, oldVal) {
      if (oldVal) {
        const index = this.model.lineItems.findIndex(i => i._id === oldVal);
        this.model.lineItems[index]._showDetails = false;
      }
      if (newVal) {
        const index = this.model.lineItems.findIndex(i => i._id === newVal);
        this.model.lineItems[index]._showDetails = true;
      }
    },
    deleteRequest(newVal) {
      if (newVal && newVal === this.model._id) {
        this.deleteInvoice(newVal);
      }
    },
    searchTerms(newVal) {
      if (newVal) {
        this.searchTermPartial = null;
      }
    },
    showThis(newVal, oldVal) {
      if (newVal) {
        this.activeItem = 1;
      }
      if (!newVal && oldVal) {
        this.changeDate = null;
        this.changeDateDue = null;
        this.changePayment = null;
        this.changeLineItem = null;
        this.linkedItem = {
          _record: null,
          _collection: null,
          price: [null, {}],
          qty: [null, {}],
          text: [],
        };
        this.addingLinkedItem = null;
        this.activeItem = 0;
      }
    },
    'model.status': {
      handler(newVal, oldVal) {
        if (newVal === 'open' && oldVal === 'sent') /* if set to Open from Sent */ {
          this.model.dateSent = null;
        }
        if (newVal === 'open' && oldVal === 'proposal') {
          this.model.date = moment().format();
        }
        if (newVal === 'proposalSent') {
          this.model.dateProposalSent = moment().format();
          if (this.preferences.proposalExpirationDateEnabled) {
            if (!this.model.dateProposalExpires && this.preferences.proposalExpirationDateIsAutomatic) {
              this.model.dateProposalExpires = moment().add(this.preferences.defaultTermsProposal, 'days').format();
            } else if (!this.model.dateProposalExpires) {
              this.model.dateProposalExpires = moment(this.model.dateProposal).add(this.preferences.defaultTermsProposal, 'days').format();
            }
          }
        }
        if (newVal === 'sent') {
          this.model.dateSent = moment().format();
          if (this.preferences.dueDateEnabled) {
            if (!this.model.dateDue && this.preferences.dueDateIsAutomatic) {
              this.model.dateDue = moment().add(this.preferences.defaultTerms, 'days').format();
            } else if (!this.model.dateDue) {
              this.model.dateDue = moment(this.model.date).add(this.preferences.defaultTerms, 'days').format();
            }
          }
        }
        this.saveRequest();
      },
    },
  },
};
</script>
