<template>
  <div class="text-center">
    <loading-spinner
        :active.sync="isLoading"
        :is-full-page="fullPage"
        :loader="type"
        :height="h"
        :width="w"
        :color="color"
    />
  </div>
</template>

<script>
import LoadingSpinner from 'vue-loading-overlay';

export default {
  props: [
    'color',
    'fullPage',
    'type',
    'h',
    'w',
  ],
  data() {
    return {
      isLoading: true,
    };
  },
  components: {
    LoadingSpinner,
  },
};
</script>
