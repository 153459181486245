<template>
  <div
    id="invoiceTableContainer">
    <b-table
        id="item-table"
        striped
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :busy="this.$store.state.showLoader"
        primary-key="_id"
        class="invoice__table"
        :tbody-transition-props="{name: 'fade', mode: 'out-in'}">
      <template
          v-slot:table-busy>
        <loader
            type="spinner"
            color="#013f10"
            :fullPage="false"
            :h="80"
            :w="200"
        />
        </template>
        <template
            v-slot:cell(file-badge)="row">
          <div
              :id="`scroller-${row.item._id}`"
              :class="showItem === row.item._id ? 'my-3' : ''">
            <file-badge
                :id="row.item._id"
                :model="row.item"
                @click.stop.native
                @scrollTo="scrollToItem"
                @duplicateDocument="duplicateInvoice"
                @loadCustomer="requestCustomerFilter"
            />
          </div>
        </template>
    </b-table>
  </div>
</template>
<script>
import moment from 'moment';
import fileBadge from '@/components/data/InvoiceFileBadge';
import totalCalculators from '@/mixins/totalCalculators';

export default {
  name: 'InvoiceTable',
  title() {
    return `${this.$t('adminInvoice')} | ${this.$t('project.brand')}`;
  },
  components: {
    fileBadge,
  },
  mixins: [
    totalCalculators,
  ],
  props: [
    'currentPage',
    'items',
    'perPage',
  ],
  data() {
    return {
      fields: [
        { key: 'file-badge', label: '' },
      ],
      sortDesc: false,
      updateStatus: null,
    };
  },
  computed: {
    showItem() {
      return this.$store.state.showItem;
    },
  },
  methods: {
    dateDisplay(date) {
      return moment(date).format('l');
    },
    duplicateInvoice(model) {
      this.$emit('duplicateDocument', model);
    },
    requestCustomerFilter(customer) {
      this.$emit('customerFilterNeeded', customer);
    },
    scrollToItem() {
      this.$emit('scroll-to');
    },
  },
  watch: {
    showItem(newVal) {
      if (newVal) {
        const invoice = this.$store.getters.invoice(newVal);
        for (let a = 0; a < invoice.lineItems.length; a += 1) {
          if (invoice.lineItems[a]._showDetails) {
            invoice.lineItems[a]._showDetails = false;
          }
        }
      }
    },
  },
};
</script>
