import AWS from 'aws-sdk';
import {
  bucketOrig,
  bucketLogo,
  IdentityPoolId,
  region,
  regionSes,
  userAdminAccessKey,
  userAdminSecret,
} from '../../secrets.json';


AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId,
});
AWS.config.update({
  accessKeyId: userAdminAccessKey,
  secretAccessKey: userAdminSecret,
  region,
});

export const sns = new AWS.SNS();

export const ses = new AWS.SES({
  params: { Region: regionSes },
});

export const s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: bucketOrig },
});

export const logos = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: bucketLogo },
});

export const bucket = bucketOrig;

export async function signUp(Username, TemporaryPassword) {
  let userData;
  try {
    const cognito = new AWS.CognitoIdentityServiceProvider();
    await cognito.adminCreateUser({
      UserPoolId: IdentityPoolId,
      Username,
      TemporaryPassword,
    }).promise().then((i) => {
      userData = i;
    });
  } catch (err) {
    return { err };
  }
  return userData.User;
}

export async function resetPassword(Username, Password) {
  let status;
  try {
    const cognito = new AWS.CognitoIdentityServiceProvider();
    await cognito.adminSetUserPassword({
      UserPoolId: IdentityPoolId,
      Username,
      Password,
      Permanent: false,
    }).promise().then((i) => {
      status = i.$response;
    });
  } catch (err) {
    return { err };
  }
  return status;
}
