<template>
  <div
      v-if="$store.state.initialLoad">
    <transition
        mode="out-in"
        name="slide-fade">
      <h1
          key="tableViewHeader"
          v-if="!selectingDates && !showForm"
          class="workDay__header">
        {{$t('workDay.headers.listView')}}
        <b-btn
            id="addWorkDay"
            @click="addWorkDay()"
            :aria-label="$t('workDay.addWorkDay')"
            variant="primary"
            class="ml-2 my-auto">
          <b-icon-plus
              :scale="2"
          />
        </b-btn>
      </h1>
      <h1
          key="formViewHeader"
          v-else
          class="workDay__header">
        <b-btn
            id="cancelForm"
            :aria-label="$t('aria.cancelAddWorkDay')"
            @click="showForm = false; selectingDates = false; editingWorkDay = null;"
            variant="danger-outline">
          <b-icon-x-circle />
        </b-btn>
        {{!editingWorkDay ? $t('workDay.workDayAdd') : $t('workDay.workDayEdit')}}
      </h1>
    </transition>
    <transition
        name="fade"
        mode="out-in">
      <div
          id="workDayDashboard"
          key="normalView"
          v-if="!selectingDates && !showForm">
        <b-table
            id="item-table"
            striped
            :items="workDays"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :busy="this.$store.state.showLoader"
            primary-key="_id"
            class="invoice__table">
          <template
              v-slot:cell(dates)="row">
            <div
                :id="`dates-${row.item._id}`"
                class="workDay__list__info">
              <span
                  v-html="displayDates(row.item.dates)"
              />
            </div>
          </template>
          <template
              v-slot:cell(start)="row">
            <div
                :id="`startTime-${row.item._id}`"
                class="workDay__list__info">
              <b
                  v-text="displayTime(row.item.dates[0], row.item.time.start)"
              />
            </div>
          </template>
          <template
              v-slot:cell(end)="row">
            <div
                :id="`endTime-${row.item._id}`"
                v-if="row.item.time.end">
              <b
                  v-text="displayTime(row.item.dates[0], row.item.time.end)"
              />
            </div>
          </template>
          <template
              v-slot:cell(project)="row">
            <div
                :id="`projectInfo-${row.item._id}`">
              <display-project-info
                :shiftInfo="row.item"
              />
            </div>
          </template>
          <template
              v-slot:cell(employees)="row">
            <div
                :id="`employees-${row.item._id}`">
              <span
                  v-html="displayEmployeeData(row.item.employees)"
              />
            </div>
          </template>
          <template
              v-slot:cell(notes)="row">
            <div
                :id="`notes-${row.item._id}`">
              {{row.item.notes}}
            </div>
          </template>
          <template
              v-slot:cell(buttons)="row">
            <div
                :id="`actionButtons-${row.item._id}`">
              <b-btn-group
                  id="editAndDelete"
                  v-if="deleteRequest !== row.item._id">
                <b-btn
                    :id="`alert-${row.item._id}`"
                    :aria-label="$t('aria.alertWorkDay')"
                    @click="alertWorkDay(row.item)"
                    :variant="smsFailed === row.item._id ? 'danger' : justSent === row.item._id ? 'success' : 'tertiary'"
                    :disabled="smsFailed === row.item._id || justSent === row.item._id || !!$store.state.spinnerById"
                    class="mr-2">
                  <div
                      v-if="$store.state.spinnerById === row.item._id && smsFailed !== row.item._id">
                    <loader
                        color="white"
                        :h="22"
                        :w="22"
                        :fullPage="false"
                    />
                  </div>
                  <div
                      v-else-if="smsFailed === row.item._id">
                    <b-icon-x-circle
                    />
                    {{$t('workDay.alertFailed')}}
                  </div>
                  <div
                      v-else-if="justSent === row.item._id">
                    <b-icon-check
                    />
                  </div>
                  <div
                      v-else>
                    <b-icon-chat-right-text-fill
                    />
                  </div>
                </b-btn>
                <b-btn
                    :id="`edit-${row.item._id}`"
                    :aria-label="$t('aria.editWorkDay')"
                    @click="editWorkDay(row.item._id)"
                    variant="info"
                    class="mr-2">
                  <b-icon-pencil
                  />
                </b-btn>
                <b-btn
                    :id="`delete-${row.item._id}`"
                    :aria-label="$t('aria.deleteWorkDay')"
                    @click="deleteButtonClick(row.item._id)"
                    variant="danger">
                  <b-icon-trash
                  />
                </b-btn>
              </b-btn-group>
              <div
                  v-else>
                <small
                    v-text="$t('areYouSure')"
                />
                <br />
                <b-btn-group
                    id="confirmDelete">
                  <b-btn
                      :id="`delete-${row.item._id}`"
                      :aria-label="$t('aria.deleteWorkDayConfirm')"
                      @click="deleteWorkDay(row.item._id)"
                      variant="danger">
                    <b-icon-trash
                    />
                  </b-btn>
                  <b-btn
                      :id="`edit-${row.item._id}`"
                      :aria-label="$t('aria.cancel')"
                      @click="deleteRequest = null"
                      variant="info"
                      class="ml-2">
                    <b-icon-arrow-left
                    />
                  </b-btn>
                </b-btn-group>
              </div>
            </div>
          </template>
        </b-table>
      </div>
      <div
          v-else
          id="workDayForm"
          key="form"
          class="form__container">
        <transition
            name="slide-fade"
            mode="out-in">
          <div
              id="workDayDates"
              key="selectingDates"
              v-if="selectingDates">
            <date-picker
                key="noDatesChosen"
                v-if="!model.length"
                :disableSelectType="false"
                :startingSelectType="0"
                :startingDate="preferences.autoSelectDay ? logicalStartingDate : null"
                @datesChosen="datesSelected"
            />
          </div>
          <div
              v-else
              key="setInfo">
            <b-row
                id="times"
                align-h="center">
              <b-col
                  md="5"
                  sm="10"
                  align="center"
                  class="workDay__input__container">
                <label
                    :for="`startTime-${model._id}`"
                    v-text="$t('workDay.shiftStartTime')"
                    class="workDay__input__label"
                />
                <b-form-timepicker
                    :id="`startTime-${model._id}`"
                    :placeholder="$t('scheduler._timePlaceholder')"
                    v-model="model.time.start"
                    size="lg"
                    :locale="$t('project.timeLocale')"
                    class="workDay__input"
                />
              </b-col>
              <b-col
                  md="5"
                  sm="10"
                  align="center"
                  class="workDay__input__container">
                <label
                    :for="`endTime-${model._id}`"
                    v-text="$t('workDay.shiftEndTime')"
                    class="workDay__input__label"
                />
                <b-form-timepicker
                    :id="`endTime-${model._id}`"
                    :placeholder="$t('scheduler._timePlaceholder')"
                    v-model="model.time.end"
                    size="lg"
                    :locale="$t('project.timeLocale')"
                    class="workDay__input"
                />
              </b-col>
            </b-row>
            <b-row
                id="projectInfo"
                align-h="center">
              <b-col
                  md="10"
                  sm="12"
                  align="center"
                  class="workDay__input__container">
                <label
                    class="workDay__input__label"
                    v-text="existingProject ? searchingProjects ? $t('workDay.searchingProjects') : $t('workDay.projectsList') : $t('workDay.projectNew')"
                />
                <!-- List of existing projects -->
                <b-list-group
                    v-if="existingProject">
                  <transition-group
                      mode="out-in"
                      name="fadeQuick">
                    <b-list-group-item
                        v-for="project in projects"
                        @mouseover="hoverProject = project._id"
                        @mouseleave="hoverProject = null"
                        :variant="(hoverProject === project._id) || model.project === project._id ? 'success' : 'secondary'"
                        style="cursor: pointer"
                        @click="selectProject(project._id)"
                        :key="project._id"
                        class="px-5 py-3">
                      <display-project-info
                          :projectData="project"
                      />
                    </b-list-group-item>
                  </transition-group>
                </b-list-group>
                <!-- Enter manual project data & address -->
                <div
                    v-if="!existingProject || existingProjectData"
                    class="workDay__input__container m-0 mb-4">
                  <b-row
                      align-h="center">
                    <b-col
                        cols="auto">
                      <label
                          v-text="$t('workDay.projectTitle')"
                          :for="`title-${model._id}`"
                          class="form__label ml-0 mr-auto"
                      />
                      <b-form-input
                          :id="`title-${model._id}`"
                          type="text"
                          v-model="model.projectData.title"
                          class="form__input medium"
                      />
                    </b-col>
                    <b-col
                        cols="auto">
                      <label
                          v-text="$t('workDay.projectName')"
                          :for="`name-${model._id}`"
                          class="form__label ml-0 mr-auto"
                      />
                      <b-form-input
                          :id="`name-${model._id}`"
                          type="text"
                          v-model="model.projectData.name"
                          class="form__input medium"
                      />
                    </b-col>
                  </b-row>
                  <b-row
                      align-h="center">
                    <b-col
                        cols="auto"
                        align="center">
                      <label
                          :for="`streetEntry-${model._id}`"
                          v-text="$t('collections.address.labels.street')"
                          class="form__label ml-0 mr-auto"
                      />
                      <small
                          v-if="!model.projectData.address.streetSecondShow"
                          v-text="$t('collections.address.labels.addSecondStreet')"
                          @click="model.projectData.address.streetSecondShow = !model.projectData.address.streetSecondShow"
                          class="ml-4 mr-0 form__textButton"
                      />
                      <b-input-group
                          class="form__input xl">
                        <b-form-input
                            :id="`streetEntry-${model._id}`"
                            v-model="model.projectData.address.street"
                            class="form__input"
                            type="text"
                        />
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <b-row
                      align-h="center">
                    <b-col
                        cols="auto"
                        align="center">
                      <label
                          v-text="$t('collections.address.labels.streetSecond')"
                          v-if="model.projectData.address.streetSecondShow"
                          :for="`${model._id}-streetSecondEntry`"
                          class="form__label ml-0 mr-auto"
                      />
                      <small
                          v-if="model.projectData.address.streetSecondShow"
                          v-text="$t('collections.address.labels.removeSecondStreet')"
                          @click="model.projectData.address.streetSecondShow = !model.projectData.address.streetSecondShow"
                          class=" ml-4 mr-0 form__textButton"
                      />
                      <b-form-input
                          :id="`${model._id}-streetSecondEntry`"
                          v-if="model.projectData.address.streetSecondShow"
                          v-model="model.projectData.address.streetSecond"
                          class="form__input xl"
                          type="text"
                      />
                    </b-col>
                  </b-row>
                  <b-row
                      align-h="center">
                    <b-col
                        cols="auto">
                      <label
                          v-text="$t('collections.address.labels.city')"
                          :for="`${model._id}-cityEntry`"
                          class="form__label ml-0 mr-auto"
                      />
                      <b-form-input
                          :id="`${model._id}-cityEntry`"
                          type="text"
                          v-model="model.projectData.address.city"
                          class="form__input medium"
                      />
                    </b-col>
                    <b-col
                        cols="auto">
                      <label
                          v-text="$t('collections.address.labels.state')"
                          :for="`stateEntry-${model._id}`"
                          class="form__label ml-0 mr-auto"
                      />
                      <b-form-input
                          :id="`stateEntry-${model._id}`"
                          type="text"
                          v-model="model.projectData.address.state"
                          class="form__input stateInput"
                      />
                    </b-col>
                    <b-col
                        cols="auto">
                      <label
                          v-text="$t('collections.address.labels.zip')"
                          :for="`zipEntry-${model._id}`"
                          class="form__label ml-0 mr-auto"
                      />
                      <b-form-input
                          :id="`zipEntry-${model._id}`"
                          type="text"
                          v-model="model.projectData.address.zip"
                          class="form__input zipInput"
                      />
                    </b-col>
                  </b-row>
                </div>
                <b-btn-group
                    id="projectToolBarButtons"
                    class="workDay__input__container__dark"
                    v-if="!model.project || !existingProject">
                  <b-btn
                      id="viewOlderProjects"
                      v-if="existingProject && !searchingProjects && projects.length"
                      @click="projectPage += 1"
                      :aria-label="$t('workDay.viewOlder')"
                      variant="info">
                    <b-icon-chevron-left />
                  </b-btn>
                  <b-btn
                      id="viewNewerProjects"
                      v-if="existingProject && !searchingProjects && projects.length"
                      @click="projectPage -= 1"
                      :disabled="projectPage === 1"
                      :aria-label="$t('workDay.viewNewer')"
                      variant="info">
                    <b-icon-chevron-right />
                  </b-btn>
                  <b-btn
                      id="searchForProject"
                      v-if="existingProject && projects.length"
                      @click="searchForProject()"
                      :variant="!searchingProjects ? 'secondary' : 'warning'">
                      <div
                          v-if="!searchingProjects">
                        <b-icon-search />
                      </div>
                      <div
                          v-else>
                        {{$t('cancel')}}
                      </div>
                  </b-btn>
                  <transition
                      mode="out-in"
                      name="fadeQuick">
                    <b-btn
                        id="manualEntry"
                        v-if="!searchingProjects"
                        @click="manualEntryToggle()"
                        variant="secondary"
                        class="ml-auto mr-0"
                        v-text="existingProject ? $t('workDay.manualEntry') : $t('workDay.manualEntryCancel')"
                    />
                    <b-form-input
                        v-else
                        id="searchTermInput"
                        v-model="searchTerm"
                    />
                  </transition>
                </b-btn-group>
                <b-btn-group
                    v-if="model.project"
                    class="workDay__input__container__dark">
                  <b-btn
                      id="clearSelectionButton"
                      variant="warning"
                      @click="model.project = null">
                    <b-icon-x-circle /> {{$t('workDay.clearProjectSelection')}}
                  </b-btn>
                </b-btn-group>
              </b-col>
            </b-row>
            <b-row
                id="employees"
                align-h="center"
                v-if="employees.length">
              <b-col
                  cols="10"
                  align="center"
                  class="workDay__input__container">
                <label
                    :for="`employees-${model._id}`"
                    v-text="$t('workDay.employees')"
                    class="workDay__input__label"
                />
                <b-list-group
                    :id="`employees-${model._id}`">
                  <b-list-group-item
                      v-for="employee in employees"
                      :key="employee._id"
                      @mouseover="hoverEmployee = employee._id"
                      @mouseleave="hoverEmployee = null"
                      :variant="(hoverEmployee === employee._id) || model.employees.indexOf(employee._id) > -1 ? 'success' : 'secondary'"
                      style="cursor: pointer"
                      @click="clickEmployee(employee._id)"
                      class="px-5 py-3">
                    <span
                        v-if="preferences.showEmployeeId && employee.employeeId"
                        v-html="`${employee.employeeId}${employee.name ? ' - ' : null}`"
                    />
                    {{employee.name ? employee.name : null}}
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
            <b-row
                align-h="center">
              <b-col
                  align="center"
                  md="6"
                  sm="10"
                  class="workDay__input__container">
                <label
                    :for="`shiftNotes-${model._id}`"
                    v-text="$t('workDay.shiftNotes')"
                    class="workDay__input__label"
                />
                <b-form-textarea
                    :id="`shiftNotes-${model._id}`"
                    v-model="model.notes"
                    class="workDay__input__large"
                />
              </b-col>
              <b-col
                  md="4"
                  sm="10"
                  align="center"
                  class="workDay__input__container">
                <b-row
                    align-h="center">
                  <b-col
                      cols="*"
                      align="center">
                    <label
                        :for="`noCellService-${model._id}`"
                        v-text="$t('workDay.cellServiceHeader')"
                        class="workDay__input__label"
                    />
                    <b-btn-group
                        :id="`noCellService-${model._id}`"
                        class="workDay__input__container__dark mx-auto px-5 mt-0 py-3">
                      <b-btn
                          :id="`yesCellReceptionButton-${model._id}`"
                          @click="model.cellService = true"
                          :variant="model.cellService ? 'info' : 'info-outline'"
                          v-text="$t('workDay.cellServiceYes')">
                      </b-btn>
                      <b-btn
                          :id="`noCellReceptionButton-${model._id}`"
                          @click="model.cellService = false"
                          :variant="!model.cellService ? 'info' : 'info-outline'"
                          v-text="$t('workDay.cellServiceNo')">
                      </b-btn>
                    </b-btn-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row
                align-h="center"
                class="workDay__input__container__dark">
              <b-col
                  cols="*"
                  align="center"
                  class="mx-auto">
                <b-btn
                    id="goBackButton"
                    @click="editGoBack()"
                    :variant="!editingWorkDay ? 'warning' : 'secondary'"
                    class="my-3 px-5 py-3">
                  <b-icon-arrow-left /> {{!editingWorkDay ? $t('workDay.buttonGoBack') : $t('workDay.buttonChangeDates')}}
                </b-btn>
              </b-col>
              <b-col
                  cols="*"
                  align="center"
                  class="mx-auto">
                <b-btn
                    id="confirmButton"
                    @click="confirmWorkDay()"
                    variant="primary"
                    class="my-3 px-5 py-3">
                  <b-icon-check /> {{$t('workDay.buttonConfirm')}}
                </b-btn>
              </b-col>
            </b-row>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import totalCalculators from '@/mixins/totalCalculators';
import DatePicker from '@/components/data/DatePicker';
import DisplayProjectInfo from '@/components/singlePurpose/DisplayWorkDayProjectInfo';
import { sns } from '@/store/aws';

export default {
  title() {
    return `${this.$t('workDay.titles.workDayDash')} | ${this.$t('project.brand')}`;
  },
  mixins: [
    totalCalculators,
  ],
  components: {
    DatePicker,
    DisplayProjectInfo,
  },
  computed: {
    buttonSpinner() {
      return this.$store.state.spinnerById;
    },
    employees() {
      const list = [];
      const employees = this.$store.getters.employees;
      const fields = this.$store.state.account.employeesFields;
      employees.forEach((e) => {
        const employee = {
          _id: e._id,
          employeeId: e[fields.employeeId],
          active: e[fields.active],
          address: e[fields.address],
          email: e[fields.email],
          name: e[fields.name],
          notes: e[fields.notes],
          phone: e[fields.phone],
        };
        if (employee.active && employee.active.value) {
          list.push(employee);
        }
      });
      return list;
    },
    existingProjectData() {
      if (this.model.projectData.title
          || this.model.projectData.name
          || this.model.projectData.address.street
          || this.model.projectData.address.city
          || this.model.projectData.address.state
          || this.model.projectData.address.zip) {
        return true;
      }
      return false;
    },
    logicalStartingDate() {
      const hour = moment().format('HH');
      if (hour > 14) {
        return new Date(moment().add(1, 'days'));
      }
      return new Date();
    },
    preferences() {
      return this.$store.getters.userPreferences;
    },
    projects() {
      if (this.model.project) {
        return [this.$store.getters.project(this.model.project)];
      }
      if (!this.searchingProjects) {
        return this.$store.getters.projectsByPage(this.projectPage);
      }
      if (!this.searchTerm) {
        return [];
      }
      const searchableItems = [];
      const results = [];
      for (let x = 0; x < this.$store.getters.projects.length; x += 1) {
        const item = this.$store.getters.projects[x];
        const customer = this.$store.getters.customer(item.customer);
        const workingItem = [];
        if (item.title) {
          workingItem.push(item.title.toLowerCase());
        }
        if (customer.name) {
          workingItem.push(customer.name.toLowerCase());
        }
        if (customer.email) {
          workingItem.push(customer.email.toLowerCase());
        }
        if (customer.phone) {
          workingItem.push(customer.phone);
        }
        if (customer.contact) {
          workingItem.push(customer.contact.toLowerCase());
        }
        if (customer.address) {
          if (customer.address.street) {
            workingItem.push(customer.address.street.toLowerCase());
          }
          if (customer.address.city) {
            workingItem.push(customer.address.city.toLowerCase());
          }
          if (customer.address.state) {
            workingItem.push(customer.address.state.toLowerCase());
          }
          if (customer.address.zip) {
            workingItem.push(customer.address.zip);
          }
          if (customer.addressForBilling) {
            if (customer.billingAddress.street) {
              workingItem.push(customer.billingAddress.street.toLowerCase());
            }
            if (customer.billingAddress.city) {
              workingItem.push(customer.billingAddress.city.toLowerCase());
            }
            if (customer.billingAddress.state) {
              workingItem.push(customer.billingAddress.state.toLowerCase());
            }
            if (customer.billingAddress.zip) {
              workingItem.push(customer.billingAddress.zip);
            }
          }
        }
        if (workingItem.length) {
          searchableItems.push({ _id: item._id, data: workingItem });
        }
      }
      for (let a = 0; a < searchableItems.length; a += 1) {
        for (let b = 0; b < searchableItems[a].data.length; b += 1) {
          if (searchableItems[a].data[b] && searchableItems[a].data[b].includes(this.searchTerm.toLowerCase())) {
            results.push(this.$store.getters.invoice(searchableItems[a]._id));
            break;
          }
        }
      }
      return results;
    },
    workDays: {
      get() {
        return this.$store.getters.workDays;
      },
      set(newVal) {
        this.$store.state.data.workDays = newVal;
      },
    },
  },
  created() {
    this.$store.dispatch('initialLoad').catch((err) => {
      console.log(err);
      this.$router.go();
    });
    this.readyCheck();
  },
  data() {
    return {
      currentPage: 1,
      deleteRequest: null,
      editingWorkDay: null,
      existingProject: true,
      fields: [
        'dates',
        'start',
        'end',
        'project',
        'employees',
        'notes',
        'buttons',
      ],
      hoverEmployee: null,
      hoverProject: null,
      justSent: null, // id of shift when SNS is sent
      model: {},
      modelDefault: {
        _id: null,
        adminLogs: [],
        dateAdded: null,
        dateUpdated: null,
        dates: [],
        employees: [],
        logins: [],
        cellService: true,
        notes: null,
        project: null,
        projectData: {
          name: null,
          title: null,
          address: {
            street: null,
            streetSecond: null,
            streetSecondShow: false,
            city: null,
            zip: null,
          },
        },
        time: {
          start: null,
          end: null,
        },
      },
      perPage: 10,
      projectPage: 1,
      searchingProjects: false,
      searchTerm: null,
      selectingDates: true,
      showForm: true,
      smsFailed: null,
    };
  },
  methods: {
    addWorkDay() {
      // Create new work shift
      this.selectingDates = true;
    },
    alertWorkDay(shift) {
      // Send SMS/SNS to relevant employees
      this.$store.commit('SHOW_SPINNER_BY_ID', shift._id);
      let shiftNote = '';
      let ignoreDate = false;
      if (shift.dates.length === 1) {
        if (moment().format('L') === moment(shift.dates[0]).format('L')) {
          ignoreDate = true;
          shiftNote = this.$t('workDay.message.introToday');
        } else {
          shiftNote = this.$t('workDay.message.intro');
          shiftNote += this.$t('workDay.message.date');
        }
      } else {
        shiftNote = this.$t('workDay.message.introMulti');
        shiftNote += this.$t('workDay.message.dates');
      }
      if (!ignoreDate) {
        for (let a = 0; a < shift.dates.length; a += 1) {
          const wholeDate = moment(shift.dates[a]).format('llll').split(',');
          let date = '';
          date += wholeDate[0]; // Fri
          date += ',';
          date += wholeDate[1]; // Sep 4
          shiftNote += date;
          if (a !== shift.dates.length - 1) { // ie isnt the last
            shiftNote += '\n';
          }
        }
      }
      shiftNote += this.$t('workDay.message.startTime');
      const startTime = this.setTime(shift.dates[0], shift.time.start);
      shiftNote += startTime.format('h:mm a');
      if (shift.time.end) {
        shiftNote += this.$t('workDay.message.endTime');
        const endTime = this.setTime(shift.dates[0], shift.time.end);
        shiftNote += endTime.format('h:mm a');
      }
      if (shift.notes) {
        shiftNote += this.$t('workDay.message.shiftNote');
        shiftNote += shift.notes;
      }
      let projectInfo;
      if (shift.project) {
        projectInfo = this.$store.getters.project(shift.project);
      } else if (shift.projectData.name || shift.projectData.title || shift.projectData.address.street) {
        projectInfo = shift.projectData;
      }
      if (projectInfo) {
        console.log(projectInfo);
        let title = false;
        if (projectInfo.title || projectInfo.name) {
          title = true;
          shiftNote += this.$t('workDay.message.project');
          if (projectInfo.title) {
            shiftNote += projectInfo.title;
          } else {
            shiftNote += projectInfo.name;
          }
        }
        const ad = projectInfo.address;
        let address;
        if (ad.street) {
          address = ad.street;
        }
        if (ad.streetSecond) {
          address += ' ';
          address += ad.streetSecond;
        }
        if (ad.city || ad.state || ad.zip) {
          address += ', ';
          if (ad.city) {
            address += ad.city;
          }
          if (ad.state) {
            address += ad.state;
          }
          if (ad.zip) {
            address += ad.zip;
          }
        }
        if (address) {
          if (!title) {
            shiftNote += this.$t('workDay.message.location');
          } else {
            shiftNote += '\n';
          }
          shiftNote += address;
        }
      }
      if (!shift.cellService) {
        shiftNote += this.$t('workDay.message.noCellService');
      }
      if (shiftNote.length) {
        shift.employees.forEach(async (e) => {
          const employee = this.$store.getters.employee(e);
          const phoneField = this.$store.state.account.employeesFields.phone;
          if (employee && employee[phoneField]) {
            const phone = this.formatPhoneForSns(employee[phoneField].number);
            if (phone) {
              const params = {
                Message: shiftNote,
                PhoneNumber: phone,
              };
              sns.setSMSAttributes({
                attributes: {
                  DefaultSMSType: 'Transactional',
                },
              });
              await sns.publish(params).promise().catch((err) => {
                console.log(err);
                this.smsFailed = shift._id;
                setTimeout(() => {
                  this.smsFailed = null;
                }, 5000);
              });
            }
          }
        });
      }
      this.justSent = shift._id;
      this.$store.commit('HIDE_SPINNER_BY_ID');
      setTimeout(() => { this.justSent = null; }, 5000);
    },
    clickEmployee(id) {
      const index = this.model.employees.indexOf(id);
      if (index > -1) {
        this.hoverEmployee = null;
        this.model.employees.splice(index, 1);
      } else {
        this.model.employees.push(id);
      }
    },
    confirmWorkDay() {
      // Save/update changes to work shift
      this.model.dateUpdated = new Date();
      if (!this.editingWorkDay) {
        this.$store.dispatch('addWorkDay', this.model);
      } else {
        this.$store.dispatch('updateWorkDay', this.model);
        this.editingWorkDay = false;
      }
      this.model = {};
      this.showForm = false;
    },
    datesSelected(e) {
      this.selectedDates = e;
      if (!this.model._id) {
        this.model = { ...this.modelDefault };
        this.model._id = 'temp';
        this.model.dateAdded = new Date();
        if (this.preferences.autoSelectAllEmployees) {
          this.employees.forEach(employee => this.model.employees.push(employee._id));
        }
        if (this.preferences.autoStartTime) {
          this.model.time.start = this.preferences.autoStartTime;
        }
        if (this.preferences.autoEndTime) {
          this.model.time.end = this.preferences.autoEndTime;
        }
        if (this.projects.length === 1) {
          this.model.project = this.projects[0]._id;
        }
      }
      this.model.dates = e;
      this.selectingDates = false;
      this.showForm = true;
    },
    deleteButtonClick(id) {
      this.deleteRequest = id;
    },
    deleteWorkDay(id) {
      this.$store.dispatch('removeWorkDay', id);
    },
    displayDates(array) {
      let list = '';
      const dates = [...array];
      dates.sort((a, b) => ((a > b) ? 1 : -1));
      for (let a = 0; a < dates.length; a += 1) {
        let string = this.dateString(dates[a], true);
        const nextDay = new Date(moment(dates[a]).add(1, 'days')).getTime();
        if (dates[a + 1]
        && nextDay === new Date(dates[a + 1]).getTime()) {
          while (dates[a + 1]
          && nextDay === new Date(dates[a + 1]).getTime()) {
            a += 1;
          }
          string += `  -  ${this.dateString(dates[a], true)}`;
        }
        list += `${string}<br />`;
      }
      return list;
    },
    displayEmployeeData(employees) {
      const fields = this.$store.state.account.employeesFields;
      let string = '';
      for (let a = 0; a < employees.length; a += 1) {
        const employee = this.$store.getters.employee(employees[a]);
        if (this.preferences.showEmployeeId && employee[fields.employeeId]) {
          string += `${employee[fields.employeeId]} - `;
        }
        if (employee[fields.name]) {
          string += employee[fields.name];
        }
        if (a < employees.length - 1) {
          string += '<br />';
        }
      }
      return string;
    },
    editWorkDay(timecardId) {
      this.editingWorkDay = timecardId;
      this.model = this.$store.getters.workDayById(timecardId);
      this.showForm = true;
    },
    editGoBack() {
      this.selectingDates = true;
      this.showForm = false;
    },
    manualEntryToggle() {
      if (!this.existingProject && this.existingProjectData) {
        this.model.projectData = {
          title: null,
          name: null,
          address: {
            street: null,
            city: null,
            state: null,
            zip: null,
          },
        };
      }
      this.existingProject = !this.existingProject;
    },
    searchForProject() {
      this.searchingProjects = !this.searchingProjects;
      if (this.searchingProjects) {
        setTimeout(() => {
          document.getElementById('searchTermInput').focus();
        }, 250);
      }
    },
    selectProject(id) {
      if (this.model.project !== id) {
        this.model.project = id;
      } else {
        this.model.project = null;
      }
    },
    selectEmployee(id) {
      const index = this.model.employees.indexOf(id);
      if (index > -1) {
        this.model.employees.splice(index, 1);
      } else {
        this.model.employees.push(id);
      }
    },
  },
  watch: {},
};
</script>
