<template>
  <div>
    <span
        v-text="model.text"
        :class="model.urgent ? 'record__cell__status__urgentText' : null"
    />
    <br />
    <small
        v-if="model.dateOfStatus"
        v-text="calendarDate(model.dateOfStatus)"
    />
  </div>
</template>
<script>
import totalCalculators from '@/mixins/totalCalculators';

export default {
  props: ['model'],
  mixins: [totalCalculators],
};
</script>
