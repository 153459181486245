<template>
  <b-row
    v-if="dataExists"
    class="mx-0 my-2">
    <b-col
        v-if="model[1].metaType === 'Address'"
        align="left"
        class="mr-2"
        cols="*">
      <span
          v-if="displayAddress(model[1].data, addressDisplayType)"
          class="dataViewer__label"
          v-text="`${model[1].label}:`"
      />
    </b-col>
    <b-col
        v-else
        align="left"
        class="mr-2"
        cols="*">
      <span
          class="dataViewer__label"
          v-text="`${model[1].label}:`"
      />
    </b-col>
    <b-col
        align="left"
        class="mx-2 mt-auto mb-0"
        v-if="model[1].metaType === 'Address'"
        cols="*">
      <display-address
          :addressDisplayType="addressDisplayType"
          :model="model[1].data"
      />
    </b-col>
    <b-col
        v-else-if="model[1].metaType === 'Email'"
        align="left"
        class="mx-2 mt-auto mb-0"
        cols="*">
      <display-email
          :model="model[1].data"
      />
    </b-col>
    <b-col
        v-else-if="model[1].metaType === 'Inventory'"
        align="left"
        class="mx-2 mt-auto mb-0"
        cols="*">
      <display-inventory
          :model="model[1].data"
      />
    </b-col>
    <b-col
        v-else-if="model[1].metaType === 'Link'"
        align="left"
        class="mx-2 mt-auto mb-0"
        cols="*">
      <div
          v-for="link in model[1].data.linksChosen"
          :key="link">
        <b-row
            v-for="(field, key) in $store.getters.recordLink(
                $store.getters.collection(collection.schemaObj[model[0]].default.linkTo)._id,
                link,
                collection.schemaObj[model[0]].default.linkedFields)"
            :key="key"
            class="mx-3">
          <b-col
              v-if="field.metaType === 'Address'"
              align="left"
              class="mr-2"
              cols="*">
            <span
                v-if="displayAddress(field.data, addressDisplayTypeLookup(model[0], key))"
                class="dataViewer__label"
                v-text="`${model[1].label}:`"
            />
          </b-col>
          <b-col
              v-else
              align="left"
              class="mr-2"
              cols="*">
            <span
                class="dataViewer__label"
                v-text="`${field.label}:`"
            />
          </b-col>
          <b-col>
            <display-address
                v-if="field.metaType === 'Address'"
                :addressDisplayType="addressDisplayTypeLookup(model[0], key)"
                :model="field.data"
            />
            <display-email
                v-else-if="field.metaType === 'Email'"
                :model="field.data"
            />
            <display-inventory
                v-else-if="field.metaType === 'Inventory'"
                :model="field.data"
            />
            <display-select
                v-else-if="field.metaType === 'SelectOne' || field.metaType === 'MultiSelect'"
                :model="field.data"
            />
            <display-phone
                v-else-if="field.metaType === 'Phone'"
                :model="field.data"
            />
            <display-price
                v-else-if="field.metaType === 'Price'"
                :model="field.data"
            />
            <display-status
                v-else-if="field.metaType === 'Status'"
                :model="field.data"
            />
            <display-tags
                v-else-if="field.metaType === 'Tags'"
                :model="field.data"
            />
            <display-toggle
                v-else-if="field.metaType === 'Toggle'"
                :model="field.data"
                :fieldDefault="$store.getters.collection(collection.schemaObj[model[0]].default.linkTo).schemaObj[key].default"
            />
            <display-default
                v-else
                :model="field.data"
            />
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col
        v-else-if="model[1].metaType === 'MultiSelect'
        || model[1].metaType === 'SelectOne'"
        align="left"
        class="mx-2 mt-auto mb-0"
        cols="*">
      <display-select
          :model="model[1].data"
      />
    </b-col>
    <b-col
        v-else-if="model[1].metaType === 'Phone'"
        align="left"
        class="mx-2 mt-auto mb-0"
        cols="*">
        <display-phone
            :model="model[1].data"
        />
    </b-col>
    <b-col
        v-else-if="model[1].metaType === 'Price'"
        align="left"
        class="mx-2 mt-auto mb-0"
        cols="*">
      <display-price
          :model="model[1].data"
      />
    </b-col>
    <b-col
        v-else-if="model[1].metaType === 'Status'"
        align="left"
        class="mx-2 mt-auto mb-0"
        cols="*">
      <display-status
          :model="model[1].data"
      />
    </b-col>
    <b-col
        v-else-if="model[1].metaType === 'Tags'"
        align="left"
        class="mx-2 mt-auto mb-0"
        cols="*">
      <display-tags
          :model="model[1].data"
      />
    </b-col>
    <b-col
        v-else-if="model[1].metaType === 'Toggle'"
        align="left"
        class="mx-2 mt-auto mb-0"
        cols="*">
      <display-toggle
          :model="model[1].data"
          :fieldDefault="collection.schemaObj[model[0]].default"
      />
    </b-col>
    <b-col
        v-else
        align="left"
        class="mx-2 mt-auto mb-0"
        cols="*">
      <display-default
          :model="model[1].data"
      />
    </b-col>
  </b-row>
  <div
      v-else>
  </div>
</template>

<script>
// PURPOSE: Render Linked Record data, based on its metaType

import totalCalculators from '@/mixins/totalCalculators';
import displayDefault from '@/components/metaType/display/Default';
import displayPhone from '@/components/metaType/display/Phone';
import displayAddress from '@/components/metaType/display/Address';
import displayEmail from '@/components/metaType/display/Email';
import displayInventory from '@/components/metaType/display/Inventory';
import displaySelect from '@/components/metaType/display/Select';
import displayPrice from '@/components/metaType/display/Price';
import displayStatus from '@/components/metaType/display/Status';
import displayTags from '@/components/metaType/display/Tags';
import displayToggle from '@/components/metaType/display/Toggle';

export default {
  components: {
    displayDefault,
    displayPhone,
    displayAddress,
    displayEmail,
    displayInventory,
    displaySelect,
    displayPrice,
    displayStatus,
    displayTags,
    displayToggle,
  },
  mixins: [
    totalCalculators,
  ],
  props: [
    'collection',
    'addressDisplayType',
    'model',
  ],
  computed: {
    dataExists() {
      return this.model && typeof this.model[1].data !== 'undefined';
    },
    linkedFields() {
      const fields = [];
      this.collection.schemaObj[this.model[0]].displayLinkedIn.forEach((f) => {
        fields.push(f);
      });
      return fields;
    },
  },
  methods: {
    displayAddress(data, display) {
      switch (display) {
        default:
        case ('whole'): {
          let address = '';
          const ad = data.address;
          if (ad.street) {
            address = ad.street;
          }
          if (ad.streetSecond && ad.streetSecondShow) {
            address += `<br /><small>${ad.streetSecond}</small>`;
          }
          if (address && (ad.city || ad.state || ad.zip || ad.country || ad.postal)) {
            address += '<br />';
          }
          if (ad.city) {
            address += ad.city;
          }
          if (ad.city && (ad.state || ad.zip || ad.country || ad.postal)) {
            address += '<small>,</small> ';
          }
          if (data.type === 'usaFormat') {
            address += `${ad.state ? `<small>${ad.state}</small>` : ''} ${ad.zip ? `<small>${ad.zip}</small>` : ''}`;
          } else {
            address += `${ad.country ? `<small>${ad.country}</small>` : ''} ${ad.postal ? `<small>${ad.postal}</small>` : ''}`;
          }
          return address;
        }
        case ('first'): {
          if (!data.address.streetSecondShow || !data.address.streetSecond) {
            return data.address.street;
          }
          return `${data.address.street}<br /> ${data.address.streetSecond}`;
        }
        case ('cityState'): {
          const ad = data.address;
          if (ad.city && ad.state) {
            return `${ad.city}, ${ad.state}`;
          }
          if (ad.city && ad.country) {
            return `${ad.city}, ${ad.country}`;
          }
          if (ad.city) {
            return ad.city;
          }
          if (ad.state) {
            return ad.state;
          }
          return null;
        }
        case ('state'): {
          if (data.address.state) {
            return data.address.state;
          }
          return null;
        }
        case ('zip'): {
          if (data.address.zip) {
            return data.address.zip;
          }
          if (data.address.postal) {
            return data.address.postal;
          }
          return null;
        }
        case ('country'): {
          if (data.type === 'usaFormat') {
            return 'USA';
          }
          if (data.address.country) {
            return data.address.country;
          }
          return null;
        }
        case ('cityCountry'): {
          const ad = data.address;
          let address = '';
          if (data.type === 'usaFormat') {
            if (ad.city) {
              address += ad.city;
            }
            if (ad.state) {
              address += ', ';
              address += ad.state;
            }
            if (address) {
              address += ' ';
            }
            address += this.$t('countries.usa');
            return address;
          }
          if (data.address.country) {
            let cityCountry = '';
            if (data.address.city) {
              cityCountry = data.address.city;
            }
            if (data.address.country) {
              if (cityCountry) {
                cityCountry += ', ';
              }
              cityCountry += data.address.country;
            }
            return cityCountry;
          }
          return null;
        }
      }
    },
    addressDisplayTypeLookup(field, key) {
      // Looks up the address display type of a linked field
      if (this.collection.schemaObj[field]
        && this.collection.schemaObj[field].default.linkedFields
        && this.collection.schemaObj[field].default.linkedFields.find(i => i._id === key)
        && this.collection.schemaObj[field].default.linkedFields.find(i => i._id === key).addressDisplayType) {
        return this.collection.schemaObj[field].default.linkedFields.find(i => i._id === key).addressDisplayType;
      }
      return null;
    },
  },
};
</script>
