<template>
  <div>
    <span
        v-for="(option, index) in model"
        :key="index"
        v-html="index === model.length - 1 ? option : `${option}<br/>`"
        class="dataViewer__data mr-2"
    />
  </div>
</template>
<script>

export default {
  props: ['model'],
};
</script>
