<template>
  <div
      id="employeeTimecardOptions">
    Current Phone Number
    Current Address
    Prefer Notifications to
        Email/SMS
    Notify When
      Timecard
        Clocked In
        Clocked Out
        Clock In/Out Error
        Pay period is completed
      WorkDay
        Added
        Edited
        Removed
        WorkDay Reminder (when scheduled in advance)
    Change Password
  </div>
</template>

<script>
import totalCalculators from '@/mixins/totalCalculators';

export default {
  title() {
    return `${this.$t('workDay.titles.timeCardOptions')} | ${this.$t('project.brand')}`;
  },
  mixins: [
    totalCalculators,
  ],
  computed: {},
  data() {
    return {
      model: {},
    };
  },
  methods: {},
  watch: {},
};
</script>
