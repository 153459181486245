<template>
  <span
      v-if="model && displayAddress(model, addressDisplayType)"
      class="dataViewer__data"
      v-html="displayAddress(model, addressDisplayType)"
  />
</template>
<script>
import totalCalculators from '@/mixins/totalCalculators';

export default {
  mixins: [
    totalCalculators,
  ],
  props: ['model', 'addressDisplayType'],
};
</script>
