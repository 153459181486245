<template>
  <b-container
      fluid>
    <b-navbar
        toggleable="md"
        class="nav__container">
      <!--Version of nav links that is Left aligned -->
      <main-menu
          v-if="$_app.switch.nav.mainMenu && !$_app.switch.nav.linksRight"
      />
      <b-collapse
          is-nav
          id="nav_collapse"
          v-if="NavButtons.length > 0 && !$_app.switch.nav.linksRight">
        <b-navbar-nav
            class="nav__links-container-leftAligned">
          <!--Home icon -->
          <b-navbar-brand
              router-link
              to="/"
              class="nav__homeIcon"
              v-if="$_app.switch.nav.homeIcon">
            <icon
                icon="home"
            />
          </b-navbar-brand>
          <b-nav-item
              router-link
              @click="ifComingSoon(button.label)"
              v-for="(button, index) in NavButtons"
              :key="index"
              :to="button.to"
              class="nav_link nav__link">
            {{button.label}}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <!--  Three navbar brands, 1) router-link, 2) href link, 3)@click Action 4) not a link
            controlled by AppData. Href link is configured in translations.project. -->
      <b-navbar-brand
          v-if="$_app.switch.nav.brand
                && $_app.switch.nav.routerLink
                && !$_app.switch.nav.hrefLink
                && !$_app.switch.nav.restartAppLink"
          router-link
          :to="$t('project.brandNavbar.routerLink')"
          v-text="$t('project.brandNavbar.text')"
          class="nav__brand"
      />
      <a
          v-if="$_app.switch.nav.brand
                && !$_app.switch.nav.routerLink
                && $_app.switch.nav.hrefLink
                && !$_app.switch.nav.restartAppLink"
          :href="$t('project.brandNavbar.hrefLink')">
        <b-navbar-brand
          v-text="$t('project.brandNavbar.text')"
          class="nav__brand"
        />
      </a>
      <b-navbar-brand
          v-if="$_app.switch.nav.brand
          && !$_app.switch.nav.routerLink
          && !$_app.switch.nav.hrefLink
          && !$_app.switch.nav.restartAppLink"
          v-text="$t('project.brandNavbar.text')"
          class="nav__brand"
      />
      <b-navbar-brand
          v-if="$_app.switch.nav.brand
          && !$_app.switch.nav.routerLink
          && !$_app.switch.nav.hrefLink
          && $_app.switch.nav.restartAppLink"
          @click="restartApp()"
          @mouseover="brandHoverMessage = true"
          @mouseleave="brandHoverMessage = false"
          v-text="brandHoverMessage ? $t('sys.restartApp') : $t('project.brandNavbar.text')"
          :class="brandHoverMessage ? 'nav__brand__restartMessage nav__brand' : 'nav__brand'"
      />
      <b-navbar-brand
          v-if="$_app.switch.nav.brand
          && $_app.switch.nav.brandBottom"
          v-text="$t('project.brandNavbar.textBottom')"
          class="nav__brand__bottomText"
      />
      <b-navbar-toggle
          target="nav_collapse"
          v-if="NavButtons.length > 0"
      />
      <!-- Version of nav links that is right-aligned -->
      <main-menu
          v-if="$_app.switch.nav.mainMenu && $_app.switch.nav.linksRight"
      />
      <b-collapse
          is-nav
          id="nav_collapse"
          v-if="NavButtons.length > 0 && $_app.switch.nav.linksRight">
        <b-navbar-nav
            class="nav__links-container">
          <b-nav-item
              router-link
              v-for="(button, index) in NavButtons"
              :key="index"
              :to="button.to"
              class="nav_link">
            {{button.label}}
          </b-nav-item>
            <!--Home icon -->
          <b-navbar-brand
              router-link
              to="/"
              class="nav__homeIcon"
              v-if="$_app.switch.nav.homeIcon">
            &nbsp; <icon icon="home" /> &nbsp;
          </b-navbar-brand>
        </b-navbar-nav>
      </b-collapse>
      <transition
          name="fade">
        <b-navbar-brand
            id="settingsButton"
            @click="showSettings()"
            @mouseover="showSettingsLabel = true"
            @mouseleave="showSettingsLabel = false"
            class="nav__settingsIcon__container"
            v-show="$_app.switch.nav.settingsButton && initialLoad">
          <transition
              name="fadeQuick"
              mode="out-in">
            <span
                key="settingsLabel"
                v-if="showSettingsLabel"
                class="nav__settingsIcon__text"
                v-text="$t('settingsShow')"
            />
          </transition>
          <icon
              icon="cog"
              class="nav__settingsIcon"
              :aria-label="$t('aria.showSettings')"
              color="lightgrey"
              size="lg"
          />
        </b-navbar-brand>
      </transition>
    </b-navbar>
    <b-modal
        id="settingsModal"
        centered
        size="xl"
        ok-only
        :ok-title="$t('close')"
        :title="$t('prefs.tab.header')"
        header-class="preferences__header"
        body-class="preferences"
        footer-class="preferences__footer"
        @ok="closeSettings()">
      <preference-pane
          :preferences="preferences"
          :enable="prefsToEnable"
          @save-now="updatePreferences"
      />
    </b-modal>
  </b-container>
</template>

<script>
import moment from 'moment';
import mainMenu from './MainMenu';
import totalCalculators from '@/mixins/totalCalculators';
import preferencePane from '@/components/util/Preferences';

export default {
  name: 'NavBar',
  mixins: [totalCalculators],
  components: {
    mainMenu,
    preferencePane,
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'settingsModal') {
        this.$store.commit('CLOSE_PREFERENCES');
      }
    });
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'settingsModal') {
        this.$store.commit('CLOSE_PREFERENCES_CLEAR');
      }
    });
  },
  computed: {
    initialLoad() {
      return this.$store.state.initialLoad;
    },
    preferences: {
      get() {
        return this.$store.state.preferences;
      },
      set(newVal) {
        console.log('updating global prefs');
        this.$store.state.preferences = newVal;
      },
    },
  },
  data() {
    return {
      addingLogo: false,
      brandHoverMessage: false,
      customColors: false,
      deleteInProgress: false,
      lastChanged: moment(),
      lastChangedBefore: moment(),
      lastSaved: moment(),
      NavButtons: this.$_app.switch.nav.enableButtons ? this.$t('project.navButtons') : {},
      showColors: false,
      showErrorMsg: null,
      showSettingsLabel: false,
      uploadInProgress: false,
      prefsToEnable: {
        customer: false,
        default: true,
        document: false,
        user: true,
        system: true,
        account: true,
        help: true,
        workDay: this.$_app.switch.workDay,
      },
    };
  },
  methods: {
    changeColor(color) {
      this.preferences.color = {
        ...color,
      };
      this.showColors = false;
      this.saveSettings();
    },
    closeSettings() {
      this.$bvModal.hide('settingsModal');
    },
    customizeColors() {
      this.customColors = !this.customColors;
      if (this.customColors && this.showColors) {
        this.showColors = false;
      }
    },
    ifComingSoon(goingTo) {
      if (goingTo === 'Items') {
        this.$store.dispatch('comingSoon');
      }
    },
    restartApp() {
      this.$store.dispatch('restartApp');
      this.$router.push('/');
      this.$router.go();
    },
    saveNow() {
      this.$store.commit('AUTOSAVE_COMPLETE');
      this.$store.dispatch('preferenceSet', this.preferences);
    },
    updatePreferences(updatedPref) {
      this.$store.commit('AUTOSAVE_REQUEST');
      this.preferences = {
        ...this.preferences,
        ...updatedPref,
      };
      this.lastChangedBefore = this.lastChanged;
      this.lastChanged = moment();
    },
    showSettings() {
      this.$store.commit('CLOSE_PREFERENCES');
      this.$bvModal.show('settingsModal');
    },
  },
  watch: {
    lastChanged() {
      const autosaveWatcher = setInterval(() => {
        if ((this.lastSaved < this.lastChanged)
          && ((this.lastChangedBefore < moment(this.lastChanged).subtract(2.15, 'seconds'))
            || (this.lastChanged < moment().subtract(2.15, 'seconds'))
          )) {
          this.lastSaved = moment();
          this.saveNow();
        }
      }, 1250);
      // eslint-disable-next-line no-unused-expressions
      autosaveWatcher;
      setTimeout(() => {
        clearInterval(autosaveWatcher);
      }, 3000);
    },
  },
};
</script>
