import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
// import SecureLS from 'secure-ls';

import { projectUrl } from '../translations';
import {
  dataGetters,
  utilityGetters,
  workDayGetters,
} from './getters';
import {
  dataMutations,
  utilityMutations,
  workdayMutations,
} from './mutations';
import {
  dataActions,
  utilityActions,
  workDayActions,
} from './actions';

// const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  strict: false,
  plugins: [
    createPersistedState({
      // storage: {
      //   getItem: key => ls.get(key),
      //   setItem: (key, value) => ls.set(key, value),
      //   removeItem: key => ls.remove(key),
      // },
      paths: [
        'logo', // Prevents the need to constantly re-process image data.
        // 'data.customers',
        // 'data.invoices',
        // 'dbversion',
        // 'preferences',
      ],
    }),
  ],
  state: {
    account: {
      services: null,
      people: null,
      employees: null,
      employeesFields: null,
      team: null,
    },
    activeItem: 0,
    additionalActions: [],
    addNew: false,
    adminLogEntry: {
      customer: projectUrl,
    },
    autosaveNow: false,
    autosaveTriggered: false,
    changesMade: false,
    collectionFilters: [],
    collectionFilterActive: null,
    collectionsChangedRestart: false,
    data: {
      collections: [],
      customers: [],
      customer: {},
      customerHasBeenAdded: false,
      invoices: [],
      invoice: {},
      printData: {},
      records: {},
      userId: null,
      workDays: [],
      workDayTimecards: [],
    },
    dbversion: 0,
    dbversionMismatch: false,
    deleteRequest: null,
    initialLoad: false,
    editItem: false,
    errors: [],
    errorsCount: 0,
    enterButton: false,
    escButton: false,
    fileAction: { // data posts here to trigger pdf creation
      _id: null,
      action: null,
      cmd: null,
      result: null,
      restartRequired: false,
    },
    fileUploadToDelete: null,
    filterAddressesBy: {
      address: true,
      type: null,
      label: null,
      cityState: [],
      cityCountry: [],
      state: [],
      country: [],
      postal: [],
    },
    filterStatusesBy: {
      hasStatus: false,
      hasUrgent: false,
      noStatus: false,
    },
    filterAddressesByDefault: {
      address: true,
      type: null,
      label: null,
      cityState: [],
      cityCountry: [],
      state: [],
      country: [],
      postal: [],
    },
    itemActionButtonPress: false,
    itemActionCancelPress: false,
    justViewed: {
      collection: null,
      record: null,
    },
    launchPreview: null,
    linkedRecordSaved: {
      recordId: null,
      requestId: null,
    },
    logo: {
      location: null,
      data: null,
    },
    navText: null,
    needToClosePreferences: false,
    offline: false, // offline development mode. Set by AuthenticationPortal
    preferences: {},
    refreshNeeded: false,
    restartNeeded: false,
    retry: {
      cmd: null,
      data: null,
    },
    retrySuccess: false,
    searchTermPartial: null,
    searchTerms: null,
    sendFileSuccess: null,
    showItem: null,
    showLoader: false,
    showSpinner: false,
    showToast: {},
    signedIn: false,
    signOut: false,
    spinnerById: null,
    user: null,
    viewItem: {
      record: null,
      collection: null,
    },
    zipLookup: {
      id: null,
      city: null,
      state: null,
      zip: null,
    },
    zipRequest: null,
  },
  getters: Object.assign(
    {},
    dataGetters,
    utilityGetters,
    workDayGetters,
  ),
  mutations: Object.assign(
    {},
    dataMutations,
    utilityMutations,
    workdayMutations,
  ),
  actions: Object.assign(
    {},
    dataActions,
    utilityActions,
    workDayActions,
  ),
});
