<template>
  <div
      v-if="model"
      :style="`background-color:${model[0]}; color: ${model[1]}`">
    <b-icon-star-fill />
  </div>
</template>
<script>

export default {
  props: ['model'],
};
</script>
