<template>
  <span
      class="dataViewer__data"
      v-text="displayToggle(model, fieldDefault)"
  />
</template>
<script>
export default {
  props: ['model', 'fieldDefault'],
  methods: {
    displayToggle(data, { labelOn, labelOff }) {
      if (data.value || data === true) {
        if (labelOn) {
          return labelOn;
        }
        return this.$t('collections.toggleOn');
      }
      if (labelOff) {
        return labelOff;
      }
      return null;
    },
  },
};
</script>
