<template>
  <b-pagination
      align="center"
      v-model="page"
      :hide-goto-end-buttons="(rows / perPage) < 5 ? true : false"
      :total-rows="rows"
      :per-page="perPage"
      class="m-0"
  />
</template>

<script>

export default {
  props: [
    'rows',
    'perPage',
    'currentPage',
  ],
  computed: {
    page: {
      get() {
        return this.currentPage;
      },
      set(page) {
        this.$emit('changePage', page);
      },
    },
  },
  data() {
    return {
    };
  },
};

</script>
