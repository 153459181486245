<template>
  <div
      v-if="date"
      :id="`availabilityContainer-${date.getTime()}`"
      class="scheduler__availability__container">
    <h4
        v-if="showDate"
        class="form__header__small">
      {{dateString(date, true)}}
    </h4>
    <h4
        v-if="dayOfWeek || dayOfWeek === 0"
        class="form__header__small">
      {{$t(`scheduler.dayOfWeek[${dayOfWeek}]`)}}
    </h4>
    <b-btn-group
        id="allDaySettings">
      <b-btn
          :id="`availableAllDay-${date.getTime()}`"
          @click="allDayChange(true);"
          :variant="model.allDay ? 'info' : 'info-outline'"
          class="px-4 mr-2 my-2">
        <b-icon-calendar
        />
        <span
            v-text="$t('scheduler.availableAllDay')"
        />
      </b-btn>
      <b-btn
          :id="`timedAvailablility-${date.getTime()}`"
          :variant="!model.allDay ? 'info' : 'info-outline'"
          @click="allDayChange(false)"
          class="px-4 my-2">
        <b-icon-clock
        />
        <span
            v-text="$t('scheduler.availableBasedOnTime')"
        />
      </b-btn>
    </b-btn-group>
    <transition
        mode="out-in"
        name="slide-fade">
      <div
          v-if="model.allDay"
          key="allDay">
        <available-or-not-buttons
            :id="date.getTime()"
            :available="model.available"
            @buttonClicked="availabilityClick"
        />
      </div>
      <div
          v-else
          key="segmentedAvailability">
        <transition-group
            name="fade"
            mode="out-in">
          <div
              v-for="segment in segments"
              :key="segment._id">
            <transition
                name="slide-fade"
                mode="out-in">
            <!-- Adding/Editing Segment -->
              <div
                  v-if="editSegment && editSegment === segment._id"
                  key="editing"
                  class="scheduler__availability__segment__container"
                  :class="!segment.available ? 'table-warning' : 'table-primary'">
                <b-row>
                  <b-col
                      cols="*"
                      align="center"
                      class="scheduler__availability__input__container mr-2">
                    <label
                        :for="`startTime-${editSegment}`"
                        v-text="$t('scheduler.availabilityStart')"
                        class="scheduler__availability__input__label"
                    />
                    <b-form-timepicker
                        :id="`startTime-${editSegment}`"
                        :placeholder="$t('scheduler._timePlaceholder')"
                        v-model="activeSegment.start"
                        size="lg"
                        :locale="$t('project.timeLocale')"
                        class="scheduler__availability__input"
                    />
                  </b-col>
                  <b-col
                      cols="*"
                      align="center"
                      class="scheduler__availability__input__container ml-2">
                    <label
                        :for="`endTime-${editSegment}`"
                        v-text="$t('scheduler.availabilityEnd')"
                        class="scheduler__availability__input__label"
                    />
                    <b-form-timepicker
                        :id="`endTime-${editSegment}`"
                        :placeholder="$t('scheduler._timePlaceholder')"
                        v-model="activeSegment.end"
                        size="lg"
                        :locale="$t('project.timeLocale')"
                        class="scheduler__availability__input"
                    />
                  </b-col>
                </b-row>
                <b-row
                    align-h="center">
                  <b-col
                      cols="*"
                      align="center">
                    <available-or-not-buttons
                        :id="editSegment"
                        :available="activeSegment.available"
                        @buttonClicked="availabilityClick"
                    />
                  </b-col>
                </b-row>
                <b-btn
                    :id="`confirmSegmentChange-${date.getTime()}`"
                    @click="confirmSegment()"
                    variant="primary"
                    class="my-3">
                  {{$t('scheduler.segment.confirm')}} <b-icon-check />
                </b-btn>
              </div>
              <!-- Segment List -->
              <b-row
                  class="scheduler__availability__segment__table"
                  key="listView"
                  v-else
                  :class="!segment.available ? 'table-warning' : 'table-primary'">
                <b-col
                    cols="auto"
                    class="ml-0 mr-auto my-auto"
                    align="left">
                  <h5
                      class="my-auto">
                    {{displayTime(date, segment.start)}}
                    <span
                        v-if="segment.start
                        && segment.end">
                      &nbsp; - &nbsp;
                    </span>
                    {{displayTime(date, segment.end)}}
                  </h5>
                </b-col>
                <b-col
                    cols="*"
                    class="mx-3 my-auto">
                  <b-icon
                      :icon="segment.available ? 'check' : 'exclamation-triangle'"
                  />
                  <span
                      v-text="segment.available ? $t('scheduler.available') : $t('scheduler.notAvailable')"
                  />
                </b-col>
                <b-col
                    cols="*"
                    align="right"
                    class="ml-auto mr-0 my-auto">
                  <b-btn
                      :id="`editSegmentButton-${segment._id}`"
                      :aria-label="$t('aria.editSegment')"
                      variant="info"
                      size="sm"
                      @click="editSegment = segment._id">
                    <b-icon-pencil />
                  </b-btn>
                  <b-btn
                      :id="`removeSegmentButton-${segment._id}`"
                      :aria-label="$t('aria.deleteSegment')"
                      variant="danger-outline"
                      size="sm"
                      @click="removeSegment(segment._id)">
                    <b-icon-trash
                        :scale="1"
                    />
                  </b-btn>
                </b-col>
              </b-row>
            </transition>
        </div>
        </transition-group>
        <transition
            mode="out-in"
            name="fadeQuick">
          <b-btn
              :id="`addSegmentButton-${date.getTime()}`"
              v-if="!editSegment"
              @click="addSegment()"
              variant="primary"
              class="my-3">
            {{$t('scheduler.segment.add')}} <b-icon-plus />
          </b-btn>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import totalCalculators from '@/mixins/totalCalculators';
import availableOrNotButtons from '@/components/singlePurpose/AvailableOrNotButtons';

export default {
  // title() {
  //   return ` | ${this.$t('project.brand')}`;
  // },
  mixins: [
    totalCalculators,
  ],
  props: [
    'dateObj',
    'dayOfWeek',
    'showDate',
  ],
  components: {
    availableOrNotButtons,
  },
  computed: {
    activeSegment: {
      get() {
        if (this.editSegment) {
          return this.model.segments.find(i => i._id === this.editSegment);
        }
        return {};
      },
      set(newVal) {
        const index = this.model.segments.findIndex(i => i._id === this.editSegment);
        this.model.segments.splice(index, 1, newVal);
      },
    },
    date() {
      if (this.dateObj) {
        return this.dateObj;
      }
      return new Date();
    },
    segments: {
      get() {
        return this.model.segments;
      },
    },
  },
  data() {
    return {
      editSegment: false,
      model: {
        allDay: true,
        allSame: true,
        available: true,
        segments: [],
      },
    };
  },
  methods: {
    addSegment() {
      const newId = this.$guid();
      this.model.segments.push({
        _id: newId,
        start: null,
        end: null,
        available: true,
      });
      this.editSegment = newId;
    },
    allDayChange(allDay) {
      if (!allDay) {
        this.model.allDay = false;
        this.addSegment();
      } else {
        this.model.segments = [];
        this.model.allDay = true;
      }
    },
    availabilityClick(choice) {
      if (this.model.allDay) {
        this.model.available = choice;
      } else {
        const index = this.model.segments.findIndex(i => i._id === this.editSegment);
        this.model.segments[index].available = choice;
      }
    },
    confirmSegment() {
      const segment = this.model.segments.find(i => i._id === this.editSegment);
      if (!segment || (!segment.start && !segment.end)) {
        this.removeSegment(this.editSegment);
      }
      this.editSegment = null;
    },
    removeSegment(id) {
      const index = this.model.segments.findIndex(i => i._id === id);
      this.model.segments.splice(index, 1);
    },
  },
  watch: {},
};
</script>
