<template>
  <span
      v-text="displayCurrency(model.price, model.type)"
      class="dataViewer__data"
  />
</template>
<script>
import totalCalculators from '@/mixins/totalCalculators';

export default {
  props: ['model'],
  mixins: [totalCalculators],
};
</script>
