<template>
  <div>
    <span
        v-text="model.number"
        class="dataViewer__data"
    />
    <br />
    <a
        :href="model.format === 'usaFormat' ? `tel:011${model.number}` : `tel:${model.number}`"
        style="text-decoration: none"
        class="ml-3">
      <span
          class="form__textButton2">
        <b-icon
            stack
            scale="1.4"
            icon="phone"
        />
        <small
            v-text="$t('call')"
        />
      </span>
    </a>
  </div>
</template>
<script>
import totalCalculators from '@/mixins/totalCalculators';

export default {
  props: ['model'],
  mixins: [totalCalculators],
};
</script>
