<template>
  <div
      v-if="customer"
      :id="`cusotmerDetails-${customer._id}`">
    <b-row
        align-h="center"
        align-v="center">
      <b-col
          align="center"
          v-if="customer"
          sm="6"
          md="auto">
        <h5
            :class="hovered ? 'invoice__customer__name__hovered' : 'invoice__customer__name'"
            v-text="customerIdentifier(customer)"
        />
      </b-col>
      <b-col
          align="center"
          v-if="customer.lastActive"
          cols="*">
        <span
            class="invoice__date">
          <b
              v-text="$t('invoice.lastActive')"
          />
          <br />
          {{dateDisplayWithTime(customer.lastActive)}}
        </span>
        <div
            v-if="customer.status">
          {{customer.status}}
        </div>
      </b-col>
      <b-col
          id="chargesTotal"
          align="center"
          cols="*"
          class="my-auto">
        <span
            v-text="screenTotal"
            class="invoice__customer__accountDetails"
        />
        <br />
        <span
            v-text="screenPayments"
            class="invoice__customer__accountDetails"
        />
        <hr  class="my-0"/>
          <span
            v-text="screenDue"
            class="invoice__customer__accountDetails__due"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Display customer details

import totalCalculators from '@/mixins/totalCalculators';

export default {
  props: [
    'customer',
    'hovered',
  ],
  mixins: [
    totalCalculators,
  ],
  computed: {
    items() {
      if (this.customer && this.customer._id) {
        return this.$store.getters.invoicesByCustomer(this.customer._id);
      }
      return [];
    },
    screenDue() {
      return `${this.$t('invoice.outstanding')}: ${this.multiDocDue(this.items, 'display')}`;
    },
    screenTotal() {
      return `${this.$t('invoice.viewTotal')}: ${this.multiDocTotal(this.items, 'display')}`;
    },
    screenPayments() {
      const payments = [];
      this.items
        .filter(item => item.payments.length)
        .forEach(item => item.payments
          .forEach(payment => payments.push(payment)));
      return `${this.$t('invoice.viewPayments')}: ${this.displayTotalPayments(payments)}`;
    },
  },
  data() {
    return {
      model: {},
    };
  },
  methods: {},
  watch: {},
};
</script>
