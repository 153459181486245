<template>
  <div
      class="invoice__container">
    <div
        id="topOfPage"
    />
    <transition
        name="fadeQuick"
        mode="out-in">
      <b-row
          key="defaultHeader"
          class="invoice__container__header"
          align-v="center"
          v-if="readyToRun">
        <b-col
            sm="6"
            md="4">
          <transition-group
              name="fadeQuick">
            <b-btn
                id="addNewInvoiceButton"
                key="default"
                class="invoice__buttons__addInvoice"
                @click="addInvoice()"
                :variant="(!addingInvoice || customerHasBeenSelected) ? 'secondary' : 'primary'">
              <icon
                  icon="plus"
                  v-if="!addingInvoice"
                  class="admin__buttonContent"
              />
              <span
                  v-if="!addingInvoice"
                  v-text="$t('invoice.new')"
              />
              <span
                  v-else
                  v-text="$t('invoice.newInProgress')"
              />
            </b-btn>
            <b-btn
                id="cancelNewInvoiceTop"
                key="cancel"
                @click="addInvoiceCancel()"
                v-if="addingInvoice && !filterActive.customer"
                size="sm"
                variant="warning"
                class="invoice__buttons__addInvoice__cancel ml-3">
              <icon
                  icon="times-circle"
                  class="invoice__buttons__addInvoice__cancel__icon"
              />
              <span
                  v-text="$t('cancel')"
                  class="invoice__buttons__addInvoice__cancel__text"
              />
            </b-btn>
          </transition-group>
        </b-col>
        <b-col
            id="customerFilterLabel"
            sm="6"
            md="4"
            v-if="!addingInvoice">
          <b-row
              id="customerSelectButtonRow"
              align-v="end">
            <b-col
                align="center">
              <customer-dropdown
                  id="customerSearchAndFilter"
                  @new-customer="addCustomer()"
                  @clear-filter="customerDropdownClear"
                  @filter-customer="customerDropdownAction"
                  :customers="customers"
                  :filterActive="filterActive"
                  :customerFilterLabel="customerFilterLabel"
              />
              <b-btn
                  id="clearCustomerFilter"
                  v-if="filterActive.customer"
                  size="sm"
                  :aria-label="$t('aria.clearCustomerFilter')"
                  @click="customerDropdownClear()"
                  class="invoice__table__customerDropdown__closeButton"
                  variant="warning-outline">
                <b-icon-x-circle-fill
                />
                {{$t('clear')}}
              </b-btn>
            </b-col>
          </b-row>
        </b-col>
        <transition
            name="fadeQuick"
            mode="out-in">
          <b-col
              id="sortAndDateOptions"
              key="defaultView"
              v-if="!addingInvoice"
              sm="6"
              md="4">
            <b-row
                id="dateOptions">
              <b-col
                  align="left"
                  cols="*">
                <span
                    v-text="$t('invoice.filter.daysFilterLabel')"
                    class="invoice__table__dateFilter__label"
                  />
              </b-col>
              <b-col
                  cols="*">
                <b-dropdown
                    id="filterPastDays"
                    :text="buttonLabelFilterPastDays"
                    class="invoice__table__dateFilter__mainButton"
                    variant="info">
                  <b-dropdown-item-button
                      id="allTime"
                      @click="setNumberOfDaysInView(-1)"
                      :variant="numberOfDaysInView === -1 && !customDateRange ? 'primary' : 'secondary'">
                      {{$t('invoice.filter.allTime')}}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button
                      id="pastDay"
                      @click="setNumberOfDaysInView(1)"
                      :variant="numberOfDaysInView === 1 ? 'primary' : 'secondary'">
                      {{$t('invoice.filter.day')}}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button
                      id="pastWeek"
                      @click="setNumberOfDaysInView(7)"
                      :variant="numberOfDaysInView === 7 ? 'primary' : 'secondary'">
                      {{$t('invoice.filter.day7')}}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button
                      id="past30Days"
                      @click="setNumberOfDaysInView(30)"
                      :variant="numberOfDaysInView === 30 ? 'primary' : 'secondary'">
                      {{$t('invoice.filter.day30')}}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button
                      id="pastQuarter"
                      @click="setNumberOfDaysInView(90)"
                      :variant="numberOfDaysInView === 90 ? 'primary' : 'secondary'">
                      {{$t('invoice.filter.dayQuarter')}}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button
                      id="customDateRangeOption"
                      @click="customDateFilterSet"
                      :variant="customDateRange ? 'primary' : 'secondary'">
                      {{$t('invoice.filter.dayCustom')}}
                  </b-dropdown-item-button>
                </b-dropdown>
                <transition
                    name="fadeQuick"
                    mode="out-in">
                  <div
                      id="customDateRangeContainer"
                      key="customDateRange"
                      v-if="customDateRange">
                    <b-form-datepicker
                        id="dateFilterStartInput"
                        v-model="customDateFilterStart"
                        :placeholder="$t('dateBeginning')"
                        :dark="true"
                        @input="itemRefresh()"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        locale="en"
                    />
                    <b-form-datepicker
                        id="dateFilterEndInput"
                        v-model="customDateFilterEnd"
                        :placeholder="$t('dateEnding')"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        :dark="true"
                        @input="itemRefresh()"
                        locale="en"
                    />
                  </div>
                </transition>
              </b-col>
            </b-row>
            <b-row
                id="sortOptions">
              <b-col
                  cols="*">
                <span
                    v-text="$t('invoice.sort.by')"
                    class="invoice__table__sortDropdown__label"
                />
              </b-col>
              <b-col
                  cols="*">
                <b-btn-group
                    id="sortOptions">
                  <b-dropdown
                      id="tableSorts"
                      ref="dropdown"
                      :text="sortBy"
                      variant="info"
                      class="invoice__table__sortDropdown">
                    <b-dropdown-item-button
                        id="sortNum"
                        @click.stop="newSort('Document Number')">
                      {{$t('invoice.sort.number')}}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                        id="sortCustomerName"
                        @click.stop="newSort('Customer Name')">
                      {{$t('invoice.sort.customerName')}}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                        id="sortTotal"
                        @click.stop="newSort('Total Charges')">
                      {{$t('invoice.sort.total')}}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                        id="sortDue"
                        @click.stop="newSort('Balance Due')">
                      {{$t('invoice.sort.due')}}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                        id="sortCreatedDate"
                        @click.stop="newSort('Date')">
                      {{$t('invoice.sort.createdDate')}}
                    </b-dropdown-item-button>
                  </b-dropdown>
                  <b-btn
                      id="sortDirection"
                      :aria-label="$t('aria.swapSort')"
                      @click.stop="sortDirectionChange()"
                      class="invoice__table__sortReverseButton"
                      :variant="sortDirection ? 'info' : 'inactive'">
                    <icon
                        icon="arrow-alt-circle-down"
                        size="lg"
                    />
                  </b-btn>
                  <b-btn
                      id="sortDirection"
                      :aria-label="$t('aria.swapSort')"
                      @click.stop="sortDirectionChange()"
                      class="invoice__table__sortReverseButton"
                      :variant="sortDirection ? 'inactive' : 'info'">
                    <icon
                        icon="arrow-alt-circle-up"
                        size="lg"
                    />
                  </b-btn>
                </b-btn-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col
              key="confirmationButtons"
              align="right"
              v-else>
            <b-btn-group>
              <transition-group
                  name="fadeQuick">
                <b-btn
                    id="cancelNewInvoice"
                    key="cancel"
                    @click="addInvoiceCancel()"
                    variant="warning"
                    v-if="filterActive.customer"
                    class="invoice__buttons__addInvoice__cancel">
                  <icon
                      icon="times-circle"
                      class="invoice__buttons__addInvoice__cancel__icon"
                  />
                  <span
                      v-text="$t('cancel')"
                      class="invoice__buttons__addInvoice__cancel__text"
                  />
                </b-btn>
                <b-btn
                    id="saveNewInvoice"
                    key="save"
                    class="invoice__buttons__addInvoice__confirm"
                    v-if="customerHasBeenSelected"
                    @click="addInvoiceConfirm()"
                    variant="primary">
                  <icon
                      icon="save"
                      class="admin__buttonContent"
                  />
                  <span
                      v-text="saveButtonText"
                  />
                </b-btn>
              </transition-group>
            </b-btn-group>
          </b-col>
        </transition>
      </b-row>
    </transition>
    <transition
        name="fadeQuick"
        mode="out-in">
      <div
          v-if="addingInvoice && !filterActive.customer">
        <div
            class="text-center my-4">
          <span
              id="customerSelectHeader"
              v-text="$t('invoice.customerSelect')"
              class="instructions"
          />
        </div>
        <b-row
            align-h="center">
          <b-col
              cols="*"
              class="invoice__customer__search__container mx-2 my-2">
            <b-form-input
                id="customerSearchTerm"
                :aria-label="$t('aria.customerSearch')"
                :placeholder="$t('search')"
                v-model="customerSearchTerm"
                class="invoice__customer__search"
            />
          </b-col>
          <b-col
              cols="*"
              class="invoice__customer__search__container mx-2 my-2"
              v-if="customers.length > customersPerPage">
            <table-pagination
                id="customerPagination"
                key="customerPagination"
                @changePage="changePageCustomer"
                :currentPage="customersPage"
                :perPage="customersPerPage"
                :rows="customers.length"
            />
          </b-col>
        </b-row>
        <transition-group
            mode="out-in"
            name="fadeQuick">
          <b-row
              align-h="center"
              class="mx-1"
              :class="hoverCustomer === customer._id ? 'invoice__customer__container__hovered' : 'invoice__customer__container'"
              v-for="customer in customersPaginated"
              @click="selectCustomer(customer._id)"
              @mouseover="hoverCustomer = customer._id"
              @mouseleave="hoverCustomer = null"
              :key="customer._id">
            <b-col
                :id="`customer-${customer._id}`"
                align="center"
                cols="*">
              <customer-account-details
                  :customer="customer"
                  :hovered="hoverCustomer === customer._id"
              />
            </b-col>
          </b-row>
        </transition-group>
        <b-row
            align-h="center"
            class="mx-1 mt-2"
            :class="hoverCustomer === 'new' ? 'invoice__customer__container__hovered' : 'invoice__customer__container'">
          <b-col
              id="addCustomerContainer"
              align="center"
              cols="*">
            <b-btn
                id="addCustomerViaNewInvoice"
                @mouseover="hoverCustomer = 'new'"
                @mouseleave="hoverCustomer = null"
                v-text="$t('adminCustomerAdd')"
                @click="addCustomer"
            />
          </b-col>
        </b-row>
      </div>
    </transition>
    <transition
        mode="out-in"
        name="slide-fade">
      <div
          key="accountDetails"
          id="customerAccountDetails"
          v-if="filterActive.customer"
          class="invoice__customer__container mx-1 pb-3">
        <customer-account-details
            :customer="customerDetails"
        />
      </div>
    </transition>
    <transition
        name="fade"
        mode="out-in">
      <b-row
          id="customerOptionButtons"
          v-if="filterActive.customer"
          class="invoice__customer__container mx-1">
        <b-col
            align="center">
          <transition
              name="fadeQuick"
              mode="out-in">
            <b-btn-group
                id="customerOptionsButtons"
                v-if="customerDeleteConfirm !== customerDetails._id"
                key="defaultView">
              <b-btn
                  id="showCustomerContactDetailsButton"
                  class="invoice__customer__menuOptionsButton"
                  :variant="customerDetailsActive === 1 ? 'primary' : 'info'"
                  @click="customerDetailsActive !== 1 ? customerDetailsActive = 1 : customerDetailsActive = -1"
                  v-text="$t('adminCustomerShowContact')"
              />
              <b-btn
                  id="showCustomerRecurringItemsButton"
                  class="invoice__customer__menuOptionsButton"
                  :variant="customerDetailsActive === 2 ? 'primary' : 'info'"
                  @click="customerDetailsActive !== 2 ? customerDetailsActive = 2 : customerDetailsActive = -1"
                  v-text="$t('adminCustomerShowRecurring')"
              />
              <b-btn
                  id="showCustomerOptions"
                  class="invoice__customer__menuOptionsButton"
                  :variant="customerDetailsActive === 3 ? 'primary' : 'info'"
                  @click="customerDetailsActive !== 3 ? customerDetailsActive = 3 : customerDetailsActive = -1"
                  v-text="$t('adminCustomerShowOptions')"
              />
              <b-btn
                  id="showCustomerNotes"
                  class="invoice__customer__menuOptionsButton"
                  :variant="customerDetailsActive === 4 ? 'primary' : 'info'"
                  @click="customerDetailsActive !== 4 ? customerDetailsActive = 4 : customerDetailsActive = -1"
                  v-text="$t('adminCustomerShowNotes')"
              />
              <b-btn
                  :id="`delete-${customerDetails._id}`"
                  @click="deleteCustomerReq(customerDetails._id)"
                  variant="danger"
                  class="invoice__customer__deleteButton">
                <icon
                    icon="trash-alt"
                />
              </b-btn>
            </b-btn-group>
            <div
                v-else
                class="form__container>">
              <span
                  class="form__header">
                {{$t('invoice.deleteConfirm')}}
              </span>
              <span>
                {{$t('invoice.deleteCustomerWarning')}}
              </span>
              <br />
              <b-button-group
                  key="deleteCustomerConfirm"
                  class="py-5">
                <b-btn
                    @click="deleteCustomerConfirm(customerDetails._id)"
                    variant="warning"
                    class="p-1 px-4 mx-2"
                    v-text="$t('deleteCustomer')"
                />
                <b-btn
                    @click="deleteCustomerCancel()"
                    variant="secondary"
                    class="p-2 px-3 mx-1"
                    v-text="$t('cancel')"
                />
              </b-button-group>
            </div>
          </transition>
        </b-col>
      </b-row>
    </transition>
    <transition
        name="fade"
        mode="out-in">
      <div
          id="customerContactDetails"
          key="customerDetails"
          v-if="customerDetailsActive === 1"
          class="invoice__customer__container mx-1 pb-3">
        <span
            class="form__header"
            v-text="$t('adminCustomerContactDetails')"
        />
        <div
            class="form__container">
          <b-row
              align-h="center">
            <b-col
                sm="6"
                md="auto">
              <label
                  for="nameInput"
                  v-text="$t('name')"
                  class="form__label"
              />
              <b-form-input
                  id="nameInput"
                  name="name"
                  type="text"
                  v-model="customerDetails.name"
                  @input="saveRequest()"
                  class="form__input large"
              />
            </b-col>
            <!-- <b-col
                sm="6"
                md="auto">
              <label
                  for="contactNameInput"
                  v-text="$t('adminCustomerBillingContact')"
                  class="form__label"
              />
              <b-form-input
                  id="contactNameInput"
                  name="nameOfContact"
                  type="text"
                  v-model="customerDetails.contact"
                  @input="saveRequest()"
                  class="form__input large"
              />
            </b-col> -->
          </b-row>
        </div>
        <div
            class="form__container">
          <b-row
              align-h="center">
            <b-col
                sm="6"
                md="auto">
              <label
                  for="phoneInput"
                  v-text="$t('phone')"
                  class="form__label"
              />
              <b-form-input
                  id="phoneInput"
                  name="phone"
                  type="text"
                  v-model="customerPhone"
                  @input="saveRequest()"
                  class="form__input medium"
              />
            </b-col>
            <!-- <b-col
                id="faxNumber"
                sm="6"
                md="auto">
              <label
                  for="faxInput"
                  v-text="$t('invoice.fax')"
                  class="form__label"
              />
              <b-form-input
                  id="faxInput"
                  name="fax"
                  type="text"
                  v-model="customerFax"
                  @input="saveRequest()"
                  class="form__input medium"
              />
            </b-col> -->
            <b-col
                sm="6"
                md="auto">
              <label
                  for="emailInput"
                  v-text="$t('email')"
                  class="form__label"
              />
              <b-form-input
                  id="emailInput"
                  name="email"
                  type="text"
                  v-model="customerDetails.email"
                  @input="saveRequest()"
                  class="form__input large"
              />
            </b-col>
          </b-row>
        </div>
        <b-row
            class="form__container"
            align-h="center">
          <b-col
              sm="6"
              md="auto">
            <transition
                mode="out-in"
                name="fadeQuick">
              <div
                  v-if="!addressEditPrimaryLabel"
                  key="standardView">
                <label
                    for="streetAddressInput"
                    v-text="preferences.addressLabel"
                    class="form__label"
                />
                <b-btn
                    variant="info-outline"
                    size="sm"
                    @click="addressEditPrimaryLabel = true; hoverIcon = null"
                    @mouseover="hoverIcon = 'editAddressLabel'"
                    @mouseleave="hoverIcon = null"
                    class="mb-1">
                  <b-icon-pencil
                      id="editAddressLabelIcon"
                      :aria-label="$t('aria.addressEditLabel')"
                  />
                </b-btn>
                <transition
                    mode="out-in"
                    name="fadeQuick">
                  <b
                      id="editAddressLabelText"
                      key="edit"
                      v-if="hoverIcon === 'editAddressLabel'">
                    {{$t('addressEditLabel')}}
                  </b>
                </transition>
              </div>
              <div
                  v-else
                  key="editingLabel"
                  class="mb-1">
                <b-input-group>
                  <b-form-input
                      id="addressLabelInput"
                      name="addressLabelInput"
                      type="text"
                      v-model="preferences.addressLabel"
                      class="form__input medium"
                      @input="saveRequest()"
                  />
                  <b-btn
                      id="confirmLabelChange"
                      variant="info"
                      size="sm"
                      @click="addressEditPrimaryLabel = false">
                    <b-icon-check
                        id="editAddressLabelConfirmIcon"
                        :aria-label="$t('aria.doneEditing')"
                    />
                  </b-btn>
                </b-input-group>
              </div>
            </transition>
            <b-input-group>
              <b-form-input
                  id="streetAddressInput"
                  name="address"
                  type="text"
                  v-model="customerDetails.address.street"
                  class="form__input xl"
                  @input="saveRequest()"
              />
              <b-input-group-append
                  v-if="!addingSecondLine && customerDetails.address && !customerDetails.address.secondLine">
                <span
                    @click="addressSecondLine()"
                    class="form__textButton"
                    v-text="$t('prefs.userAddressSecondAdd')">
                </span>
              </b-input-group-append>
            </b-input-group>
            <transition
                name="fade"
                mode="out-in">
              <b-input-group
                  v-if="addingSecondLine || (customerDetails.address && customerDetails.address.secondLine)">
                <b-form-input
                    id="streetAddressSecondLineInput"
                    name="addressSecondLine"
                    :aria-label="$t('aria.addressSecondLine')"
                    type="text"
                    v-model="customerDetails.address.secondLine"
                    class="form__input xl"
                    @input="saveRequest()"
                />
                <b-input-group-append>
                  <span
                      @click="addressSecondLine()"
                      class="form__textButton"
                      v-text="$t('prefs.userAddressSecondClear')">
                  </span>
                </b-input-group-append>
              </b-input-group>
            </transition>
            <b-row>
              <b-col
                  cols="auto">
                <label
                    for="cityInput"
                    class="form__label">
                  {{$t('addressCity')}} <b-spinner v-if="zipWatcher.id" small type="grow" />
                </label>
                <b-form-input
                    id="cityInput"
                    name="addressCityInput"
                    type="text"
                    v-model="customerDetails.address.city"
                    class="form__input large"
                    @input="saveRequest()"
                />
              </b-col>
              <b-col
                  cols="auto">
                <label
                    for="stateInput"
                    v-text="$t('addressState')"
                    class="form__label"
                />
                <b-form-input
                    id="stateInput"
                    name="addressStateInput"
                    type="text"
                    v-model="customerDetails.address.state"
                    class="form__input smallInput"
                    @input="saveRequest()"
                />
              </b-col>
              <b-col
                  cols="auto">
                  <label
                    for="zipInput"
                    v-text="$t('addressZip')"
                    class="form__label"
                />
                <b-form-input
                    id="zipInput"
                    name="addressZipInput"
                    type="text"
                    v-model="customerDetails.address.zip"
                    class="form__input smallish"
                    @input="zipLookup(customerDetails.address.zip, 'primary')"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row
            v-if="!customerDetails.addressForBilling"
            class="form__container"
            align-h="center">
          <b-col
              align="center">
            <b-btn
                id="addBillingAddressButton"
                @click="billingAddressAdd()"
                variant="secondary"
                v-text="$t('addAddress')"
            />
          </b-col>
        </b-row>
        <b-row
            v-else
            align-h="center"
            class="form__container">
          <b-col
              sm="6"
              md="auto">
            <transition
                mode="out-in"
                name="fadeQuick">
              <div
                  v-if="!addressEditBillingLabel"
                  key="standardView">
                <label
                    for="billingStreetAddressInput"
                    v-text="preferences.addressSecondaryLabel"
                    class="form__label"
                />
                <b-btn
                    variant="info-outline"
                    size="sm"
                    @click="addressEditBillingLabel = true; hoverIcon = null"
                    @mouseover="hoverIcon = 'editBillingLabel'"
                    @mouseleave="hoverIcon = null"
                    :disabled="hoverIcon === 'deleteBilling'"
                    class="mb-1">
                  <b-icon-pencil
                      id="editBillingAddressLabelIcon"
                      :aria-label="$t('aria.addressEditLabel')"
                  />
                </b-btn>
                <b-btn
                    variant="warning-outline"
                    size="sm"
                    @click="billingAddressRemove()"
                    @mouseover="hoverIcon = 'deleteBilling'"
                    @mouseleave="hoverIcon = null"
                    :disabled="hoverIcon === 'editBillingLabel'"
                    class="mr-2 mb-1">
                  <b-icon-trash
                      id="deleteBillingAddressIcon"
                      :aria-label="$t('aria.addressDeleteBillingAddress')"
                  />
                </b-btn>
                <transition
                    mode="out-in"
                    name="fadeQuick">
                  <b
                      id="removeBillingAddress"
                      key="delete"
                      v-if="hoverIcon === 'deleteBilling'"
                      style="cursor: pointer">
                    {{$t('addressBillingRemove')}}
                  </b>
                  <b
                      id="editBillingAddressLabelText"
                      key="edit"
                      v-if="hoverIcon === 'editBillingLabel'"
                      style="cursor: pointer">
                    {{$t('addressEditLabel')}}
                  </b>
                </transition>
              </div>
              <div
                  v-else
                  key="editingLabel"
                  class="mb-1">
                <b-input-group>
                  <b-form-input
                      id="billingAddressLabelInput"
                      name="billingAddressLabel"
                      type="text"
                      v-model="preferences.addressSecondaryLabel"
                      class="form__input medium"
                      @input="saveRequest()"
                  />
                  <b-btn
                      id="confirmSecondaryLabelChange"
                      variant="info"
                      size="sm"
                      @click="addressEditBillingLabel = false">
                    <b-icon-check
                        id="editBillingAddressLabelConfirmIcon"
                        :aria-label="$t('aria.doneEditing')"
                    />
                  </b-btn>
                </b-input-group>
              </div>
            </transition>
            <b-input-group>
              <b-form-input
                  id="billingStreetAddressInput"
                  name="billingAddress"
                  type="text"
                  v-model="customerDetails.billingAddress.street"
                  class="form__input xl"
                  @input="saveRequest()"
              />
              <b-input-group-append
                  v-if="!addingSecondLineBilling && !customerDetails.billingAddress.secondLine">
                <span
                    @click="addressSecondLineBilling()"
                    class="form__textButton"
                    v-text="$t('prefs.userAddressSecondAdd')">
                </span>
              </b-input-group-append>
            </b-input-group>
            <transition
                name="fade"
                mode="out-in">
              <b-input-group
                  v-if="addingSecondLineBilling || customerDetails.billingAddress.secondLine">
                <b-form-input
                    id="streetAddressSecondLineInput"
                    name="billingAddressSecondLine"
                    :aria-label="$t('aria.addressSecondLineBilling')"
                    type="text"
                    v-model="customerDetails.billingAddress.secondLine"
                    class="form__input xl"
                    @input="saveRequest()"
                />
                <b-input-group-append>
                  <span
                      @click="addressSecondLineBilling()"
                      class="form__textButton"
                      v-text="$t('prefs.userAddressSecondClear')">
                  </span>
                </b-input-group-append>
              </b-input-group>
            </transition>
            <b-row>
              <b-col
                  cols="auto">
                <label
                    for="billingCityInput"
                    class="form__label">
                  {{$t('addressCity')}} <b-spinner v-if="zipWatcher.id" small type="grow" />
                </label>
                <b-form-input
                    id="billingCityInput"
                    name="billingCity"
                    type="text"
                    v-model="customerDetails.billingAddress.city"
                    class="form__input large"
                    @input="saveRequest()"
                />
              </b-col>
              <b-col
                  cols="auto">
                <label
                    for="billingStateInput"
                    v-text="$t('addressState')"
                    class="form__label"
                />
                <b-form-input
                    id="billingStateInput"
                    name="billingAddressStateInput"
                    type="text"
                    v-model="customerDetails.billingAddress.state"
                    class="form__input smallInput"
                    @input="saveRequest()"
                />
              </b-col>
              <b-col
                  cols="auto">
                  <label
                    for="billingZipInput"
                    v-text="$t('addressZip')"
                    class="form__label"
                />
                <b-form-input
                    id="billingZipInput"
                    name="billingAddressZipInput"
                    type="text"
                    v-model="customerDetails.billingAddress.zip"
                    class="form__input smallish"
                    @input="zipLookup(customerDetails.billingAddress.zip, 'billing')"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div
          key="recurringLineItems"
          class="invoice__customer__container"
          v-if="customerDetailsActive === 2">
        <div
            class="form__container">
          <b-row
              class="form__header">
            <h4
                id="tableHeader">
                {{$t('adminCustomerRecurringLineItem')}}
              <b-btn
                  id="addLineItem"
                  class="admin__button"
                  variant="secondary"
                  @click.stop="recurringItemAdd()">
                <icon
                    icon="plus"
                    class="admin__buttonContent"
                />
              </b-btn>
            </h4>
          </b-row>
          <p
              v-if="!customerDetails.recurringLineItems.length"
              v-text="$t('invoice.recurringLineItemsDesc')"
              class="invoice__customer__recurringLineItems__description"
          />
          <b-table
              key="main"
              id="recurringLineItems"
              v-if="customerDetails.recurringLineItems.length !== 0"
              :fields="lineItemTableFields"
              :items="customerDetails.recurringLineItems"
              :per-page="perPage"
              stacked="md"
              class="invoice__table"
              :tbody-transition-props="{name: 'fadeQuick'}">
            <template
                v-if="$_app.invoiceQtyOption"
                v-slot:cell(qty)="row">
              <b-form-input
                  :id="`lineItemQty-${row.index}`"
                  class="invoice__qty"
                  type="number"
                  v-model.number="customerDetails.recurringLineItems[`${row.index}`].qty"
                  @input="saveRequest()"
              />
            </template>
            <template
                v-slot:cell(price)="row">
              <div
                  class="invoice__price">
                <b-input-group
                    prepend="$">
                  <b-form-input
                      :id="`lineItemPrice-${row.index}`"
                      type="number"
                      v-model.number="customerDetails.recurringLineItems[`${row.index}`].price"
                      @input="saveRequest()"
                  />
                </b-input-group>
              </div>
            </template>
            <template
                v-slot:cell(item)="row">
              <voice-input
                  :id="`lineItemDescription-${row.index}`"
                  :itemId="row.index"
                  :activeInput="true"
                  :modelProp="customerDetails.recurringLineItems[`${row.index}`].item"
                  :placeholder="$t('invoice.lineItemDesc')"
                  @textInput="updateTextInputRecurring"
                  @startingRecognition="activeRecurringInput = row.index"
                  @saveNow="saveRequest()"
              />
            </template>
            <template
                v-slot:cell(taxable)="row"
                v-if="$_app.invoiceTaxOption">
              <b-form-checkbox
                  :id="`taxableToggle-${row.index}`"
                  class="invoice__taxable"
                  v-model="customerDetails.recurringLineItems[`${row.index}`].taxable"
                  @change="saveRequest()"
              />
            </template>
            <template
                v-slot:cell(total)="row">
              <b-input-group-prepend
                  :id="`lineTotal-${row.index}`"
                  class="invoice__table__lineTotal"
                  type="text">
                {{lineTotal(customerDetails.recurringLineItems[`${row.index}`], { enable: preferences.taxOption, rate: preferences.taxRate })}}
              </b-input-group-prepend>
            </template>
            <template
                v-slot:cell(delete)="row">
              <transition
                  name="fadeQuick"
                  mode="out-in">
                <b-btn-group
                    key="deleteRecurringLineItem"
                    v-if="deleteRecurringItemRequest !== customerDetails.recurringLineItems[row.index]._id">
                  <b-btn
                      :id="`removeRecurringLineItem-${row.index}`"
                      name="RemoveRecurringLineItem"
                      :aria-label="$t('delete')"
                      variant="danger"
                      @click="recurringItemRemRequest(row.index)"
                      class="invoice__fileBadge__buttons__delete"
                      size="md">
                    <icon
                        icon="trash-alt"
                    />
                  </b-btn>
                </b-btn-group>
                <b-btn-group
                    v-else
                    key="deleteConfirm">
                  <b-btn
                      id="deleteCancel"
                      key="deleteCancel"
                      :aria-label="$t('deleteCancel')"
                      @click="deleteRecurringItemRequest = null"
                      class="invoice__fileBadge__buttons__delete__cancel"
                      variant="secondary">
                    <icon
                        icon="arrow-circle-left"
                    />
                  </b-btn>
                  <b-btn
                      key="deleteConfirm"
                      id="deleteConfirm"
                      :aria-label="$t('deleteConfirm')"
                      @click="recurringItemRemConfirm(row.index)"
                      class="invoice__fileBadge__buttons__delete__confirm"
                      variant="warning">
                    <icon
                        icon="trash-alt"
                        size="lg"
                    />
                    <small
                        v-if="deleteRecurringItemRequest === customerDetails.recurringLineItems[row.index]._id"
                        v-text="$t('sys.confirm')"
                    />
                  </b-btn>
                </b-btn-group>
              </transition>
            </template>
          </b-table>
        </div>
      </div>
      <div
          key="customerOptions"
          class="invoice__customer__container mx-1"
          v-if="customerDetailsActive === 3">
        <b-row
            class="form__header mx-3">
          <h4
              id="tableHeader">
            {{$t('adminCustomerOptions')}} {{customerIdentifier(customerDetails)}}
          </h4>
        </b-row>
        <transition
            name="fadeQuick"
            mode="out-in">
          <preference-pane
              v-if="!closePreferences"
              :customerDetails="customerDetails"
              :enable="prefsToEnable"
              @clearCustomerPrefs="clearCustomerPrefs()"
              @clearCustomerPrefItem="clearCustomerPrefItem"
              @save-now="savePreferences"
              class="mx-4"
          />
          <div
              v-else>
            <loader
              color="white"
              type="dots"
              :fullPage="false"
              :h="40"
              :w="100"
            />
          </div>
        </transition>
      </div>
      <div
          key="customerNotes"
          class="invoice__customer__container"
          v-if="customerDetailsActive === 4">
        <div
            class="form__container">
          <b-row
              class="form__header">
            <b-col
                cols="auto"
                align="center">
              <h4
                  id="tableHeader"
                  v-text="$t('adminCustomerNotes')"
              />
            </b-col>
            <b-col
                cols="auto"
                align="right">
              <b-form-checkbox
                  switch
                  v-model="hideSystemNotes"
                  class="mt-1">
                <span
                    v-text="$t('invoice.hideSystemNotes')"
                />
              </b-form-checkbox>
            </b-col>
          </b-row>
          <div
              class="mx-5">
            <b-table
                :fields="notesTableFields"
                :items="notesToShowCustomer"
                :per-page="perPage"
                stacked="md"
                class="invoice__table"
                :tbody-transition-props="{name: 'fadeQuick'}">
              <template
                  v-slot:cell(text)="row">
                <div
                  class="admin__notes__textContainer"
                  @click="noteEditTextRequest(row.item._id)">
                <transition
                    name="fadeQuick"
                    mode="out-in">
                  <span
                      key="plainText"
                      v-if="noteEditText !== row.item._id"
                      v-text="row.item.text"
                  />
                  <b-form-textarea
                      key="formArea"
                      v-else
                      :id="`note-${row.item._id}`"
                      :max-rows="10"
                      @input="saveRequest()"
                      :disabled="row.item.systemGenerated ? true : false"
                      v-model="row.item.text"
                      :placeholder="noteEditText === row.item._id ? `${$t('note.new')}` : ''"
                      class="admin__notes__textInput"
                  />
                </transition>
              </div>
              </template>
              <template
                  v-slot:cell(date)="row">
                <span
                    id="displayDate"
                    v-if="noteEditDate !== row.item._id"
                    @click="row.item.systemGenerated ? '' : noteEditDateRequest(row.item._id)"
                    v-text="dateDisplayWithTime(row.item.date)"
                />
                <b-input-group
                    class="invoice__date">
                  <b-form-input
                      :id="`date-${row.item._id}`"
                      v-if="noteEditDate === row.item._id"
                      type="date"
                      v-model="row.item.date"
                      @change="noteDateChoose(row.item)"
                  />
                  <b-input-group-append>
                    <icon
                        icon="times-circle"
                        v-if="noteEditDate === row.item._id"
                        :aria-label="$t('cancelDateInput')"
                        @click="noteEditDate = null"
                    />
                  </b-input-group-append>
                </b-input-group>
              </template>
              <template
                  v-slot:cell(delete)="row">
                <transition
                    name="fadeQuick"
                    mode="out-in">
                  <b-btn-group
                      key="noteDeleteandSystemButtoms"
                      v-if="noteDeleteConfirm !== row.item._id">
                    <b-btn
                        id="systemGeneratedLabel"
                        v-if="row.item.systemGenerated"
                        disabled
                        v-text="$t('invoice.systemGenerated')"
                        variant="info"
                        class="admin__notes__systemGeneratedLabel"
                    />
                    <b-btn
                        :id="`noteDelete-${row.item._id}`"
                        v-if="noteEditDate !== row.item._id
                          && !row.item.systemGenerated"
                        @click="noteDeleteRequest(row.item._id)"
                        :aria-label="$t('deleteNote')"
                        class="admin__button"
                        variant="danger"
                        size="md">
                      <icon
                          icon="trash-alt"
                          class="admin__buttonContent"
                      />
                    </b-btn>
                  </b-btn-group>
                  <b-btn-group
                      key="deleteNoteConfirmation"
                      v-else>
                    <b-btn
                        id="cancelNoteDelete"
                        @click="noteDeleteCancel()"
                        class="mx-2"
                        :aria-label="$t('deleteCancel')"
                        variant="primary">
                      <icon
                          icon="arrow-circle-left"
                      />
                    </b-btn>
                    <b-btn
                        id="confirmNoteDelete"
                        @click="noteDeleteConfirmed(row.item._id)"
                        :aria-label="$t('deleteConfirm')"
                        variant="warning">
                      <icon
                          icon="trash-alt"
                          class="admin__buttonContent"
                          size="lg"
                      />
                      <small
                          v-text="$t('sys.confirm')"
                      />
                    </b-btn>
                  </b-btn-group>
                </transition>
              </template>
            </b-table>
            <b-row
                class="mb-2">
              <b-col
                  id="newNoteCol"
                  align="center">
                <b-btn
                    id="addNote"
                    variant="secondary"
                    class="invoice__fileBadge__buttons__addItems"
                    @click="noteAdd()">
                  <icon
                      icon="plus"
                      size="lg"
                      class="admin__buttonContent"
                  />
                  <span
                      v-text="$t('invoice.noteAdd')"
                  />
                </b-btn>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </transition>
    <transition
        name="fade"
        mode="out-in">
      <div
          key="addingInvoice"
          class="form__container"
          v-if="customerHasBeenSelected">
        <h2
            class="text-center form__header"
            v-text="$t('invoice.adding')"
        />
        <b-row
            align-h="center">
          <b-col
              id="invoiceNumberInputContainer"
              cols="auto">
            <label
                for="invoiceNumberInput"
                v-text="$t('invoice.number')"
                class="form__label"
            />
            <b-form-input
                id="invoiceNumberInput"
                v-model="documentNumber"
                class="form__input medium largeText"
                type="text"
            />
          </b-col>
          <b-col
              id="invoiceTypeInputContainer"
              cols="auto">
            <label
                for="invoiceType"
                v-text="$t('invoice.type')"
                class="form__label"
            />
            <br />
            <b-btn-group
                id="documentType"
                v-if="addingInvoice"
                key="documentOption"
                :aria-label="$t('aria.invoiceType')"
                class="mx-4">
              <b-btn
                  id="documentIsProposal"
                  @click="model.status = 'proposal'"
                  v-text="$t('invoice.documentProposal')"
                  :variant="model.status === 'proposal' ? 'primary' : 'primary-outline'"
              />
              <b-btn
                  id="documentIsInvoice"
                  @click="model.status = 'open'"
                  v-text="$t('invoice.documentInvoice')"
                  :variant="model.status === 'open' ? 'primary' : 'primary-outline'"
              />
            </b-btn-group>
          </b-col>
        </b-row>
        <b-row
            id="dateOptionsRow"
            align-h="center"
            v-if="model.status === 'open'"
            class="my-3">
          <b-col
              cols="auto">
            <div
                id="datePreferenceContainer">
              <b-row>
                <b-col
                    cols="auto">
                  <b-form-checkbox
                      v-if="model.status === 'open'"
                      v-model="preferences.dueDateEnabled"
                      name="enableDueDate"
                      @input="updateSettings('dueDateEnabled', preferences.dueDateEnabled)"
                      switch
                      size="lg">
                    {{$t('prefs.dueDateEnabled')}}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <div
                  v-if="preferences.dueDateEnabled">
                <b-row>
                  <b-col>
                    <b-form-radio-group
                        id="automaticOrManualDueDate"
                        :options="typeOfDueDate"
                        stacked
                        v-model="preferences.dueDateIsAutomatic"
                        @input="updateSettings('dueDateIsAutomatic', preferences.dueDateIsAutomatic)"
                        class="m-2 ml-3 mb-1"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
          <b-col
              cols="auto"
              v-if="preferences.dueDateEnabled">
            <transition
                mode="out-in"
                name="fadeQuick">
              <div
                  key="paymentTerms"
                  v-if="preferences.dueDateEnabled
                    && preferences.dueDateIsAutomatic">
                <b-input-group
                    id="defaultTermsInputGroup">
                  <b-input-group-prepend
                      v-text="$t('prefs.defaultTermsDoc')"
                      class="form__label"
                  />
                  <b-form-input
                      id="defaultTermsInput"
                      type="number"
                      :min="0"
                      v-model.number="terms"
                      class="form__input smallInput"
                  />
                  <b-input-group-append
                      class="my-auto ml-2"
                      v-text="$t('invoice.days')"
                  />
                </b-input-group>
                <small>
                  {{$t('prefs.dateWillBeDue')}} <b>{{dueDateWouldBe}}</b>
                </small>
              </div>
              <div
                  key="setDateManually"
                  v-else>
                <div
                    id="dateOption"
                    v-if="!changeDateDue"
                    class="ml-auto">
                  <b-input-group
                      v-if="!changeDate">
                    <b-input-group-prepend
                        v-text="$t('invoice.date')"
                        class="form__label mx-2"
                    />
                    <b-btn
                        id="displayDate"
                        v-if="!changeDate"
                        v-text="displayDate(model.date)"
                        @click="changeInvoiceDate()"
                        :aria-label="$t('sys.clickDate')"
                    />
                  </b-input-group>
                  <div
                      v-else>
                    <span
                        v-text="$t('invoice.date')"
                        class="form__label mx-2"
                    />
                    <b-input-group>
                      <b-form-input
                          id="changeDueDateInput"
                          v-model="model.date"
                          type="date"
                          @change="checkInvoiceDate()"
                          class="form__input medium"
                      />
                    </b-input-group>
                  </div>
                </div>
                <div
                    id="dueDateOption"
                    v-if="!changeDate"
                    class="ml-auto">
                  <b-input-group
                      v-if="!changeDateDue">
                    <b-input-group-prepend
                      v-text="$t('invoice.dateDue')"
                      class="form__label"
                    />
                    <b-btn
                        id="displayDueDate"
                        v-if="!changeDateDue"
                        v-text="displayDate(dueDate)"
                        class="invoice__fileBadge__options__button"
                        :aria-label="$t('sys.clickDate')"
                        @click="changeInvoiceDateDue()"
                    />
                  </b-input-group>
                  <div
                      v-else>
                    <span
                      v-text="$t('invoice.dateDue')"
                      class="form__label"
                    />
                    <b-input-group>
                      <b-form-input
                          id="changeDueDateInput"
                          v-model="model.dateDue"
                          type="date"
                          @change="checkInvoiceDateDue()"
                          @click.stop
                          class="form__input medium"
                      />
                    </b-input-group>
                  </div>
                </div>
              </div>
            </transition>
          </b-col>
          <b-col
              cols="6"
              v-else> <!-- Change document date when due date is disabled -->
            <div
                id="dateOption"
                class="ml-4">
              <b-input-group
                  v-if="!changeDate">
                <b-input-group-prepend
                    v-text="$t('invoice.dateOfDoc')"
                    class="form__label"
                />
                <b-btn
                    id="displayDate"
                    v-if="!changeDate"
                    v-text="displayDate(model.date)"
                    @click="changeInvoiceDate()"
                    :aria-label="$t('sys.clickDate')"
                />
              </b-input-group>
              <div
                  v-else>
                <span
                    v-text="$t('invoice.date')"
                    class="form__label mx-2"
                />
                <b-input-group>
                  <b-form-input
                      id="changeDueDateInput"
                      v-model="model.date"
                      type="date"
                      @change="checkInvoiceDate()"
                      class="form__input medium"
                  />
                </b-input-group>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row
            id="dateOptionsRow"
            align-h="center"
            v-if="model.status === 'proposal'"
            class="my-3">
          <b-col
              cols="auto">
            <div
                id="datePreferenceContainer">
              <b-row>
                <b-col
                    cols="auto">
                  <b-form-checkbox
                      v-model="preferences.proposalExpirationDateEnabled"
                      name="enableExpirationDate"
                      @input="updateSettings('proposalExpirationDateEnabled', preferences.proposalExpirationDateEnabled)"
                      switch
                      size="lg">
                    {{$t('prefs.proposalExpirationDateEnabled')}}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <div
                  v-if="preferences.proposalExpirationDateEnabled">
                <b-row>
                  <b-col>
                    <b-form-radio-group
                        id="automaticOrManualExpirationDate"
                        :options="typeOfExpirationDate"
                        stacked
                        v-model="preferences.proposalExpirationDateIsAutomatic"
                        @input="updateSettings('proposalExpirationDateIsAutomatic', preferences.proposalExpirationDateIsAutomatic)"
                        class="m-2 ml-3 mb-1"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
          <b-col
              cols="auto"
              v-if="preferences.proposalExpirationDateEnabled">
            <transition
                mode="out-in"
                name="fadeQuick">
              <div
                  key="paymentTerms"
                  v-if="preferences.proposalExpirationDateEnabled
                    && preferences.proposalExpirationDateIsAutomatic">
                <b-input-group
                    id="defaultTermsInputGroup">
                  <b-input-group-prepend
                      v-text="$t('prefs.defaultTermsProposalDoc')"
                      class="form__label"
                  />
                  <b-form-input
                      id="defaultTermsInput"
                      type="number"
                      :min="0"
                      v-model.number="termsProposal"
                      class="form__input smallInput"
                  />
                  <b-input-group-append
                      class="my-auto ml-2"
                      v-text="$t('invoice.days')"
                  />
                </b-input-group>
                <small>
                  {{$t('prefs.dateWillExpire')}} <b>{{expirationDateWouldBe}}</b>
                </small>
              </div>
              <div
                  key="setDateManually"
                  v-else>
                <div
                    id="dateOption"
                    v-if="!changeDateExpires"
                    class="ml-auto">
                  <b-input-group
                      v-if="!changeDateOfProposal">
                    <b-input-group-prepend
                        v-text="$t('invoice.date')"
                        class="form__label mx-2"
                    />
                    <b-btn
                        id="displayDate"
                        v-if="!changeDateOfProposal"
                        v-text="displayDate(model.dateOfProposal)"
                        @click="changeProposalDate()"
                        :aria-label="$t('sys.clickDate')"
                    />
                  </b-input-group>
                  <div
                      v-else>
                    <span
                        v-text="$t('invoice.date')"
                        class="form__label mx-2"
                    />
                    <b-input-group>
                      <b-form-input
                          id="changeDueDateInput"
                          v-model="model.dateProposal"
                          type="date"
                          @change="checkProposalDate()"
                          class="form__input medium"
                      />
                    </b-input-group>
                  </div>
                </div>
                <div
                    id="expirationDateOption"
                    v-if="!changeDateOfProposal"
                    class="ml-auto">
                  <b-input-group
                      v-if="!changeDateExpires">
                    <b-input-group-prepend
                      v-text="$t('invoice.dateExpires')"
                      class="form__label"
                    />
                    <b-btn
                        id="displayExpirationDate"
                        v-if="!changeDateExpires"
                        v-text="displayDate(expirationDate)"
                        class="invoice__fileBadge__options__button"
                        :aria-label="$t('sys.clickDate')"
                        @click="changeProposalDateExpires()"
                    />
                  </b-input-group>
                  <div
                      v-else>
                    <span
                      v-text="$t('invoice.dateExpires')"
                      class="form__label"
                    />
                    <b-input-group>
                      <b-form-input
                          id="changeExpirationDateInput"
                          v-model="model.dateProposalExpires"
                          type="date"
                          @change="checkProposalDateExpires()"
                          @click.stop
                          class="form__input medium"
                      />
                    </b-input-group>
                  </div>
                </div>
              </div>
            </transition>
          </b-col>
          <b-col
              cols="6"
              v-else> <!-- Change document date when due date is disabled -->
            <div
                id="dateOption"
                class="ml-4">
              <b-input-group
                  v-if="!changeDateExpires">
                <b-input-group-prepend
                    v-text="$t('invoice.dateOfDoc')"
                    class="form__label"
                />
                <b-btn
                    id="displayDate"
                    v-if="!changeDateExpires"
                    v-text="displayDate(model.dateProposal)"
                    @click="changeProposalDate()"
                    :aria-label="$t('sys.clickDate')"
                />
              </b-input-group>
              <div
                  v-else>
                <span
                    v-text="$t('invoice.date')"
                    class="form__label mx-2"
                />
                <b-input-group>
                  <b-form-input
                      id="changeExpirationDateInput"
                      v-model="model.dateProposal"
                      type="date"
                      @change="checkProposalDate()"
                      class="form__input medium"
                  />
                </b-input-group>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row
            align-h="center">
          <b-col
              id="invoiceTitleContainer"
              cols="auto">
            <label
                for="invoiceTitleInput"
                v-text="$t('invoice.title')"
                class="form__label"
            />
            <b-form-input
                id="invoiceTitleInput"
                v-model="model.title"
                :placeholder="$t('optional')"
                class="form__input xl"
                type="text"
            />
          </b-col>
          <b-col
              id="invoiceMemoContainer"
              cols="auto">
            <label
                for="invoiceMemoInput"
                v-text="$t('invoice.memo')"
                class="form__label"
            />
            <b-input-group
                v-if="model.status === 'open'">
              <b-form-checkbox
                  v-model="model.memo.enable"
                  name="enableMemoSwitch"
                  :aria-label="model.memo.enable ? $t('aria.memoDisable') : $t('aria.memoEnable')"
                  class="ml-3 my-auto"
                  switch
                  size="lg"
              />
              <transition
                  mode="out-in"
                  name="fade">
                <b-form-textarea
                    id="invoiceMemoInput"
                    v-if="model.memo.enable"
                    v-model="model.memo.text"
                    :placeholder="$t('optional')"
                    class="form__input xl"
                    type="text"
                />
              </transition>
            </b-input-group>
            <b-input-group
                v-else-if="model.status === 'proposal'">
              <b-form-checkbox
                  v-model="model.memo.enableProposal"
                  name="enableMemoSwitch"
                  :aria-label="model.memo.enableProposal ? $t('aria.memoDisable') : $t('aria.memoEnable')"
                  class="ml-3 my-auto"
                  switch
                  size="lg"
              />
              <transition
                  mode="out-in"
                  name="fadeQuick">
                <b-form-textarea
                    id="invoiceMemoInput"
                    v-if="model.memo.enableProposal"
                    v-model="model.memo.textProposal"
                    :placeholder="$t('optional')"
                    class="form__input xl"
                    type="text"
                />
              </transition>
            </b-input-group>
          </b-col>
        </b-row>
        <!-- <b-row
            align-h="center"
            v-if="$_app.switch.workDay">
          <b-col
              cols="auto"
              align="center">
            <h2
                class="text-center form__header"
                v-text="$t('invoice.workDaySectionHeader')"
            />
          </b-col>
        </b-row> -->
        <b-row
            align-h="center"
            align-v="center"
            v-if="accountDue(this.customerDetails, 'raw') !== 0">
          <b-col
              cols="auto"
              align="center"
              class="form__container">
            <label
                for="accountBalanceForwardOptions"
                v-text="`${$t('invoice.accountBalance')} ${accountDue(this.customerDetails, 'display')}`"
                class="form__label"
            />
            <b-btn-group
                id="accountBalanceForwardOptions">
              <b-btn
                  :variant="outstandingInvoicesAllSelected ? 'secondary' : 'secondary-outline'"
                  v-text="outstandingInvoicesAllSelected ? $t('invoice.included') : $t('invoice.includeAll')"
                  @click="!outstandingInvoicesAllSelected ? outstandingIncludeAll() : outstandingIncludeClear()"
                  class="mx-2"
              />
              <b-btn
                  :variant="outstandingShowList ? 'secondary' : 'secondary-outline'"
                  @click="outstandingListToggle()"
                  v-text="outstandingShowList ? $t('invoice.includeOptionsHide') : $t('invoice.includeOptions')"
              />
            </b-btn-group>
            <transition
                name="fadeQuick">
              <div
                  key="listOfOutstandingItems"
                  v-if="outstandingShowList">
                <div
                    v-for="outstanding in outstandingItems"
                    :key="outstanding._id">
                  <b-row
                      v-if="outstanding.totalCharges - outstanding.totalPayments > 0"
                      class="invoice__customer__accountDetails__invoiceList__container"
                      align-h="center"
                      align-v="center">
                    <b-col
                        align="center">
                      <span
                          v-text="outstanding.number"
                          class="invoice__customer__accountDetails__invoiceList"
                      />
                    </b-col>
                    <b-col
                        align="center">
                      <span
                          class="invoice__customer__accountDetails__invoiceList__bold mx-2 text-nowrap">
                        {{displayAmount(outstanding.totalCharges - outstanding.totalPayments)}} {{$t('invoice.due')}}
                      </span>
                    </b-col>
                    <b-col
                        align="center">
                      <span
                          v-text="dueDateInformal(outstanding, 'bypassPreferences')"
                          class="invoice__customer__accountDetails__invoiceList__bold text-nowrap"
                      />
                    </b-col>
                    <b-col
                        cols="auto"
                        align="center">
                      <b-btn
                          v-text="outstanding.include ? $t('invoice.included') : $t('invoice.includeInactive')"
                          :variant="outstanding.include ? 'secondary' : 'secondary-outline'"
                          @click="includeOutstandingInvoice(outstanding._id)"
                          class="m-auto"
                      />
                    </b-col>
                  </b-row>
                </div>
              </div>
            </transition>
          </b-col>
        </b-row>
      </div>
    </transition>
    <transition
        name="fadeQuick"
        mode="out-in">
      <table-pagination
          v-if="itemResults.length > perPage && readyToRun && !addingInvoice"
          key="paginationTop"
          @changePage="changePage"
          :currentPage="currentPage"
          :perPage="perPage"
          :rows="itemResults.length"
      />
    </transition>
    <transition
        name="fade"
        mode="out-in">
      <invoice-table
          key="table"
          v-if="readyToRun && !addingInvoice"
          :currentPage="currentPage"
          :items="itemResults"
          :perPage="perPage"
          @customerFilterNeeded="filterCustomer"
          @duplicateDocument="addInvoiceDuplicate"
          @invoiceAdded="addInvoiceSuccess"
          @scroll-to="scrollToItem"
      />
    </transition>
    <transition
        name="fadeQuick"
        mode="out-in">
      <table-pagination
          v-if="itemResults.length > perPage && readyToRun && !addingInvoice"
          key="paginationBottom"
          @changePage="changePage"
          :currentPage="currentPage"
          :perPage="perPage"
          :rows="itemResults.length"
      />
    </transition>
    <!-- Bottom filters bar -->
    <b-row
        id="totalAndFilters"
        class="invoice__table__bottomControls">
      <transition
          name="fadeQuick"
          mode="out-in">
        <b-col
            key="addingInvoiceControls"
            v-if="addingInvoice">
          <b-btn-group
              class="invoice__bottomButtonBar">
            <b-btn
                id="cancelNewInvoice-secondLine"
                @click="addInvoiceCancel()"
                variant="warning"
                class="invoice__buttons__addInvoice__cancel ml-3 my-auto">
              <icon
                  icon="times-circle"
                  class="invoice__buttons__addInvoice__cancel__icon"
              />
              <span
                  v-text="$t('cancel')"
                  class="invoice__buttons__addInvoice__cancel__text"
              />
            </b-btn>
            <b-btn
                id="saveNewInvoice-secondLine"
                v-if="customerHasBeenSelected"
                class="invoice__buttons__addInvoice__confirm"
                @click="addInvoiceConfirm()"
                variant="primary">
              <icon
                  icon="save"
                  class="admin__buttonContent"
              />
              <span
                  v-text="saveButtonText"
              />
            </b-btn>
          </b-btn-group>
        </b-col>
        <b-col
            key="defaultViewBottomToolbar"
            v-else>
          <b-btn-toolbar
              class="invoice__bottomButtonBar">
            <b-input-group
                id="tableFilters"
                class="m-0 p-0">
                <b-btn
                    id="filterHome"
                    :aria-label="$t('aria.clearAllInvoiceFilters')"
                    @click.stop="filterChange('clearAll')"
                    class="ml-1 mr-2 my-2 px-2"
                    :variant="(filterActive.all && !filterActive.flagged && numberOfDaysInView === -1 && customerFilterLabel === allCustomersLabel) ? 'second' : 'info'">
                  <icon
                      icon="home"
                  />
                </b-btn>
                <b-btn
                    id="filterAll"
                    v-text="buttonLabelFilterAll"
                    @click.stop="filterChange('all')"
                    :class="filterActive.all ? 'invoice__bottomButtonBar__buttons__active' : 'invoice__bottomButtonBar__buttons'"
                    :variant="filterActive.all ? 'primary' : 'info'"
                />
                <b-btn
                    id="filterOpen"
                    v-text="buttonLabelFilterOpen"
                    @click.stop="filterChange('open')"
                    :class="filterActive.open ? 'invoice__bottomButtonBar__buttons__active': 'invoice__bottomButtonBar__buttons'"
                    :variant="filterActive.open ? 'primary' : 'info'"
                />
                <b-btn
                    id="filterProposal"
                    v-text="buttonLabelFilterProposal"
                    @click.stop="filterChange('proposal')"
                    :class="filterActive.proposal ? 'invoice__bottomButtonBar__buttons__active': 'invoice__bottomButtonBar__buttons'"
                    :variant="filterActive.proposal ? 'primary' : 'info'"
                />
                <b-btn
                    id="filterSent"
                    v-text="buttonLabelFilterSent"
                    @click.stop="filterChange('sent')"
                    :class="filterActive.sent ? 'invoice__bottomButtonBar__buttons__active': 'invoice__bottomButtonBar__buttons'"
                    :variant="filterActive.sent ? 'primary' : 'info'"
                />
                <b-btn
                    id="filterPaid"
                    v-text="buttonLabelFilterPaid"
                    @click.stop="filterChange('paid')"
                    :class="filterActive.paid ? 'invoice__bottomButtonBar__buttons__active': 'invoice__bottomButtonBar__buttons'"
                    :variant="filterActive.paid ? 'primary' : 'info'"
                />
                <b-btn
                    id="filterPastDue"
                    v-text="buttonLabelFilterPastDue"
                    @click.stop="filterChange('pastDue')"
                    :class="filterActive.pastDue ? 'invoice__bottomButtonBar__buttons__active': 'invoice__bottomButtonBar__buttons'"
                    :variant="filterActive.pastDue ? 'primary' : 'info'"
                />
                <b-btn
                    id="filterFlagged"
                    :aria-label="$t('aria.flagInvoiceFilter')"
                    @click.stop="filterChange('flagged')"
                    :class="filterActive.flagged ? 'invoice__bottomButtonBar__buttons__active': 'invoice__bottomButtonBar__buttons__flagged'"
                    :variant="filterActive.flagged ? 'primary' : 'info'">
                  <icon
                      :icon="['fas', 'bookmark']"
                  />
                </b-btn>
            </b-input-group>
            <div
                id="totalsViewerContainerWindow"
                class="invoice__bottomButtonBar__totalsViewer__container"
                @click.stop="totalsViewer < 3 ? totalsViewer += 1 : totalsViewer = 0; totalsViewerTransitionTimer = false">
              <transition
                  name="fade"
                  mode="out-in">
                <span
                    key="total"
                    id="screenTotal"
                    v-if="totalsViewer === 0"
                    v-text="screenTotal"
                    class="invoice__bottomButtonBar__totalsViewer"
                />
                <span
                    key="Due"
                    id="screenDue"
                    v-else-if="totalsViewer === 1"
                    v-text="screenDue"
                    class="invoice__bottomButtonBar__totalsViewer"
                />
                <span
                    key="Payments"
                    id="screenPayments"
                    v-else-if="totalsViewer === 2"
                    v-text="screenPayments"
                    class="invoice__bottomButtonBar__totalsViewer"
                />
                <span
                    key="Proposals"
                    id="screenProposed"
                    v-else
                    v-text="screenProposed"
                    class="invoice__bottomButtonBar__totalsViewer"
                />
              </transition>
              <p
                class="m-0 p-0 pr-3">
                <span
                    id="numberOfInvoicesOnScreen"
                    v-text="$t('invoice.numberOnScreen')"
                />
                <span
                    class="invoice__bottomButtonBar__totalsViewer__resultsLength"
                    v-text="itemResults.length"
                />
              </p>
            </div>
          </b-btn-toolbar>
        </b-col>
      </transition>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable no-continue */
import moment from 'moment';
import customerAccountDetails from '@/components/data/CustomerAccountDetails';
import customerDropdown from '@/components/data/InvoiceCustomerDropdown';
import tablePagination from '@/components/data/TablePagination';
import invoiceTable from '@/components/data/InvoiceTable';
import preferencePane from '@/components/util/Preferences';
import totalCalculators from '@/mixins/totalCalculators';
import voiceInput from '@/components/data/VoiceInput';

export default {
  name: 'InvoiceAdmin',
  mixins: [
    totalCalculators,
  ],
  title() {
    return `${this.$t('adminInvoice')} | ${this.$t('project.brand')}`;
  },
  components: {
    customerAccountDetails,
    customerDropdown,
    tablePagination,
    invoiceTable,
    preferencePane,
    voiceInput,
  },
  data() {
    return {
      activeRecurringInput: null,
      addingInvoice: false,
      addingSecondLine: false,
      addingSecondLineBilling: false,
      addressEditPrimaryLabel: false,
      addressEditBillingLabel: false,
      allCustomersLabel: this.$t('invoice.filter.allCustomers'),
      changeDate: null,
      changeDateDue: null,
      changeDateOfProposal: null,
      changeDateExpires: null,
      currentPage: 1,
      customersPerPage: 10,
      customersPage: 1,
      customDateRange: false,
      customDateFilterStart: null,
      customDateFilterEnd: null,
      customerDeleteConfirm: null,
      customerDetailsActive: -1,
      customerNewLabel: this.$t('invoice.customerNew'),
      customerHasBeenSelected: false,
      customerSearchTerm: null,
      customerSelectLabel: this.$t('invoice.customerSelect'),
      deleteRecurringItemRequest: null,
      filterActive: {
        all: true,
        customer: null,
        open: false,
        flagged: false,
        paid: false,
        pastDue: false,
        pastDays: false,
        pastDaysCount: 30,
        sent: false,
        proposal: false,
      },
      numberOfDaysInView: -1,
      hideSystemNotes: false,
      hoverCustomer: null,
      hoverIcon: null,
      itemResults: [],
      lineItemTableFields: [
        this.$_app.invoiceDateOption
          ? { key: 'date', label: this.$t('date') }
          : {},
        this.$_app.invoiceQtyOption
          ? { key: 'qty', label: this.$t('invoice.qty') }
          : {},
        { key: 'price', label: this.$t('invoice.priceEach') },
        { key: 'item', label: this.$t('invoice.desc') },
        this.$_app.invoiceTaxOption ? {
          key: 'taxable',
          label: this.$t('invoice.taxable'),
          class: 'text-center',
        } : {},
        { key: 'total', label: this.$t('invoice.total') },
        { key: 'delete', label: '' },
      ],
      model: {},
      notesTableFields: [
        { key: 'text', label: this.$t('invoice.note') },
        { key: 'date', label: this.$t('invoice.date'), sortable: true },
        { key: 'delete', label: '' },
      ],
      noteDeleteConfirm: null,
      noteEditDate: null,
      noteEditText: null,
      outstandingItems: [],
      prefsToEnable: {
        account: false,
        customer: true,
        default: true,
        document: false,
        system: true,
        help: false,
        user: true,
      },
      outstandingShowList: false,
      sortDirection: true,
      sortedItems: [],
      totalsViewer: 0,
      totalsViewerTransitionTimer: true,
      typeOfDueDate: [
        {
          text: this.$t('prefs.dueDateIsAutomatic'),
          value: true,
        },
        {
          text: this.$t('prefs.dueDateIsManual'),
          value: false,
        },
      ],
      typeOfExpirationDate: [
        {
          text: this.$t('prefs.dueDateIsAutomaticProposal'),
          value: true,
        },
        {
          text: this.$t('prefs.dueDateIsManualProposal'),
          value: false,
        },
      ],
      zipWatcher: {
        id: null,
        type: null,
      },
    };
  },
  created() {
    this.$store.dispatch('initialLoad').catch((err) => {
      console.log(err);
      this.$router.go();
    });
    this.readyCheck();
  },
  mounted() {
    this.itemRefresh();
    setInterval(() => {
      if (this.totalsViewerTransitionTimer) {
        if (this.totalsViewer === 3) {
          this.totalsViewer = 0;
        } else {
          this.totalsViewer += 1;
        }
      }
    }, 6200);
    if (this.routeItemId) {
      this.$store.state.showItem = this.routeItemId;
      this.$router.push('/dashboard/billing');
      this.$store.state.activeItem = 1;
      this.$store.state.justViewed = {
        record: null,
        collection: null,
      };
    }
  },
  computed: {
    buttonLabelFilterAll() {
      if (this.filterActive.all) {
        return `${this.$t('invoice.filter.all')} (${this.itemResults.length})`;
      }
      return this.$t('invoice.filter.all');
    },
    buttonLabelFilterOpen() {
      const label = this.$t('invoice.statusOptions.open');
      if (!this.filterActive.open) {
        return label;
      }
      let counter = 0;
      function addOne() { counter += 1; }
      this.itemResults.filter(item => item.status === 'open').forEach(() => addOne());
      return `${label} (${counter})`;
    },
    buttonLabelFilterProposal() {
      const label = this.$t('invoice.statusOptions.proposal');
      if (!this.filterActive.proposal) {
        return label;
      }
      let counter = 0;
      function addOne() { counter += 1; }
      this.itemResults.filter(item => item.status === 'proposal').forEach(() => addOne());
      return `${label} (${counter})`;
    },
    buttonLabelFilterPaid() {
      const label = this.$t('invoice.statusOptions.paid');
      if (!this.filterActive.paid) {
        return label;
      }
      let counter = 0;
      function addOne() { counter += 1; }
      this.itemResults.filter(item => item.status === 'paid').forEach(() => addOne());
      return `${label} (${counter})`;
    },
    buttonLabelFilterPastDays() {
      if (this.customDateRange) {
        return `${this.$t('invoice.filter.dayCustom')} (${this.itemResults.length})`;
      }
      if (this.numberOfDaysInView === -1) {
        return `${this.$t('invoice.filter.allTime')} (${this.itemResults.length})`;
      }
      if (this.numberOfDaysInView === 1) {
        return `${this.$t('invoice.filter.pastDay')} (${this.itemResults.length})`;
      }
      return `${this.$t('invoice.filter.pastDays')} ${this.numberOfDaysInView} ${this.$t('invoice.days')} (${this.itemResults.length})`;
    },
    buttonLabelFilterPastDue() {
      const label = this.$t('invoice.statusOptions.pastDue');
      if (!this.filterActive.pastDue) {
        return label;
      }
      let counter = 0;
      function addOne() { counter += 1; }
      this.itemResults.filter(item => item.status === 'pastDue').forEach(() => addOne());
      return `${label} (${counter})`;
    },
    buttonLabelFilterSent() {
      const label = this.$t('invoice.statusOptions.sent');
      if (!this.filterActive.sent) {
        return label;
      }
      let counter = 0;
      function addOne() { counter += 1; }
      this.itemResults.filter(item => item.status === 'sent').forEach(() => addOne());
      return `${label} (${counter})`;
    },
    closePreferences() {
      return this.$store.state.needToClosePreferences;
    },
    customerDetails() {
      if (this.filterActive.customer
          && this.filterActive.customer !== this.allCustomersLabel) {
        return this.$store.getters.customer(this.filterActive.customer);
      }
      return {
        none: true,
        text: 'empty',
        address: null,
        invoices: [],
        recurringLineItems: [],
        payments: [],
      };
    },
    customerPreferences() {
      if (this.filterActive.customer
          && this.$store.getters.customerPreferences(this.filterActive.customer)) {
        return this.$store.getters.customerPreferences(this.filterActive.customer);
      }
      return {};
    },
    customerFilterLabel() {
      if (this.filterActive.customer) {
        return this.customerIdentifier(this.customerDetails)
          ? this.customerIdentifier(this.customerDetails)
          : this.customerNewLabel;
      }
      if (this.addingInvoice) {
        return this.customerSelectLabel;
      }
      return this.allCustomersLabel;
    },
    customerHasBeenAdded() {
      return this.$store.getters.customerHasBeenAdded;
    },
    customerFax: {
      get() {
        return this.displayPhone(this.customerDetails.fax);
      },
      set(newVal) {
        this.customerDetails.fax = newVal.replace(/[^A-Z0-9]+/ig, '');
      },
    },
    customerPhone: {
      get() {
        return this.displayPhone(this.customerDetails.phone);
      },
      set(newVal) {
        this.customerDetails.phone = newVal.replace(/[^A-Z0-9]+/ig, '');
      },
    },
    sortBy: {
      get() {
        return this.preferences.defaultSort;
      },
      set(newVal) {
        this.preferences.defaultSort = newVal;
      },
    },
    customerSince() {
      return `${this.$t('adminCustomerSince')} ${moment(this.customerDetails.dateAdded).format('l')}`;
    },
    customers() {
      const customers = this.$store.getters.allCustomers;
      if (this.customerSearchTerm) {
        return this.searchCustomers(customers, this.customerSearchTerm);
      }
      return customers;
    },
    customersPaginated() {
      const records = this.customers;
      const perPage = this.customersPerPage;
      const page = this.customersPage;
      return records.slice(((page - 1) * perPage), page * perPage);
    },
    dateDisplayFormInput() {
      return moment(this.model.date).format('L').substr(0, 10);
    },
    dbversion() {
      return this.$store.state.dbversion;
    },
    documentNumber: {
      get() {
        if (!this.model.number) {
          return this.invoiceNumber;
        }
        return this.model.number;
      },
      set(input) {
        console.log(this.model.number);
        this.model.number = input;
      },
    },
    dueDate() {
      if (this.model.dateDue) {
        return this.model.dateDue;
      }
      return moment(this.model.date).add(this.preferences.defaultTerms, 'days');
    },
    dueDateWouldBe() {
      return moment().add(this.preferences.defaultTerms, 'days').format('L');
    },
    expirationDate() {
      if (this.model.dateProposalExpires) {
        return this.model.dateProposalExpires;
      }
      return moment(this.model.dateProposal).add(this.preferences.defaultTermsProposal, 'days');
    },
    expirationDateWouldBe() {
      return moment().add(this.preferences.defaultTermsProposal, 'days').format('L');
    },
    items() {
      return this.$store.getters.allInvoices;
    },
    itemRefreshNeeded() {
      return this.$store.state.refreshNeeded;
    },
    notesToShowCustomer() {
      if (!this.hideSystemNotes) {
        return this.customerDetails.notes;
      }
      return this.customerDetails.notes.filter(note => !note.systemGenerated);
    },
    perPage() {
      return this.$store.state.preferences.perPage;
    },
    preferences() {
      if (this.model && this.model.preferences) {
        return {
          ...this.$store.state.preferences,
          ...this.customerPreferences,
          ...this.model.preferences,
        };
      }
      return {
        ...this.$store.state.preferences,
        ...this.customerPreferences,
      };
    },
    outstandingInvoicesAllSelected() {
      if (this.outstandingItems.length && this.outstandingItems.every(a => a.include)) {
        return true;
      }
      return false;
    },
    readyToRun() {
      return this.$store.state.initialLoad;
    },
    routeItemId() {
      if (this.$route.params.id) {
        return this.$route.params.id;
      }
      return false;
    },
    saveButtonText() {
      let buttonText = this.$t('invoice.save');
      buttonText += ' ';
      if (this.model.status === 'proposal') {
        if (this.preferences.defaultLabelProposal) {
          buttonText += this.preferences.defaultLabelProposal;
        } else {
          buttonText += this.$t('invoice.defaultProposal');
        }
      } else if (this.preferences.defaultLabelInvoice) {
        buttonText += this.preferences.defaultLabelInvoice;
      } else {
        buttonText += this.$t('invoice.defaultInvoice');
      }
      return buttonText;
    },
    screenTotal() {
      return `${this.$t('invoice.viewTotal')}: ${this.multiDocTotal(this.itemResults, 'display')}`;
    },
    screenPayments() {
      const payments = [];
      this.itemResults
        .filter(item => item.payments.length)
        .forEach(item => item.payments
          .forEach(payment => payments.push(payment)));
      return `${this.$t('invoice.viewPayments')}: ${this.displayTotalPayments(payments)}`;
    },
    screenProposed() {
      return `${this.$t('invoice.viewProposed')}: ${this.multiDocProposal(this.itemResults, 'display')}`;
    },
    screenDue() {
      return `${this.$t('invoice.outstanding')}: ${this.multiDocDue(this.itemResults, 'display')}`;
    },
    terms: {
      get() {
        return this.preferences.defaultTerms;
      },
      set(newVal) {
        if (newVal * 1 === 0) {
          this.preferences.defaultTerms = 0;
        } else {
          this.preferences.defaultTerms = newVal;
        }
        this.updateSettings('defaultTerms', this.preferences.defaultTerms);
      },
    },
    termsProposal: {
      get() {
        return this.preferences.defaultTermsProposal;
      },
      set(newVal) {
        if (newVal * 1 === 0) {
          this.preferences.defaultTermsProposal = 0;
        } else {
          this.preferences.defaultTermsProposal = newVal;
        }
        this.updateSettings('defaultTermsProposal', this.preferences.defaultTermsProposal);
      },
    },
    zipResults() {
      return this.$store.state.zipLookup;
    },
  },
  methods: {
    addCustomer() {
      const customerTemplate = {
        address: {
          street: '',
          city: null,
          state: null,
          zip: null,
        },
        addressForBilling: false,
        billingAddress: {
          street: null,
          city: null,
          state: null,
          zip: null,
        },
        contact: null,
        dateAdded: moment().format(),
        email: null,
        invoices: [],
        invoiceNumberPrefix: null,
        lastActive: moment().format(),
        name: null,
        notes: [],
        phone: null,
        preferences: {
          documentNumber: 1,
        },
        rates: [],
        recurringLineItems: [],
        status: this.$t('customer.added'),
        totalCharges: 0.00,
        totalPayments: 0.00,
      };
      customerTemplate.notes.push(this.noteAddSystemNote(this.$t('customer.added')));
      this.$store.dispatch('addCustomer', customerTemplate);
      // futher action is handled by customerHasBeenAdded watcher
    },
    addInvoice() {
      const newInvoice = {
        customer: null,
        date: new Date(),
        dateCreated: new Date(),
        dateDue: null,
        datePaid: null,
        dateSent: null,
        dateProposal: new Date(),
        dateProposalSent: null,
        dateProposalExpires: null,
        dateUpdated: new Date(),
        // TODO - implement dates like this
        // date: {
        //   created: null,
        //   due: null,
        //   paid: null,
        //   sent: null,
        //   proposal: {
        //     created: null,
        //     sent: null,
        //     expires: null,
        //   },
        // },
        deliveryMethod: {
          invoice: null,
          proposal: null,
        },
        flagged: false,
        footer: {
          enable: true,
          text: null,
        },
        header: {
          enable: true,
          text: null,
        },
        lineItems: [],
        linkedRecords: [],
        memo: {
          enable: this.preferences.enableMemo,
          text: this.preferences.defaultMemo,
          enableProposal: this.preferences.enableMemoProposal,
          textProposal: this.preferences.defaultMemoProposal,
        },
        notes: [],
        number: null,
        outstandingItems: [],
        payments: [],
        preferences: {
          taxOption: this.preferences.taxOption,
          taxRate: this.preferences.taxRate,
          statusAutoUpdate: this.preferences.statusAutoUpdate,
        },
        status: 'proposal',
        subheader: {
          enable: true,
          text: null,
        },
        tempCustomerData: null,
        title: null,
        type: {
          enable: true,
          text: null,
        },
        totalCharges: 0.00,
        totalPayments: 0.00,
        totalOutstanding: 0.00,
      };
      if (this.filterActive.customer) {
        this.$store.dispatch('customerById', this.filterActive.customer);
        newInvoice.customer = this.filterActive.customer;
        this.customerHasBeenSelected = true;
        this.customerDetailsActive = -1;
      }
      this.model = newInvoice;
      this.addingInvoice = true;
    },
    addInvoiceCancel() {
      this.customerDropdownClear();
      this.addInvoiceReset();
    },
    addInvoiceConfirm() {
      this.customerDetailsActive = -1;
      for (let a = 0; a < this.outstandingItems.length; a += 1) {
        if (this.outstandingItems[a].include) {
          const item = this.outstandingItems[a];
          const record = {
            _id: this.$guid(),
            date: this.dueDateCalc(item),
            originalId: item._id,
          };
          this.model.outstandingItems.push(record);
        }
      }
      for (let a = 0; a < this.customerDetails.recurringLineItems.length; a += 1) {
        this.model.lineItems.push(this.customerDetails.recurringLineItems[a]);
      }
      this.customerStatusUpdate(`${this.$t('customer.addedInvoice')} ${this.model.number}`);
      this.model.notes.push(this.noteAddSystemNote(this.$t('invoice.created')));
      if (!this.model.number) {
        this.model.number = this.invoiceNumber;
      }
      if (this.model.number === this.invoiceNumber) {
        if (!this.preferences.masterNumbering) {
          this.customerDetails.preferences.documentNumber = this.customerDetails.preferences.documentNumber * 1;
          this.customerDetails.preferences.documentNumber += 1;
          this.saveNow();
        } else {
          this.$store.state.preferences.documentNumber += 1;
          this.$store.dispatch('preferenceSet', this.$store.state.preferences);
        }
      }
      this.model.customer = this.customerDetails._id;
      this.$store.dispatch('addInvoice', this.model)
        .then(() => {
          console.log('resetting invoice');
          this.addInvoiceReset();
        });
    },
    addInvoiceReset() {
      this.model = null;
      this.addingInvoice = false;
      this.customerHasBeenSelected = false;
      this.customerSearchTerm = null;
      this.outstandingItems = [];
      this.outstandingShowList = false;
      this.filterCustomer(this.allCustomersLabel);
    },
    addInvoiceDuplicate(model) {
      this.addInvoiceReset();
      const notesToAdd = [];
      const duplicationReceipt = {
        _id: this.$guid(),
        date: moment().format(),
        text: `${model.type} ${this.$t('invoice.duplicationNote')} ${model.number}`,
        showToCustomer: false,
      };
      notesToAdd.push(duplicationReceipt);
      const duplicateInvoice = {
        customer: model.customer,
        date: new Date(),
        dateCreated: new Date(),
        dateDue: null,
        datePaid: null,
        dateSent: null,
        dateProposal: null,
        dateProposalSent: null,
        dateProposalExpires: null,
        dateUpdated: new Date(),
        deliveryMethod: {
          invoice: null,
          proposal: null,
        },
        flagged: model.flagged,
        footer: model.footer,
        header: model.header,
        lineItems: model.lineItems,
        linkedRecords: [],
        memo: model.memo,
        notes: notesToAdd,
        number: null,
        outstandingItems: [],
        payments: [],
        status: 'open',
        subheader: model.subheader,
        tempCustomerData: model.tempCustomerData ? model.tempCustomerData : null,
        title: model.title,
        type: model.type,
        totalCharges: this.updateTotalCharges(model.lineItems, model.taxRate ? model.taxRate : this.preferences.taxRate),
        totalPayments: 0.00,
        totalOutstanding: 0.00,
      };
      this.filterActive.customer = model.customer;
      this.customerHasBeenSelected = true;
      this.customerDetailsActive = -1;
      this.model = duplicateInvoice;
      this.addingInvoice = true;
    },
    addInvoiceSuccess() {
      this.newSort('Date');
    },
    addressSecondLine() {
      if (!this.addingSecondLine && !this.customerDetails.address.secondLine) {
        this.addingSecondLine = true;
      } else {
        this.addingSecondLine = false;
        if (this.customerDetails.address.secondLine) {
          this.customerDetails.address.secondLine = null;
          this.saveRequest();
        }
      }
    },
    addressSecondLineBilling() {
      if (!this.addingSecondLineBilling && !this.customerDetails.billingAddress.secondLine) {
        this.addingSecondLineBilling = true;
      } else {
        this.addingSecondLineBilling = false;
        if (this.customerDetails.billingAddress.secondLine) {
          this.customerDetails.billingAddress.secondLine = null;
          this.saveRequest();
        }
      }
    },
    billingAddressAdd() {
      this.customerDetails.addressForBilling = true;
    },
    billingAddressRemove() {
      this.customerDetails.billingAddress = {
        street: null,
        city: null,
        state: null,
        zip: null,
      };
      this.customerDetails.addressForBilling = false;
      this.saveRequest(this.$t('customer.update.billingAddressRemoved'));
    },
    changeDateDueReset() {
      this.model.dateDue = moment(this.model.date).add(this.preferences.defaultTerms, 'days').format();
      this.changeDateDue = null;
    },
    changeInvoiceDate() {
      this.model.date = moment(this.model.date).format().substring(0, 10);
      this.changeDate = true;
    },
    changeInvoiceDateDue() {
      if (this.model.dateDue) {
        this.model.dateDue = moment(this.model.dateDue).format().substring(0, 10);
      } else {
        this.model.dateDue = moment(this.model.date).add(this.preferences.defaultTerms, 'days').format().substring(0, 10);
      }
      this.changeDateDue = true;
    },
    changeProposalDate() {
      this.model.dateProposal = moment(this.model.dateProposal).format().substring(0, 10);
      this.changeDateProposal = true;
    },
    changeProposalDateExpires() {
      if (this.model.dateProposalExpires) {
        this.model.dateProposalExpires = moment(this.model.dateProposalExpires).format().substring(0, 10);
      } else {
        this.model.dateProposalExpires = moment(this.model.dateProposal).add(this.preferences.defaultTermsProposal, 'days').format().substring(0, 10);
      }
      this.changeDateExpires = true;
    },
    changePage(page) {
      this.currentPage = page;
    },
    changePageCustomer(page) {
      this.customersPage = page;
    },
    checkInvoiceDate() {
      if (!this.model.date) {
        this.model.date = moment().format();
      } else {
        this.model.date = moment(this.model.date);
      }
      this.changeDate = false;
    },
    checkProposalDate() {
      if (!this.model.dateProposal) {
        this.model.dateProposal = moment().format();
      } else {
        this.model.dateProposal = moment(this.model.dateProposal);
      }
      this.changeDateProposal = false;
    },
    checkInvoiceDateDue() {
      if (!this.model.dateDue) {
        this.model.dateDue = moment().add(this.preferences.defaultTerms, 'days');
      } else {
        this.model.dateDue = moment(this.model.dateDue).format();
      }
      this.changeDateDue = false;
    },
    checkProposalDateExpires() {
      if (!this.model.dateProposalExpires) {
        this.model.dateProposalExpires = moment().add(this.preferences.defaultTermsProposal, 'days');
      } else {
        this.model.dateProposalExpires = moment(this.model.dateProposalExpires).format();
      }
      this.changeDateExpires = false;
    },
    clearCustomerPrefs() {
      this.customerDetails.preferences = {};
      this.$nextTick(() => {
        this.customerDetails.preferences = {};
        this.saveNow(this.$t('customer.clearedPref'));
      });
      this.scrollToItem();
    },
    clearCustomerPrefItem({ preferences, prefs, receipt }) {
      this.customerDetails.preferences = preferences;
      this.$nextTick(() => {
        this.customerDetails.preferences = prefs; // committing only once causes a bug where some of the preferences re-appear. This resolves bug
        this.saveNow(receipt);
      });
      this.scrollToItem();
    },
    customDateFilterSet() {
      this.numberOfDaysInView = -1;
      this.customDateRange = true;
      this.$store.dispatch('itemRefresh');
    },
    customerDropdownAction(customer) {
      this.$store.dispatch('showItemClear');
      if (this.customerDetails._id
          && !this.customerDetails.name) {
        this.customerEmptyChecker(this.customerDetails);
      }
      if (this.addingInvoice) {
        this.selectCustomer(customer);
      } else {
        if (this.customerDetailsActive === -1) {
          this.customerDetailsActive = 0;
        }
        if (this.customerDetailsActive === 3) {
          this.$store.commit('CLOSE_PREFERENCES');
          setTimeout(() => {
            this.$store.commit('CLOSE_PREFERENCES_CLEAR');
          }, 5); // Prevents an issue where loading new customer preferences caused customer data to be auto-saved unnecessarily
        }
        this.filterCustomer(customer);
      }
      if (this.customerDetails._id) {
        this.$store.dispatch('customerById', this.customerDetails._id);
      }
    },
    customerDropdownClear() {
      if (this.customerDetails._id
          && !this.customerDetails.name) {
        this.customerEmptyChecker(this.customerDetails);
      }
      if (this.addingInvoice) {
        this.model.customer = null;
        this.customerHasBeenSelected = false;
      } else {
        this.filterCustomer(this.allCustomersLabel);
      }
      this.filterActive.customer = null;
      this.customerDetailsActive = -1;
    },
    customerEmptyChecker(cust) {
      console.log('checking');
      if (!cust.address.street
            && !cust.address.city
            && !cust.address.state
            && !cust.address.zip
            && !cust.addressForBilling
            && !cust.email
            && !cust.invoices.length
            && !cust.invoiceNumberPrefix
            && !cust.name
            && !cust.contact
            && !cust.phone
            && !cust.rates.length
            && !cust.recurringLineItems.length
            && !cust.totalCharges
            && !cust.totalPayments) {
        this.$store.dispatch('removeCustomer', cust._id);
      }
    },
    customerStatusUpdate(content) {
      this.customerDetails.lastActive = moment().format();
      this.customerDetails.status = content;
      const noteToAdd = this.noteAddSystemNote(content);
      if (this.customerDetails.notes.length) {
        const previousNote = this.customerDetails.notes[this.customerDetails.notes.length - 1];
        if (((noteToAdd.text.substring(0, (noteToAdd.text.length - 1)))
              || (noteToAdd.text === previousNote.text))
              && (moment().diff(moment(previousNote.date)) < 300000)) {
          this.customerDetails.notes.splice(this.customerDetails.notes.length - 1, 1);
        }
      }
      this.customerDetails.notes.push(noteToAdd);
    },
    deleteCustomerReq(id) {
      this.customerDeleteConfirm = id;
      this.customerDetailsActive = -1;
    },
    deleteCustomerCancel() {
      this.customerDeleteConfirm = null;
      this.customerDetailsActive = -1;
    },
    deleteCustomerConfirm(id) {
      this.customerDeleteConfirm = null;
      this.customerDetailsActive = -1;
      this.$store.dispatch('removeCustomer', id);
      this.filterCustomer(this.allCustomersLabel);
      this.addInvoiceReset();
    },
    filterByCustomer() {
      return this.$store.getters.invoicesByCustomer(this.filterActive.customer);
    },
    filterChange(type) {
      if (type === 'clearAll') {
        this.numberOfDaysInView = -1;
        this.customDateFilter = false;
        this.filterActive.flagged = false;
        this.filterCustomer(this.allCustomersLabel);
        this.filterChange('all');
        this.addInvoiceReset();
        this.$store.dispatch('showItemClear');
      }
      if (type === 'all') {
        this.filterActive.all = true;
        this.filterActive.open = false;
        this.filterActive.paid = false;
        this.filterActive.sent = false;
        this.filterActive.pastDue = false;
        this.showDaysPopper = false;
      } else {
        if (type === 'open') {
          this.filterActive.open = !this.filterActive.open;
        }
        if (type === 'proposal') {
          this.filterActive.proposal = !this.filterActive.proposal;
        }
        if (type === 'paid') {
          this.filterActive.paid = !this.filterActive.paid;
        }
        if (type === 'sent') {
          this.filterActive.sent = !this.filterActive.sent;
        }
        if (type === 'pastDue') {
          this.filterActive.pastDue = !this.filterActive.pastDue;
        }
        if (type === 'flagged') {
          this.filterActive.flagged = !this.filterActive.flagged;
        }
        if (type === 'dateUpdated') {
          console.log('filtering by date');
        }
        if (this.filterActive.open
            || this.filterActive.paid
            || this.filterActive.pastDue
            || this.filterActive.sent) {
          this.filterActive.all = false;
        } else {
          this.filterActive.all = true;
        }
      }
      this.$store.dispatch('itemRefresh');
    },
    filterCustomer(cust) {
      if (!cust || cust === this.allCustomersLabel) {
        this.filterActive.customer = null;
      } else {
        this.filterActive.customer = cust;
        this.$store.dispatch('showItemClear');
        this.customerDetailsActive = 0;
      }
      this.$store.dispatch('itemRefresh');
    },
    filterItems() {
      // triggered when itemRefresh() is called
      const filter = this.filterActive;
      let items = this.items;
      if (filter.customer) {
        items = this.filterByCustomer(items);
      }
      if (filter.flagged) {
        items = items.filter(item => item.flagged);
      }
      if (this.numberOfDaysInView > -1) {
        items = items.filter(item => moment(this.dateReturn(item)) > moment().subtract(this.numberOfDaysInView, 'day'));
      }
      if (this.customDateRange) {
        if (this.customDateFilterStart && this.customDateFilterEnd) {
          items = items.filter(item => moment(this.dateReturn(item)).isBetween(this.customDateFilterStart, this.customDateFilterEnd, 'day', []));
        } else if (this.customDateFilterStart) {
          items = items.filter(item => moment(this.dateReturn(item)).isSameOrAfter(this.customDateFilterStart, 'day'));
        } else if (this.customDateFilterEnd) {
          items = items.filter(item => moment(this.dateReturn(item)).isSameOrBefore(this.customDateFilterEnd, 'day'));
        }
      }
      if (!filter.all) {
        items = items.filter(item => filter[item.status]);
      }
      return items;
    },
    itemRefresh() {
      const itemsToSort = this.filterItems();
      let sortedList = itemsToSort;
      switch (this.sortBy) {
        case 'Document Number':
          sortedList = itemsToSort.sort((a, b) => ((a.number.toUpperCase() > b.number.toUpperCase()) ? 1 : -1)).reverse();
          break;
        case 'Customer Name':
          sortedList = itemsToSort.sort((a, b) => (
            (this.customerIdentifier(this.$store.getters.customer(a.customer)).toUpperCase()
              > this.customerIdentifier(this.$store.getters.customer(b.customer)).toUpperCase())
              ? 1 : -1)).reverse();
          break;
        case 'Total Charges':
          sortedList = itemsToSort.sort((a, b) => ((a.totalCharges > b.totalCharges) ? 1 : -1));
          break;
        case 'Balance Due':
          sortedList = itemsToSort.sort((a, b) => (
            (((a.totalCharges + this.totalOutstandingCalc(a)) - a.totalPayments) > ((b.totalCharges + this.totalOutstandingCalc(b)) - b.totalPayments)) ? 1 : -1));
          break;
        case 'Date':
          sortedList = itemsToSort.sort((a, b) => (this.dateReturn(a) > this.dateReturn(b) ? 1 : -1));
          break;
        default:
          sortedList = itemsToSort;
          break;
      }
      if (this.sortDirection) {
        this.itemResults = sortedList.reverse();
      } else {
        this.itemResults = sortedList;
      }
      this.scrollToItem();
    },
    includeOutstandingInvoice(key) {
      for (let a = 0; a < this.outstandingItems.length; a += 1) {
        if (this.outstandingItems[a]._id === key) {
          this.outstandingItems[a].include = !this.outstandingItems[a].include;
        }
      }
    },
    newSort(type) {
      this.$store.dispatch('preferenceSetSort', type);
      this.$store.dispatch('itemRefresh');
    },
    noteAdd() {
      const newNoteId = this.$guid();
      this.customerDetails.notes.push({
        _id: newNoteId,
        date: moment().format(),
        text: null,
        showToCustomer: false,
        systemGenerated: false,
      });
      this.noteEditText = newNoteId;
    },
    noteAddSystemNote(content) {
      return {
        _id: this.$guid(),
        date: moment().format(),
        text: content,
        showToCustomer: false,
        systemGenerated: true,
      };
    },
    noteDateChoose({ date, _id }) {
      this.noteEditDate = null;
      if (!date) {
        for (let a = 0; a < this.customerDetails.notes.length; a += 1) {
          if (this.customerDetails.notes[a]._id === _id) {
            this.customerDetails.notes[a].date = moment();
          }
        }
      }
      this.saveRequest();
    },
    noteDeleteCancel() {
      this.noteDeleteConfirm = null;
    },
    noteDeleteConfirmed(id) {
      const i = this.customerDetails.notes.findIndex(note => note._id === id);
      this.customerDetails.notes.splice(i, 1);
      this.noteDeleteConfirm = null;
      this.saveRequest();
    },
    noteDeleteRequest(id) {
      this.noteDeleteConfirm = id;
    },
    noteEditDateRequest(id) {
      this.noteEditDate = id;
    },
    noteEditTextRequest(noteId) {
      this.noteEditText = noteId;
    },
    outstandingIncludeAll() {
      if (!this.outstandingItems.length) {
        this.outstandingItemsGeneration();
      }
      // eslint-disable-next-line no-param-reassign
      this.outstandingItems.forEach((item) => { item.include = true; });
    },
    outstandingIncludeClear() {
      // eslint-disable-next-line no-param-reassign
      this.outstandingItems.forEach((item) => { item.include = false; });
    },
    outstandingItemsGeneration() {
      const invoices = [];
      this.customerDetails.invoices.forEach((inv) => {
        invoices.push(this.$store.getters.invoice(inv));
      });
      invoices
        .filter(invoice => invoice.totalCharges - invoice.totalPayments > 0)
        .forEach(invoice => this.outstandingItems.push({ ...invoice, include: false }));
    },
    outstandingListToggle() {
      if (!this.outstandingItems.length) {
        this.outstandingItemsGeneration();
      }
      this.outstandingShowList = !this.outstandingShowList;
    },
    recurringItemAdd() {
      this.customerDetails.recurringLineItems.push({
        _id: this.$guid(),
        added: moment(),
        qty: 1,
        item: '',
        price: 0,
        recur: true,
        taxable: false,
      });
      this.saveRequest(this.$t('customer.update.addRecurringItem'));
    },
    recurringItemRemConfirm(i) {
      this.customerDetails.recurringLineItems.splice(i, 1);
      this.saveRequest(this.$t('customer.update.removedRecurringItem'));
    },
    recurringItemRemRequest(i) {
      this.deleteRecurringItemRequest = this.customerDetails.recurringLineItems[i]._id;
    },
    saveNow(updateType) {
      this.$store.commit('AUTOSAVE_COMPLETE');
      if (updateType) {
        this.customerStatusUpdate(updateType);
      }
      if (this.autoSaveTimer) {
        this.autoSaveCancel = true;
      }
      this.$store.dispatch('updateCustomer', this.customerDetails);
    },
    savePreferences(newPref) {
      this.customerDetails.preferences = {
        ...this.customerDetails.preferences,
        ...newPref,
      };
      this.saveRequest(this.$t('customer.update.preferences'));
    },
    saveRequest(updateType) {
      this.$store.commit('AUTOSAVE_REQUEST');
      if (updateType) {
        this.customerStatusUpdate(updateType);
      }
      this.lastChangedBefore = this.lastChanged;
      this.lastChanged = moment();
    },
    scrollToItem() {
      setTimeout(() => {
        const activeItem = document.getElementById(`scroller-${this.$store.state.showItem}`);
        let scrollDestination = document.getElementById('topOfPage');
        if (activeItem) {
          const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
          const dimensions = activeItem.getBoundingClientRect();
          const topOfEl = dimensions.top + window.pageYOffset;
          scrollDestination = topOfEl - (windowHeight / 6);
        }
        window.scrollTo({ left: 0, top: scrollDestination, behavior: 'smooth' });
      }, 400);
    },
    selectCustomer(customer) {
      this.outstandingItems = [];
      this.outstandingShowList = false;
      this.customerHasBeenSelected = true;
      this.filterActive.customer = customer;
      this.model.customer = customer;
    },
    setNumberOfDaysInView(count) {
      this.customDateRange = false;
      this.numberOfDaysInView = count;
      this.filterChange('dateUpdated');
      this.itemRefresh();
    },
    showDayOptions() {
      this.showDaysPopper = !this.showDaysPopper;
    },
    sortDirectionChange() {
      this.sortDirection = !this.sortDirection;
      this.$store.dispatch('itemRefresh');
    },
    updateSettings(pref, value) {
      const prefObj = {};
      prefObj[pref] = value;
      this.model.preferences = { ...this.model.preferences, ...prefObj };
    },
    updateTextInputRecurring(input) {
      this.customerDetails.recurringLineItems[this.activeRecurringInput].item = input;
      this.saveRequest();
    },
    zipLookup(zip, destination) {
      if (!this.preferences.zipAutofill) {
        this.saveRequest();
        return;
      }
      if (zip.length === 5) {
        const lookupId = this.$guid();
        const lookup = {
          id: lookupId,
          zip,
        };
        this.zipWatcher.id = lookupId;
        this.zipWatcher.type = destination;
        this.$store.dispatch('zipLookup', lookup);
      } else {
        this.zipWatcher.id = null;
        this.zipWatcher.type = null;
        this.$store.dispatch('zipLookupClear');
      }
      this.saveRequest();
    },
  },
  watch: {
    dbversion(newVal, oldVal) {
      if (newVal !== oldVal) {
        console.log('DATABASE VERSION MISMATCH');
      }
    },
    customerHasBeenAdded(newVal) {
      if (newVal) {
        this.filterCustomer(newVal);
        this.customerDetailsActive = 1;
        if (this.addingInvoice) {
          this.customerHasBeenSelected = true;
          this.model.customer = newVal;
        }
      }
    },
    invoiceNumber(newVal) {
      console.log(newVal);
    },
    itemRefreshNeeded(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.itemRefresh();
        this.$store.dispatch('itemRefreshDone');
      }
    },
    'zipResults.id': {
      handler(newVal) {
        function titleCase(input) {
          let city = input.toLowerCase();
          city = city.split(' ');
          for (let a = 0; a < city.length; a += 1) {
            city[a] = `${city[a].charAt(0).toUpperCase()}${city[a].slice(1)}`;
          }
          return city.join(' ');
        }
        console.log('zip lookup success');
        if (newVal && newVal !== 'err'
        && (this.zipWatcher.type === 'primary'
        || this.zipWatcher.type === 'billing')) {
          if (newVal === this.zipWatcher.id) {
            if (this.zipWatcher.type === 'primary' && this.zipResults.city) {
              this.customerDetails.address.city = titleCase(this.zipResults.city);
              this.customerDetails.address.state = this.zipResults.state;
            }
            if (this.zipWatcher.type === 'billing' && this.zipResults.city) {
              this.customerDetails.billingAddress.city = titleCase(this.zipResults.city);
              this.customerDetails.billingAddress.state = this.zipResults.state;
            }
            this.zipWatcher = {
              id: null,
              type: null,
            };
            this.$store.dispatch('zipLookupClear');
            this.saveRequest();
          }
        }
        if (newVal === 'err'
        && (this.zipWatcher.type === 'primary'
        || this.zipWatcher.type === 'billing')) {
          if (this.zipWatcher.type === 'primary') {
            if (this.customerDetails.address.city) {
              this.customerDetails.address.city = null;
            }
            if (this.customerDetails.address.state) {
              this.customerDetails.address.state = null;
            }
          }
          if (this.zipWatcher.type === 'billing') {
            if (this.customerDetails.billingAddress.city) {
              this.customerDetails.billingAddress.city = null;
            }
            if (this.customerDetails.billingAddress.state) {
              this.customerDetails.billingAddress.state = null;
            }
          }
          this.zipWatcher = {
            id: null,
            type: null,
          };
          this.$store.dispatch('zipLookupClear');
          this.saveRequest();
        }
      },
    },
  },
};
</script>
