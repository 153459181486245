<template>
  <div>
    <b-btn
        id="tableFieldsButton"
        variant="secondary"
        v-b-modal.tableOptionsModal>
      {{$t('records.tableOptions')}}
    </b-btn>
    <b-modal
        id="tableOptionsModal"
        :title="$t('records.tableOptions')"
        centered
        size="sm"
        ok-only>
      <b-row
          align-h="center"
          class="pb-2"
          style="border-bottom: 1px solid lightgrey">
        <b-col
            align="center">
          <b-input-group>
            <b-input-group-prepend
                class="ml-auto mr-3 my-auto"
                v-text="$t('collections.quickEntry')">
            </b-input-group-prepend>
            <b-checkbox
                switch
                v-model="quickEntry"
                @change="saveRequest()"
                size="lg"
                class="mr-1"
            />
            <b-input-group-append
                id="quickModeTooltips"
                class="mr-auto mt-1">
              <b-icon-question-circle-fill />
            </b-input-group-append>
          </b-input-group>
          <b-tooltip
              target="quickModeTooltips">
            <h5
                v-text="$t('tooltips.quickEntry')"
            />
            <span
                v-text="$t('tooltips.quickEntrySave')"
            />
            <p class="my-2" />
            <span
                v-text="$t('tooltips.quickEntrySave2')"
            />
          </b-tooltip>
        </b-col>
      </b-row>
      <b-row
          class="py-2"
          align-h="center"
          style="border-bottom: 1px solid lightgrey">
        <b-col
            align="center"
            class="my-auto">
          <b-input-group
              class="m-auto"
              style="max-width: 180px;">
            <b-input-group-prepend
                class="my-auto mr-2 ml-auto">
              <span
                  v-text="$t('records.options.perPage')"
              />
            </b-input-group-prepend>
            <b-form-input
                type="number"
                v-model.number="$store.state.preferences.perPage"
                @input="saveRequest"
                class="form__input smallish my-auto mr-auto">
            </b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <transition
          mode="out-in"
          name="fade">
        <b-row
            v-if="perPage > 50"
            align-h="center"
            class="mb-3"
            style="background-color: lightgrey; padding: 10px;">
          <b-col
              align="center">
            <small
                v-text="$t('records.options.perPageWarning')"
            />
          </b-col>
        </b-row>
      </transition>
      <b-row
          class="mt-3">
        <b-col
            align="center">
          <label
              :for="`${collection._id}-idType`"
              v-text="$t('collections.types._id.label')"
              class="ml-auto"
          />
          <b-icon-question-circle-fill
              id="idMethodTooltip"
          />
          <b-tooltip
              target="idMethodTooltip">
            <h5
                v-text="$t('tooltips.idMethodHeader')"
            />
            <span
                v-text="$t('tooltips.idMethod')"
            />
            <div
                v-if="collection.idType === 'guid'">
              <h6
                  v-text="$t('collections.types._id.guid')"
                  class="mt-4"
              />
              <span
                  v-text="$t('tooltips.guid')"
              />
            </div>
            <div
                v-if="collection.idType === 'humanId'">
              <h6
                  v-text="$t('collections.types._id.human')"
                  class="mt-4"
              />
              <span
                  v-text="$t('tooltips.humanId')"
              />
            </div>
            <div
                v-if="collection.idType === 'noId'">
              <h6
                  v-text="$t('collections.types._id.noId')"
                  class="mt-4"
              />
              <span
                  v-text="$t('tooltips.noId')"
              />
            </div>
          </b-tooltip>
          <b-form-select
              :id="`${collection._id}-idType`"
              v-model="collection.idType"
              class="medium"
              @change="saveRequest()"
              :options="[{ text: $t('collections.types._id.noId'), value: 'noId' },
                { text: $t('collections.types._id.human'), value: 'humanId' },
                { text: $t('collections.types._id.guid'), value: 'guid' }]"
          />
          <transition
              mode="out-in"
              name="fade">
            <div
                v-if="collection.idType === 'humanId'"
                class="pt-2 ml-4">
              <b-input-group>
                <b-input-group-prepend
                    v-text="$t('collections.idCounterPrefix')"
                    class="mr-2 ml-auto my-auto"
                />
                <b-form-input
                    :id="`${collection._id}-prefix`"
                    v-model="collection.humanIdPrefix"
                    type="text"
                    :placeholder="$t('optional')"
                    class="form__input smallInput mr-auto"
                    @input="saveRequest()"
                />
              </b-input-group>
            </div>
          </transition>
        </b-col>
      </b-row>
      <b-row
          class="mt-3"
          style="background-color: lightgrey">
        <b-col>
          <h5
              v-text="$t('records.options.fields')"
              class="my-auto"
          />
        </b-col>
        <b-col>
          <span
              v-text="$t('records.options.display')"
              style="cursor:pointer"
              @click="displayHeaderClick()"
              class="ml-auto mr-2 my-auto"
          />
          <span
              v-text="$t('records.options.download')"
              style="cursor:pointer"
              @click="downloadHeaderClick()"
              class="ml-2 mr-0 my-auto"
          />
        </b-col>
      </b-row>
      <transition
          mode="out-in"
          name="fade">
        <b-row
            v-if="collection.idType !== 'noId'"
            style="background-color: whitesmoke">
          <b-col
              class="ml-2">
            {{$t('recordNumber')}}
          </b-col>
          <b-col
              align="center">
            <b-input-group>
              <b-form-checkbox
                  v-model="collection.idDisplay"
                  class="ml-3 mr-5"
                  @input="saveRequest()"
              />
              <b-form-checkbox
                  v-model="collection.idDownload"
                  class="mx-2"
                  @input="saveRequest()"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </transition>
      <b-row
          id="dateAddedOptions">
        <b-col
            class="ml-2">
            {{$t('records.options.dateAdded')}}
        </b-col>
        <b-col>
          <b-input-group>
            <b-form-checkbox
                v-model="collection.dateAddedDisplay"
                class="ml-3 mr-5"
                @input="saveRequest()"
            />
            <b-form-checkbox
                v-model="collection.dateAddedDownload"
                class="mx-2"
                @input="saveRequest()"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row
          id="dateUpdatedOptions">
        <b-col
            class="ml-2">
            {{$t('records.options.dateUpdated')}}
        </b-col>
        <b-col>
          <b-input-group>
            <b-form-checkbox
                v-model="collection.dateUpdatedDisplay"
                class="ml-3 mr-5"
                @input="saveRequest()"
            />
            <b-form-checkbox
                v-model="collection.dateUpdatedDownload"
                class="mx-2"
                @input="saveRequest()"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row
          v-for="(field, index) in fields"
          :key="field[0]"
          :style="index % 2 !== 0 ? 'background-color: whitesmoke' : ''">
        <b-col
            class="ml-2">
          {{field[1].label}}
        </b-col>
        <b-col>
          <b-input-group>
            <b-form-checkbox
                v-model="field[1].display"
                class="ml-3 mr-5"
                @input="saveRequest()"
            />
            <b-form-checkbox
                v-model="field[1].download"
                class="mx-2"
                @input="saveRequest()"
            />
            <b-form-select
                class="my-0"
                v-if="field[1].metaType === 'Address'"
                v-model="field[1].displayType"
                :options="addressDisplayTypeOptions"
            />
          </b-input-group>
          <transition
              mode="out-in"
              name="fadeQuick">
            <b-row
                v-if="(field[1].display || field[1].download) && field[1].metaType === 'Notes'">
              <b-col
                  cols="auto">
                <b-form-input
                    v-model.number="field[1].notesToShow"
                    :id="`${field[0]}-notesToShow`"
                    type="number"
                    class="form__input smallInput"
                    :step="1"
                    @change="saveRequest()"
                />
                <b-form-select
                    v-if="field[1].display"
                    :id="`${field[0]}-notesPreviewLength`"
                    v-model="field[1].notePreviewLength"
                    :options="notePreviewLengthOptions"
                    class="form__input smallish"
                    @change="saveRequest()"
                />
              </b-col>
            </b-row>
          </transition>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import totalCalculators from '@/mixins/totalCalculators';

export default {
  props: [
    'collection',
  ],
  data() {
    return {
      addressDisplayTypeOptions: [
        { value: 'whole', text: this.$t('records.addressDisplayOptions[0]') },
        { value: 'first', text: this.$t('records.addressDisplayOptions[1]') },
        { value: 'cityState', text: this.$t('records.addressDisplayOptions[2]') },
        { value: 'state', text: this.$t('records.addressDisplayOptions[3]') },
        { value: 'zip', text: this.$t('records.addressDisplayOptions[4]') },
        { value: 'country', text: this.$t('records.addressDisplayOptions[5]') },
        { value: 'cityCountry', text: this.$t('records.addressDisplayOptions[6]') },
      ],
    };
  },
  mixins: [totalCalculators],
  computed: {
    fields: {
      get() {
        return this.$store.getters.fields(this.$route.params.collection);
      },
      set(newVal) {
        // TODO - Add a user-specific download/display preference
        console.log(newVal);
      },
    },
    idTypes() {
      return [
        { text: this.$t('collections.types._id.human'), value: 'humanId' },
        { text: this.$t('collections.types._id.guid'), value: 'guid' },
        { text: this.$t('collections.types._id.noId'), value: 'noId' },
      ];
    },
    notePreviewLengthOptions() {
      return [
        { text: this.$t('collections.notePreviewOptions.sm'), value: 'sm' },
        { text: this.$t('collections.notePreviewOptions.md'), value: 'md' },
        { text: this.$t('collections.notePreviewOptions.lg'), value: 'lg' },
      ];
    },
    perPage() {
      return this.$store.state.preferences.perPage;
    },
    quickEntry: {
      get() {
        return this.collection.quickEntry;
      },
      set(newVal) {
        this.collection.quickEntry = newVal;
      },
    },
  },
  methods: {
    displayHeaderClick() {
      let clearAll;
      let count = 0;
      this.fields.forEach((i) => {
        if (i[1].display) {
          count += 1;
        }
      });
      if (count !== this.fields.length) {
        clearAll = true;
      } else {
        clearAll = false;
      }
      for (let a = 0; a < this.fields.length; a += 1) {
        this.fields[a][1].display = clearAll;
      }
      this.saveRequest();
    },
    downloadHeaderClick() {
      let clearAll;
      let count = 0;
      this.fields.forEach((i) => {
        if (i[1].download) {
          count += 1;
        }
      });
      if (count !== this.fields.length) {
        clearAll = true;
      } else {
        clearAll = false;
      }
      for (let a = 0; a < this.fields.length; a += 1) {
        this.fields[a][1].download = clearAll;
      }
      this.saveRequest();
    },
    saveNow() {
      this.$store.commit('AUTOSAVE_COMPLETE');
      this.$store.dispatch('updateCollection', this.$route.params.collection);
    },
  },
};
</script>
