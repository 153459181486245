<template>
  <b-dropdown
      id="customerSelect"
      ref="customerSelect"
      class="invoice__table__customerDropdown"
      :variant="buttonVariant"
      :no-caret="$store.state.showSpinner ? true : false">
    <template
        v-slot:button-content>
      <span
          key="filterLabel"
          v-if="!$store.state.showSpinner"
          v-text="customerFilterLabel"
      />
      <loader
          v-else
          key="loader"
          color="white"
          :h="22"
          :w="220"
          type="dots"
          :fullPage="false"
      />
    </template>
    <b-dropdown-item-button
        id="allCustomersOption"
        v-if="filterActive.customer"
        @click.stop="clearFilter()">
      <b>
        {{$t('invoice.filter.allCustomersOption')}}
      </b>
    </b-dropdown-item-button>
    <b-dropdown-divider
        v-if="filterActive.customer"
    />
    <b-dropdown-item-button
        :id="addNewCustomer"
        @click.stop="addNewCustomer()">
      <b>
        <icon
            icon="plus"
        />
        {{$t('adminCustomerAdd')}}
      </b>
    </b-dropdown-item-button>
    <b-dropdown-divider />
    <b-dropdown-form
        class="invoice__table__customerDropdown__search__container">
      <b-form-input
          id="customerSearchTerm"
          :aria-label="$t('aria.customerSearch')"
          :placeholder="$t('search')"
          v-model="customerSearchTerm"
          class="invoice__table__customerDropdown__search"
      />
    </b-dropdown-form>
    <b-dropdown-divider />
    <div
        v-if="customerSearchTerm">
      <b-dropdown-text
          v-if="!customersToShow.length"
          class="invoice__table__customerDropdown__listLabel__container">
        <span
            v-text="$t('invoice.search.resultsEmpty')"
            class="invoice__table__customerDropdown__listLabel__active"
        />
        <span
            v-text="$t('invoice.search.clear')"
            class="invoice__table__customerDropdown__listLabel"
            @click.stop="customerSearchTerm = null"
        />
      </b-dropdown-text>
      <b-dropdown-text
          v-if="customersToShow.length > 0
                && !customerSearchResultsContact.length
                && !customerSearchResultsOther.length"
          class="invoice__table__customerDropdown__listLabel__container">
        <span
            v-text="$t('invoice.search.results')"
            class="invoice__table__customerDropdown__listLabel__active"
        />
        <span
            v-text="$t('invoice.search.clear')"
            class="invoice__table__customerDropdown__listLabel"
            @click.stop="customerSearchTerm = null"
        />
      </b-dropdown-text>
      <b-dropdown-text
          v-if="customersToShow.length > 0
                && (customerSearchResultsContact.length !== 0
                || customerSearchResultsOther.length !== 0)"
          class="invoice__table__customerDropdown__listLabel__container">
        <span
            v-text="$t('invoice.search.resultsMain')"
            class="invoice__table__customerDropdown__listLabel__active"
        />
        <span
            v-text="$t('invoice.search.clear')"
            class="invoice__table__customerDropdown__listLabel"
            @click.stop="customerSearchTerm = null"
        />
      </b-dropdown-text>
    </div>
    <b-dropdown-text
        v-else
        class="invoice__table__customerDropdown__listLabel__container">
      <span
          v-text="showListRecentCustomers ? $t('invoice.search.recentSelected') : $t('invoice.search.recent')"
          :class="showListRecentCustomers ? 'invoice__table__customerDropdown__listLabel__active' : 'invoice__table__customerDropdown__listLabel'"
          @click="changeCustomerList('recent')"
      />
      /
      <span
          v-text="showListTopCustomers ? $t('invoice.search.topSelected') : $t('invoice.search.top')"
          :class="showListTopCustomers ? 'invoice__table__customerDropdown__listLabel__active' : 'invoice__table__customerDropdown__listLabel'"
          @click="changeCustomerList('top')"
      />
      /
      <span
          v-text="showListAllCustomers ? $t('invoice.search.allSelected') : $t('invoice.search.all')"
          :class="showListAllCustomers ? 'invoice__table__customerDropdown__listLabel__active' : 'invoice__table__customerDropdown__listLabel'"
          @click="changeCustomerList('all')"
      />
    </b-dropdown-text>
    <b-tabs
        v-if="customersToShow.length > 10"
        id="customerSearchResultsPaginationContainer"
        bottom
        pills>
      <b-tab
          v-for="(i, index) in Math.ceil(customersToShow.length / 10)"
          :key="index"
          class="invoice__table__customerDropdown__search__pagination__container"
          :title="`${index + 1}`">
        <transition
            v-for="customer in customersToShow.slice((i - 1) * 10, i * 10)"
            :key="customer._id"
            name="fadeQuick"
            mode="out-in">
          <b-dropdown-item-button
              :id="`customer-${customer._id}`"
              :key="customer._id"
              @click.stop="filterCustomer(customer._id)">
            {{customerIdentifier(customer.name)}}
          </b-dropdown-item-button>
        </transition>
      </b-tab>
    </b-tabs>
    <transition
        v-else
        v-for="customer in customersToShow"
        :key="customer._id"
        name="fadeQuick"
        mode="out-in">
      <b-dropdown-item-button
          :id="`customer-${customer._id}`"
          :key="customer._id"
          @click.stop="filterCustomer(customer._id)">
        {{customerIdentifier(customer)}}
      </b-dropdown-item-button>
    </transition>
    <b-dropdown-text
        id="otherResultsLabel"
        v-if="customersToShow.length > 0
              && customerSearchResultsOther.length !== 0"
        class="invoice__table__customerDropdown__listLabel__container">
      <span
          v-text="$t('invoice.search.resultsOther')"
          class="invoice__table__customerDropdown__listLabel__active"
      />
    </b-dropdown-text>
    <b-tabs
        v-if="customerSearchResultsOther.length > 10"
        id="customerSearchResultsOtherPaginationContainer"
        bottom
        pills>
      <b-tab
          v-for="(i, index) in Math.ceil(customerSearchResultsOther.length / 10)"
          :key="index"
          class="invoice__table__customerDropdown__search__pagination__container"
          :title="`${index + 1}`">
        <transition
            v-for="customer in customerSearchResultsOther.slice((i - 1) * 10, i * 10)"
            :key="`customerSearchResults-${customer._id}`"
            name="fadeQuick"
            mode="out-in">
          <b-dropdown-item-button
              :id="`customer-${customer._id}`"
              :key="customer._id"
              @click.stop="filterCustomer(customer._id)">
            {{customerIdentifier(customer.name)}}
          </b-dropdown-item-button>
        </transition>
      </b-tab>
    </b-tabs>
    <transition
        v-else
        id="otherSearchResults"
        v-for="customer in customerSearchResultsOther"
        :key="`otherResult-${customer._id}`"
        name="fadeQuick"
        mode="out-in">
      <b-dropdown-item-button
          :id="`customer-${customer._id}`"
          :key="customer._id"
          @click.stop="filterCustomer(customer._id)">
        {{customerIdentifier(customer.name)}}
      </b-dropdown-item-button>
    </transition>
  </b-dropdown>
</template>

<script>
/* eslint-disable no-continue */
import totalCalculators from '@/mixins/totalCalculators';

export default {
  name: 'CustomerDropdown',
  mixins: [
    totalCalculators,
  ],
  props: [
    'buttonVariant',
    'customers',
    'customerFilterLabel',
    'filterActive',
  ],
  data() {
    return {
      customerSearchTerm: null,
      customerSearchResultsContact: [],
      customerSearchResultsOther: [],
      showListAllCustomers: false,
      showListRecentCustomers: true,
      showListTopCustomers: false,
    };
  },
  computed: {
    customersToShow() {
      if (!this.customers.length) { return []; }
      const customersToProcess = this.customers;
      let customerSearchResults = [];
      let customerSearchResultsContact = [];
      let customerSearchResultsOther = [];
      this.clearResults();
      if (this.customerSearchTerm) {
        for (let a = 0; a < customersToProcess.length; a += 1) {
          if (customersToProcess[a].name) {
            if (customersToProcess[a].name.toLowerCase().includes(this.customerSearchTerm.toLowerCase())) {
              customerSearchResults.push(customersToProcess[a]);
              continue;
            }
          }
          if (customersToProcess[a].contact) {
            if (customersToProcess[a].contact.toLowerCase().includes(this.customerSearchTerm.toLowerCase())) {
              customerSearchResultsContact.push(customersToProcess[a]);
              continue;
            }
          }
          if (customersToProcess[a].invoiceNumberPrefix) {
            if (customersToProcess[a].invoiceNumberPrefix.toLowerCase().includes(this.customerSearchTerm.toLowerCase())) {
              customerSearchResultsContact.push(customersToProcess[a]);
              continue;
            }
          }
          if (customersToProcess[a].invoices.length) {
            for (let z = 0; z < customersToProcess[a].invoices.length; z += 1) {
              if (this.$store.getters.invoice(customersToProcess[a].invoices[z]).number.toLowerCase().includes(this.customerSearchTerm.toLowerCase())) {
                customerSearchResults.push(customersToProcess[a]);
                continue;
              }
            }
          }
          if (customersToProcess[a].address.street) {
            if (customersToProcess[a].address.street.toLowerCase().includes(this.customerSearchTerm.toLowerCase())) {
              customerSearchResultsContact.push(customersToProcess[a]);
              continue;
            }
          }
          if (customersToProcess[a].address.city) {
            if (customersToProcess[a].address.city.toLowerCase().includes(this.customerSearchTerm.toLowerCase())) {
              customerSearchResultsContact.push(customersToProcess[a]);
              continue;
            }
          }
          if (customersToProcess[a].address.zip) {
            if (customersToProcess[a].address.zip.toLowerCase().includes(this.customerSearchTerm.toLowerCase())) {
              customerSearchResultsContact.push(customersToProcess[a]);
              continue;
            }
          }
          if (customersToProcess[a].addressForBilling) {
            if (customersToProcess[a].billingAddress.street) {
              if (customersToProcess[a].billingAddress.street.toLowerCase().includes(this.customerSearchTerm.toLowerCase())) {
                customerSearchResultsContact.push(customersToProcess[a]);
                continue;
              }
            }
            if (customersToProcess[a].billingAddress.city) {
              if (customersToProcess[a].billingAddress.city.toLowerCase().includes(this.customerSearchTerm.toLowerCase())) {
                customerSearchResultsContact.push(customersToProcess[a]);
                continue;
              }
            }
            if (customersToProcess[a].billingAddress.zip) {
              if (customersToProcess[a].billingAddress.zip.toLowerCase().includes(this.customerSearchTerm.toLowerCase())) {
                customerSearchResultsContact.push(customersToProcess[a]);
                continue;
              }
            }
          }
          if (customersToProcess[a].recurringLineItems.length > 0) {
            let match = false;
            for (let z = 0; z < customersToProcess[a].recurringLineItems.length; z += 1) {
              if (customersToProcess[a].recurringLineItems[z].item
                  && customersToProcess[a].recurringLineItems[z].item.toLowerCase().includes(this.customerSearchTerm.toLowerCase())) {
                match = true;
                customerSearchResultsOther.push(customersToProcess[a]);
                break;
              }
            }
            if (match) {
              continue;
            }
          }
          if (customersToProcess[a].notes.length > 0) {
            let match = false;
            for (let z = 0; z < customersToProcess[a].notes.length; z += 1) {
              if (customersToProcess[a].notes[z].text && customersToProcess[a].notes[z].text.toLowerCase().includes(this.customerSearchTerm.toLowerCase())) {
                match = true;
                customerSearchResultsOther.push(customersToProcess[a]);
                break;
              }
            }
            if (match) {
              continue;
            }
          }
        }
      } else if (this.showListRecentCustomers) {
        customersToProcess.sort((a, b) => ((a.lastActive < b.lastActive) ? 1 : -1));
        for (let a = 0; a < 7; a += 1) {
          if (customersToProcess[a]) {
            customerSearchResults.push(customersToProcess[a]);
          }
        }
      } else if (this.showListAllCustomers) {
        customerSearchResults = customersToProcess.sort((a, b) => ((a.name > b.name) ? 1 : -1));
      } else if (this.showListTopCustomers) {
        customerSearchResults = customersToProcess.sort((a, b) => ((a.totalCharges > b.totalCharges) ? 1 : -1));
      }
      if (!customerSearchResults.length && customerSearchResultsContact.length) {
        customerSearchResults = customerSearchResultsContact;
        customerSearchResultsContact = [];
      }
      if (!customerSearchResults.length && customerSearchResultsOther.length) {
        customerSearchResults = customerSearchResultsOther;
        customerSearchResultsOther = [];
      }
      if (customerSearchResultsContact.length) {
        customerSearchResultsOther = customerSearchResultsOther.concat(customerSearchResultsContact);
      }
      if (customerSearchResultsOther.length) {
        this.populateOtherResults(customerSearchResultsOther);
      }
      return customerSearchResults;
    },
  },
  methods: {
    addNewCustomer() {
      this.$emit('new-customer');
    },
    changeCustomerList(view) {
      this.showListAllCustomers = false;
      this.showListRecentCustomers = false;
      this.showListTopCustomers = false;
      if (view === 'all') {
        this.showListAllCustomers = true;
      }
      if (view === 'recent') {
        this.showListRecentCustomers = true;
      }
      if (view === 'top') {
        this.showListTopCustomers = true;
      }
    },
    clearFilter() {
      this.$emit('clear-filter');
    },
    clearResults() {
      this.customerSearchResultsContact = [];
      this.customerSearchResultsOther = [];
    },
    filterCustomer(customerId) {
      this.$emit('filter-customer', customerId);
    },
    populateOtherResults(results) {
      this.customerSearchResultsOther = results;
    },
  },
};
</script>
