<template>
  <span
      class="dataViewer__data"
      v-text="model"
  />
</template>
<script>
export default {
  props: ['model'],
};
</script>
