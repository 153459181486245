<template>
  <div>
    <transition
        name="fadeQuick"
        mode="out-in">
      <div
            v-if="readyToRun && tableIsVisible && !requestDeleteMultiple">
        <b-row
            id="dataRefinementOptions"
            key="defaultHeader"
            align-h="center"
            class="record__container__header">
          <b-col
              cols="auto"
              v-show="!hideButtons">
            <b-btn
                id="showHideFiltersPane"
                :variant="filtersShow ? 'warning' : 'secondary'"
                @click="filterButton">
              <b-icon-x-circle
                  v-if="(filters.length || filtersShow)"
              />
              <span
                  v-text="(filters.length || filtersShow) ? $t('filter.clear') : $t('filter.add')"
              />
            </b-btn>
          </b-col>
          <b-col
              cols="auto"
              v-if="!hideButtons">
            <b-btn-group>
              <b-btn
                  id="searchButtonTop"
                  @click="searchButton">
                <b-icon-search
                    class="font-weight-bold"
                />
                <span
                    v-if="!searching">
                  {{$t('search')}}
                </span>
              </b-btn>
              <b-form-tags
                  id="searchInputField"
                  type="text"
                  input-id="searchInput"
                  :add-on-change="true"
                  @tag-state="searchTermEntry"
                  remove-on-delete
                  v-if="searching"
                  v-model="searchTerms"
                  :placeholder="$t('searchFor')"
              />
            </b-btn-group>
          </b-col>
          <!-- <b-col
              cols="*">
            <span
                id="collectionName"
                @click="toggleButtonsVisibility"
                v-text="collection.name"
            />
          </b-col> -->
          <b-col
              cols="auto"
              v-if="!hideButtons">
            <table-options
                id="tableOptionsPopup"
                :collection="collection"
            />
          </b-col>
          <b-col
              cols="*"
              v-if="!hideButtons">
            <b-btn
                id="addOrSaveItemButton"
                class="px-5"
                @click="$store.state.itemActionButtonPress = true"
                variant="primary">
              <b-icon-plus
                  scale="1.5"
              />
            </b-btn>
          </b-col>
        </b-row>
        <b-row
            v-if="!hideButtons">
          <b-col>
            <transition
                name="fade"
                mode="out-in">
              <b-container
                  v-if="filtersShow && tableIsVisible"
                  class="filters__container">
                <b-row
                    class="m-auto">
                  <b-col>
                    <h3
                        @click="hideFilterPane = !hideFilterPane"
                        class="text-center mb-2 pointer">
                      {{hideFilterPane ? $t('records.filter.filteredResults') : $t('records.filter.filterResults')}}
                      <b-icon
                          :class="hideFilterPane ? 'ml-1 filters__caretInactive' : 'ml-1 filters__caret'"
                          icon="caret-down-fill"
                      />
                    </h3>
                  </b-col>
                  <b-col
                      cols="*"
                      class="ml-auto mr-0">
                    <b-btn
                        :variant="filtersShow ? 'warning' : 'secondary'"
                        @click="filterButton"
                        class="ml-3 mr-4">
                      <b-icon-x-circle
                          v-if="(filters.length || filtersShow)"
                      />
                    </b-btn>
                  </b-col>
                </b-row>
                <hr
                    class="mb-3"
                />
                <transition
                    id="filterPane"
                    name="fade"
                    mode="out-in">
                  <b-row
                      align-h="center"
                      v-if="!hideFilterPane">
                    <transition
                        mode="out-in"
                        name="fadeQuick">
                      <b-col
                          key="leftWindow"
                          align="center"
                          cols="*"
                          class="mx-3"
                          v-if="!filterSelection">
                        <b-list-group>
                          <b-list-group-item
                              v-for="filter in filters"
                              :key="filter.key"
                              class="my-1 filters__existingFilter">
                            <b-row
                                class="px-4"
                                align-h="center">
                              <b-col
                                  cols="10"
                                  @click="filterActive = filter.key"
                                  align="center">
                                <h5>
                                  {{filter.label}}
                                </h5>
                                <div
                                    v-for="(selected, index) in filter.selectedOptions"
                                    :key="index">
                                  <span
                                      class="font-weight-light text-muted"
                                      v-text="filterOptionDisplay(selected)"
                                  />
                                  <br
                                      v-if="index > filter.selectedOptions.length - 1"
                                  />
                                </div>
                              </b-col>
                              <b-col
                                  cols="2">
                                <b-btn
                                    @click="filterRemove(filter.key)"
                                    size="sm"
                                    variant="secondary-outline"
                                    class="mx-3 my-0 p-1">
                                  <b-icon-trash
                                      scale="0.75"
                                      class="m-0 my-auto p-0"
                                  />
                                </b-btn>
                              </b-col>
                            </b-row>
                          </b-list-group-item>
                        </b-list-group>
                        <b-btn
                            @click="filterChoose()"
                            class="mt-4 mb-2">
                          <b-icon-plus />
                            {{$t('records.filter.add')}}
                        </b-btn>
                      </b-col>
                      <b-col
                          cols="*"
                          key="rightWindow"
                          align="center"
                          class="mx-3"
                          v-else>
                        <transition
                            mode="out-in"
                            name="fade">
                          <b-container
                              key="type"
                              v-if="filterSelection"
                              class="mb-3">
                            <div
                                class="form__header">
                              <b-btn
                                  v-if="filters.length"
                                  @click="filterSelection = false"
                                  variant="tertiary"
                                  class="mr-4 ml-0 my-0">
                                <b-icon-backspace
                                    class="m-0 p-0"
                                />
                              </b-btn>
                              <span
                                  v-text="$t('records.filter.by')"
                                  class="ml-4 mr-5"
                              />
                            </div>
                            <b-row>
                              <b-col
                                  v-for="(i, index) in Math.ceil(filterTypes.length / 6)"
                                  :key="index"
                                  cols="auto">
                                <b-list-group>
                                  <b-list-group-item
                                      v-for="filter in filterTypes.slice((i - 1) * 6, i * 6)"
                                      :key="filter.key"
                                      @click="filterAdd(filter)"
                                      class="filters__option">
                                    {{filter.label}}
                                  </b-list-group-item>
                                </b-list-group>
                              </b-col>
                            </b-row>
                          </b-container>
                        </transition>
                      </b-col>
                    </transition>
                    <transition
                        name="fadeQuick"
                        mode="out-in">
                      <b-col
                          cols="*"
                          key="rightWindow"
                          align="center"
                          class="mx-3 filters__options"
                          v-if="filterActive && !filterSelection">
                        <h4>
                          {{filters.filter(i => i.key === filterActive)[0].label}} {{$t('records.filter.options')}}
                        </h4>
                        <b-list-group
                            v-if="collection.schemaObj[filterActive].metaType !== 'Address'
                            && collection.schemaObj[filterActive].metaType !== 'Email'
                            && collection.schemaObj[filterActive].metaType !== 'Inventory'
                            && collection.schemaObj[filterActive].metaType !== 'Phone'
                            && collection.schemaObj[filterActive].metaType !== 'Price'"
                            key="options">
                          <transition-group
                              mode="out-in"
                              name="fade">
                            <b-list-group-item
                                v-for="option in filterOptions(this.filterActive)"
                                :key="option"
                                @click="filterOptionClicked(option)"
                                class="filters__option">
                              <transition
                                  name="fadeQuick"
                                  mode="out-in">
                                <b-icon-check
                                    v-if="filterOptionChecked(option)"
                                />
                              </transition>
                              {{filterOptionDisplay(option)}}
                            </b-list-group-item>
                          </transition-group>
                        </b-list-group>
                        <b-list-group
                            v-if="collection.schemaObj[filterActive].metaType === 'Address'">
                          <b-list-group-item
                              v-if="!filterAddressesBy.type"
                              @click="filterAddressesByType('usaFormat')"
                              class="filters__option">
                            {{$t('collections.address.labels.filterUS')}}
                          </b-list-group-item>
                          <b-list-group-item
                              v-if="!filterAddressesBy.type"
                              @click="filterAddressesByType('international')"
                              class="filters__option">
                            {{$t('collections.address.labels.filterInt')}}
                          </b-list-group-item>
                          <b-list-group-item
                              v-if="filterAddressesBy.type === 'usaFormat'"
                              @click="filterAddressesByType('state')"
                              class="filters__option">
                            {{$t('collections.address.labels.filterState')}}
                          </b-list-group-item>
                          <b-list-group-item
                              v-if="filterAddressesBy.type === 'usaFormat'"
                              @click="filterAddressesByType('cityState')"
                              class="filters__option">
                            {{$t('collections.address.labels.filterCityState')}}
                          </b-list-group-item>
                          <b-list-group-item
                              v-if="filterAddressesBy.type === 'international'"
                              @click="filterAddressesByType('country')"
                              class="filters__option">
                            {{$t('collections.address.labels.filterCountry')}}
                          </b-list-group-item>
                          <b-list-group-item
                              v-if="filterAddressesBy.type === 'international'"
                              @click="filterAddressesByType('cityCountry')"
                              class="filters__option">
                            {{$t('collections.address.labels.filterCityCountry')}}
                          </b-list-group-item>
                          <b-list-group-item
                              v-if="filterAddressesBy.type === 'international'"
                              @click="filterAddressesByType('postal')"
                              class="filters__option">
                            {{$t('collections.address.labels.filterPostal')}}
                          </b-list-group-item>
                          <div
                              v-if="filterAddressesBy.type
                              && filterAddressesBy.type !== 'usaFormat'
                              && filterAddressesBy.type !== 'international'">
                            <b-list-group-item
                                v-for="(option, index) in addressFilterOptions"
                                @click="filterAddressOptionClicked(option[filterAddressesBy.type][0])"
                                class="filters__option"
                                :key="index">
                              <b-icon-check
                                  v-if="filterAddressOptionCheckmark(option[filterAddressesBy.type][0])"
                              />
                              {{option[filterAddressesBy.type][0]}}
                            </b-list-group-item>
                          </div>
                          <b-list-group-item
                              v-if="filterAddressesBy.type"
                              class="filters__option"
                              @click="filterAddressesGoBack()">
                            <b-icon-arrow-left />
                            {{$t('goBack')}}
                          </b-list-group-item>
                        </b-list-group>
                        <div
                            v-if="collection.schemaObj[filterActive].metaType === 'Email'"
                            id="emailFilterOptions">
                          <b-list-group>
                            <b-list-group-item
                                @click="currentFilterHas()"
                                class="filters__option">
                              <b-icon-check
                                  v-if="currentFilter.has"
                              />
                              {{$t('collections.hasEmail')}}
                            </b-list-group-item>
                            <b-list-group-item
                                @click="currentFilterHasNone()"
                                class="filters__option">
                              <b-icon-check
                                    v-if="currentFilter.hasNone"
                                />
                              {{$t('collections.hasNoEmail')}}
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div
                            v-if="collection.schemaObj[filterActive].metaType === 'Inventory'
                            || collection.schemaObj[filterActive].metaType === 'Number'"
                            id="inventoryFilterOptions">
                          <label
                              :for="`${currentFilter.label}-minFilter`"
                              v-text="$t('min')"
                              class="form__labels"
                          />
                          <b-form-input
                              :id="`${currentFilter.label}-minFilter`"
                              v-model.number="currentFilter.minCount"
                              @input="updateCountFilter(currentFilter)"
                              type="number"
                              :step="0.01"
                              :placeholder="$t('min')"
                              class="form__input smallish"
                          />
                          <br />
                          <label
                              :for="`${currentFilter.label}-maxFilter`"
                              v-text="$t('max')"
                              class="form__labels"
                          />
                          <b-form-input
                              :id="`${currentFilter.label}-maxFilter`"
                              v-model.number="currentFilter.maxCount"
                              @input="updateCountFilter(currentFilter)"
                              type="number"
                              :step="0.01"
                              :placeholder="$t('max')"
                              class="form__input smallish"
                          />
                        </div>
                        <div
                            v-if="collection.schemaObj[filterActive].metaType === 'Phone'"
                            id="phoneFilterOptions">
                          <b-list-group>
                            <b-list-group-item
                                @click="currentFilter.has = !currentFilter.has"
                                class="filters__option">
                              {{$t('collections.hasPhone')}}
                            </b-list-group-item>
                            <b-list-group-item
                                @click="currentFilter.hasNone = !currentFilter.hasNone"
                                class="filters__option">
                              {{$t('collections.hasNoPhone')}}
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div
                            v-if="collection.schemaObj[filterActive].metaType === 'Price'"
                            id="priceFilterOptions">
                          <b-input-group
                              :prepend="currencySymbol(currentFilter.type)">
                            <b-form-input
                                v-model.number="currentFilter.minPrice"
                                @input="updatePriceFilter(currentFilter)"
                                type="number"
                                :step="0.01"
                                :placeholder="$t('minPrice')"
                                class="form__input smallish"
                            />
                          </b-input-group>
                          <br />
                          <b-input-group
                              :prepend="currencySymbol(currentFilter.type)">
                            <b-form-input
                                v-model.number="currentFilter.maxPrice"
                                @input="updatePriceFilter(currentFilter)"
                                type="number"
                                :step="0.01"
                                :placeholder="$t('maxPrice')"
                                class="form__input smallish"
                            />
                          </b-input-group>
                        </div>
                        <b-list-group
                            v-if="collection.schemaObj[filterActive].metaType === 'Status'">
                          <b-list-group-item
                              @click="filterStatusByType('hasStatus')"
                              class="filters__option">
                            <b-icon-check
                                v-if="filterStatusOptionCheckmark('hasStatus')"
                            />
                            {{$t('collections.status.labels.hasStatus')}}
                          </b-list-group-item>
                          <b-list-group-item
                              @click="filterStatusByType('hasUrgent')"
                              class="filters__option">
                            <b-icon-check
                                v-if="filterStatusOptionCheckmark('hasUrgent')"
                            />
                            {{$t('collections.status.labels.hasUrgent')}}
                          </b-list-group-item>
                          <b-list-group-item
                              @click="filterStatusByType('noStatus')"
                              class="filters__option">
                            <b-icon-check
                                v-if="filterStatusOptionCheckmark('noStatus')"
                            />
                            {{$t('collections.status.labels.noStatus')}}
                          </b-list-group-item>
                        </b-list-group>
                      </b-col>
                    </transition>
                  </b-row>
                </transition>
              </b-container>
            </transition>
          </b-col>
        </b-row>
      </div>
      <b-row
          align-h="center"
          key="deleteMultiple"
          v-if="requestDeleteMultiple">
        <b-col
            cols="12"
            align="center">
          <h3
              id="deleteMultiConfirmation">
             {{$t('records.deleteConfirm[0]')}} {{itemsSelected.length}} {{$t('records.deleteConfirm[1]')}}
          </h3>
          <b-btn-group
              clas="mt-3">
            <b-btn
                variant="danger"
                class="mx-1 px-4 my-3"
                @click="deleteSelected()">
                <h4><b-icon-trash /> {{$t('records.deleteConfirmButton')}}</h4>
            </b-btn>
            <b-btn
                variant="secondary"
                class="mx-1 px-4 my-3"
                @click="deleteSelectedCancel()">
              {{$t('cancel')}}
            </b-btn>
          </b-btn-group>
          <br />
          <i>
            {{$t('records.deleteWarning')}}
          </i>
        </b-col>
      </b-row>
    </transition>
  </div>
</template>

<script>
import totalCalculators from '@/mixins/totalCalculators';
import tableOptions from '@/components/data/TableOptions';

export default {
  components: {
    tableOptions,
  },
  mixins: [totalCalculators],
  props: [
    'collection',
    'fileResults',
    'itemResults',
    'itemsFiltered',
    'itemsSelected',
    'itemSearchResults',
    'needToClear',
    'requestDeleteMultiple',
    'tableIsVisible',
  ],
  computed: {
    addressFilterOptions() {
      const options = this.filterOptions(this.filterActive);
      return options.sort((a, b) => ((a[this.filterAddressesBy.type][0] > b[this.filterAddressesBy.type][0]) ? 1 : -1));
    },
    collectionFields() {
      return this.$store.getters.fields(this.$route.params.collection);
    },
    currentFilter() {
      return this.filters.find(i => i.key === this.filterActive);
    },
    filters: {
      get() {
        return this.$store.state.collectionFilters;
      },
      set(newVal) {
        this.$store.state.collectionFilters = newVal;
      },
    },
    filterAddressesBy: {
      get() {
        return this.$store.state.filterAddressesBy;
      },
      set(newVal) {
        this.$store.state.filterAddressesBy = newVal;
      },
    },
    filterStatusesBy: {
      get() {
        return this.$store.state.filterStatusesBy;
      },
      set(newVal) {
        this.$store.state.filterStatusesBy = newVal;
      },
    },
    filterTypes() {
      // Generates each potential type of filter
      // TODO -- Change this so it interacts with this.collection instead
      const filters = [];
      const fields = this.collectionFields;
      fields.forEach((i) => {
        if (filters.indexOf(i[0]) === -1
            && this.filters.indexOf(i[0]) === -1) {
          if (i[1].metaType !== 'Inventory'
          && i[1].metaType !== 'Notes'
          && i[1].metaType !== 'Price'
          && i[1].metaType !== 'Phone'
          && i[1].metaType !== 'Email') {
            const type = {
              key: i[0],
              label: this.titleCase(i[1].label),
              selectedOptions: [],
            };
            filters.push(type);
          } else if (i[1].metaType === 'Email') {
            const type = {
              key: i[0],
              label: this.titleCase(i[1].label),
              has: false,
              hasNone: false,
              selectedOptions: [],
            };
            filters.push(type);
          } else if (i[1].metaType === 'Inventory') {
            const type = {
              key: i[0],
              label: this.titleCase(i[1].label),
              minCount: null,
              maxCount: null,
              selectedOptions: [],
            };
            filters.push(type);
          } else if (i[1].metaType === 'Phone') {
            const type = {
              key: i[0],
              label: this.titleCase(i[1].label),
              has: false,
              hasNone: false,
              selectedOptions: [],
            };
            filters.push(type);
          } else if (i[1].metaType === 'Price') {
            const type = {
              key: i[0],
              label: this.titleCase(i[1].label),
              type: 'usd',
              minPrice: null,
              maxPrice: null,
              selectedOptions: [],
            };
            filters.push(type);
          }
        }
      });
      return filters;
    },
    hideButtons: {
      get() {
        return this.preferences.hideCollTopButtons;
      },
      set(newVal) {
        this.preferences.hideCollTopButtons = newVal;
      },
    },
    preferences: {
      get() {
        return this.$store.state.preferences;
      },
      set(newVal) {
        this.$store.state.preferences = newVal;
      },
    },
    readyToRun() {
      return this.$store.state.initialLoad;
    },
    searchTermPartial: {
      get() {
        return this.$store.state.searchTermPartial;
      },
      set(newVal) {
        this.$store.state.searchTermPartial = newVal;
      },
    },
    searchTerms: {
      get() {
        return this.$store.state.searchTerms;
      },
      set(newVal) {
        this.$store.state.searchTerms = newVal;
      },
    },
  },
  data() {
    return {
      addressFilterStage: 1,
      filterActive: null,
      filterSelection: false,
      filtersShow: false,
      hideFilterPane: false,
      searching: false,
    };
  },
  methods: {
    addressFilterByType(type) {
      this.filterAddressesBy.type = type;
    },
    currentFilterHas() {
      if (!this.currentFilter.has) {
        this.currentFilter.has = true;
        this.currentFilter.selectedOptions.push(this.$t('collections.hasEmail'));
      } else {
        this.currentFilter.has = false;
        const i = this.currentFilter.selectedOptions.indexOf(this.$t('collections.hasEmail'));
        this.currentFilter.selectedOptions.splice(i, 1);
      }
    },
    currentFilterHasNone() {
      if (!this.currentFilter.hasNone) {
        this.currentFilter.hasNone = true;
        this.currentFilter.selectedOptions.push(this.$t('collections.hasNoEmail'));
      } else {
        this.currentFilter.hasNone = false;
        const i = this.currentFilter.selectedOptions.indexOf(this.$t('collections.hasNoEmail'));
        this.currentFilter.selectedOptions.splice(i, 1);
      }
    },
    deleteSelected() {
      this.$emit('deleteConfirm');
    },
    deleteSelectedCancel() {
      this.$emit('deleteCancel');
    },
    filterAdd(type) {
      this.filterSelection = false;
      this.filters.push(type);
      this.filterActive = type.key;
    },
    filterAddressesByType(type) {
      const currentFilter = this.filters.find(i => i.key === this.filterActive);
      this.filterAddressesBy.type = type;
      switch (type) {
        case 'usaFormat': {
          this.filterAddressesBy.label = this.$t('collections.address.labels.filterUS');
          break;
        }
        case 'international': {
          this.filterAddressesBy.label = this.$t('collections.address.labels.filterInt');
          break;
        }
        case 'cityState': {
          this.filterAddressesBy.cityState = [];
          this.filterAddressesBy.label = this.$t('collections.address.labels.filterCityState');
          break;
        }
        case 'state': {
          this.filterAddressesBy.state = [];
          this.filterAddressesBy.label = this.$t('collections.address.labels.filterState');
          break;
        }
        case 'cityCountry': {
          this.filterAddressesBy.cityCountry = [];
          this.filterAddressesBy.label = this.$t('collections.address.labels.filterCityCountry');
          break;
        }
        case 'country': {
          this.filterAddressesBy.country = [];
          this.filterAddressesBy.label = this.$t('collections.address.labels.filterCountry');
          break;
        }
        case 'postal': {
          this.filterAddressesBy.postal = [];
          this.filterAddressesBy.label = this.$t('collections.address.labels.filterPostal');
          break;
        }
        default: {
          break;
        }
      }
      if (!currentFilter.selectedOptions[0]) {
        currentFilter.selectedOptions.push(this.filterAddressesBy);
      } else {
        currentFilter.selectedOptions[0].type = this.filterAddressesBy.type;
        currentFilter.selectedOptions[0].label = this.filterAddressesBy.label;
      }
    },
    filterAddressesGoBack() {
      if (this.filters.length) {
        const currentFilter = this.filters.find(i => i.key === this.filterActive);
        currentFilter.selectedOptions = [];
        const type = this.filterAddressesBy.type;
        if (type === 'usaFormat'
        || type === 'international') {
          this.filterAddressesBy.type = null;
        } else if (type !== 'usaFormat'
        && type !== 'international') {
          if (type === 'country') {
            this.filterAddressesBy.type = 'international';
            this.filterAddressesBy.country = [];
          }
          if (type === 'cityCountry') {
            this.filterAddressesBy.type = 'international';
            this.filterAddressesBy.cityCountry = [];
          }
          if (type === 'postal') {
            this.filterAddressesBy.type = 'international';
            this.filterAddressesBy.postal = [];
          }
          if (type === 'state') {
            this.filterAddressesBy.type = 'usaFormat';
            this.filterAddressesBy.state = [];
          }
          if (type === 'cityState') {
            this.filterAddressesBy.type = 'usaFormat';
            this.filterAddressesBy.cityState = [];
          }
        }
        if (!currentFilter.selectedOptions[0]) {
          currentFilter.selectedOptions.push(this.filterAddressesBy);
        } else {
          currentFilter.selectedOptions[0] = this.filterAddressesBy;
        }
      }
    },
    filterAddressOptionClicked(option) {
      if (this.filters.length) {
        const currentFilter = this.filters.find(i => i.key === this.filterActive);
        const optionIndex = currentFilter.selectedOptions[0][this.filterAddressesBy.type].indexOf(option);
        if (optionIndex === -1) {
          currentFilter.selectedOptions[0][this.filterAddressesBy.type].push(option);
        } else {
          currentFilter.selectedOptions[0][this.filterAddressesBy.type].splice(optionIndex, 1);
        }
      }
    },
    filterAddressOptionCheckmark(option) {
      if (this.filters.length) {
        const currentFilter = this.filters.find(i => i.key === this.filterActive);
        const optionIndex = currentFilter.selectedOptions[0][this.filterAddressesBy.type].indexOf(option);
        if (optionIndex > -1) {
          return true;
        }
      }
      return false;
    },
    filterButton() {
      if (this.filters.length || this.filtersShow) {
        this.filters = [];
        this.filtersShow = false;
      } else {
        this.filtersShow = true;
        this.filterSelection = true;
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    },
    filterChoose() {
      this.filterSelection = true;
    },
    filterOptionChecked(option) {
      const currentFilter = this.filters.find(i => i.key === this.filterActive);
      const index = currentFilter.selectedOptions.indexOf(option);
      if (index !== -1) {
        return true;
      }
      return false;
    },
    filterOptionClicked(option) {
      if (this.filters.length) {
        const currentFilter = this.filters.find(i => i.key === this.filterActive);
        const optionIndex = currentFilter.selectedOptions.indexOf(option);
        if (optionIndex === -1) {
          currentFilter.selectedOptions.push(option);
        } else {
          currentFilter.selectedOptions.splice(optionIndex, 1);
        }
      }
    },
    filterOptionDisplay(option) {
      if (option === true) {
        return 'Yes';
      }
      if (option === false) {
        return 'No';
      }
      if (option.address) {
        if (option.label) {
          return option.label;
        }
        return null;
      }
      if (option.type === 'Status') {
        let status;
        if (option.hasStatus) {
          status = this.$t('collections.status.labels.hasStatus');
        }
        if (option.hasUrgent) {
          if (status) {
            if (!option.noStatus) {
              status += ` or ${this.$t('collections.status.labels.hasUrgent')}`;
            } else {
              status += `, ${this.$t('collections.status.labels.hasUrgent')}`;
            }
          } else {
            status = this.$t('collections.status.labels.hasUrgent');
          }
        }
        if (option.noStatus) {
          if (status) {
            status += `, or ${this.$t('collections.status.labels.noStatus')}`;
          } else {
            status = this.$t('collections.status.labels.noStatus');
          }
        }
        return status;
      }
      if (option.type === null) {
        return null;
      }
      return option;
    },
    filterOptions(key) {
      // Generates the possible options for each type of filter, once that filter is selected
      const items = this.itemSearchResults;
      const metaType = this.collection.schemaObj[key].metaType;
      const options = [];
      for (let a = 0; a < items.length; a += 1) {
        if (metaType === 'Address') {
          if (items[a][key]) {
            const option = {
              _id: items[a][key]._id,
              selected: false,
            };
            option.type = this.filterAddressesBy.type;
            switch (option.type) {
              case 'usaFormat': {
                this.filterAddressesBy.label = this.$t('collections.address.labels.filterUS');
                break;
              }
              case 'international': {
                this.filterAddressesBy.label = this.$t('collections.address.labels.filterInt');
                break;
              }
              case 'cityState': {
                let cityState;
                option.cityState = [];
                if (items[a][key].type === 'usaFormat'
                && items[a][key].address.city) {
                  cityState = items[a][key].address.city;
                  if (items[a][key].address.state) {
                    cityState += `, ${items[a][key].address.state}`;
                  }
                  if (option.cityState.indexOf(cityState) === -1) {
                    option.cityState.push(cityState);
                  }
                  options.push(option);
                }
                break;
              }
              case 'state': {
                let state;
                option.state = [];
                if (items[a][key].address.state) {
                  state = items[a][key].address.state;
                  if (option.state.indexOf(state) === -1) {
                    option.state.push(state);
                  }
                  options.push(option);
                }
                break;
              }
              case 'cityCountry': {
                let cityCountry;
                option.cityCountry = [];
                if (items[a][key].type === 'international'
                && items[a][key].address.city) {
                  cityCountry = items[a][key].address.city;
                  if (items[a][key].address.country) {
                    cityCountry += `, ${items[a][key].address.country}`;
                  }
                  if (option.cityCountry.indexOf(cityCountry) === -1) {
                    option.cityCountry.push(cityCountry);
                  }
                  options.push(option);
                }
                break;
              }
              case 'country': {
                let country;
                option.country = [];
                if (items[a][key].type === 'international'
                && items[a][key].address.country) {
                  country = items[a][key].address.country;
                  if (option.country.indexOf(country) === -1) {
                    console.log('pushing', country);
                    option.country.push(country);
                  }
                  options.push(option);
                }
                break;
              }
              case 'postal': {
                let postal;
                option.postal = [];
                if (items[a][key].type === 'international'
                && items[a][key].address.postal) {
                  postal = items[a][key].address.postal;
                  if (option.postal.indexOf(postal) === -1) {
                    option.postal.push(postal);
                  }
                  options.push(option);
                }
                break;
              }
              default: {
                break;
              }
            }
          }
        } else if (metaType === 'Inventory') {
          options.push({ minCount: null, maxCount: null });
        } else if (metaType === 'MultiSelect'
               || metaType === 'SelectOne') {
          if (items[a][key]) {
            const entries = Object.entries(items[a][key]);
            for (let z = 0; z < entries.length; z += 1) {
              if (entries[z][1].value
              && options.indexOf(entries[z][1].label) === -1) {
                options.push(entries[z][1].label);
              }
            }
          }
        } else if (metaType === 'Notes'
        || metaType === 'Number'
        || metaType === 'Status') {
          break;
        } else if (metaType === 'Price') {
          options.push({ type: 'usd', minPrice: null, maxPrice: null });
        } else if (metaType === 'Tags') {
          for (let z = 0; z < items[a][key].length; z += 1) {
            if (options.indexOf(items[a][key][z][0]) === -1) {
              options.push(items[a][key][z][0]);
            }
          }
        } else if (metaType === 'Toggle') {
          let on;
          let off;
          if (this.collection.schemaObj[key].default.labelOn) {
            on = this.collection.schemaObj[key].default.labelOn;
          } else {
            on = this.$t('collections.toggleOn');
          }
          if (this.collection.schemaObj[key].default.labelOff) {
            off = this.collection.schemaObj[key].default.labelOff;
          } else {
            off = this.$t('collections.toggleOff');
          }
          if (options.indexOf(on) === -1) {
            options.push(on);
          }
          if (options.indexOf(off) === -1) {
            options.push(off);
          }
        } else if (typeof items[a][key] !== 'undefined'
            && items[a][key] !== null
            && items[a][key] !== ''
            && typeof items[a][key] !== 'boolean'
            && typeof items[a][key] !== 'object') {
          if (options.indexOf(items[a][key]) === -1) {
            options.push(items[a][key]);
          } else {
            const entries = Object.entries(items[a].default);
            for (let z = 0; z < entries.length; z += 1) {
              if (options.indexOf(entries[z].label) === -1) {
                options.push(entries[z].label);
              }
            }
          }
        }
        // if (typeof items[a][key] === 'boolean') {
        //   options = ['Yes', 'No'];
        // }
      }
      return options.sort();
    },
    filterStatusByType(type) {
      const currentFilter = this.filters.find(i => i.key === this.filterActive);
      this.filterStatusesBy.type = 'Status';
      switch (type) {
        case 'hasStatus': {
          this.filterStatusesBy.hasStatus = !this.filterStatusesBy.hasStatus;
          this.filterStatusesBy.hasUrgent = false;
          this.filterStatusesBy.noStatus = false;
          break;
        }
        case 'hasUrgent': {
          this.filterStatusesBy.hasUrgent = !this.filterStatusesBy.hasUrgent;
          this.filterStatusesBy.hasStatus = false;
          this.filterStatusesBy.noStatus = false;
          break;
        }
        case 'noStatus': {
          this.filterStatusesBy.noStatus = !this.filterStatusesBy.noStatus;
          this.filterStatusesBy.hasUrgent = false;
          this.filterStatusesBy.hasStatus = false;
          break;
        }
        default: {
          break;
        }
      }
      if (!currentFilter.selectedOptions[0]) {
        currentFilter.selectedOptions.push(this.filterStatusesBy);
      } else {
        currentFilter.selectedOptions[0].type = this.filterStatusesBy.type;
        currentFilter.selectedOptions[0].label = this.filterStatusesBy.label;
      }
    },
    filterStatusOptionCheckmark(option) {
      if (this.filters.length) {
        const currentFilter = this.filters.find(i => i.key === this.filterActive);
        if (currentFilter.selectedOptions[0] && currentFilter.selectedOptions[0][option]) {
          return true;
        }
      }
      return false;
    },
    filterRemove(key) {
      const index = this.filters.findIndex(i => i.key === key);
      this.filters.splice(index, 1);
      if (this.filterActive === key) {
        this.filterActive = null;
      }
    },
    searchButton() {
      if (this.searching) {
        this.searchTerms = [];
        this.searchTermPartial = null;
      }
      this.searching = !this.searching;
      if (this.searching) {
        setTimeout(() => {
          document.getElementById('searchInput').focus();
          document.getElementById('searchInput').select();
        }, 250);
      }
    },
    searchTermEntry(e) {
      this.searchTermPartial = e[0];
    },
    toggleButtonsVisibility() {
      this.hideButtons = !this.hideButtons;
      this.$store.dispatch('preferenceSet', this.preferences);
    },
    updateCountFilter(filter) {
      // eslint-disable-next-line no-param-reassign
      filter.selectedOptions = [];
      if (filter.minCount) {
        filter.selectedOptions.push(`${this.$t('minimum')}: ${filter.minCount}`);
      }
      if (filter.maxCount) {
        filter.selectedOptions.push(`${this.$t('maximum')}: ${filter.maxCount}`);
      }
    },
    updatePriceFilter(filter) {
      // eslint-disable-next-line no-param-reassign
      filter.selectedOptions = [];
      if (filter.minPrice) {
        filter.selectedOptions.push(`${this.$t('minPrice')}: ${this.displayCurrency(filter.minPrice, filter.type)}`);
      }
      if (filter.maxPrice) {
        filter.selectedOptions.push(`${this.$t('maxPrice')}: ${this.displayCurrency(filter.maxPrice, filter.type)}`);
      }
    },
  },
  watch: {
    searchTerms(newVal) {
      if (newVal) {
        this.searchTermPartial = null;
      }
    },
  },
};
</script>
