<template>
  <div
      id="timesheetHistory">
    <time-sheets
        :timecards="timecards"
        :employees="employees"
    />
  </div>
</template>

<script>
import totalCalculators from '@/mixins/totalCalculators';
import TimeSheets from '@/components/workDay/TimeSheets';

export default {
  title() {
    return `${this.$t('workDay.titles.timeSheetsAdmin')} | ${this.$t('project.brand')}`;
  },
  components: {
    TimeSheets,
  },
  created() {
    this.$store.dispatch('initialLoad').catch((err) => {
      console.log(err);
      this.$router.go();
    });
    this.readyCheck();
  },
  mixins: [
    totalCalculators,
  ],
  computed: {
    employees() {
      const list = [];
      const employees = this.$store.getters.employees;
      const fields = this.$store.state.account.employeesFields;
      employees.forEach((e) => {
        const employee = {
          _id: e._id,
          employeeId: e[fields.employeeId] ? e[fields.employeeId] : '',
          active: e[fields.active],
          address: e[fields.address],
          email: e[fields.email],
          name: e[fields.name] ? e[fields.name] : '',
          notes: e[fields.notes],
          phone: e[fields.phone],
        };
        if (employee.active && employee.active.value) {
          list.push(employee);
        }
      });
      return list;
    },
    timecards() {
      return this.$store.state.data.workDayTimecards;
    },
  },
  data() {
    return {
      model: {},
    };
  },
  methods: {},
  watch: {},
};
</script>
