/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:8b42beb8-adb2-4fd8-98ac-a4193a2b65d4",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_W7omV0TH0",
    "aws_user_pools_web_client_id": "7b323l27eojf7dcoji7d8rkeeh",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://gfnib4acze.execute-api.us-west-1.amazonaws.com/dev",
            "region": "us-west-1"
        }
    ]
};


export default awsmobile;
