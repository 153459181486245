<template>
  <div>
    <div
        id="recordTableContainer"
        ref="mainContainer"
        :class="!addItem && !editItem ? 'record__table__container' : ''"
        v-on:keydown.13="handleEnterPress">
      <transition
          name="fade">
        <h3
            v-if="displayJustAddedMessage"
            v-html="`${$t('collections.justAdded')}<p/>${countdownTimer}`"
            class="form__header mt-4 mx-auto"
        />
      </transition>
      <transition
          name="fadeQuick"
          mode="out-in">
        <b-table
            id="recordtable"
            ref="recordtable"
            key="table"
            v-if="showTable"
            bordered
            sticky-header="calc(100vh - 115px)"
            responsive
            striped
            :hover="!quickEntry"
            :items="items"
            :fields="fieldsToDisplay"
            :per-page="perPage"
            :current-page="currentPage"
            :busy="$store.state.showLoader"
            selectable
            selected-variant="warning"
            @row-hovered="hoveredRow"
            @row-unhovered="hoveredRowEnd"
            @row-selected="rowClicked"
            primary-key="_id"
            class="record__table"
            :tbody-transition-props="{name: 'fadeQuick', mode: 'out-in'}">
          <template
              v-slot:table-busy>
            <loader
                type="spinner"
                color="#013f10"
                :fullPage="false"
                :h="80"
                :w="200"
            />
            </template>
            <template
                v-slot:cell(idDisplay)="row">
              <div
                  class="record__cell__id__container">
                <transition
                    name="fadeQuick"
                    mode="out-in">
                  <span
                      key="displayCheck"
                      v-if="row.rowSelected"
                      aria-hidden="true">
                    <b-icon-check
                        scale="2"
                    />
                  </span>
                  <span
                      v-else
                      key="noCheck"
                      aria-hidden="true">
                    &nbsp;
                  </span>
                </transition>
                <span
                    v-if="row.rowSelected"
                    v-text="$t('aria.selected')"
                    class="sr-only"
                />
                <span
                    v-text="$t('aria.selectedFalse')"
                    v-else
                    class="sr-only"
                />
                <span
                    v-if="collection.idDisplay
                      && collection.idType !== 'noId'
                      && quickEditRow !== row.item._id"
                    v-text="collection.idType === 'humanId' ? row.item.humanId : row.item._id"
                    :class="collection.idType === 'humanId' ? 'record__cell__id' : 'record__cell__id smallText'"
                />
                <transition
                    name="fade"
                    mode="out-in">
                  <b-btn-group
                      class="mx-auto"
                      vertical>
                    <b-btn
                        v-if="quickEditRow !== row.item._id"
                        @click="editItemFlexClick(row.item._id)"
                        :variant="(hoverRow === row.item._id || quickEditRow === row.item._id) ? 'success' : 'btn-outline'"
                        class="py-0 px-1">
                      <b-icon-pencil />
                    </b-btn>
                    <b-btn
                        v-if="quickEditRow === row.item._id"
                        @click="quickEditSave(row.item._id)"
                        :variant="(hoverRow === row.item._id || quickEditRow === row.item._id) ? 'success' : 'btn-outline'"
                        class="py-0 px-1">
                      <b-icon-check />
                    </b-btn>
                    <b-btn
                        v-if="quickEntry && quickEditRow === row.item._id"
                        @click="quickEditCancel()"
                        variant="danger"
                        class="py-0">
                      <small
                          v-text="$t('cancel')"
                      />
                    </b-btn>
                  </b-btn-group>
                </transition>
              </div>
            </template>
            <template
                #cell()="row">
              <table-cell
                  :collectionId="collection._id"
                  :field="row.field"
                  :hovered="hoverRow === row.item._id"
                  :modelId="row.item._id"
                  :schemaObj="collection.schemaObj"
                  :quickEntry="quickEntry"
                  :quickEditRow="quickEditRow"
                  @switchToRecord="seeDataLinkFromTable"
                  @quickNote="quickNoteSave"
                  @quickSave="quickEditSave(row.item._id)"
              />
            </template>
            <template
                v-slot:cell(dateAdded)="row">
              <small
                  v-text="dateDisplayWithTime(row.item.dateAdded)"
              />
            </template>
            <template
                v-slot:cell(dateUpdated)="row">
              <small
                  v-text="dateSince(row.item.dateUpdated)"
              />
            </template>
            <template
                v-slot:cell(tags)="row">
              <small
                  v-if="!(quickEntry && quickEditRow === row.item._id)">
                  {{row.item.tags.join(', ')}}
              </small>
              <b-form-tags
                  v-else
                  id="tagsInput"
                  v-model="row.item.tags"
                  class="form__input medium"
              />
            </template>
            <template
                v-slot:cell(actionButtons)="row">
              <transition
                  name="fadeQuick"
                  mode="out-in">
                <b-btn
                    v-if="showSpinner === row.item._id"
                    key="deleteInProgress"
                    variant="warning">
                  <transition
                      name="fadeQuick"
                      mode="out-in">
                    <small
                        v-if="1 > 0">
                      Deleting...
                    </small>
                  </transition>
                </b-btn>
                <b-btn-group
                    key="deleteAndEdit"
                    v-else-if="deleteConfirm !== row.item._id">
                  <b-btn
                      @click="quickEditRow !== row.item._id ? editItemClick(row.item._id) : quickEditSave(row.item._id)"
                      :variant="hoverRow === row.item._id ? 'success' : 'btn-outline'"
                      class="py-0 px-3 mr-2">
                    <b-icon
                        :icon="quickEntry ? 'search' : 'pencil'"
                        v-if="!(quickEntry && quickEditRow === row.item._id)"
                    />
                    <b-icon-check
                        v-else
                    />
                  </b-btn>
                  <b-btn
                      @click="deleteConfirm = row.item._id"
                      :variant="hoverRow === row.item._id ? 'danger' : 'danger-outline'"
                      class="py-0 px-3 ml-2">
                    <b-icon-trash />
                  </b-btn>
                </b-btn-group>
                <b-btn-group
                    v-else-if="deleteConfirm === row.item._id"
                    key="deleteConfirm">
                  <b-btn
                      @click="deleteItem(row.item._id)"
                      variant="danger"
                      class="py-0 px-3 ml-2">
                    <b-icon-trash
                    />
                    <small>
                      Confirm
                    </small>
                  </b-btn>
                  <b-btn
                      @click="deleteConfirm = null"
                      variant="secondary"
                      class="py-0 px-3 ml-2">
                    <small>
                      Cancel
                    </small>
                  </b-btn>
                </b-btn-group>
              </transition>
            </template>
        </b-table>
      </transition>
    </div>
    <!-- Add/Edit Form -->
    <div
        id="recordForm">
      <transition
          name="fadeQuick"
          mode="out-in">
        <b-form
            key="recordForm"
            v-if="addItem || editItem"
            class="record__form__container">
          <b-row
              class="record__form__container__header"
              align-h="center"
              align-v="center">
            <b-col
                cols="*"
                class="mr-2 ml-auto">
              <b-btn
                  id="cancelSaveButton"
                  @click="itemActionCancel"
                  class="ml-auto mr-2 px-2"
                  variant="secondary">
                <b-icon-backspace />
                <small
                    v-text="$t('goBack')"
                />
              </b-btn>
            </b-col>
            <b-col
                cols="*">
              <h3
                  id="collectionName">
                {{editItem ? `${$t('records.updating')} ${collection.name} ${$t('records.record')}` : `${$t('records.adding')} ${collection.name}`}}
              </h3>
            </b-col>
            <b-col
                cols="*"
                class="mr-auto ml-2">
              <b-btn
                  id="saveRecordButton"
                  @click="itemActionButtonPress = true"
                  class="mr-auto ml-2 px-5"
                  variant="primary">
                <b-icon-check
                    scale="1.5"
                />
                {{$t('save')}}
              </b-btn>
            </b-col>
          </b-row>
          <b-row
              id="saveAndGoBackButton"
              v-if="justViewed.collection"
              class="py-3">
            <b-col
                align="center">
              <b-btn
                  id="saveAndGoBack"
                  @click="saveThenGoBack()"
                  variant="primary"
                  class="mx-auto"
                  size="sm">
                <b-icon-arrow-left-circle />
                <span
                    v-text="$t('goBackSave[0]')"
                />
                <br />
                <small
                    class="mx-auto"
                    v-text="goBackToPreviousItemText"
                />
              </b-btn>
            </b-col>
          </b-row>
          <b-row
              id="idAndRefDisplay"
              class="mx-0"
              v-if="collection.idType !== 'noId'">
            <b-col
                align="center"
                cols="*"
                class="mx-auto record__form__accent">
              <b-input-group
                  v-if="collection.idType === 'humanId'">
                <b-input-group-prepend
                  class="admin__container__header__emphasis"
                  v-text="$t('refNumber')"
                />
                <b-form-input
                    id="humanIdInput"
                    v-model="model.humanId"
                    type="text"
                    class="form__input"
                />
              </b-input-group>
              <b-input-group
                  class="refNumber__guid"
                  v-if="collection.idType === 'guid' && model._id">
                <span
                    id="guidDisplay">
                  {{$t('refNumber')}}: {{model._id}}
                </span>
              </b-input-group>
            </b-col>
          </b-row>
          <div
              v-if="editItem"
              class="text-center my-4">
            <span
                class="record__form__accent">
              {{$t('dateAdded')}} <b>{{dateAdded}}</b>
              <span
                  v-if="dateAdded !== lastUpdated">
                <small class="mx-2">|</small>
                {{$t('lastUpdate')}} <b>{{lastUpdated}}</b>
              </span>
              <b-btn
                  id="copyThisDataLink"
                  v-if="model._id"
                  @click="copyDataLink()"
                  variant="info"
                  :aria-label="$t('aria.copyDataLink')"
                  size="sm"
                  class="ml-3 py-1 px-4">
                  <b-icon-clipboard-plus
                      v-if="!dataUrlJustCopied"
                      key="readyToCopy"
                  />
                  <b-icon-clipboard-check
                      v-else
                      key="copied"
                  />
              </b-btn>
            </span>
            <b-tooltip
                target="copyThisDataLink">
              <h5>
                <span
                    v-text="$t('tooltips.linkCopied')"
                />
                <transition
                    mode="out-in"
                    name="fade">
                  <b-icon-check
                      v-if="dataUrlJustCopied"
                  />
                </transition>
              </h5>
              <b-form-input
                  id="copyTextInput"
                  v-model="dataUrlText"
                  class="form__input xs"
              />
            </b-tooltip>
          </div>
          <b-row
              align-h="center"
              class="my-3 mx-0">
            <b-col
                v-for="field in fields"
                :key="field[0]"
                align="center"
                :lg="colCount(field[1].metaType)"
                md="12"
                class="record__form__col">
              <div
                  v-if="field[1].metaType !== 'Address'
                  && field[1].metaType !== 'Notes'
                  && field[1].metaType !== 'Link'
                  && field[1].metaType !== 'Phone'
                  && field[1].metaType !== 'Price'"
                  class="record__form__col__label">
                <span
                    :for="`${field[0]}-input`"
                    v-text="field[1].label"
                />
              </div>
              <!-- Address -->
              <div
                  v-if="field[1].metaType === 'Address'">
                <div
                    class="record__form__col__label">
                  <label
                      :for="`${field[0]}-select`"
                      v-text="addressesInCollection[0] === field[0] && addressesInCollection.length > 1 ? field[1].default.labelMulti : field[1].label"
                  />
                  <b-form-select
                      :id="`${field[0]}-select`"
                      v-model="model[field[0]].type"
                      :options="[
                        { text: $t('collections.address.formatUSA'), value: 'usaFormat' },
                        { text: $t('collections.address.formatInt'), value: 'international' }
                      ]"
                      @change="model[field[0]].address = addressTemplates(model[field[0]].type)"
                      class="smallSelect"
                  />
                </div>
                <small
                    v-if="model[field[0]].expanded && !field[1].default.expanded"
                    v-text="`(${$t('collections.address.remove')})`"
                    @click="model[field[0]].expanded = !model[field[0]].expanded"
                    class="form__textButton"
                />
                <b-btn
                    v-if="!model[field[0]].expanded"
                    @click="model[field[0]].expanded = !model[field[0]].expanded"
                    v-text="`${$t('collections.address.add')} ${model[field[0]].labelMulti}`"
                />
                <div
                    v-if="model[field[0]].expanded">
                  <label
                      :for="`${field[0]}-streetEntry`"
                      v-text="$t('collections.address.labels.street')"
                      class="form__label ml-0 mr-auto"
                  />
                  <small
                      v-if="!model[field[0]].address.streetSecondShow"
                      v-text="$t('collections.address.labels.addSecondStreet')"
                      @click="model[field[0]].address.streetSecondShow = !model[field[0]].address.streetSecondShow"
                      class="ml-4 mr-0 form__textButton"
                  />
                  <b-input-group
                      class="form__input xl">
                    <b-form-input
                        :id="`${field[0]}-streetEntry`"
                        v-model="model[field[0]].address.street"
                        class="form__input"
                        type="text"
                    />
                  </b-input-group>
                  <label
                      v-text="$t('collections.address.labels.streetSecond')"
                      v-if="model[field[0]].address.streetSecondShow"
                      :for="`${field[0]}-streetSecondEntry`"
                      class="form__label ml-0 mr-auto"
                  />
                  <small
                      v-if="model[field[0]].address.streetSecondShow"
                      v-text="$t('collections.address.labels.removeSecondStreet')"
                      @click="model[field[0]].address.streetSecondShow = !model[field[0]].address.streetSecondShow"
                      class=" ml-4 mr-0 form__textButton"
                  />
                  <b-form-input
                      :id="`${field[0]}-streetSecondEntry`"
                      v-if="model[field[0]].address.streetSecondShow"
                      v-model="model[field[0]].address.streetSecond"
                      class="form__input xl"
                      type="text"
                  />
                  <b-row
                      v-if="model[field[0]].type === 'usaFormat'"
                      align-h="center">
                    <b-col
                        cols="auto">
                      <label
                          v-text="$t('collections.address.labels.city')"
                          :for="`${field[0]}-cityEntry`"
                          class="form__label ml-0 mr-auto"
                      />
                      <b-form-input
                          :id="`${field[0]}-cityEntry`"
                          type="text"
                          v-model="model[field[0]].address.city"
                          class="form__input medium"
                      />
                    </b-col>
                    <b-col
                        cols="auto">
                      <label
                          v-text="$t('collections.address.labels.state')"
                          :for="`stateEntry-${field[0]}`"
                          class="form__label ml-0 mr-auto"
                      />
                      <b-form-input
                          :id="`stateEntry-${field[0]}`"
                          type="text"
                          v-model="model[field[0]].address.state"
                          class="form__input stateInput"
                      />
                    </b-col>
                    <b-col
                        cols="auto">
                      <label
                          v-text="$t('collections.address.labels.zip')"
                          :for="`zipEntry-${field[0]}`"
                          class="form__label ml-0 mr-auto"
                      />
                      <b-form-input
                          :id="`zipEntry-${field[0]}`"
                          type="text"
                          v-model="model[field[0]].address.zip"
                          @input="zipLookup(model[field[0]].address.zip, field[0])"
                          class="form__input zipInput"
                      />
                    </b-col>
                  </b-row>
                  <div
                      v-else>
                    <label
                        v-text="$t('collections.address.labels.city')"
                        :for="`${field[0]}-cityEntry`"
                        class="form__label ml-0 mr-auto"
                    />
                    <b-form-input
                        :id="`${field[0]}-cityEntry`"
                        type="text"
                        v-model="model[field[0]].address.city"
                        class="form__input xl"
                    />
                    <b-row
                        align-h="center">
                      <b-col
                          cols="auto">
                        <label
                            v-text="$t('collections.address.labels.country')"
                            :for="`${field[0]}-countryEntry`"
                            class="form__label ml-0 mr-auto"
                        />
                        <b-form-input
                            :id="`${field[0]}-countryEntry`"
                            type="text"
                            v-model="model[field[0]].address.country"
                            class="form__input large"
                        />
                      </b-col>
                      <b-col
                          cols="auto">
                        <label
                            v-text="$t('collections.address.labels.postal')"
                            :for="`${field[0]}-postalEntry`"
                            class="form__label ml-0 mr-auto"
                        />
                        <b-form-input
                            :id="`${field[0]}-postalEntry`"
                            type="text"
                            v-model="model[field[0]].address.postal"
                            class="form__input medium"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <br />
              </div>
              <!-- Color -->
              <div
                  v-if="field[1].metaType === 'Color'">
                <small
                    v-text="$t('collections.types.colorTypes[0]')"
                />
                <b-form-input
                    :id="`input-${field[0]}`"
                    type="color"
                    v-model="model[field[0]]"
                    class="preferences__colorScheme__selector"
                />
              </div>
              <!-- ColorBadge -->
              <div
                  v-if="field[1].metaType === 'ColorBadge'">
                <small
                    v-text="$t('collections.types.colorBadgeTypes[0]')"
                />
                <b-form-input
                    :id="`input-${field[0]}-1`"
                    type="color"
                    v-model="model[field[0]][0]"
                    class="preferences__colorScheme__selector"
                />
                <small
                    v-text="$t('collections.types.colorBadgeTypes[1]')"
                />
                <b-form-input
                    :id="`input-${field[0]}-2`"
                    type="color"
                    v-model="model[field[0]][1]"
                    class="preferences__colorScheme__selector"
                />
              </div>
              <!-- Email -->
              <div
                  v-if="field[1].metaType === 'Email'">
                <b-form-input
                    :id="`${field[0]}-input`"
                    class="form__input medium"
                    type="text"
                    v-model="model[field[0]]"
                />
              </div>
              <!-- Inventory -->
              <div
                  v-if="field[1].metaType === 'Inventory'">
                <b-form-input
                    :id="`${field[0]}-input`"
                    class="form__input smallish"
                    type="number"
                    :step="0.001"
                    v-model.number="model[field[0]].counter"
                />
                <b-btn
                    @click="model[field[0]].counter = Number(model[field[0]].counter) + 1"
                    class="py-1 px-4 mt-2">
                  <b-icon-arrow-up />
                </b-btn>
                <b-btn
                    @click="model[field[0]].counter = Number(model[field[0]].counter) - 1"
                    class="py-1 px-4 mt-2">
                  <b-icon-arrow-down />
                </b-btn>
                <div
                    v-if="model[field[0]].invoicePulls.length"
                    :id="`pullReceipts-${field[0]}`"
                    class="mt-2">
                  <span
                      v-if="showInvoicePulls.indexOf(field[0]) > -1"
                      v-text="$t('records.pullReceiptHeader')"
                  />
                  <b-btn
                      @click="showInvoicePullsClick(field[0])"
                      :id="`showPullsButton-${field[0]}`"
                      class="my-2">
                    <b-icon
                        :icon="showInvoicePulls.indexOf(field[0]) > -1 ? 'eye-slash' : 'eye'"
                    />
                    <small
                        v-text="showInvoicePulls.indexOf(field[0]) > -1 ? $t('records.pullReceiptButton[0]') : $t('records.pullReceiptButton[1]')"
                    />
                  </b-btn>
                  <transition
                      name="fade"
                      mode="out-in">
                    <b-list-group
                        v-if="showInvoicePulls.indexOf(field[0]) > -1">
                      <b-list-group-item
                          v-for="pull in pullReceipts(model[field[0]].invoicePulls)"
                          :key="pull.date">
                          {{dateSince(pull.date)}}
                          <b
                              class="mx-3">
                            {{$store.getters.invoice(pull.invoice).number ? $store.getters.invoice(pull.invoice).number : $t('records.pullReceiptDeleted')}}
                          </b>
                          <span
                              v-if="pull.change < 0">
                            +
                          </span>
                          {{pull.change * -1}}
                          <b-btn
                              :id="`seeItem-${pull.date}`"
                              :aria-label="$t('aria.showInvoice')"
                              @click="switchToRecord('Billing', pull.invoice)"
                              variant="info"
                              @mouseover="hoverOverPullReceipt(pull.date)"
                              @mouseleave="hoverPullReceipt = null"
                              v-if="$store.getters.invoice(pull.invoice)"
                              class="ml-4">
                              <b-icon-eye />
                              <transition
                                  mode="out-in"
                                  name="fade">
                                <span
                                    v-if="hoverPullReceipt === pull.date"
                                    v-text="$t('records.pullReceiptView')"
                                />
                              </transition>
                          </b-btn>
                      </b-list-group-item>
                    </b-list-group>
                  </transition>
                </div>
              </div>
              <!-- Link -->
              <div
                  v-if="field[1].metaType === 'Link'">
                <label
                    :for="`${field[0]}-input`"
                    class="record__form__col__label">
                    {{field[1].label}}
                    <small
                        v-text="$t('linkedTo')"
                    />
                    <div
                        v-if="model[field[0]] && field[1].default.linkTo">
                      <b
                          v-text="linkedCollection(field[1].default.linkTo).name"
                      />
                    </div>
                </label>
                <transition-group
                    mode="out-in"
                    name="fadeQuick">
                  <!-- Adding override Data -->
                  <div
                      v-if="addLinkedDataOverride === field[0] || (model[field[0]] && model[field[0]].dataOverride._id)"
                      key="addingNew">
                    <span
                        v-if="model[field[0]].dataOverride._id"
                        v-text="model[field[0]].dataOverride.humanId"
                        class="linkedItem__id smallText"
                    />
                    <div
                        v-if="addLinkedDataOverride === field[0]">
                      <div
                          v-for="overrideEntry in fieldsToOverride(field[1].default.linkedFields, field[1].default.linkTo)"
                          :key="overrideEntry._id"
                          class="my-3">
                        <span
                            class="smallLabel"
                            v-text="linkedCollection(field[1].default.linkTo).schemaObj[overrideEntry._id].label"
                        />
                        <!-- Override data entry form -->
                        <table-cell
                            :collectionId="collection._id"
                            :field="{ ...linkedCollection(field[1].default.linkTo).schemaObj[overrideEntry._id], key: overrideEntry._id }"
                            :hovered="hoverRow === model._id"
                            :modelId="model._id"
                            :modelOverride="model[field[0]].dataOverride"
                            :schemaObj="linkedCollection(field[1].default.linkTo).schemaObj"
                            :quickEntry="true"
                            :quickEditRow="overrideEntry._id"
                            @switchToRecord="seeDataLink"
                            @quickNote="quickNoteSave"
                            @quickSave="quickEditSave(row.item._id)"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Selected Links List Display -->
                  <div
                      id="linksHaveBeenChosen"
                      key="mainview"
                      v-else-if="model[field[0]] && model[field[0]].linksChosen && model[field[0]].linksChosen.length
                      && linkedLookup.linkOrigin !== field[0]">
                    <b-row
                        v-for="(selected, index) in selectedLinks(field[0])"
                        :class="index % 2 !== 0 ? 'linkedItem__evenRow' : 'linkedItem__row'"
                        @mouseover="linkedDataHover(selected, field[0])"
                        @mouseleave="linkedDataHoverDone()"
                        :key="selected">
                      <b-col
                          cols="*"
                          class="linkedItem__id__container">
                        <!-- Delete icon -->
                        <transition
                            name="fade">
                          <b-btn
                              id="deleteLinkedItem"
                              class="linkedItem__deleteIcon"
                              variant="danger"
                              v-if="linkedDataHoverOver.record === selected && linkedDataHoverOver.field === field[0]"
                              @click="linkedDataRemove(selected, field)"
                              :aria-label="$t('aria.delete')">
                            <b-icon-trash
                                scale="1"
                            />
                          </b-btn>
                        </transition>
                        <!-- ID / HumanID display -->
                        <span
                            v-if="linkedCollection(field[1].default.linkTo).idType === 'guid'
                              || linkedCollection(field[1].default.linkTo).idType === 'humanId'"
                            :class="linkedCollection(field[1].default.linkTo).idType === 'humanId'
                              ? 'linkedItem__id' : 'linkedItem__id smallText'"
                            v-text="linkedCollection(field[1].default.linkTo).idType === 'humanId'
                              ? Object.entries(
                                linkedRecordCaller(field, selected))[0][1].humanId
                              : Object.entries(linkedRecordCaller(field, selected))[0][0]"
                        />
                        <transition
                            name="fade">
                          <b-btn
                              id="viewLinkedItem"
                              class="linkedItem__viewIcon"
                              variant="tertiary"
                              v-if="linkedDataHoverOver.record === selected && linkedDataHoverOver.field === field[0]"
                              @click="linkedDataView(selected, field)"
                              :aria-label="$t('aria.view')">
                            <b-icon-search
                                scale="1"
                            />
                          </b-btn>
                        </transition>
                      </b-col>
                      <b-col
                          class="linkedItem__container"
                          align="right"
                          cols="auto">
                        <div
                            v-for="linkedField in Object.entries(
                              linkedRecordCaller(field, selected))"
                            :key="linkedField[0]">
                          <!-- Linked data rendering -->
                          <data-viewer
                              :addressDisplayType="collection.schemaObj[field[0]]
                                && field[1].default.linkedFields.find(i => i._id === linkedField[0])
                                && field[1].default.linkedFields.find(i => i._id === linkedField[0]).addressDisplayType
                                ? field[1].default.linkedFields.find(i => i._id === linkedField[0]).addressDisplayType : null"
                              :model="linkedField"
                              :collection="linkedCollection(field[1].default.linkTo)"
                          />
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- Number of links selected text label -->
                  <div
                      key="numberOfLinksChosen"
                      v-else-if="linkedLookup.linkOrigin === field[0] && model[field[0]] && model[field[0]].linksChosen.length">
                    <b>
                      {{model[field[0]].linksChosen.length}} {{$t('records.records')}} {{$t('selectedAlready')}}
                    </b>
                  </div>
                  <!-- Add/Find linked records buttons -->
                  <b-row
                      v-if="(!field[1].default.allowMany && model[field[0]] && !model[field[0]].linksChosen.length)
                      || field[1].default.allowMany"
                      key="buttonMenu"
                      class="my-2">
                    <b-col>
                      <b-btn
                          v-if="addLinkedDataOverride !== field[0] && field[1].default.allowNew && model[field[0]].dataOverride && model[field[0]].dataOverride._id"
                          @click="addLinkedDataOverride = field[0]"
                          variant="tertiary">
                        {{$t('edit')}}
                      </b-btn>
                      <b-btn
                          v-if="addLinkedDataOverride !== field[0] && field[1].default.allowNew && model[field[0]].dataOverride && model[field[0]].dataOverride._id"
                          @click="dataOverrideClear(field[0]);"
                          variant="warning-outline">
                        {{$t('clear')}}
                      </b-btn>
                      <b-btn-group
                          v-else-if="addLinkedDataOverride !== field[0]">
                        <b-btn
                            @click="linkedDataFindButton(field)"
                            v-text="linkedLookup.linkOrigin === field[0] ? $t('close') : $t('find')"
                            :variant="(linkedLookup.linkOrigin === field[0]) ? 'primary' : 'tertiary'"
                            class="mx-2">
                          {{$t('find')}}
                        </b-btn>
                        <b-btn
                            v-if="field[1].default.allowNew && !model[field[0]].dataOverride._id"
                            @click="addLinkedData(field[0]);"
                            variant="tertiary">
                          {{$t('new')}}
                        </b-btn>
                      </b-btn-group>
                      <div
                          v-else>
                        <b-btn-group>
                          <b-btn
                              @click="addLinkedDataSave(field[0])"
                              v-if="field[1].default.allowSave || field[1].default.forceSave"
                              variant="success"
                              class="mx-2"
                              v-text="`${$t('save')} ${$t('in')} ${linkedCollection(field[1].default.linkTo).name}`"
                          />
                          <b-btn
                              @click="addLinkedDataUse(field[0])"
                              v-if="!field[1].default.forceSave"
                              class="mx-2"
                              :variant="field[1].default.allowSave ? 'secondary' : 'success'"
                              v-text="field[1].default.allowSave ? $t('useHere') : $t('use')"
                          />
                        </b-btn-group>
                        <div
                            class="mt-2">
                          <b-btn
                              @click="dataOverrideClear(field[0])"
                              variant="danger"
                              v-text="$t('cancel')"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <!-- Linked Data Choices Table -->
                  <div
                      id="linkSelectionTable"
                      v-if="linkedLookup.linkOrigin === field[0]"
                      key="dataChoices">
                    <b-form-tags
                        id="linkedRecordSearch"
                        type="text"
                        input-id="searchInput"
                        :add-on-change="true"
                        @tag-state="searchTermEntry"
                        remove-on-delete
                        v-model="searchTerms"
                        :placeholder="$t('searchFor')"
                    />
                    <b-row
                        v-for="(record, index) in linkedRecordsPaginated"
                        :key="record[0]"
                        :class="index % 2 !== 0 ? 'linkedItem__evenRow' : 'linkedItem__row'"
                        @click="linkedRowClicked(record[0], field)">
                      <b-col
                          class="linkedItem__id__container"
                          cols="1">
                        <span
                            v-if="linkedCollection(field[1].default.linkTo)
                              && (linkedCollection(field[1].default.linkTo).idType === 'guid'
                                || linkedCollection(field[1].default.linkTo).idType === 'humanId')"
                            v-text="linkedCollection(field[1].default.linkTo).idType === 'humanId'
                              ? Object.entries(record[1])[0][1].humanId
                              : Object.entries(record[1])[0][0]"
                            :class="linkedCollection(field[1].default.linkTo).idType === 'humanId'
                              ? 'linkedItem__id' : 'linkedItem__id smallText'"
                        />
                        <span
                            class="sr-only"
                            v-if="model[field[0]].linksChosen.indexOf(record[0]) > -1"
                            v-text="$t('aria.selected')"
                        />
                        <transition
                            name="fade">
                          <b-icon-check
                              scale="2.2"
                              class="linkedItem__id__selected"
                              v-if="model[field[0]].linksChosen.indexOf(record[0]) > -1"
                          />
                        </transition>
                      </b-col>
                      <b-col
                          class="my-auto py-2 mr-auto"
                          cols="auto">
                        <div
                            v-for="linkedField in Object.entries(record[1])"
                            :key="linkedField[0]">
                          <data-viewer
                              :addressDisplayType="collection.schemaObj[field[0]]
                                && collection.schemaObj[field[0]].default.linkedFields.find(i => i._id === linkedField[0])
                                ? collection.schemaObj[field[0]].default.linkedFields.find(i => i._id === linkedField[0]).addressDisplayType : null"
                              :model="linkedField"
                              :collection="$store.getters.collection(collection.schemaObj[field[0]].default.linkTo)"
                          />
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </transition-group>
                <div
                    v-if="linkedRecords.length > linkedRecordsPerPage && linkedLookup.linkOrigin === field[0]">
                  <!-- Linked Records Pagination -->
                  <b-pagination
                      v-model="linkedRecordsPage"
                      :total-rows="linkedRecords.length"
                      :per-page="linkedRecordsPerPage"
                      aria-controls="my-table"
                  />
                </div>
              </div>
              <!-- Multi Select -->
              <b-list-group
                  v-if="field[1].metaType === 'MultiSelect'">
                <b-list-group-item
                    v-for="(option, index) in model[field[0]]"
                    :key="index"
                    :id="`${index}-toggle`"
                    button
                    :variant="model[field[0]][index].value ? 'primary' : 'light'"
                    @click="model[field[0]][index].value = !model[field[0]][index].value">
                  {{ option.label }}
                </b-list-group-item>
              </b-list-group>
              <!-- Notes -->
              <div
                  v-if="field[1].metaType === 'Notes'">
                <b-row
                    align-h="center">
                  <b-col
                      align="center">
                    <div
                        class="record__form__col__label">
                      <label
                          :for="`${field[0]}-notes`"
                          v-text="field[1].label"
                      />
                      <b-btn
                          v-if="model[field[0]].length"
                          size="sm"
                          @click="hideNotes = !hideNotes"
                          class="ml-4">
                        <b-icon
                            :icon="hideNotes ? 'eye' : 'eye-slash'"
                        />
                        <span
                            v-text="hideNotes ? 'Show' : 'Hide'"
                        />
                      </b-btn>
                    </div>
                  </b-col>
                </b-row>
                <span
                    class="mx-auto"
                    v-if="hideNotes && model[field[0]].length">
                  {{model[field[0]].length > 1 ? `${model[field[0]].length} ${$t('note.notes')}` : `${model[field[0]].length} ${$t('note.note')}` }}
                </span>
                <b-row
                    v-if="model[field[0]].length > 3 && !hideNotes">
                  <b-col
                      align="center">
                    <b-dropdown
                        variant="tertiary"
                        size="sm">
                      <template
                          v-slot:button-content>
                        <span
                            v-text="filterNoteByAuthor ? `${$t('note.notesBy')} ${filterNoteByAuthor}` : $t('note.notesByAll')"
                        />
                      </template>
                      <b-dropdown-item
                          @click="filterNoteByAuthor = null">
                        {{$t('note.notesByAll')}}
                      </b-dropdown-item>
                      <b-dropdown-item
                          v-for="(author, index) in noteAuthors(field[0])"
                          :key="index"
                          @click="filterNoteByAuthor = author">
                        {{author}}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
                <b-row
                    align-h="center"
                    class="my-2"
                    v-if="model[field[0]].length && !hideNotes">
                  <b-col
                      align="center">
                    <b-btn
                        v-if="model[field[0]].length > 4"
                        @click="addNote(field[0])"
                        variant="tertiary"
                        size="sm">
                      <b-icon-plus />
                      <span
                          v-text="$t('note.add')"
                      />
                    </b-btn>
                  </b-col>
                </b-row>
                <div
                    v-if="!hideNotes"
                    class="my-4">
                  <transition-group
                      mode="out-in"
                      name="fadeQuick">
                    <div
                        v-for="note in notesToShow(field[0])"
                        :key="note._id"
                        class="record__form__note">
                      <b-row
                          align-h="center"
                          v-if="showNoteDetails !== note._id">
                        <b-col
                            align="center"
                            class="ml-2 mr-auto pl-3 pt-1"
                            cols="*">
                          <span
                              v-if="note.addedBy"
                              class="font-weight-bold"
                              v-text="note.addedBy"
                          />
                          <br />
                          <small
                              v-text="calendarDate(note.date)"
                          />
                        </b-col>
                        <b-col
                            v-if="showNoteDetails !== note._id"
                            cols="*"
                            class="ml-auto mr-2 pr-3 pt-1">
                          <b-btn-toolbar
                              v-if="deleteNoteReq !== note._id">
                            <b-btn
                                variant="danger-outline"
                                class="py-0 px-3 ml-1"
                                @click="deleteNoteReq = note._id">
                              <b-icon-trash />
                            </b-btn>
                            <b-btn
                                variant="success"
                                class="mr-auto ml-2 py-0 px-4"
                                @click="showNote(field[0], note._id)">
                              <b-icon-pencil
                                  scale="1"
                              />
                            </b-btn>
                          </b-btn-toolbar>
                          <b-btn-toolbar
                              v-else>
                            <b-btn
                                variant="info-outline"
                                @click="deleteNoteReq = null">
                              <b-icon-x /> {{$t('keep')}}
                            </b-btn>
                            <b-btn
                                variant="danger"
                                class="ml-auto mr-0"
                                @click="deleteNote(field[0], note._id)">
                              <b-icon-trash /> {{$t('delete')}}
                            </b-btn>
                          </b-btn-toolbar>
                        </b-col>
                      </b-row>
                      <b-row
                          v-if="showNoteDetails !== note._id && note.text"
                          class="mt-2 mx-5 form__note"
                          align-h="center">
                        <b-col
                            cols="*">
                          <span
                              class="font-weight-bold mx-auto"
                              v-text="note.text"
                          />
                        </b-col>
                      </b-row>
                      <transition
                          name="fadeQuick">
                        <div
                            v-if="showNoteDetails === note._id">
                          <b-row
                              align-h="center">
                            <b-col
                                align="center"
                                cols="*"
                                class="mx-3">
                              <label
                                  for="addedByInput"
                                  class="form__label"
                                  v-text="$t('note.addedBy')"
                              />
                              <b-form-input
                                  id="addedByInput"
                                  class="form__input medium"
                                  v-model="note.addedBy"
                                  type="text"
                                  v-on:keydown.13.stop
                                  v-on:keydown.shift.13="showNoteDetails = null"
                              />
                            </b-col>
                          </b-row>
                          <b-row
                              align-h="center">
                            <b-col
                                cols="12"
                                class="mx-auto mt-3">
                              <span
                                  v-text="$t('note.note')"
                                  class="form__label"
                              />
                              <b-form-textarea
                                  :id="`noteText-${note._id}`"
                                  class="record__form__note__input"
                                  v-model="note.text"
                                  :rows="4"
                                  v-on:keydown.13.stop
                                  v-on:keydown.shift.13="showNoteDetails = null"
                              />
                              <b-btn-toolbar
                                  v-if="deleteNoteReq !== note._id">
                                <b-btn
                                    variant="danger-outline"
                                    class="py-0 px-4"
                                    @click="deleteNoteReq = note._id">
                                  <b-icon-trash />
                                </b-btn>
                                <b-btn
                                    variant="success"
                                    class="ml-auto mr-0 py-0 px-5"
                                    @click="showNoteDetails = null">
                                  <b-icon-check
                                      scale="2"
                                  />
                                </b-btn>
                              </b-btn-toolbar>
                              <b-btn-toolbar
                                  v-else>
                                <b-btn
                                    variant="info-outline"
                                    @click="deleteNoteReq = null">
                                  <b-icon-x /> {{$t('keep')}}
                                </b-btn>
                                <b-btn
                                    variant="danger"
                                    class="ml-auto mr-0"
                                    @click="deleteNote(field[0], note._id)">
                                  <b-icon-trash /> {{$t('delete')}}
                                </b-btn>
                              </b-btn-toolbar>
                            </b-col>
                          </b-row>
                        </div>
                      </transition>
                      <hr />
                    </div>
                  </transition-group>
                  <b-row
                      align-h="center">
                    <b-col
                        cols="*">
                      <b-btn
                          @click="addNote(field[0])"
                          variant="tertiary"
                          size="sm">
                        <b-icon-plus />
                        <span
                            v-text="$t('note.add')"
                        />
                      </b-btn>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <!-- Number Input -->
              <b-form-input
                  :id="`${field[0]}-input`"
                  v-if="field[1].metaType === 'Number'"
                  class="form__input large"
                  type="number"
                  :step="0.000001"
                  v-model.number="model[field[0]]"
              />
              <!-- Phone Input -->
              <div
                  v-if="field[1].metaType === 'Phone'">
                <div
                    class="record__form__col__label">
                  <label
                      :for="`${field[0]}-input`"
                      v-text="field[1].label"
                      class="ml-auto"
                  />
                  <b-form-select
                      v-model="model[field[0]].format"
                      :options="phoneFormatOptions"
                      class="smallSelect mr-auto"
                  />
                </div>
                <b-form-input
                    :id="`${field[0]}-input`"
                    class="form__input large mx-auto"
                    type="text"
                    v-model="model[field[0]].number"
                    @input="formatPhone([field[0]])"
                />
              </div>
              <!-- Price Input -->
              <div
                  v-if="field[1].metaType === 'Price'">
                <div
                    class="record__form__col__label">
                  <label
                      :for="`${field[0]}-input`"
                      v-text="field[1].label"
                      class="ml-auto"
                  />
                  <b-form-select
                      v-model="model[field[0]].type"
                      :options="currencyTypes"
                      class="smallSelect mr-auto"
                  />
                </div>
                <b-input-group>
                  <b-input-group-prepend
                      class="ml-auto"
                      v-text="currencySymbol(model[field[0]].type)">
                  </b-input-group-prepend>
                  <b-form-input
                      :id="`${field[0]}-input`"
                      class="form__input smallInput mr-auto"
                      type="number"
                      :step="0.01"
                      v-model.number="model[field[0]].price"
                  />
                </b-input-group>
              </div>
              <!-- Select One -->
              <b-list-group
                  v-if="field[1].metaType === 'SelectOne'">
                <b-list-group-item
                    v-for="(option, index) in model[field[0]]"
                    :key="index"
                    :id="`${index}-toggle`"
                    button
                    :variant="model[field[0]][index].value ? 'primary' : 'light'"
                    @click="selectOneClick(field[0], index)">
                  {{ option.label }}
                </b-list-group-item>
              </b-list-group>
              <!-- Status -->
              <div
                  v-else-if="field[1].metaType === 'Status'">
                <div
                    v-if="model[field[0]]">
                  <b-form-textarea
                      v-model="model[field[0]].text"
                      :id="`${model._id}-statusInput`"
                      @click.stop
                      @change="updateStatusDate(field[0])"
                      v-on:keydown.13.stop
                      v-on:keydown.shift.13="itemActionButton"
                      class="form__input large mx-auto"
                      :placeholder="$t('collections.status.placeholder')"
                  />
                  <urgent-button
                      @urgent="model[field[0]].urgent = !model[field[0]].urgent"
                      :active="model[field[0]].urgent"
                      class="ml-0 mr-5"
                  />
                </div>
              </div>
              <!-- String Input -->
              <b-form-input
                  :id="`${field[0]}-input`"
                  v-if="field[1].metaType === 'String'"
                  class="form__input large"
                  type="text"
                  :placeholder="field[1].default"
                  v-model="model[field[0]]"
              />
              <!-- Tags -->
              <b-form-tags
                  no-outer-focus
                  v-if="field[1].metaType === 'Tags'"
                  :id="`tags-${field[0]}`"
                  v-model="model[field[0]]"
                  class="formTags">
                <template
                    v-slot="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
                  <b-input-group
                      aria-controls="my-custom-tags-list">
                    <b-form-input
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                        v-on:keydown.13.stop
                        v-on:keydown.shift.13="itemActionButton"
                        :placeholder="$t('tagNew')"
                        class="form__input large ml-auto mb-2"
                    />
                    <b-input-group-append>
                      <b-button
                          @click="addTag()"
                          variant="secondary"
                          class="mr-auto py-0 my-2"
                          style="z-index: 0">
                        {{$t('add')}}
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <ul
                      id="my-custom-tags-list"
                      class="record__cell__tags__container"
                      aria-live="polite"
                      aria-atomic="false"
                      aria-relevant="additions removals">
                    <!-- Always use the tag value as the :key, not the index! -->
                    <!-- Otherwise screen readers will not read the tag
                        additions and removals correctly -->
                    <b-card
                        v-for="tag in tags"
                        :key="tag"
                        :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                        tag="li"
                        class="mt-1 mr-1"
                        body-class="record__cell__tags__tag">
                      <strong
                          class="record__cell__tags__text">
                        {{ tag }}
                      </strong>
                      <b-button
                          @click="removeTag(tag)"
                          variant="danger-outline"
                          size="sm"
                          class="px-1 py-0"
                          :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`">
                        <b-icon-x-circle
                            :scale="0.8"
                        />
                      </b-button>
                    </b-card>
                  </ul>
                </template>
              </b-form-tags>
              <!-- Toggle -->
              <div
                  v-if="field[1].metaType === 'Toggle'">
                <b-input-group>
                  <label
                      :for="`${field[0]}-input`"
                      v-text="model[field[0]].value ? field[1].default.labelOn : (field[1].default.labelOff ? field[1].default.labelOff : null)"
                      class="ml-auto"
                  />
                  <b-form-checkbox
                      :id="`${field[0]}-input`"
                      switch
                      size="lg"
                      v-model="model[field[0]].value"
                      class="mr-auto ml-3"
                  />
                </b-input-group>
              </div>
              <!-- Textarea -->
              <voice-input
                    v-if="field[1].metaType === 'Textarea'"
                    :id="`${field[0]}-input`"
                    :itemId="field[0]"
                    :modelProp="model[field[0]]"
                    :placeholder="field[1].default"
                    @textInput="updateTextInput"
                    @startingRecognition="activeField = field[0]"
                    @enterKeyPressed="itemActionButton"
              />
              <!-- WorkDay Employee Login -->
              <div
                  v-if="field[1].metaType === 'LoginInfo'">
                <span
                    class="form__label"
                    v-text="$t('collections._employees.userAccount')"
                />
                <div
                    v-if="displayErr"
                    class="warning">
                  {{displayErr}}
                </div>
                <br />
                {{$t('collections._employees.username')}}:
                <b-form-input
                    id="usernameinput"
                    :disabled="!!(model[field[0]].signedUp)"
                    v-model="model[field[0]].username"
                    class="form__input medium"
                />
                <div
                    v-if="model[field[0]].signedUp">
                  {{$t('collections._employees.signedUp')}} {{dateSince(model[field[0]].signedUp)}}
                  <br />
                  <div
                      v-if="model[field[0]].status === 'FORCE_CHANGE_PASSWORD'">
                      {{$t('collections._employees.tempPass')}}: <b>{{model[field[0]].tempPassword}}</b>
                  </div>
                </div>
                <b-btn
                    id="createSignIn"
                    v-if="!model[field[0]].signedUp"
                    :disabled="!model[field[0]].username"
                    @click="userSignUp(field[0])"
                    class="mt-2"
                    v-text="`${$t('collections._employees.createLogin')}:`">
                </b-btn>
                <b-btn
                    id="resetPassword"
                    v-else
                    @click="userResetPassword(field[0])"
                    class="mt-2"
                    v-text="$t('collections._employees.resetPassword')">
                </b-btn>
              </div>
              <!-- WorkDay Availability Login -->
              <div
                  v-if="field[1].metaType === 'Availability'">
                <span
                    class="form__label"
                    v-text="$t('collections._employees.availability')"
                />
                <b-input-group>
                  <label
                      :for="`input-${field[0]}`"
                      v-html="model[field[0]].alwaysAvailable ? $t('collections._availability.alwaysAvailableTrue') : $t('collections._availability.alwaysAvailableFalse')"
                      class="ml-auto"
                  />
                  <b-form-checkbox
                      :id="`input-${field[0]}`"
                      switch
                      size="lg"
                      v-model="model[field[0]].alwaysAvailable"
                      class="mr-auto ml-3"
                  />
                </b-input-group>
                {{availabilitySegments}}
                <b-btn
                    id="addAvailabilitySegment"
                    @click="addAvailabilitySegment(field[0])"
                    class="mt-2"
                    v-text="`${$t('collections._availability.addSegment')}:`">
                </b-btn>
              </div>
            </b-col>
          </b-row>
          <b-row
              v-if="linkedInDisplayList.length"
              class="form__container">
            <b-col>
              <h5
                  class="text-center">
                {{$t('collections.linkedInOtherColl[0]')}}
                <b
                    class="mx-1">
                  {{linkedInDisplayList.length}}
                </b>
                {{linkedInDisplayList.length > 1
                  ? $t('collections.linkedInOtherColl[1]')
                  : $t('collections.linkedInOtherColl[2]')}}
                <b-btn
                    v-text="showLinkedRecordsDisplay ? $t('hide') : $t('show')"
                    @click="showLinkedRecordsDisplay = !showLinkedRecordsDisplay"
                    class="ml-3"
                    :variant="showLinkedRecordsDisplay ? 'secondary-outline' : 'secondary'"
                    size="sm"
                />
              </h5>
              <div
                  v-if="showLinkedRecordsDisplay">
                <div
                    v-for="(links, index) in linkedInDisplayList"
                    :key="index">
                  <h5
                      class="record__form__accent text-center">
                    {{$store.getters.collection(links.collection).name}}
                  </h5>
                  <b-row
                      :id="`linkedRecordsDisplay-${index}`"
                      v-for="link in links.links"
                      :key="link.linkedBy"
                      class="mb-4">
                      <b-col
                          cols="*">
                      <b-row>
                        <b-col
                            class="mx-auto">
                          <small
                              class="mx-3">
                            <b>{{dateSince(link.linkedOn)}}</b>
                          </small>
                        </b-col>
                      </b-row>
                      <b-row
                          class="mt-2">
                        <b-col
                            align="center">
                          <span
                              v-if="$store.getters.collection(links.collection).idType !== 'noId'"
                              :class="$store.getters.collection(links.collection).idType === 'humanId'
                                ? 'linkedItem__id__alt' : 'linkedItem__id__alt smallText'"
                              v-text="$store.getters.collection(links.collection).idType === 'humanId'
                                ? $store.getters.record(links.collection, link.linkedBy).humanId
                                : $store.getters.record(links.collection, link.linkedBy)._id"
                          />
                          {{$store.getters.collection(links.collection).name}}
                          <b-btn
                              :id="`seeDataLink-${link.linkedBy}`"
                              @click="seeDataLink(links.collection, link.linkedBy)"
                              variant="tertiary"
                              :aria-label="$t('aria.view')"
                              size="sm"
                              class="mr-auto ml-1">
                            <b-icon-search
                                scale="0.8"
                            />
                          </b-btn>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col>
                      <div
                          v-for="linkedField in Object.entries(linkedRecord(links.originField, links.collection, links.fields, link.linkedBy))"
                          :key="linkedField[0]">
                        <data-viewer
                            :addressDisplayType="collection.schemaObj[links.originField]
                              && collection.schemaObj[links.originField].default.linkedFields.find(i => i._id === linkedField[0])
                              ? collection.schemaObj[links.originField].default.linkedFields.find(i => i._id === linkedField[0]).addressDisplayType : null"
                            :collection="$store.getters.collection(links.collection)"
                            :model="linkedField"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row
              id="invoicedIn"
              align-h="center"
              class="mx-0 mt-2 mb-4"
              v-if="model.invoicedIn && model.invoicedIn.length">
            <b-col
                cols="auto"
                align="center">
              <div
                  class="record__form__col__label">
                {{$t('records.invoicedIn')}}
              </div>
              <b-list-group>
                <b-list-group-item
                    v-for="invoiced in model.invoicedIn"
                    :key="invoiced.lineItem"
                    class="record__invoicedIn">
                    <b
                        class="mx-3">
                      {{$store.getters.invoice(invoiced.document) ? $store.getters.invoice(invoiced.document).number : $t('records.pullReceiptDeleted')}}
                    </b>
                    <small
                        v-text="dateSince(invoiced.date)"
                    />
                    <b-btn
                        :id="`seeItem-${invoiced.lineItem}`"
                        :aria-label="$t('aria.showInvoice')"
                        @click="switchToRecord('Billing', invoiced.document)"
                        variant="info"
                        @mouseover="hoverOverInvoicedIn(invoiced.lineItem)"
                        @mouseleave="hoverInvoicedIn = null"
                        v-if="$store.getters.invoice(invoiced.document)"
                        class="ml-4">
                        <b-icon-eye />
                        <transition
                            mode="out-in"
                            name="fade">
                          <span
                              v-if="hoverInvoicedIn === invoiced.lineItem"
                              v-text="$t('records.invoicedView')"
                          />
                        </transition>
                    </b-btn>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-form>
      </transition>
    </div>
  </div>
</template>
<script>
/* eslint-disable vue/valid-v-if */
import moment from 'moment';
import dataViewer from '@/components/data/DataViewer';
import tableCell from '@/components/data/TableCell';
import totalCalculators from '@/mixins/totalCalculators';
import urgentButton from '@/components/singlePurpose/UrgentButton';
import voiceInput from '@/components/data/VoiceInput';

import { signUp, resetPassword } from '@/store/aws';

export default {
  name: 'RecordTable',
  title() {
    return `${this.collection.name} | ${this.$t('project.brand')}`;
  },
  mixins: [
    totalCalculators,
  ],
  components: {
    dataViewer,
    tableCell,
    urgentButton,
    voiceInput,
  },
  props: [
    'collection',
    'currentPage',
    'items',
    'needToClear',
    'perPage',
    'selectedItems',
  ],
  mounted() {
    if (this.$store.state.collectionsChangedRestart) {
      // This is a workaround for a bug that prevents brand new collections from displaying table data.
      // The data shows up in RecordAdmin, but for some reason is not passed via prop 'Items' until page is refreshed.
      this.displayJustAddedMessage = true;
      setTimeout(() => {
        this.countdownTimer = `3 ${this.$t('collections.justAddedSec')}`;
      }, 350);
      setTimeout(() => {
        this.countdownTimer = `2 ${this.$t('collections.justAddedSec')}`;
      }, 950);
      setTimeout(() => {
        this.countdownTimer = `1 ${this.$t('collections.justAddedSec')}`;
      }, 1900);
      setTimeout(() => {
        this.countdownTimer = `0 ${this.$t('collections.justAddedSec')}`;
      }, 2900);
      setTimeout(() => {
        this.$router.go();
      }, 3200);
    }
  },
  data() {
    return {
      activeField: null,
      addLinkedDataOverride: null,
      addLinkedRecord: null,
      countdownTimer: null,
      dataUrlJustCopied: false,
      deleteConfirm: null,
      deleteNoteReq: null,
      displayErr: null,
      displayJustAddedMessage: false,
      filterNoteByAuthor: null,
      hideNotes: false,
      hoverInvoicedIn: null,
      hoverPullReceipt: null,
      hoverRow: null,
      hoverRowLinked: null,
      hoverSupplier: null,
      linkedDataHoverOver: {
        field: null,
        record: null,
      },
      linkedLookup: {
        linkTo: null,
        linkOrigin: null,
        linkedFields: [],
      },
      linkedRecordsPerPage: 10,
      linkedRecordsPage: 1,
      model: {},
      populatedFieldSearchTerm: null,
      populatedFieldSearchFor: null,
      quickEditRow: null,
      quickEditCancelReq: null,
      searchTerms: [], // handles linked record searched
      searchTermPartial: null,
      showInvoicePulls: [],
      showLinkedRecordsDisplay: true,
      showNoteDetails: null,
      sortDesc: false,
      supplierTextInput: null,
      tempItemStorage: null,
      updateStatus: null,
      zipWatcher: {
        id: null,
        type: null,
      },
    };
  },
  computed: {
    additionalActions: {
      get() {
        return this.$store.state.additionalActions;
      },
      set(newVal) {
        this.$store.state.additionalActions = newVal;
      },
    },
    addItem: {
      // Notifies the system a new file is being added
      get() {
        return this.$store.state.addNew;
      },
      set(newVal) {
        this.$store.state.addNew = newVal;
      },
    },
    addressesInCollection() {
      const ids = [];
      if (this.collection.schemaObj) {
        const entries = Object.entries(this.collection.schemaObj);
        entries.forEach((e) => {
          if (e[1].metaType === 'Address') {
            if (!ids.length) {
              ids.push(e[0]);
            } else if (this.model[e[0]].expanded) {
              ids.push(e[0]);
            }
          }
        });
      }
      return ids;
    },
    currencyTypes() {
      return [
        'usd',
        'eur',
      ];
    },
    dataUrlText() {
      let url = `${this.$t('project.url')}${this.$route.path}`;
      if (!this.$route.params.id) {
        url += `/${this.model._id}`;
      }
      return url;
    },
    dateAdded() {
      return moment(this.model.dateAdded).calendar();
    },
    editItem: {
      get() {
        return this.$store.state.editItem;
      },
      set(newVal) {
        this.$store.state.editItem = newVal;
      },
    },
    enterButton: {
      get() {
        return this.$store.state.enterButton;
      },
      set(newVal) {
        this.$store.state.enterButton = newVal;
      },
    },
    escButton: {
      get() {
        return this.$store.state.escButton;
      },
      set(newVal) {
        this.$store.state.escButton = newVal;
      },
    },
    fields() {
      return this.$store.getters.fields(this.$route.params.collection, 'form');
    },
    fieldsToDisplay() {
      return this.$store.getters.fields(this.$route.params.collection, 'display');
    },
    itemActionButtonPress: {
      get() {
        return this.$store.state.itemActionButtonPress;
      },
      set(newVal) {
        this.$store.state.itemActionButtonPress = newVal;
      },
    },
    itemActionCancelPress: {
      get() {
        return this.$store.state.itemActionCancelPress;
      },
      set(newVal) {
        this.$store.state.itemActionCancelPress = newVal;
      },
    },
    humanId() {
      let humanId;
      const prefix = this.collection.humanIdPrefix;
      const numbers = [];
      this.items.forEach((item) => {
        if (item.humanId) {
          if (Number(item.humanId)) {
            numbers.push(item.humanId);
          } else {
            const id = item.humanId.split('-')[item.humanId.split('-').length - 1];
            numbers.push(id);
          }
        }
      });
      let count;
      if (numbers.length) {
        count = Math.max(...numbers);
      } else {
        count = 0;
      }
      if (prefix) {
        humanId = `${prefix}-${count + 1}`;
      } else {
        humanId = count + 1;
      }
      return humanId;
    },
    goBackToPreviousItemText() {
      let text = this.$t('goBackSave[1]');
      if (this.justViewed.collection) {
        if (this.justViewed.collection !== 'billing') {
          text += ` ${this.$store.getters.collection(this.justViewed.collection).name}`;
        } else {
          text += ` ${this.$t('collections.billing')}`;
        }
      }
      return text;
    },
    justViewed: {
      get() {
        return this.$store.state.justViewed;
      },
      set(newVal) {
        this.$store.state.justViewed = newVal;
      },
    },
    lastUpdated() {
      return moment(this.model.dateUpdated).calendar();
    },
    linkedInDisplayList() {
      /*
        {
          label
          linkedColl
          fields: [] fields to display
          items: [] list of items that have been linked to this record
        }
      */
      const linksToShow = [];
      const links = this.model && this.model.linkedIn ? this.model.linkedIn : [];
      if (links.length) {
        const newLinks = [];
        links.forEach((l) => {
          let index = -1;
          if (newLinks.length) {
            index = newLinks.findIndex(i => i.originField === l.originField);
          }
          if (index === -1) {
            newLinks.push(l);
          }
        });
        if (newLinks.length) {
          for (let a = 0; a < newLinks.length; a += 1) {
            if (newLinks[a].collection) {
              // TODO -- This is a workaround.
              // When a collection is linked by a collection that contains multiple links, and then those links are deleted,
              // the link is not currently being removed.
              let schemaObj;
              if (typeof newLinks[a].collection === 'string') {
                schemaObj = this.$store.getters.collection(newLinks[a].collection).schemaObj;
              } else {
                schemaObj = newLinks[a].collection.schemaObj;
              }
              const linkedField = schemaObj[newLinks[a].originField];
              if (linkedField.displayLinked
                && linkedField.displayLinkedIn.length) {
                const link = newLinks[a];
                link.collection = newLinks[a].collection;
                link.fields = linkedField.displayLinkedIn;
                link.label = linkedField.label;
                linksToShow.push(link);
              }
            }
          }
        }
      }
      return linksToShow;
    },
    linkedRecordSaved() {
      return this.$store.state.linkedRecordSaved;
    },
    linkedRecordsPaginated() {
      const records = this.linkedRecords;
      const perPage = this.linkedRecordsPerPage;
      const page = this.linkedRecordsPage;
      return records.slice(((page - 1) * perPage), page * perPage);
    },
    linkedRecords() {
      // Populate linked records.
      if (this.linkedLookup.linkTo) {
        const records = {};
        const recs = this.$store.getters.records(this.linkedLookup.linkTo);
        const field = [
          this.linkedLookup.linkOrigin,
          {
            default: {
              linkedFields: this.linkedLookup.linkedFields,
              linkTo: this.linkedLookup.linkTo,
            },
          },
        ];
        if (recs) {
          for (let a = 0; a < recs.length; a += 1) {
            if (this.linkedRecordCaller(field, recs[a]._id)) {
              records[recs[a]._id] = this.linkedRecordCaller(field, recs[a]._id);
            }
          }
          if (this.linkedItemSearchNeeded) {
            const items = this.linkedItemsToSearch(records, this.linkedLookup.linkTo);
            const terms = this.linkedItemSearchTerms;
            for (let a = 0; a < items.length; a += 1) {
              let matchCount = 0;
              for (let t = 0; t < terms.length; t += 1) {
                for (let b = 0; b < items[a].data.length; b += 1) {
                  if (items[a].data[b].includes(terms[t])) {
                    matchCount += 1;
                    break;
                  }
                }
                if (matchCount >= terms.length) {
                  break;
                }
              }
              if (matchCount < terms.length) {
                delete records[items[a]._id];
              }
            }
          }
          return Object.entries(records);
        }
      }
      return [];
    },
    linkedItemSearchNeeded() {
      if ((this.searchTerms && this.searchTerms.length) || this.searchTermPartial) {
        return true;
      }
      return false;
    },
    linkedItemSearchTerms() {
      const terms = [];
      if (this.searchTermPartial) {
        terms.push(this.searchTermPartial.toLowerCase());
      }
      if (this.searchTerms && this.searchTerms.length) {
        this.searchTerms.forEach(i => terms.push(i.toLowerCase()));
      }
      return terms;
    },
    modelDefault() {
      let model = {
        _collection: this.collection._id,
        dateAdded: new Date(),
        dateUpdated: new Date(),
        humanId: this.humanId,
        linkedIn: [],
        invoicedIn: [],
      };
      const schemaObj = this.collection.schemaObj;
      if (schemaObj) {
        const schema = JSON.parse(JSON.stringify(schemaObj));
        const keys = Object.keys(schema);
        model = this.applyDefaults(model, schema, keys);
      }
      return model;
    },
    phoneFormatOptions() {
      return [
        {
          value: 'usaFormat',
          text: this.$t('collections.phoneTypes.usForm'),
        },
        {
          value: 'noFormat',
          text: this.$t('collections.phoneTypes.noForm'),
        },
      ];
    },
    quickEntry() {
      return this.collection.quickEntry;
    },
    routeWithItemId() {
      if (this.$route.params.id) {
        return this.$route.params.id;
      }
      return false;
    },
    showItem() {
      return this.$store.state.showItem;
    },
    showTable() {
      if (this.addItem || this.editItem || this.displayJustAddedMessage || this.viewItem.record) {
        return false;
      }
      return true;
    },
    suppliersDropdown() {
      const suppliers = [];
      this.$store.getters.allSuppliers.forEach(i => suppliers.push({ text: i.name, value: i._id }));
      return suppliers;
    },
    suppliersToShow() {
      const items = [];
      this.$store.getters.allSuppliers
        .filter(i => ((i.name && i.name.toLowerCase().includes(this.supplierTextInput.toLowerCase()))
        || (i.contactPrimary && i.contactPrimary.toLowerCase().includes(this.supplierTextInput.toLowerCase()))))
        .forEach(i => items.push({ name: i.name, contact: i.contactPrimary, _id: i._id }));
      return items;
    },
    showSpinner() {
      return this.$store.state.showSpinner;
    },
    tableVisible() {
      if (this.addItem || this.editItem) {
        return false;
      }
      return true;
    },
    viewItem: {
      get() {
        return this.$store.state.viewItem;
      },
      set(newVal) {
        this.$store.state.viewItem = newVal;
      },
    },
    zipResults() {
      return this.$store.state.zipLookup;
    },
  },
  created() {
    if (this.$route.params.id) {
      console.log('setting created viewitem');
      this.viewItem.record = this.$route.params.id;
    }
  },
  methods: {
    addLinkedData(fieldKey) {
      if (this.linkedLookup.linkOrigin) {
        this.linkedLookup = {
          linkTo: null,
          linkOrigin: null,
          linkedFields: [],
        };
      }
      this.addLinkedDataOverride = fieldKey;
      const newId = this.$guid();
      const model = {
        _id: newId,
        humanId: this.$t('tempId'),
        dateAdded: new Date(),
        dateUpdated: new Date(),
        linkedIn: [],
        invoicedIn: [],
      };
      const linkedFields = [];
      const linkTo = this.collection.schemaObj[fieldKey].default.linkTo;
      const schemaObj = this.$store.getters.collection(linkTo).schemaObj;
      if (schemaObj) {
        const schema = JSON.parse(JSON.stringify(schemaObj));
        this.collection.schemaObj[fieldKey].default.linkedFields.forEach((lf) => {
          if (schema[lf._id]) {
            linkedFields.push(lf._id);
          }
        });
        this.model[fieldKey].dataOverride = this.applyDefaults(model, schema, linkedFields);
      }
    },
    addLinkedDataSave(fieldKey) {
      const linkTo = this.collection.schemaObj[fieldKey].default.linkTo;
      const model = this.model[fieldKey].dataOverride;
      delete model._id;
      model._collection = linkTo;
      model.humanId = this.humanIdLinked(linkTo);
      model.dateAdded = new Date();
      model.dateUpdated = new Date();
      this.addLinkedRecord = fieldKey;
      this.$store.dispatch('addRecordViaLink', { collection: linkTo, record: model, requestId: fieldKey });
    },
    addLinkedDataUse(fieldKey) {
      const newId = this.model[fieldKey].dataOverride._id;
      this.model[fieldKey].linksChosen.push(`temp__${newId}`);
      if (!this.model[fieldKey].tempData) {
        this.model[fieldKey].tempData = [];
      }
      this.model[fieldKey].tempData.push({ ...this.model[fieldKey].dataOverride });
      this.dataOverrideClear(fieldKey);
    },
    addNote(field) {
      if (this.hideNotes) {
        this.hideNotes = false;
      }
      const newId = this.$uuid.v4();
      this.model[field].push(
        {
          _id: newId,
          date: new Date(),
          addedBy: this.$store.state.offline ? 'Dev text' : this.titleCase(this.$store.state.user.attributes.email),
          text: null,
        },
      );
      this.filterNoteByAuthor = null;
      this.showNoteDetails = newId;
      setTimeout(() => {
        document.getElementById(`noteText-${newId}`).focus();
      }, 250);
    },
    applyDefaults(incModel, schema, keys) {
      const model = { ...incModel };
      for (let a = 0; a < keys.length; a += 1) {
        if (schema[keys[a]].default) {
          if (typeof schema[keys[a]].default === 'object') {
            // copy -- not ref -- object values, to create clean defaults
            const metaType = schema[keys[a]].metaType;
            switch (metaType) {
              default: {
                model[keys[a]] = JSON.parse(JSON.stringify(schema[keys[a]].default));
                break;
              }
              case ('id'): {
                console.log('id called');
                break;
              }
              case ('Link'): {
                model[keys[a]] = {
                  linksChosen: [],
                  dataOverride: {
                    _id: null,
                  },
                  tempData: [],
                };
                break;
              }
              case ('MultiSelect'):
              case ('SelectOne'): {
                const options = Object.entries({ ...schema[keys[a]].default });
                const optionsObj = {};
                for (let z = 0; z < options.length; z += 1) {
                  optionsObj[options[z][0]] = { ...options[z][1] };
                }
                model[keys[a]] = { ...optionsObj };
                break;
              }
              case ('Tags'): {
                model[keys[a]] = [...schema[keys[a]].default];
                break;
              }
              case ('Toggle'): {
                model[keys[a]] = schema[keys[a]].default;
                break;
              }
            }
          } else if (typeof schema[keys[a]].default === 'string' || typeof schema[keys[a]].default === 'number') {
            model[keys[a]] = schema[keys[a]].default;
          } else {
            model[keys[a]] = schema[keys[a]].default.slice(0);
          }
        } else {
          model[keys[a]] = null;
        }
      }
      return model;
    },
    checkModel(req) {
      // Checks the model vs fields, and adds entries for undefined. (So when a new type is added, old records wont break)
      const model = req;
      for (let a = 0; a < this.fields.length; a += 1) {
        const i = this.fields[a];
        if (typeof model[i[0]] === 'undefined') {
          if (i[1].default) {
            model[i[0]] = JSON.parse(JSON.stringify(this.modelDefault[i[0]]));
          } else {
            model[i[0]] = null;
          }
        }
      }
      return model;
    },
    colCount(metaType) {
      // Determines how many Cols each metaType receives in Add/Edit form
      switch (metaType) {
        case ('Address'):
        case ('Link'):
        case ('Textarea'): {
          return 5;
        }
        case ('Notes'):
        case ('Tags'):
        case ('Status'): {
          return 5;
        }
        default: {
          return 5;
        }
        case ('Toggle'): {
          return 5;
        }
      }
    },
    copyDataLink() {
      this.$nextTick(() => {
        document.getElementById('copyTextInput').select();
        document.getElementById('copyTextInput').setSelectionRange(0, 99999); /* For mobile devices */
        document.execCommand('copy');
        this.dataUrlJustCopied = true;
        setTimeout(() => {
          this.dataUrlJustCopied = false;
        }, 1800);
      });
    },
    dataOverrideClear(fieldKey) {
      this.addLinkedDataOverride = null;
      this.model[fieldKey].dataOverride = {
        _id: null,
        humanId: null,
      };
    },
    dateDisplay(date) {
      return moment(date).format('l');
    },
    deleteItem(id) {
      this.$store.dispatch('removeRecord', { collection: this.collection._id, record: id });
      this.deleteConfirm = null;
    },
    deleteNote(field, id) {
      const index = this.model[field].findIndex(i => i._id === id);
      this.model[field].splice(index, 1);
      this.deleteNoteReq = null;
    },
    editItemClick(id) {
      this.editItem = true;
      const item = this.items.find(i => i._id === id);
      this.model = this.checkModel(JSON.parse(JSON.stringify(item)));
    },
    editItemFlexClick(id) {
      if (!this.quickEntry) {
        this.editItemClick(id);
      } else {
        this.quickEditRow = id;
      }
    },
    fieldsToOverride(linkedFields, linkTo) {
      // Check if fields still exist in linked collection
      const fields = [];
      linkedFields.forEach((f) => {
        if (this.linkedCollection(linkTo).schemaObj[f._id]) {
          fields.push(f);
        }
      });
      return fields;
    },
    formatPhone(fieldKey) {
      if (this.model[fieldKey].format === 'usaFormat') {
        this.model[fieldKey].number = this.model[fieldKey].number.replace(/[^A-Z0-9]+/ig, '');
        this.model[fieldKey].number = this.displayPhone(this.model[fieldKey].number);
      }
    },
    handleEnterPress() {
      // TODO this is not working properly
      if (!this.addItem && !this.editItem && this.quickEditRow !== null) {
        this.quickSaveRequest();
      } else {
        this.itemActionButton();
      }
    },
    hoverOverLinked(e) {
      this.hoverRowLinked = e[0];
    },
    hoverOverLinkedEnd() {
      this.hoverRowLinked = null;
    },
    hoveredRow(e) {
      this.hoverRow = e._id;
    },
    hoveredRowEnd() {
      this.hoverRow = null;
    },
    hoverOverInvoicedIn(id) {
      this.hoverInvoicedIn = id;
    },
    hoverOverSupplier() {
      this.hoverSupplier = !this.hoverSupplier;
    },
    hoverOverPullReceipt(id) {
      this.hoverPullReceipt = id;
    },
    humanIdLinked(collectionId) {
      let humanId;
      const collection = this.$store.getters.collection(collectionId);
      const prefix = collection.humanIdPrefix;
      const numbers = [];
      const items = this.$store.getters.records(collectionId);
      items.forEach((item) => {
        if (Number(item.humanId)) {
          numbers.push(item.humanId);
        } else {
          const id = item.humanId.split('-')[item.humanId.split('-').length - 1];
          numbers.push(id);
        }
      });
      let count;
      if (numbers.length) {
        count = Math.max(...numbers);
      } else {
        count = 0;
      }
      if (prefix) {
        humanId = `${prefix}-${count + 1}`;
      } else {
        humanId = count + 1;
      }
      return humanId;
    },
    itemActionButton() {
      if (this.justViewed.collection || this.justViewed.record) {
        this.justViewed = {
          collection: null,
          record: null,
        };
      }
      if (!this.addItem && !this.editItem) { // Adding a new item
        this.addItem = true;
        // this.addingNew = true;
        this.model = JSON.parse(JSON.stringify(this.modelDefault));
        setTimeout(() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }, 320);
      } else if (this.editItem) { // Updating an item
        this.model.dateUpdated = new Date();
        this.$store.dispatch('updateRecord', { collection: this.collection._id, record: this.model });
        this.editItem = null;
        // this.addingNew = false;
        this.model = null;
        setTimeout(() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }, 320);
      } else { // Saving a record
        this.model.dateAdded = new Date();
        this.model.dateUpdated = new Date();
        this.$store.dispatch('addRecord', { collection: this.collection._id, record: this.model });
        this.addItem = false;
        // this.addingNew = false;
        this.model = null;
      }
      if (this.additionalActions.length) {
        this.additionalActions = [];
      }
    },
    itemActionCancel() {
      console.log('this cancel instead');
      this.itemActionCancelPress = true;
    },
    linkedCollection(linkTo) {
      if (linkTo && this.$store.getters.collection(linkTo)) {
        return this.$store.getters.collection(linkTo);
      }
      return {};
    },
    linkedInDisplay(link) {
      const displayData = link;
      return displayData;
    },
    linkedDataHover(record, field) {
      this.linkedDataHoverOver.record = record;
      this.linkedDataHoverOver.field = field;
    },
    linkedDataHoverDone() {
      this.linkedDataHoverOver = {
        field: null,
        record: null,
      };
    },
    linkedDataFindButton(field) {
      if (this.linkedLookup.linkOrigin !== field[0]) {
        this.linkedLookup.linkTo = field[1].default.linkTo;
        this.linkedLookup.linkedFields = field[1].default.linkedFields;
        this.linkedLookup.linkOrigin = field[0];
      } else {
        this.linkedLookup = {
          linkTo: null,
          linkOrigin: null,
          linkedFields: [],
        };
      }
    },
    linkedDataRemove(record, field) {
      const tempCheck = record.split('__');
      if (tempCheck[0] === 'temp') {
        const index = this.model[field[0]].tempData.findIndex(i => i._id === tempCheck[1]);
        this.model[field[0]].tempData.splice(index, 1);
      } else {
        this.manageRecordLink(record, field, 'remove');
      }
      this.model[field[0]].linksChosen.splice(this.model[field[0]].linksChosen.indexOf(record), 1);
      this.linkedDataHoverOver.record = record;
      this.linkedDataHoverOver.field = field[0];
    },
    linkedDataView(record, field) {
      const tempCheck = record.split('__');
      if (tempCheck[0] === 'temp') {
        this.addLinkedDataOverride = field[0];
      } else {
        this.switchToRecord(field[1].default.linkTo, record);
      }
      this.model[field[0]].linksChosen.splice(this.model[field[0]].linksChosen.indexOf(record), 1);
      this.linkedDataHoverOver.record = record;
      this.linkedDataHoverOver.field = field[0];
    },
    linkedItemsToSearch(inc, coll) {
      // Creates searchable objects based on metatype, pairs them with item id
      // This method is slightly different than itemsToSearch in RecordAdmin.vue. This is a reductive search (it deletes keys that do not match)
      const items = Object.entries(inc);
      const workingItems = [];
      const collection = this.$store.getters.collection(coll);
      for (let x = 0; x < items.length; x += 1) {
        const item = Object.entries(items[x][1]);
        const workingItem = [];
        for (let a = 0; a < item.length; a += 1) {
          const metaType = item[a][1].metaType;
          if (collection.schemaObj[item[a][0]]) {
            if (item[a][1]) {
              if (metaType === 'Address' && item[a][1].data.address) {
                const adr = item[a][1].data.address;
                if (adr.attn) {
                  workingItem.push(adr.attn.toLowerCase());
                }
                if (adr.street) {
                  workingItem.push(adr.street.toLowerCase());
                }
                if (adr.streetSecond && adr.streetSecondShow) {
                  workingItem.push(adr.streetSecond.toLowerCase());
                }
                if (adr.city) {
                  workingItem.push(adr.city.toLowerCase());
                }
                if (adr.state) {
                  workingItem.push(adr.state.toLowerCase());
                }
                if (adr.city && adr.state) {
                  workingItem.push(`${adr.city.toLowerCase()}, ${adr.state.toLowerCase()}`);
                }
                if (adr.zip) {
                  workingItem.push(adr.zip);
                }
                if (adr.country) {
                  workingItem.push(adr.country.toLowerCase());
                }
                if (adr.postal) {
                  workingItem.push(adr.postal.toLowerCase());
                }
              }
              if (metaType === 'Email') {
                workingItem.push(item[a][1].data.toLowerCase());
              }
              if (metaType === 'MultiSelect' || metaType === 'SelectOne') {
                item[a][1].data.forEach((entry) => {
                  workingItem.push(entry.toLowerCase());
                });
              }
              if (metaType === 'Number' && item[a][1].data) {
                workingItem.push(item[a][1].data.toString());
              }
              if (metaType === 'Notes' && item[a][1].data.length) {
                item[a][1].data.forEach((note) => {
                  workingItem.push(note.text.toLowerCase());
                });
              }
              if (metaType === 'Phone') {
                if (item[a][1].data.number) {
                  workingItem.push(item[a][1].data.number.replace(/[^A-Z0-9]+/ig, ''));
                }
              }
              if (metaType === 'Price' && item[a][1].data.price) {
                workingItem.push(item[a][1].data.price.toString());
              }
              if (metaType === 'Status') {
                if (item[a][1].data.text) {
                  workingItem.push(item[a][1].data.text.toLowerCase());
                }
              }
              if (metaType === 'String' || metaType === 'Textarea') {
                workingItem.push(item[a][1].data.toLowerCase());
              }
              if (metaType === 'Tags') {
                item[a][1].data.forEach((tag) => {
                  workingItem.push(tag.toLowerCase());
                });
              }
            }
            if (metaType === 'Toggle') {
              if (item[a][1].data
              && collection.schemaObj[item[a][0]].default.labelOn) {
                workingItem.push(collection.schemaObj[item[a][0]].default.labelOn.toLowerCase());
              }
              if (!item[a][1].data
              && collection.schemaObj[item[a][0]].default.labelOff) {
                workingItem.push(collection.schemaObj[item[a][0]].default.labelOff.toLowerCase());
              }
            }
          }
        }
        if (workingItem.length) {
          workingItems.push({ _id: items[x][0], data: workingItem });
        }
      }
      return workingItems;
    },
    linkedRecord(fieldKey, collId, fields, recId) {
    // linkedRecord(field, recId) {
      // Display linked record data
      // const collId = field[1].default.linkTo;
      // const fieldKey = field[0];
      // const fields = field[1].default.linkedFields;
      let item = recId;
      const tempCheck = recId.split('__');
      if (tempCheck[0] === 'temp') {
        item = this.model[fieldKey].tempData.find(i => i._id === tempCheck[1]);
      }
      return this.$store.getters.recordLink(collId, item, fields);
    },
    linkedRecordCaller(field, recId) {
      return this.linkedRecord(field[0], field[1].default.linkTo, field[1].default.linkedFields, recId);
    },
    linkedByRecord(linkId, linkedBy, fields) {
      const record = this.$store.getters.record(linkId, linkedBy);
      const rec = {
        _id: record._id,
      };
      for (let a = 0; a < fields.length; a += 1) {
        if (record[fields[a]]) {
          rec[fields[a]] = record[fields[a]];
        }
      }
      return rec;
    },
    linkedRowClicked(linkedItemId, field) {
      const linksChosen = this.model[field[0]].linksChosen;
      if (linksChosen.indexOf(linkedItemId) === -1) {
        linksChosen.push(linkedItemId);
        this.manageRecordLink(linkedItemId, field, 'add');
        if (!this.collection.schemaObj[field[0]].default.allowMany) {
          this.linkedLookup.linkOrigin = null;
          this.linkedLookup.linkTo = null;
          this.linkedLookup.linkedFields = null;
        }
      } else {
        this.manageRecordLink(linkedItemId, field, 'remove');
        linksChosen.splice(linksChosen.indexOf(linkedItemId), 1);
      }
    },
    manageRecordLink(linkedItemId, field, action) {
      // Adds a record link receipt. (checks for duplicate actions before adding, only the latest action is sent to api)
      // linkedItemId: the item that was linked to
      // field: the field containing the references
      const index = this.additionalActions.findIndex(act => act.linkedItemId === linkedItemId && act.originField[0] === field[0]);
      if (index > -1) {
        this.additionalActions.splice(index, 1);
      }
      this.additionalActions.push(
        {
          linkedItemId,
          linkTo: field[1].default.linkTo,
          linkFrom: this.collection._id,
          originField: field[0],
          action,
          time: new Date(),
        },
      );
    },
    noteAuthors(field) {
      const authors = [];
      for (let a = 0; a < this.model[field].length; a += 1) {
        if (this.model[field][a].addedBy
            && !authors.find(i => i === this.titleCase(this.model[field][a].addedBy))) {
          authors.push(this.titleCase(this.model[field][a].addedBy));
        }
      }
      return authors;
    },
    notesToShow(field) {
      if (!this.filterNoteByAuthor) {
        return this.model[field];
      }
      const notes = [...this.model[field]];
      return notes.filter(i => i.addedBy.toLowerCase() === this.filterNoteByAuthor.toLowerCase());
    },
    pullReceipts(pulls) {
      // Returns the latest 5 Invoiced In receipts
      const list = [];
      for (let a = pulls.length - 1; a > pulls.length - 6; a -= 1) {
        list.push(pulls[a]);
      }
      return list;
    },
    quickEditCancel() {
      const i = this.items.findIndex(w => w._id === this.quickEditRow);
      this.items.splice(i, 1, this.tempItemStorage);
      this.quickEditCancelReq = this.quickEditRow;
      this.tempItemStorage = null;
      this.quickEditRow = null;
    },
    quickEditSave(incoming, nextUp) {
      const item = this.items.find(i => i._id === incoming);
      item.dateUpdated = new Date();
      this.$store.dispatch('updateRecord', { collection: this.collection._id, record: item });
      if (nextUp) {
        this.quickEditRow = nextUp;
      } else {
        this.quickEditRow = null;
      }
    },
    quickNoteSave(model, field, note) {
      const item = this.items.find(i => i._id === model);
      if (!item[field]) {
        item[field] = [];
      }
      item[field].push(note);
      this.$store.dispatch('updateRecord', { collection: this.collection._id, record: item });
    },
    quickSaveRequest() {
      if (this.quickEntry && this.quickEditRow) {
        this.quickEditSave(this.quickEditRow);
      } else {
        this.itemActionButton();
      }
    },
    rowClicked(e) {
      this.$emit('selected', e);
    },
    saveThenGoBack() {
      this.model.dateUpdated = new Date();
      this.$store.dispatch('updateRecord', { collection: this.collection._id, record: this.model });
      this.itemActionCancel();
    },
    scrollToItem() {
      this.$emit('scroll-to');
    },
    searchTermEntry(e) {
      this.searchTermPartial = e[0];
    },
    seeDataLink(collection, record) {
      const tempCheck = record.split('__');
      if (tempCheck[0] !== 'temp') {
        this.justViewed = {
          record: null,
          collection: null,
        };
        this.switchToRecord(collection, record);
      }
    },
    seeDataLinkFromTable({ collection, record }) {
      const tempCheck = record.split('__');
      if (tempCheck[0] !== 'temp') {
        this.switchToRecord(collection, record, 'returnToTableView');
      }
    },
    seeSupplier(id) {
      this.$router.push('/suppliers');
      setTimeout(() => {
        this.editItem = id;
      }, 500);
    },
    selectedLinks(fieldKey) {
      return this.model[fieldKey].linksChosen;
    },
    selectOneClick(fieldKey, id) {
      const options = Object.entries(this.model[fieldKey]);
      for (let a = 0; a < options.length; a += 1) {
        if (options[a][0] === id) {
          options[a][1].value = !options[a][1].value;
        } else {
          options[a][1].value = false;
        }
      }
    },
    showInvoicePullsClick(fieldKey) {
      const index = this.showInvoicePulls.indexOf(fieldKey);
      if (index > -1) {
        this.showInvoicePulls.splice(index, 1);
      } else {
        this.showInvoicePulls.push(fieldKey);
      }
    },
    showNote(field, id) {
      this.showNoteDetails = id;
      const note = this.model[field].find(i => i._id === id);
      setTimeout(() => {
        document.getElementById(`noteText-${id}`).focus();
      }, 250);
      note.date = new Date();
    },
    switchToRecord(collection, record, returnToTableView) {
      this.justViewed = {
        record: this.model && this.model._id && !returnToTableView ? this.model._id : 'tableView',
        collection: this.collection._id,
      };
      this.viewItem = {
        record,
        collection,
      };
    },
    tempPassword() {
      // Generates a simple numeric password for new user accounts.
      return moment().format('SSSMMHH');
    },
    updateStatusDate(field) {
      this.model[field].dateOfStatus = new Date();
    },
    async userResetPassword(fieldKey) {
      const password = this.tempPassword();
      if (this.displayErr) {
        this.displayErr = null;
      }
      const req = await resetPassword(this.model[fieldKey].username, password);
      if (!req.err && req.httpResponse.statusCode === 200) {
        this.model[fieldKey].tempPassword = password;
      } else {
        this.displayErr = req.err;
      }
    },
    updateTextInput(input) {
      this.model[this.activeField] = input;
    },
    async userSignUp(fieldKey) {
      // create users for WorkDay logins
      const password = this.tempPassword();
      if (this.displayErr) {
        this.displayErr = null;
      }
      const userDetails = await signUp(this.model[fieldKey].username, password);
      if (!userDetails.err) {
        this.model.addingUser = userDetails.Attributes[0].Value;
        this.model[fieldKey].signedUp = new Date();
        this.model[fieldKey].idToken = userDetails.Attributes[0].Value;
        this.model[fieldKey].username = userDetails.Username;
        this.model[fieldKey].status = userDetails.UserStatus;
        this.model[fieldKey].tempPassword = password;
      } else {
        this.displayErr = userDetails.err;
      }
    },
    zipLookup(zip, fieldId) {
      if (this.model[fieldId].type !== 'usaFormat') {
        return;
      }
      if (zip.length === 5) {
        console.log('zip lookup', zip, fieldId);
        const lookup = {
          id: fieldId,
          zip,
        };
        this.zipWatcher.id = fieldId;
        this.zipWatcher.type = 'field';
        this.$store.dispatch('zipLookup', lookup);
      } else {
        this.zipWatcher.id = null;
        this.zipWatcher.type = null;
      }
    },
  },
  watch: {
    addItem(newVal) { // local version
      if (newVal) {
        this.model = JSON.parse(JSON.stringify(this.modelDefault));
        if (this.fields.length) {
          this.$nextTick(() => {
            if (document.getElementById(`${this.fields[0][0]}-input`)) {
              document.getElementById(`${this.fields[0][0]}-input`).focus();
              document.getElementById(`${this.fields[0][0]}-input`).select();
            }
          });
        }
      }
      // } else {
      //   this.addingNew = false;
      // }
    },
    // addingNew(newVal, oldVal) { // vuex store
    //   if (!oldVal && newVal) {
    //     this.addNewSupplier = false;
    //     this.addItem = true;
    //     if (this.$store.state.addNewSupplemental) {
    //       this.$nextTick(() => {
    //         this.model.supplier = this.$store.state.addNewSupplemental;
    //         this.$store.state.addNewSupplemental = null;
    //       });
    //     }
    //   } else {
    //     this.itemActionCancel();
    //   }
    // },
    editItem(newVal) {
      if (newVal) {
        if (newVal !== true) {
          const item = this.items.filter(i => i._id === newVal)[0];
          this.model = JSON.parse(JSON.stringify(item));
        }
        if (this.fields.length) {
          this.$nextTick(() => {
            if (document.getElementById(`${this.fields[0][0]}-input`)) {
              document.getElementById(`${this.fields[0][0]}-input`).focus();
              document.getElementById(`${this.fields[0][0]}-input`).select();
            }
          });
        }
      }
    },
    enterButton(pressed) {
      if (pressed) {
        this.handleEnterPress();
      }
      this.escButton = false;
    },
    escButton(pressed) {
      if (pressed) {
        if (this.quickEditRow) {
          this.quickEditCancel();
        } else if (this.addItem || this.editItem) {
          this.itemActionCancel();
        } else {
          this.$refs.recordtable.clearSelected();
        }
      }
      this.escButton = false;
    },
    itemActionButtonPress(newVal) {
      if (newVal) {
        this.itemActionButton();
        this.itemActionButtonPress = null;
      }
    },
    itemActionCancelPress(newVal) {
      if (newVal) {
        this.addItem = false;
        this.editItem = null;
        this.itemActionCancelPress = null;
        if (this.justViewed.collection && this.justViewed.collection !== this.collection._id) {
          if (this.justViewed.collection !== 'billing') {
            this.switchToRecord(this.justViewed.collection, this.justViewed.record);
            this.justViewed = {
              collection: null,
              record: null,
            };
          } else {
            this.$router.push('/dashboard/billing');
            setTimeout(() => {
              this.$store.state.showItem = this.justViewed.record;
              this.justViewed = {
                collection: null,
                record: null,
              };
            }, 300);
          }
        } else if (this.addItem || this.editItem) {
          if (this.$route.params.id) {
            // Clear the itemId from the url
            this.$router.push(`/dashboard/records/${this.$route.params.collection}`);
          }
          setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }, 250);
          this.model = null;
        }
        if (this.additionalActions.length) {
          this.additionalActions = [];
        }
      }
    },
    routeWithItemId(newVal) {
      if (newVal && this.model && this.model._id !== newVal) {
        this.viewItem.record = newVal;
      }
    },
    'linkedRecordSaved.requestId': {
      handler(newVal) {
        if (newVal) {
          if (newVal === this.addLinkedRecord) {
            this.model[newVal].linksChosen.push(this.linkedRecordSaved.recordId);
            this.manageRecordLink(this.linkedRecordSaved.recordId, [newVal, this.$store.getters.collection(this.collection._id).schemaObj[newVal]], 'add');
            this.dataOverrideClear(newVal);
          }
          this.$store.state.linkedRecordSaved.requestId = null;
          this.$store.state.linkedRecordSaved.recordId = null;
        }
      },
    },
    supplierTextInput(newVal) {
      this.model.supplierToAdd = newVal;
    },
    needToClear(newVal) {
      if (newVal) {
        this.$refs.recordtable.clearSelected();
      }
    },
    quickEditRow(newVal, oldVal) {
      if (newVal && oldVal && !this.quickEditCancelReq) {
        this.quickEditSave(oldVal, newVal);
      } else if (oldVal && oldVal !== this.quickEditCancelReq) {
        this.quickEditSave(oldVal);
      }
      if (newVal) {
        this.tempItemStorage = { ...this.$store.getters.record(this.collection._id, newVal) };
      }
      if (!newVal) {
        this.tempItemStorage = null;
      }
    },
    searchTerms(newVal) {
      if (newVal) {
        this.searchTermPartial = null;
      }
    },
    showSpinner(newVal, oldVal) {
      if (!newVal && oldVal) {
        if (this.editItem) {
          this.editItem = false;
        }
        if (this.addItem) {
          this.addItem = false;
        }
      }
    },
    tableVisible(newVal) {
      this.$emit('tableVisible', newVal);
      if (newVal && this.selectedItems.length) {
        this.$nextTick(() => {
          for (let a = 0; a < this.selectedItems.length; a += 1) {
            const index = this.items.findIndex(i => i._id === this.selectedItems[a]._id);
            this.$refs.recordtable.selectRow(index);
          }
        });
      }
    },
    'viewItem.record': {
      handler(newVal) {
        if (newVal) {
          this.addItem = false;
          this.editItem = false;
          if (this.viewItem.collection && this.viewItem.collection !== this.collection._id) {
            let url;
            if (this.viewItem.collection === 'Billing') {
              url = `/dashboard/billing/${this.viewItem.record}`;
            } else if (this.viewItem.record === 'tableView') {
              url = `/dashboard/records/${this.viewItem.collection}`;
            } else {
              url = `/dashboard/records/${this.viewItem.collection}/${this.viewItem.record}`;
            }
            this.$router.push(url);
            setTimeout(() => {
              if (this.viewItem.record !== 'tableView' && this.viewItem.collection !== 'Billing') {
                this.editItemClick(this.viewItem.record);
              }
              this.viewItem = {
                record: null,
                collection: null,
              };
            }, 350);
          } else {
            this.editItemClick(newVal);
            this.viewItem = {
              record: null,
              collection: null,
            };
          }
        }
      },
    },
    'zipResults.id': {
      handler(newVal) {
        if (newVal
        && newVal !== 'err'
        && this.zipWatcher.type === 'field') {
          if (this.zipResults.id === this.zipWatcher.id) {
            if (this.$store.state.zipLookup.city) {
              this.model[this.zipWatcher.id].address.city = this.titleCase(this.zipResults.city);
              this.model[this.zipWatcher.id].address.state = this.zipResults.state;
            }
            this.$store.dispatch('zipLookupClear');
          }
        }
        if (newVal === 'err' && this.zipWatcher.type === 'field') {
          this.model[this.zipWatcher.id].address.city = null;
          this.model[this.zipWatcher.id].address.state = null;
          this.zipWatcher = {
            id: null,
            type: null,
          };
          if (this.zipResults.id === this.zipWatcher.id) {
            this.$store.dispatch('zipLookupClear');
          }
        }
      },
    },
  },
};
</script>
