<template>
  <b-btn
      id="markAsUrgent"
      :variant="active ? 'warning' : 'warning-outline'"
      :class="classes ? classes : 'px-3 py-0'"
      @click.stop="$emit('urgent')">
    <b-icon-flag
        :icon="active ? 'flag-fill' : 'flag'"
    />
    {{$t('collections.status.urgent')}}
  </b-btn>
</template>

<script>

export default {
  props: ['active', 'classes'],
  computed: {},
  data() {
    return {
      model: {},
    };
  },
  methods: {},
  watch: {},
};
</script>
