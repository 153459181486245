<template>
  <div
      id="datePicker"
      v-if="stage === 0">
    <b-row
        align-h="center">
      <b-col
          align="center"
          cols="*"
          class="mx-auto my-auto">
        <div
            id="selectTypeControls"
            v-if="!disableSelectType"
            class="scheduler__input__container">
          <h5
              v-text="$t('scheduler.select')"
              class="ml-4 mt-2 workDay__input__label pb-2"
          />
          <b-button-toolbar
              id="selectionType"
              class="mx-auto mb-4"
              justify>
            <b-btn
                id="selectSingle"
                @click="selectTypeClick(0)"
                v-text="$t('scheduler.selectSingle')"
                :variant="selectType === 0 ? 'primary' : 'primary-outline'"
            />
            <b-btn
                id="selectMulti"
                @click="selectTypeClick(1)"
                v-text="$t('scheduler.selectMulti')"
                :variant="selectType === 1 ? 'primary' : 'primary-outline'"
            />
            <b-btn
                id="selectRange"
                @click="selectTypeClick(2)"
                v-text="$t('scheduler.selectRange')"
                :variant="selectType === 2 ? 'primary' : 'primary-outline'"
            />
          </b-button-toolbar>
        </div>
        <transition
            mode="out-in"
            name="fadeQuick">
          <b-row
              key="selection"
              class="scheduler__input__container">
            <b-col
                align="center"
                class="mx-auto"
                cols="*">
              <b-btn-toolbar
                  id="selectionOptions">
                <transition-group
                    mode="out-in"
                    name="fade">
                  <b-btn
                      id="clearSelection"
                      v-if="selection.length"
                      key="buttonClear"
                      @click="selection = []"
                      variant="warning"
                      class="px-4 my-2">
                    <b-icon-x-circle
                        :scale="0.8"
                    />
                    <span
                        v-text="$t('scheduler.clearSelection')"
                    />
                  </b-btn>
                  <b-input-group-prepend
                      key="infoPane"
                      class="mx-1 my-auto px-4 scheduler__header">
                    <span
                        v-if="!selection.length"
                        v-text="selection.length ? $t('scheduler.confirmDates') : selectType === 0 ? $t('scheduler.selectDate[0]') : $t('scheduler.selectDate[1]')"
                    />
                    <div
                        v-else-if="selection.length > 1">
                      <b
                          v-text="selection.length"
                      />
                      <span
                          v-text="$t('scheduler.datesSelected')"
                      />
                    </div>
                    <div
                        v-else>
                      <b
                          v-text="dateString(selection[0], true)"
                      />
                    </div>
                  </b-input-group-prepend>
                  <b-btn
                      id="confirmChoice"
                      v-if="selection.length"
                      key="buttonNext"
                      variant="primary"
                      @click="confirmSelection()"
                      class="px-4 my-2">
                    <span
                        v-text="$t('scheduler.confirmDates')"
                    />
                    <b-icon-arrow-right
                      :scale="0.8"
                    />
                  </b-btn>
                </transition-group>
              </b-btn-toolbar>
            </b-col>
          </b-row>
        </transition>
      </b-col>
      <b-col
          cols="auto"
          align="center"
          class="mx-auto">
        <b-calendar
            id="calendarInput"
            v-model="selectedDate"
            @context="clickedOnDate"
            :locale="$t('project.calendarLocale')"
            :date-info-fn="selectedDates"
            hide-header
            nav-button-variant="success"
            class="scheduler__input__container"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import totalCalculators from '@/mixins/totalCalculators';

export default {
  // title() {
  //   return ` | ${this.$t('project.brand')}`;
  // },
  mixins: [
    totalCalculators,
  ],
  props: [
    'disableSelectType',
    'startingDate',
    'startingSelectType',
  ],
  computed: {},
  data() {
    return {
      addSetmentTo: null,
      selection: [],
      selectedDate: null,
      selectStage: 1,
      selectType: this.startingSelectType ? this.startingSelectType : 0,
      stage: 0,
      year: null,
      month: null,
      week: null,
      workingDate: null,
    };
  },
  mounted() {
    if (this.startingDate) {
      this.selection.push(this.startingDate);
    }
  },
  methods: {
    addSegmentTo(date, start, end, available) {
      const day = moment(date).format('YYYYMMDD');
      this.model[day].push({ start, end, available });
    },
    clickedOnDate(input) {
      if (this.selectedDate === input.activeYMD) {
        this.selectedDate = null;
        const date = new Date(input.activeDate);
        const dates = [];
        this.selection.forEach(_date => dates.push(_date.toLocaleDateString()));
        const index = dates.indexOf(date.toLocaleDateString());
        // Select One
        if (this.selectType === 0) {
          this.selection = [];
          if (index === -1) {
            this.selection.push(date);
          }
        }
        // Select Multiple
        if (this.selectType === 1) {
          if (index > -1) {
            this.selection.splice(index, 1);
          } else {
            this.selection.push(date);
          }
        }
        // Select Range
        if (this.selectType === 2) {
          if (!this.selection.length) {
            // First Date
            this.selection.push(date);
          } else if (this.selection.length === 1) {
            // Second Date
            this.selection.push(date);
            this.fillDateRange();
          } else {
            // Subsequent clicks
            this.selection = [];
            this.selection.push(date);
          }
        }
      }
    },
    confirmSelection() {
      this.$emit('datesChosen', this.selection);
    },
    datesAreAdjacent(first, second) {
      if (new Date(first.setDate(first.getDate() + 1)).toLocaleDateString() === second.toLocaleDateString()) {
        return true;
      }
      return false;
    },
    fillDateRange() {
      const first = this.selection[0] < this.selection[1]
        ? new Date(this.selection[0]) : new Date(this.selection[1]);
      const last = this.selection[0] < this.selection[1]
        ? new Date(this.selection[1]) : new Date(this.selection[0]);
      this.selection = [];
      let workingDate = first;
      if (first !== last) {
        while (workingDate <= last) {
          this.selection.push(workingDate);
          workingDate = moment(workingDate).add(1, 'days');
          workingDate = new Date(workingDate);
        }
      } else {
        this.selection.push(new Date(first));
      }
    },
    selectedDates(ymd /* dateObj */) {
      const dates = [];
      this.selection.forEach((_date) => {
        dates.push(this.dateString(_date));
      });
      const index = this.selection.findIndex(d => this.dateString(d) === ymd);
      if (index > -1) {
        return 'table-primary';
      }
      return '';
    },
    selectTypeClick(type) {
      this.selectType = type;
      this.selection = [];
      this.model = null;
    },
  },
  watch: {
  },
};
</script>
