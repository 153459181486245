<template>
  <div
      v-if="model"
      :style="`background-color: ${model}`">
    &nbsp;
  </div>
</template>
<script>

export default {
  props: ['model'],
};
</script>
