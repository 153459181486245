<template>
  <div
      class="record__table__container">
    <div
        id="topOfPage">
    </div>
    <record-header
        :collection="collection"
        :fileResults="fileResults"
        :itemSearchResults="itemSearchResults"
        :itemsFiltered="itemsFiltered"
        :itemsSelected="itemsSelected"
        :needToClear="needToClear"
        :requestDeleteMultiple="requestDeleteMultiple"
        :tableIsVisible="tableIsVisible"
        @deleteConfirm="deleteSelected()"
        @deleteCancel="deleteSelectedCancel()"
    />
    <transition
        name="fade"
        mode="out-in">
      <record-table
          v-if="readyToRun"
          key="table"
          :collection="collection"
          :currentPage="currentPage"
          :fieldsToDisplay="fieldsToDisplay"
          :items="itemResults"
          :perPage="perPage"
          :needToClear="needToClear"
          :selectedItems="itemsSelected"
          @scroll-to="scrollToItem"
          @selected="itemSelected"
      />
      <loader
          key="loader"
          v-else
          :h="50"
          :w="200"
          :fullPage="false"
      />
    </transition>
    <div
        class="paginationBar">
      <transition
          name="fadeQuick"
          mode="out-in">
        <b-row
            key="pagination"
            align-h="center"
            v-if="readyToRun">
          <b-col
              cols="*"
              class="clearSelectionButton__container">
            <b-btn-toolbar
                v-if="itemsSelected.length && tableIsVisible">
              <b-btn
                  id="clearSelected"
                  v-text="$t('selectedClear')"
                  class="clearSelectionButton"
                  @click="clearSelected()"
              />
              <b-btn
                  variant="danger"
                  @click="deleteSelectedReq()">
                <small>
                  <b-icon-trash
                      class="clearSelectionButton__delete"
                  />
                </small>
              </b-btn>
            </b-btn-toolbar>
          </b-col>
          <b-col
              cols="*"
              class="mx-auto"
              align="center">
            <span
                v-if="tableIsVisible"
                class="paginationBar__resultsText">
              {{paginationBarText}}
            </span>
          </b-col>
          <b-col
              cols="*"
              v-if="itemResults.length > perPage && tableIsVisible"
              class="mx-auto"
              align="center">
            <table-pagination
                key="paginationTop"
                @changePage="changePage"
                :currentPage="currentPage"
                :perPage="perPage"
                :rows="itemResults.length"
                class="mx-auto"
            />
          </b-col>
          <b-col
              align="center">
            <b-btn-group
                key="primary"
                v-if="!showSpinner">
              <b-btn
                  v-if="editItem || addNew"
                  @click="itemActionCancel"
                  variant="secondary"
                  class="mr-3 py-1 my-1">
                <b-icon-backspace />
                <small
                    v-text="$t('goBack')"
                />
              </b-btn>
              <b-btn
                  @click="itemActionButton"
                  class="mx-auto px-5 py-1 my-1"
                  variant="primary">
                <b-icon
                    key="icon"
                    :icon="(addNew || editItem) ? 'check' : 'plus'"
                    scale="1.75"
                />
                <span
                    class="mx-2"
                    v-if="addNew || editItem"
                    v-text="$t('save')"
                />
              </b-btn>
            </b-btn-group>
            <b-btn
                key="spinner"
                disabled
                v-else
                class="mx-auto px-3 py-2 my-0"
                variant="tertiary">
              <b-icon
                  key="loader"
                  icon="three-dots"
                  animation="cylon"
                  class="mx-2"
                  scale="2"
              />
            </b-btn>
          </b-col>
          <b-col
              cols="auto"
              align="right"
              class="downloadResultsButton__container">
            <b-btn
                @click="fileResultsRequest()"
                v-if="tableIsVisible && !resultsNeeded && !resultsRequested"
                class="downloadResultsButton">
              <b-icon-download />  {{$t('records.download')}} ({{fileResults.length ? fileResults.length : itemResults.length}})
            </b-btn>
            <b-btn
                v-if="tableIsVisible && resultsRequested"
                class="downloadResultsButton"
                disabled>
              <b-row>
                <b-col>
                  <loader
                      color="white"
                      :h="20"
                      :w="100"
                      :fullPage="false"
                  />
                </b-col>
              </b-row>
            </b-btn>
            <download-csv
                v-if="tableIsVisible && resultsNeeded && !resultsRequested"
                class="downloadResultsButton__prime"
                :data="fileResults"
                :name="fileName"
                :fields="fileFields"
                :labels="fileLabels">
              <b-icon-download />  {{$t('records.downloadReady')}} ({{fileResults.length ? fileResults.length : itemResults.length}})
            </download-csv>
          </b-col>
        </b-row>
      </transition>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import tablePagination from '@/components/data/TablePagination';
import recordHeader from '@/components/data/RecordHeader';
import recordTable from '@/components/data/RecordTable';
import totalCalculators from '@/mixins/totalCalculators';

export default {
  name: 'RecordAdmin',
  mixins: [
    totalCalculators,
  ],
  title() {
    if (this.collection && this.collection.name) {
      return `${this.collection.name} | ${this.$t('project.brand')}`;
    }
    return this.$t('project.brand');
  },
  components: {
    recordHeader,
    recordTable,
    tablePagination,
  },
  data() {
    return {
      countdownTimer: null,
      currentPage: 1,
      itemsSelected: [],
      needToClear: false,
      requestDeleteMultiple: false,
      resultsNeeded: false,
      resultsRequested: false,
      sortDirection: true,
      sortedItems: [],
      typeToAdd: null,
      uploadBuffer: 0,
      wineInput: null,
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (this.addNew || this.editItem) {
      this.itemActionCancel();
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (this.addNew || this.editItem) {
      this.$nextTick(() => {
        this.itemActionCancel();
      });
    }
    next();
  },
  created() {
    if (this.addNew || this.editItem) {
      this.itemActionCancel();
    }
    this.$store.dispatch('initialLoad')
      .catch((err) => {
        console.log(err);
        this.$router.go();
      });
    this.readyCheck();
  },
  computed: {
    addNew() {
      return this.$store.state.addNew;
    },
    addressFilter() {
      return this.$store.state.filterAddressesBy;
    },
    collection() {
      const collection = this.$store.getters.collection(this.$route.params.collection);
      if (collection) {
        return collection;
      }
      return {};
    },
    collectionFields() {
      return Object.entries(this.collection.schemaObj);
    },
    editItem() {
      return this.$store.state.editItem;
    },
    fields() {
      return this.$store.getters.fields(this.$route.params.collection);
    },
    fieldsToDisplay() {
      return this.$store.getters.fields(this.$route.params.collection, 'display');
    },
    fieldsToDownload() {
      const fields = this.$store.getters.fields(this.$route.params.collection, 'download');
      if (fields) {
        return fields;
      }
      return [];
    },
    fileFields() {
      // Determine which fields go in the file download
      const markedFields = this.fieldsToDownload;
      const fields = [];
      markedFields.forEach((field) => {
        fields.push(field[0]);
      });
      return fields;
    },
    fileLabels() {
      // Label file download fields
      const fields = this.fieldsToDownload;
      let labels = {};
      for (let a = 0; a < fields.length; a += 1) {
        const key = fields[a][0];
        const label = fields[a][1].label;
        const labelObj = { [key]: label };
        labels = { ...labels, ...labelObj };
      }
      return labels;
    },
    fileName() {
      return `${this.collection.name}-${moment().format('L')}.csv`;
    },
    fileResults() {
      // Prepare data for CSV download
      if (this.resultsNeeded) {
        console.log('generating file reuslts');
        const results = this.itemsSelected.length ? this.itemsSelected : this.itemResults;
        if (this.readyToRun) {
          const returnArr = [];
          for (let a = 0; a < results.length; a += 1) {
            const item = JSON.parse(JSON.stringify(results[a]));
            if (this.fieldsToDownload.length) {
              for (let z = 0; z < this.fieldsToDownload.length; z += 1) {
                const fieldToDownload = this.fieldsToDownload[z];
                const key = fieldToDownload[0];
                if (item[key]) {
                  if (key === 'id'
                  && this.collection.idType !== 'noId'
                  && this.collection.idDownload) {
                    item[key] = this.collection.idType === 'humanId' ? item.humanId : item._id;
                  } else if (key === 'dateAdded'
                  && this.collection.dateAddedDownload) {
                    item[key] = moment(item[key]).format('L hh:mm a');
                  } else if (key === 'dateUpdated'
                  && this.collection.dateUpdatedDownload) {
                    item[key] = moment(item[key]).format('L hh:mm a');
                  } else {
                    const metaType = this.collection.schemaObj[key].metaType;
                    switch (metaType) {
                      default: {
                        item[key] = this.fileResultCellData(item[key], key, this.collection.schemaObj);
                        break;
                      }
                      case ('Link'): {
                        let result = '';
                        const linkTo = this.collection.schemaObj[key].default.linkTo;
                        const linkedSchemaObj = this.$store.getters.collection(linkTo).schemaObj;
                        if (linkedSchemaObj) {
                          const linksChosen = item[key].linksChosen;
                          const linkedSchema = JSON.parse(JSON.stringify(linkedSchemaObj));
                          const linkedFields = this.collection.schemaObj[key].default.linkedFields;
                          if (linksChosen && linksChosen.length) {
                            for (let x = 0; x < linksChosen.length; x += 1) {
                              if (x !== 0) {
                                result += '\n\n';
                              }
                              const linkId = linksChosen[x];
                              let linkedRecord;
                              if (linkId.split('__')[0] === 'temp') {
                                const tempItem = item[key].tempData.find(i => i._id === linkId.split('__')[1]);
                                linkedRecord = this.$store.getters.recordLink(linkTo, tempItem, linkedFields);
                              } else {
                                linkedRecord = this.$store.getters.recordLink(linkTo, linkId, linkedFields);
                              }
                              if (this.$store.getters.collection(linkTo).idType !== 'noId') {
                                let idToShow;
                                if (linkId.split('__')[0] === 'temp') {
                                  idToShow = item[key].tempData.find(i => i._id === linkId.split('__')[1]).humanId;
                                } else if (this.$store.getters.collection(linkTo).idType === 'humanId') {
                                  idToShow = this.$store.getters.record(linkTo, linkId).humanId;
                                } else {
                                  idToShow = this.$store.getters.record(linkTo, linkId)._id;
                                }
                                if (!result) {
                                  result = `${idToShow}:`;
                                } else {
                                  result += `\n\n${idToShow}:`;
                                }
                              }
                              for (let y = 0; y < linkedFields.length; y += 1) {
                                if (linkedRecord[linkedFields[y]._id]) {
                                  result += `${linkedSchema[linkedFields[y]._id].label}:\n  `;
                                  const fieldResult = this.fileResultCellData(linkedRecord[linkedFields[y]._id].data, linkedFields[y]._id, linkedSchema, true);
                                  result += `  ${fieldResult}`;
                                  if (y !== linkedFields.length - 1) {
                                    result += '\n';
                                  }
                                }
                              }
                            }
                          }
                        }
                        item[key] = result;
                        break;
                      }
                    }
                  }
                }
              }
              returnArr.push(item);
            }
          }
          return returnArr;
        }
      }
      return [];
    },
    filters() {
      return this.$store.state.collectionFilters;
    },
    itemActionButtonCancel() {
      return this.$store.state.itemActionCancelPress;
    },
    itemActionButtonSys() {
      return this.$store.state.itemActionButtonPress;
    },
    items() {
      return this.$store.getters.records(this.collection._id);
    },
    itemsFiltered() {
      // Returns filtered item results
      const items = this.itemSearchResults;
      const results = [];
      const filt = this.filters;
      for (let a = 0; a < items.length; a += 1) {
        let passesFilters = 0;
        for (let b = 0; b < filt.length; b += 1) {
          const metaType = this.collection.schemaObj[filt[b].key].metaType;
          const item = items[a][filt[b].key];
          if (filt[b].has || filt[b].hasNone) {
            if ((items[a][filt[b].key] && filt[b].has)
            || (!items[a][filt[b].key] && filt[b].hasNone)) {
              passesFilters += 1;
            }
          } else if (filt[b].selectedOptions.length) {
            switch (metaType) {
              case ('Address'): {
                const type = this.addressFilter.type;
                switch (type) {
                  case 'usaFormat': {
                    if (item.type === 'usaFormat') {
                      passesFilters += 1;
                    }
                    break;
                  }
                  case 'international': {
                    if (item.type === 'international') {
                      passesFilters += 1;
                    }
                    break;
                  }
                  case 'cityState': {
                    if (item.type === 'usaFormat'
                    && !this.addressFilter.cityState.length) {
                      passesFilters += 1;
                    }
                    if (this.addressFilter.cityState.length) {
                      let cityState;
                      if (item.address.city && item.address.state) {
                        cityState = `${item.address.city}, ${item.address.state}`;
                      } else if (item.address.city) {
                        cityState = item.address.city;
                      }
                      if (this.addressFilter.cityState.indexOf(cityState) > -1) {
                        passesFilters += 1;
                      }
                    }
                    break;
                  }
                  case 'state': {
                    if (item.type === 'usaFormat'
                    && !this.addressFilter.state.length) {
                      passesFilters += 1;
                    }
                    if (this.addressFilter.state.length) {
                      let state;
                      if (item.address.state) {
                        state = item.address.state.toUpperCase();
                      }
                      if (this.addressFilter.state.indexOf(state) > -1) {
                        passesFilters += 1;
                      }
                    }
                    break;
                  }
                  case 'cityCountry': {
                    if (item.type === 'international'
                    && !this.addressFilter.cityCountry.length) {
                      passesFilters += 1;
                    }
                    if (this.addressFilter.cityCountry.length) {
                      let cityCountry;
                      if (item.address.city && item.address.country) {
                        cityCountry = `${item.address.city}, ${item.address.country}`;
                      } else if (item.address.city) {
                        cityCountry = item.address.city;
                      }
                      if (this.addressFilter.cityCountry.indexOf(cityCountry) > -1) {
                        passesFilters += 1;
                      }
                    }
                    break;
                  }
                  case 'country': {
                    if (item.type === 'international'
                    && !this.addressFilter.country.length) {
                      passesFilters += 1;
                    }
                    if (this.addressFilter.country.length) {
                      let country;
                      if (item.address.country) {
                        country = item.address.country;
                      }
                      if (this.addressFilter.country.indexOf(country) > -1) {
                        passesFilters += 1;
                      }
                    }
                    break;
                  }
                  case 'postal': {
                    if (item.type === 'international'
                    && !this.addressFilter.postal.length) {
                      passesFilters += 1;
                    }
                    if (this.addressFilter.postal.length) {
                      let postal;
                      if (item.address.postal) {
                        postal = item.address.postal;
                      }
                      if (this.addressFilter.postal.indexOf(postal) > -1) {
                        passesFilters += 1;
                      }
                    }
                    break;
                  }
                  default: {
                    break;
                  }
                }
                break;
              }
              case ('Inventory'): {
                if (item) {
                  let passMax = false;
                  let passMin = false;
                  if (!filt[b].maxCount) {
                    passMax = true;
                  } else if (item.counter
                  && Number(item.counter) <= Number(filt[b].maxCount)) {
                    passMax = true;
                  }
                  if (!filt[b].minCount) {
                    passMin = true;
                  } else if (item.counter
                  && filt[b].minCount
                  && Number(item.counter) >= Number(filt[b].minCount)) {
                    passMin = true;
                  }
                  if (passMax
                  && passMin) {
                    passesFilters += 1;
                  }
                }
                break;
              }
              case ('MultiSelect'): {
                if (items[a][filt[b].key]) {
                  let subCount = 0;
                  const entries = Object.entries(items[a][filt[b].key]);
                  for (let c = 0; c < filt[b].selectedOptions.length; c += 1) {
                    for (let d = 0; d < entries.length; d += 1) {
                      if (filt[b].selectedOptions[c] === entries[d][1].label && entries[d][1].value) {
                        subCount += 1;
                      }
                    }
                  }
                  if (subCount >= filt[b].selectedOptions.length) {
                    passesFilters += 1;
                  }
                }
                break;
              }
              case ('Number'): {
                if (item) {
                  let passMax = false;
                  let passMin = false;
                  if (!filt[b].maxCount) {
                    passMax = true;
                  } else if (item
                  && Number(item) <= Number(filt[b].maxCount)) {
                    passMax = true;
                  }
                  if (!filt[b].minCount) {
                    passMin = true;
                  } else if (item
                  && filt[b].minCount
                  && Number(item) >= Number(filt[b].minCount)) {
                    passMin = true;
                  }
                  if (passMax
                  && passMin) {
                    passesFilters += 1;
                  }
                }
                break;
              }
              case ('Email'):
              case ('Phone'): break;
              case ('Price'): {
                if (item) {
                  let passMax = false;
                  let passMin = false;
                  // let passType = false;
                  // if (item.type === filt[b].type) {
                  //   passType = true;
                  // }
                  if (!filt[b].maxPrice) {
                    passMax = true;
                  } else if (item.price
                  && Number(item.price) <= Number(filt[b].maxPrice)) {
                    passMax = true;
                  }
                  if (!filt[b].minPrice) {
                    passMin = true;
                  } else if (item.price
                  && filt[b].minPrice
                  && Number(item.price) >= Number(filt[b].minPrice)) {
                    passMin = true;
                  }
                  if (passMax
                  && passMin
                  /* && passType */) {
                    passesFilters += 1;
                  }
                }
                break;
              }
              case ('SelectOne'): {
                if (items[a][filt[b].key]) {
                  const entries = Object.entries(items[a][filt[b].key]);
                  for (let c = 0; c < filt[b].selectedOptions.length; c += 1) {
                    for (let d = 0; d < entries.length; d += 1) {
                      if (filt[b].selectedOptions[c] === entries[d][1].label && entries[d][1].value) {
                        passesFilters += 1;
                      }
                    }
                  }
                }
                break;
              }
              case ('Status'): {
                let passes = true;
                if (item) {
                  if (!item.text && filt[b].selectedOptions[0].hasStatus) {
                    passes = false;
                  }
                  if (!item.urgent && filt[b].selectedOptions[0].hasUrgent) {
                    passes = false;
                  }
                  if (item.text && filt[b].selectedOptions[0].noStatus) {
                    passes = false;
                  }
                } else if (!filt[b].selectedOptions[0].noStatus) {
                  passes = false;
                }
                if (passes) {
                  passesFilters += 1;
                }
                break;
              }
              case ('Tags'): {
                let subCount = 0;
                const selected = filt[b].selectedOptions;
                const values = items[a][filt[b].key];
                for (let c = 0; c < selected.length; c += 1) {
                  for (let d = 0; d < values.length; d += 1) {
                    if (selected[c] === values[d][0]) {
                      subCount += 1;
                    }
                  }
                }
                if (subCount >= selected.length) {
                  passesFilters += 1;
                }
                break;
              }
              case ('Toggle'): {
                for (let c = 0; c < filt[b].selectedOptions.length; c += 1) {
                  const on = this.collection.schemaObj[filt[b].key].default.labelOn ? this.collection.schemaObj[filt[b].key].default.labelOn : this.$t('collections.toggleOn');
                  const off = this.collection.schemaObj[filt[b].key].default.labelOff ? this.collection.schemaObj[filt[b].key].default.labelOff : this.$t('collections.toggleOff');
                  if ((filt[b].selectedOptions[c] === on && item)
                  || (filt[b].selectedOptions[c] === off && !item)) {
                    passesFilters += 1;
                  }
                }
                break;
              }
              default: {
                for (let c = 0; c < filt[b].selectedOptions.length; c += 1) {
                  // Checks Boolean values
                  if (items[a][filt[b].key] === true || items[a][filt[b].key] === false) {
                    if ((filt[b].selectedOptions[c] === 'Yes'
                        && items[a][filt[b].key] === true)
                      || ((filt[b].selectedOptions[c] === 'No')
                        && items[a][filt[b].key] === false)) {
                      passesFilters += 1;
                    }
                  } else if (items[a][filt[b].key] && items[a][filt[b].key] === filt[b].selectedOptions[c]) {
                    passesFilters += 1;
                  }
                }
                break;
              }
            }
          } else {
            passesFilters += 1;
          }
        }
        if (passesFilters >= filt.length) {
          results.push(items[a]);
        }
      }
      return results;
    },
    itemResults() {
      let items;
      if (this.filters.length) {
        items = this.itemsFiltered;
      } else if (this.itemSearchNeeded) {
        items = this.itemSearchResults;
      } else {
        items = this.items;
      }
      /*
      TODO
      Add sorting
          items.sort((a, b) => ((`${a.producer.toLowerCase()}${a.varietal ? a.varietal.toLowerCase() : null}${a.varietalSecond ? a.varietalSecond.toLowerCase() : null}`
          > `${b.producer.toLowerCase()}${b.varietal ? b.varietal.toLowerCase() : null}${b.varietalSecond ? b.varietalSecond.toLowerCase() : null}`) ? 1 : -1));
      */
      // if (!items || (typeof items === 'object' && !Array.isArray(items))) {
      //   items = [];
      // }
      return items;
    },
    itemSearchNeeded() {
      if ((this.searchTerms && this.searchTerms.length) || this.searchTermPartial) {
        return true;
      }
      return false;
    },
    itemSearchResults() {
      let items = this.items;
      if (this.itemSearchNeeded) {
        items = this.itemsToSearch;
        const results = [];
        const terms = this.itemSearchTerms;
        for (let a = 0; a < items.length; a += 1) {
          let matchCount = 0;
          for (let t = 0; t < terms.length; t += 1) {
            for (let b = 0; b < items[a].data.length; b += 1) {
              if (items[a].data[b] && items[a].data[b].includes(terms[t])) {
                matchCount += 1;
                break;
              }
            }
            if (matchCount >= terms.length) {
              break;
            }
          }
          if (matchCount >= terms.length) {
            results.push(this.$store.getters.record(this.collection._id, items[a]._id));
          }
        }
        items = results;
      }
      return items;
    },
    itemsToSearch() {
      // Creates searchable objects based on metatype, pairs them with item id
      function addressResult(data) {
        const results = [];
        const adr = data.address;
        if (adr.attn) {
          results.push(adr.attn.toLowerCase());
        }
        if (adr.street) {
          results.push(adr.street.toLowerCase());
        }
        if (adr.streetSecond && adr.streetSecondShow) {
          results.push(adr.streetSecond.toLowerCase());
        }
        if (adr.city) {
          results.push(adr.city.toLowerCase());
        }
        if (adr.state) {
          results.push(adr.state.toLowerCase());
        }
        if (adr.city && adr.state) {
          results.push(`${adr.city.toLowerCase()}, ${adr.state.toLowerCase()}`);
        }
        if (adr.zip) {
          results.push(adr.zip);
        }
        if (adr.country) {
          results.push(adr.country.toLowerCase());
        }
        if (adr.postal) {
          results.push(adr.postal.toLowerCase());
        }
        console.log(results);
        return results;
      }
      function emailResult(item) {
        return item.toLowerCase();
      }
      function selectResult(items) {
        let selections = '';
        const entries = Object.entries(items);
        entries.forEach((entry) => {
          if (entry[1].value) {
            selections += `${entry[1].label.toLowerCase()}, `;
          }
        });
        return selections;
      }
      function notesResult(items) {
        const notes = [];
        items.forEach((note) => {
          notes.push(note.text.toLowerCase());
        });
        return notes;
      }
      function numberResult(item) {
        return item.toString();
      }
      function phoneResult(number) {
        return number.replace(/[^A-Z0-9]+/ig, '');
      }
      function priceResult(price) {
        return price.toString();
      }
      function statusResult(item, urgentLabel) {
        const items = [];
        items.push(item.text.toLowerCase());
        if (item.urgent) {
          items.push(urgentLabel);
        }
        return items;
      }
      function tagsResult(item) {
        const items = [];
        item.forEach((tag) => {
          items.push(tag.toLowerCase());
        });
        return items;
      }
      function toggleResult(item, schema) {
        if (item[1]
          && schema.default.labelOn) {
          return schema.default.labelOn.toLowerCase();
        }
        if (!item[1]
          && schema.default.labelOff) {
          return schema.default.labelOff.toLowerCase();
        }
        return null;
      }
      const items = [];
      for (let x = 0; x < this.items.length; x += 1) {
        const item = Object.entries(this.items[x]);
        const workingItem = [];
        for (let a = 0; a < item.length; a += 1) {
          const field = this.collection.schemaObj[item[a][0]];
          if (field) {
            const metaType = field.metaType;
            if (item[a][1]) {
              if (metaType === 'Address' && item[a][1].address) {
                if (addressResult(item[a][1]).length) {
                  workingItem.push(...addressResult(item[a][1]));
                }
              }
              if (metaType === 'Email') {
                workingItem.push(emailResult(item[a][1]));
              }
              if (metaType === 'Link') {
                if (item[a][1].linksChosen.length) {
                  for (let lc = 0; lc < item[a][1].linksChosen.length; lc += 1) {
                    const link = item[a][1].linksChosen[lc];
                    // const coll = this.$store.getters.collection(field.default.linkTo);
                    for (let z = 0; z < field.default.linkedFields.length; z += 1) {
                      const f = field.default.linkedFields[z];
                      if (Object.entries(this.$store.getters.recordLink(field.default.linkTo, link, [f._id]))[0]
                          && Object.entries(this.$store.getters.recordLink(field.default.linkTo, link, [f._id]))[0][1].data) {
                        switch (f.metaType) {
                          default: {
                            break;
                          }
                          case ('Address'): {
                            if (addressResult(Object.entries(this.$store.getters.recordLink(field.default.linkTo, link, [f._id]))[0][1].data).length) {
                              workingItem.push(...addressResult(Object.entries(this.$store.getters.recordLink(field.default.linkTo, link, [f._id]))[0][1].data));
                            }
                            break;
                          }
                          case ('Email'): {
                            workingItem.push(emailResult(Object.entries(this.$store.getters.recordLink(field.default.linkTo, link, [f._id]))[0][1].data));
                            break;
                          }
                          case ('SelectOne'):
                          case ('MultiSelect'): {
                            // Note we didn't use the selectResults function here, as the data arrives in a different format
                            Object.entries(this.$store.getters.recordLink(field.default.linkTo, link, [f._id]))[0][1].data.forEach((rl) => {
                              workingItem.push(rl.toLowerCase());
                            });
                            break;
                          }
                          case ('Notes'): {
                            workingItem.push(notesResult(Object.entries(this.$store.getters.recordLink(field.default.linkTo, link, [f._id]))[0][1].data));
                            break;
                          }
                          case ('Number'): {
                            workingItem.push(numberResult(Object.entries(this.$store.getters.recordLink(field.default.linkTo, link, [f._id]))[0][1].data));
                            break;
                          }
                          case ('Phone'): {
                            workingItem.push(phoneResult(Object.entries(this.$store.getters.recordLink(field.default.linkTo, link, [f._id]))[0][1].data.number));
                            break;
                          }
                          case ('Price'): {
                            workingItem.push(priceResult(Object.entries(this.$store.getters.recordLink(field.default.linkTo, link, [f._id]))[0][1].data.price));
                            break;
                          }
                          case ('Status'): {
                            workingItem.push(
                              statusResult(Object.entries(this.$store.getters.recordLink(field.default.linkTo, link, [f._id]))[0][1].data), this.$t('collections.status.urgent'),
                            );
                            break;
                          }
                          case ('String'):
                          case ('Textarea'): {
                            workingItem.push(Object.entries(this.$store.getters.recordLink(field.default.linkTo, link, [f._id]))[0][1].data.toLowerCase());
                            break;
                          }
                          case ('Tags'): {
                            workingItem.push(...tagsResult(Object.entries(this.$store.getters.recordLink(field.default.linkTo, link, [f._id]))[0][1].data));
                            break;
                          }
                          case ('Toggle'): {
                            console.log('fail here');
                            workingItem.push(
                              toggleResult(
                                Object.entries(this.$store.getters.recordLink(field.default.linkTo, link, [f._id]))[0][1].data,
                                this.$store.getters.collection(field.default.linkTo).schemaObj[f._id],
                              ),
                            );
                            break;
                          }
                        }
                      }
                    }
                  }
                }
              }
              if (metaType === 'MultiSelect' || metaType === 'SelectOne') {
                workingItem.push(selectResult(item[a][1]));
              }
              if (metaType === 'Notes' && item[a][1].length) {
                workingItem.push(...notesResult(item[a][1]));
              }
              if (metaType === 'Number' && item[a][1]) {
                workingItem.push(numberResult(item[a][1]));
              }
              if (metaType === 'Phone') {
                if (item[a][1].number) {
                  workingItem.push(phoneResult(item[a][1].number));
                }
              }
              if (metaType === 'Price') {
                if (item[a][1].price) {
                  workingItem.push(priceResult(item[a][1].price));
                }
              }
              if (metaType === 'Status') {
                if (item[a][1].text) {
                  workingItem.push(...statusResult(item[a][1], this.$t('collections.status.urgent')));
                }
              }
              if (metaType === 'String' || metaType === 'Textarea') {
                workingItem.push(item[a][1].toLowerCase());
              }
              if (metaType === 'Tags') {
                if (item[a][1].length) {
                  workingItem.push(...tagsResult(item[a][1]));
                }
              }
            }
            if (metaType === 'Toggle') {
              if (toggleResult(item[a], field)) {
                workingItem.push(toggleResult(item[a], field));
              }
            }
          }
        }
        if (workingItem.length) {
          items.push({ _id: this.items[x]._id, data: workingItem });
        }
      }
      return items;
    },
    itemSearchTerms() {
      const terms = [];
      if (this.searchTermPartial) {
        terms.push(this.searchTermPartial.toLowerCase());
      }
      if (this.searchTerms && this.searchTerms.length) {
        this.searchTerms.forEach(i => terms.push(i.toLowerCase()));
      }
      return terms;
    },
    paginationBarText() {
      const resultCount = this.itemsSelected.length ? this.itemsSelected.length : this.itemResults.length;
      let results = `${this.collection.name}: `;
      results += ' ';
      if (this.perPage < resultCount) {
        // show number of results on page
        const pages = Math.ceil(this.itemResults / this.perPage);
        results += 'showing';
        results += ' ';
        // calculate how many items are on the last page
        if (this.currentPage === pages && Math.ceil(resultCount % this.perPage) !== 0) {
          results += Math.ceil(resultCount % this.perPage);
        } else {
          results += this.perPage;
        }
        results += ' ';
        results += this.$t('of');
        results += ' ';
      }
      if (this.itemsSelected.length) {
        results += this.itemsSelected.length;
      } else {
        results += this.itemResults.length;
      }
      results += ' ';
      if (resultCount === 1) {
        results += this.$t('records.record');
      } else {
        results += this.$t('records.records');
      }
      if (this.itemsSelected.length) {
        results += ' ';
        results += this.$t('records.recordsSelected');
      }
      return results;
    },
    perPage: {
      get() {
        const perPage = this.$store.state.preferences.perPage;
        if (perPage > 0) {
          return perPage;
        }
        return 10;
      },
      set(newVal) {
        this.$store.state.preferences.perPage = newVal;
      },
    },
    readyToRun() {
      if (this.$store.state.initialLoad) {
        return true;
      }
      return false;
    },
    searchTermPartial: {
      get() {
        return this.$store.state.searchTermPartial;
      },
      set(newVal) {
        this.$store.state.searchTermPartial = newVal;
      },
    },
    searchTerms: {
      get() {
        return this.$store.state.searchTerms;
      },
      set(newVal) {
        this.$store.state.searchTerms = newVal;
      },
    },
    showSpinner() {
      return this.$store.state.showSpinner;
    },
    tableIsVisible() {
      if (this.addNew || this.editItem) {
        return false;
      }
      return true;
    },
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
    },
    clearSelected() {
      this.needToClear = true;
      this.$nextTick(() => {
        this.needToClear = false;
      });
    },
    deleteSelectedCancel() {
      this.requestDeleteMultiple = false;
    },
    deleteSelectedReq() {
      window.scrollTo(0, 0);
      this.requestDeleteMultiple = true;
    },
    deleteSelected() {
      let removeItems = null;
      this.itemsSelected.forEach((i) => {
        // Creates a comma-separated list to send as a header, because express.delete() doesn't accept body data
        removeItems = removeItems ? `${removeItems},${i._id}` : i._id;
      });
      this.$store.dispatch('removeRecords', { collection: this.collection._id, records: removeItems });
      this.requestDeleteMultiple = false;
    },
    fileResultsRequest() {
      this.resultsRequested = true;
      setTimeout(() => {
        this.resultsRequested = false;
      }, 800);
      this.resultsNeeded = true;
    },
    itemActionButton() {
      this.$store.state.itemActionButtonPress = true;
    },
    itemActionCancel() {
      this.$store.state.itemActionCancelPress = true;
      this.$store.state.addNew = false;
      this.$store.state.editItem = null;
      this.$nextTick(() => {
        this.$store.state.itemActionCancelPress = false;
      });
    },
    itemSelected(items) {
      this.itemsSelected = items;
    },
    fileResultCellData(data, fieldKey, schemaObj, needIndent) {
      let result = '';
      const newLine = needIndent ? '\n    ' : '\n';
      const type = schemaObj[fieldKey].metaType;
      switch (type) {
        default: {
          if (data) {
            result = data;
          }
          break;
        }
        case ('Address'): {
          if (data && data.address) {
            const ad = data.address;
            if (ad.street) {
              result = ad.street;
            }
            if (ad.streetSecond && ad.streetSecondShow) {
              result += `${newLine}${ad.streetSecond}`;
            }
            if (ad.city) {
              result += `${newLine}${ad.city}`;
              if (ad.state) {
                result += ', ';
              }
            }
            if (data.type === 'usaFormat') {
              if (ad.state) {
                if (!ad.city) {
                  result += newLine;
                }
                result += ad.state;
              }
              if (ad.zip) {
                if (!ad.state && !ad.city) {
                  result += newLine;
                }
                result += `  ${ad.zip}`;
              }
            } else if (ad.country || ad.postal) {
              result += `${newLine}${ad.country ? ad.country : ''} ${ad.postal ? ad.postal : ''}`;
            }
          }
          break;
        }
        case ('SysDate'): {
          result = moment(data).format('L hh:mm a');
          break;
        }
        case ('Inventory'): {
          if (data) {
            result = data.counter;
          }
          break;
        }
        case ('Link'): {
          break;
        }
        case ('MultiSelect'):
        case ('SelectOne'): {
          if (!Array.isArray(data)) {
            // Regular records are processed here
            const items = Object.entries(data);
            for (let i = 0; i < items.length; i += 1) {
              const optionObj = items[i][1];
              if (optionObj.value) {
                if (result !== '') {
                  result += `
${optionObj.label}`;
                } else {
                  result = optionObj.label;
                }
              }
            }
          } else {
            // Linked Selects are processed here
            let string = '';
            for (let o = 0; o < data.length; o += 1) {
              if (o === data.length - 1) {
                string += data[o];
              } else {
                string += `${data[o]}
    `;
              }
              result = string;
            }
          }
          break;
        }
        case ('Notes'): {
          if (data.length) {
            let y = 0;
            for (let x = 0; x < data.length; x += 1) {
              if (data[x].text) {
                y += 1;
                if (!result) {
                  result = `${moment(data[x].date).format('l')}:\n${data[x].text.trim()}`;
                } else {
                  result += `\n\n${moment(data[x].date).format('l')}:\n${data[x].text.trim()}`;
                }
              }
              if (Number(y) === Number(schemaObj[fieldKey].notesToShow)) {
                x += data.length;
              }
            }
          }
          break;
        }
        case ('Phone'): {
          if (data) {
            result = data.number;
          }
          break;
        }
        case ('Price'): {
          if (data && data.price) {
            result = this.displayCurrency(data.price, data.type);
          }
          break;
        }
        case ('Status'): {
          if (data && data.text) {
            if (data.urgent) {
              result = `${this.$t('collections.status.urgent')}: ${data.text}`;
            } else {
              result = data.text;
            }
          }
          break;
        }
        case ('Tags'): {
          if (data) {
            data.forEach((tag) => {
              if (!result) {
                result = tag;
              } else {
                result += `, ${tag}`;
              }
            });
          }
          break;
        }
        case ('Toggle'): {
          if (data) {
            if (data.value) {
              if (schemaObj[fieldKey].default.labelOn) {
                result = schemaObj[fieldKey].default.labelOn;
              } else {
                result = this.$t('collections.toggleOn');
              }
            } else if (schemaObj[fieldKey].default.labelOff) {
              result = schemaObj[fieldKey].default.labelOff;
            }
          }
          break;
        }
      }
      return result;
    },
    scrollToItem() {
      setTimeout(() => {
        const activeItem = document.getElementById(`scroller-${this.$store.state.showItem}`);
        let scrollDestination = document.getElementById('topOfPage');
        if (activeItem) {
          const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
          const dimensions = activeItem.getBoundingClientRect();
          const topOfEl = dimensions.top + window.pageYOffset;
          scrollDestination = topOfEl - (windowHeight / 6);
        }
        window.scrollTo({ left: 0, top: scrollDestination, behavior: 'smooth' });
      }, 400);
    },
    sortDirectionChange() {
      this.sortDirection = !this.sortDirection;
    },
  },
  watch: {
    'filters.length': {
      handler(newVal) {
        if (newVal) {
          this.currentPage = 1;
        }
      },
    },
    itemActionButtonCancel(newVal) {
      if (newVal) {
        if (this.resultsNeeded) {
          this.resultsNeeded = false;
        }
        if (this.resultsRequested) {
          this.resultsRequested = false;
        }
      }
    },
    itemActionButtonSys(newVal) {
      if (newVal) {
        if (this.resultsNeeded) {
          this.resultsNeeded = false;
        }
        if (this.resultsRequested) {
          this.resultsRequested = false;
        }
      }
    },
    itemSearchResults(newVal, oldVal) {
      if (newVal && newVal.length !== oldVal.length) {
        this.currentPage = 1;
      }
    },
    perPage(newVal) {
      if (this.currentPage > Math.ceil(this.itemResults.length / newVal)) {
        this.currentPage = 1;
      }
    },
  },
};
</script>
