<template>
  <b-container
      v-if="!signedIn"
      class="authenticator__container">
    <amplify-authenticator
        :authConfig="authConfig">
      <amplify-sign-in
          slot="sign-in"
          header-text="SINK"
          :hideSignUp="true"
      />
    </amplify-authenticator>
  </b-container>
  <b-container
      v-else
      fluid>
    <transition
        name="fade"
        mode="out-in">
      <router-view />
    </transition>
  </b-container>
</template>
<script>
import { AmplifyEventBus } from 'aws-amplify-vue';
import { Auth } from 'aws-amplify';
import { offline } from '../../secrets.json';

export default {
  name: 'AuthenticationPortal',
  title() {
    if (!offline) {
      return `${this.$t('logIn')} | ${this.$t('project.brand')}`;
    }
    return null;
  },
  computed: {
    signedIn() {
      if (!offline) {
        return this.$store.state.signedIn;
      }
      return true;
    },
    signOut() {
      return this.$store.state.signOut;
    },
  },
  created() {
    if (!offline) {
      this.findUser();
      AmplifyEventBus.$on('authState', (info) => {
        if (info === 'signedIn') {
          this.findUser();
        } else {
          this.$store.dispatch('signedOut');
        }
      });
    } else {
      this.$store.state.offline = true;
    }
  },
  mounted() {
  },
  data() {
    return {
      authConfig: {
        usernameAttributes: 'User',
        isSignupDisplayed: false,
      },
    };
  },
  methods: {
    async findUser() {
      console.log('starting signin');
      try {
        const user = await Auth.currentAuthenticatedUser();
        this.$store.dispatch('signedIn', user);
      } catch (err) {
        console.log(err);
        this.$store.dispatch('signedOut');
      }
    },
  },
  watch: {
    async signOut(req) {
      if (req) {
        await Auth.signOut().then(() => {
          this.$router.push('/');
          this.$router.go();
        });
        this.$store.state.signOut = false;
      }
    },
  },
};
</script>
