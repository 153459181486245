<template>
  <div>
    <div
        class="collections__container">
      <div
          role="tablist">
        <transition-group
            mode="out-in"
            name="fadeQuick">
          <b-card
              no-body
              v-for="collection in collections"
              :key="collection._id"
              class="mb-1">
            <b-card-header
                header-tag="header"
                class="p-1"
                role="tab">
              <b-button
                  block
                  @click="viewCollection(collection._id)"
                  variant="info"
                  class="collections__button">
                <div
                    v-if="$store.state.showSpinner && model._id === collection._id">
                  <loader
                      key="loader"
                      color="white"
                      :h="40"
                      :w="150"
                      :fullPage="false"
                  />
                </div>
                <h2
                    v-else>
                    {{collection.name}}
                </h2>
              </b-button>
            </b-card-header>
            <b-collapse
                :id="collection._id"
                accordion="collections"
                class="collections__tabContainer"
                role="tabpanel">
              <b-card-body>
                <b-row>
                  <b-col
                      cols="6"
                      class="form__container"
                      align="center">
                    <h5
                        for="collectionNameInput"
                        v-text="$t('collections.name')"
                        class="form__header__small"
                    />
                    <b-form-input
                        id="collectionNameInput"
                        v-model="collection.name"
                        type="text"
                        class="form__input large"
                        @input="saveRequest()"
                    />
                    <b-row
                        v-if="$_app.switch.scheduler && accountEmployees !== collectionId">
                      <b-col
                          v-if="(!accountPeople || accountPeople === collectionId) && accountServices !== collectionId">
                        <span
                            v-text="$t('collections._peopleLabel')"
                            class="form__header__small"
                        />
                        <b-button
                            id="useThisCollectionForPeople"
                            :variant="accountPeople === collectionId ? 'secondary' : 'secondary-outline'"
                            v-text="$t('use')"
                            @click="accountPeopleClick()"
                        />
                      </b-col>
                      <b-col
                          v-if="(!accountServices || accountServices === collectionId) && accountPeople !== collectionId">
                        <span
                            v-text="$t('collections._servicesLabel')"
                            class="form__header__small"
                        />
                        <b-button
                            id="useThisCollectionForServices"
                            :variant="accountServices === collectionId ? 'secondary' : 'secondary-outline'"
                            v-text="$t('use')"
                            @click="accountServicesClick()"
                        />
                      </b-col>
                    </b-row>
                    <b-row
                        v-if="$_app.switch.workDay">
                      <b-col
                          v-if="(!accountEmployees || accountEmployees === collectionId) && accountServices !== collectionId && accountPeople !== collectionId">
                        <span
                            v-text="$t('collections._employeesLabel')"
                            class="form__header__small"
                        />
                        <b-button
                            id="useThisCollectionForEmployees"
                            :variant="accountEmployees === collectionId ? 'secondary' : 'secondary-outline'"
                            v-text="$t('use')"
                            @click="accountEmployeesClick()"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                      key="recordIdOptions"
                      v-if="!deleteConfirmDialog"
                      class="form__container"
                      align="center">
                    <span
                        v-text="$t('collections.types._id.label')"
                        class="form__header__small"
                    />
                    <span
                        v-text="$t('tooltips.idMethod')"
                    />
                    <br />
                    <b-form-select
                        :id="`${collection._id}-idType`"
                        v-model="collection.idType"
                        class="medium my-2"
                        @change="saveRequest()"
                        :options="[{ text: $t('collections.types._id.noId'), value: 'noId' },
                          { text: $t('collections.types._id.human'), value: 'humanId' },
                          { text: $t('collections.types._id.guid'), value: 'guid' }]"
                    />
                    <div
                        v-if="collection.idType === 'guid'">
                      <span
                          v-html="$t('tooltips.guid')"
                      />
                    </div>
                    <div
                        v-if="collection.idType === 'humanId'">
                      <span
                          v-html="$t('tooltips.humanId')"
                      />
                    </div>
                    <div
                        v-if="collection.idType === 'noId'">
                      <span
                          v-html="$t('tooltips.noId')"
                      />
                    </div>
                    <transition
                        mode="out-in"
                        name="fade">
                      <b-row
                          v-if="collection.idType === 'humanId'"
                          class="pt-2"
                          align-h="center">
                        <b-col
                            align="center">
                          <b-input-group>
                            <b-input-group-prepend
                                class="ml-auto mr-2 my-auto"
                                v-text="$t('collections.idCounterPrefix')"
                            />
                            <b-form-input
                                :id="`${collection._id}-prefix`"
                                v-model="collection.humanIdPrefix"
                                type="text"
                                :placeholder="$t('optional')"
                                class="form__input smallish mr-auto"
                                @input="saveRequest()"
                            />
                          </b-input-group>
                        </b-col>
                      </b-row>
                    </transition>
                    <b-btn
                        v-if="!deleteConfirmDialog"
                        id="deleteCollectionButton"
                        @click="removeCollection(collection._id)"
                        variant="danger"
                        class="collections__deleteCollectionButton">
                      <b-icon-trash />
                    </b-btn>
                  </b-col>
                  <b-col
                      v-else
                      key="deleteRequest"
                      class="form__container"
                      align="center">
                    <span
                        class="form__header__small">
                      <b-icon-exclamation-triangle-fill
                          scale="2"
                          class="m-2"
                          variant="warning"
                      />
                      <span
                          class="mx-3"
                          v-text="$t('collections.deleteRequest')"
                      />
                      <b-icon-exclamation-triangle-fill
                          scale="2"
                          class="m-2"
                          variant="warning"
                      />
                    </span>
                    <br />
                    <span
                        v-html="$t('collections.deleteConfirm')"
                    />
                    <b-input-group>
                      <b-btn
                          id="cancelDelete"
                          variant="tertiary"
                          v-text="$t('cancel')"
                          @click="deleteConfirmDialog = false"
                      />
                      <b-form-input
                          id="alphaConfirmationInput"
                          type="text"
                          v-model="deleteConfirmText"
                          :placeholder="$t('collections.deletePlaceholder')"
                          class="form__input large mx-auto"
                      />
                      <b-btn
                          id="confirmDelete"
                          @click="removeCollection(collection._id)"
                          :variant="deleteConfirmText === model.name ? 'danger' : 'danger-outline'"
                          :disabled="deleteConfirmText !== model.name"
                          class="collections__deleteCollectionButton">
                        <b-icon-trash /> {{$t('delete')}}
                      </b-btn>
                    </b-input-group>
                  </b-col>
                </b-row>
                <h3
                    v-text="$t('collections.fields')"
                    class="form__header__big mt-4"
                />
                <!-- Field Name/Defaults Rendering -->
                <transition
                    name="fade"
                    mode="out-in">
                  <div
                      id="fieldsInCollection"
                      v-if="!addingField"
                      key="defaultView">
                    <div
                        v-if="model.schemaObj">
                      <b-row
                          v-for="field in collectionFields"
                          @mouseover="hoverField = field[0]"
                          @mouseleave="hoverField = null"
                          :key="field[0]"
                          align="center"
                          :class="hoverField === field[0]
                            || (fieldDetailsToShow === field[0])
                            ? 'collections__fields__container__hovered' : 'collections__fields__container'">
                        <b-col>
                          <div>
                            <div
                                @click="seeFieldDetails(field)"
                                class="form__header__small">
                              <b-input-group>
                                <b-btn
                                    class="ml-auto moveCollectionFieldButton"
                                    @click.stop="moveField(field[0], true)"
                                    :disabled="Object.entries(model.schemaObj).length < 2
                                    || Object.entries(model.schemaObj).findIndex(i => i[0] === field[0]) === 0
                                    || deleteFieldConfirmation === field[0]">
                                  <span
                                      class="sr-only"
                                      v-text="$t('aria.moveUp')"
                                  />
                                  <b-icon-arrow-up
                                  />
                                </b-btn>
                                <b-btn
                                    class="moveCollectionFieldButton"
                                    v-if="deleteFieldConfirmation !== field[0]"
                                    @click.stop="moveField(field[0], false)"
                                    :disabled="Object.entries(model.schemaObj).length < 2
                                    || Object.entries(model.schemaObj).findIndex(i => i[0] === field[0]) === Object.entries(model.schemaObj).length - 1
                                    || deleteFieldConfirmation === field[0]">
                                  <span
                                      class="sr-only"
                                      v-text="$t('aria.moveDown')"
                                  />
                                  <b-icon-arrow-down
                                  />
                                </b-btn>
                                <b-form-input
                                    :id="`labelInput-${field[0]}`"
                                    v-model="field[1].label"
                                    @input="saveRequest()"
                                    @click.stop="fieldDetailsToShow = field[0]"
                                    class="form__input large"
                                    type="text"
                                />
                                <b-btn
                                    :id="`deleteField${field[0]}`"
                                    variant="danger"
                                    @click.stop="deleteFieldConfirmation = field[0]"
                                    class="mr-auto moveCollectionFieldButton"
                                    :aria-label="$t('aria.deleteField')"
                                    :disabled="systemFields.indexOf(field[0]) > -1">
                                  <b-icon-trash
                                      size="sm"
                                  />
                                </b-btn>
                                <!-- Star icon, for primary/secondary key sorting options. Not properly implemented -->
                                <!-- <b-icon
                                    :icon="collection.primaryKey === field[0] || collection.secondaryKey === field[0] ? 'star-fill' : 'star'"
                                    scale="1.25"
                                    v-if="((!collection.primaryKey || !collection.secondaryKey)
                                    || (collection.primaryKey === field[0] || collection.secondaryKey === field[0]))
                                    && (field[1].metaType === 'String'
                                      || field[1].metaType === 'Phone'
                                      || field[1].metaType === 'Number')
                                      || field[1].metaType === 'Price'"
                                    @click="starClick(field[0], collection._id)"
                                    :class="collection.primaryKey === field[0]
                                    ? 'collections__star__primary'
                                    : collection.secondaryKey === field[0]
                                      ? 'collections__star__secondary' : 'collections__star'"
                                /> -->
                              </b-input-group>
                            </div>
                            <transition
                                mode="out-in"
                                name="fade">
                              <div
                                  key="viewFieldDetails"
                                  v-if="fieldDetailsToShow === field[0] && deleteFieldConfirmation !== field[0]"
                                  class="form__container">
                                <h5
                                    v-text="fieldTypeDisplay(field[1].metaType)"
                                    class="mx-auto"
                                />
                                <b-input-group
                                    id="fieldDetails"
                                    class="my-2">
                                  <div
                                      v-if="activeField.metaType === 'Address' && activeField.default"
                                      class="mx-auto">
                                    <b-form-select
                                        v-model="activeField.default.expanded"
                                        :options="[
                                          { text: $t('collections.address.expandedByDefault'), value: true },
                                          { text: $t('collections.address.expandedOption'), value: false }
                                        ]"
                                        @change="saveRequest()"
                                        class="form__input medium"
                                    />
                                    <b-form-select
                                        v-model="activeField.default.type"
                                        :options="[
                                          { text: $t('collections.address.formatUs'), value: 'usaFormat' },
                                          { text: $t('collections.address.formatInternational'), value: 'international' }
                                        ]"
                                        @change="clickChangeAddressType(field)"
                                        class="form__input medium"
                                    />
                                    <br />
                                    <small
                                        v-if="addressesInCollection[0] === fieldDetailsToShow
                                        && addressesInCollection.length > 1"
                                        :for="`${fieldDetailsToShow}-labelMulti`"
                                        class="small"
                                        v-text="$t('collections.address.labelMultiLabel')"
                                    />
                                    <b-form-input
                                        :id="`${fieldDetailsToShow}-labelMulti`"
                                        v-if="addressesInCollection[0] === fieldDetailsToShow
                                        && addressesInCollection.length > 1"
                                        v-model="activeField.default.labelMulti"
                                        @input="saveRequest()"
                                        :placeholder="$t('collections.address.labelMultiPlaceholder')"
                                        type="text"
                                        class="form__input medium"
                                    />
                                  </div>
                                  <div
                                      v-if="activeField.metaType === 'Link'
                                      && model.schemaObj && model.schemaObj[fieldDetailsToShow]"
                                      class="mx-auto">
                                    <div
                                        class="mb-4">
                                      <b-input-group>
                                          <b-input-group-prepend
                                              class="form__label"
                                              v-text="$t('collections.collection')"
                                          />
                                          <b-form-select
                                              id="linkedToSelection"
                                              v-model="activeField.default.linkTo"
                                              :options="collectionOptions"
                                              :disabled="!!activeField.default.linkTo"
                                              @change="saveRequest()"
                                              class="form__input large ml-auto"
                                          />
                                          <b-input-group-append
                                              id="cantChangeLinkedColl"
                                              class="ml-1 mr-auto mt-1">
                                          <b-icon-question-circle-fill />
                                        </b-input-group-append>
                                        <b-tooltip
                                            target="cantChangeLinkedColl">
                                          <span
                                              v-text="$t('tooltips.cantChangeLinkedColl')"
                                          />
                                          <p />
                                          <i
                                              v-text="$t('tooltips.cantChangeLinkedColl2')"
                                          />
                                        </b-tooltip>
                                      </b-input-group>
                                    </div>
                                    <transition
                                        mode="out-in"
                                        name="fade">
                                      <div>
                                        <div
                                            v-if="activeField.default.linkTo"
                                            key="oneOrMultiple">
                                          <b-btn-group
                                              class="mb-4">
                                            <b-btn
                                                :id="`selectOne-${fieldDetailsToShow}`"
                                                :variant="activeField.default.allowMany ? 'secondary-outline' : 'secondary'"
                                                @click="activeField.default.allowMany = !activeField.default.allowMany; saveRequest()">
                                              {{$t('collections.selectOne')}}
                                            </b-btn>
                                            <b-btn
                                                :id="`selectMultiple-${fieldDetailsToShow}`"
                                                :variant="activeField.default.allowMany ? 'secondary' : 'secondary-outline'"
                                                @click="activeField.default.allowMany = !activeField.default.allowMany; saveRequest()">
                                              {{$t('collections.selectMulti')}}
                                            </b-btn>
                                          </b-btn-group>
                                        </div>
                                        <div
                                            v-if="activeField.default.linkTo"
                                            key="whichFields"
                                            class="mx-2">
                                          <label
                                              class="form__header__small">
                                            {{$t('collections.fieldsToUse')}}
                                          </label>
                                          <b-row
                                              align-h="center"
                                              class="collections__fields__linkedFields">
                                            <b-col
                                                v-for="importOption in fieldsToImport(activeField.default.linkTo)"
                                                :key="importOption[0]"
                                                cols="auto"
                                                class="mx-0 my-2">
                                              <b-btn
                                                  :id="`importOptionButton-${importOption[0]}`"
                                                  v-if="(importOption[1].metaType === 'Address'
                                                    && model.schemaObj[fieldDetailsToShow].default.linkedFields.findIndex(i => i._id === importOption[0]) === -1)
                                                    || importOption[1].metaType !== 'Address'"
                                                  class="importOptionButton"
                                                  :variant="model.schemaObj[fieldDetailsToShow].default.linkedFields.findIndex(i => i._id === importOption[0]) === -1
                                                    ? 'secondary-outline' : 'secondary'"
                                                  @click="fieldsToImportClick(fieldDetailsToShow, importOption)">
                                                {{importOption[1].label}}
                                              </b-btn>
                                              <!-- Linked Address Field Options -->
                                              <b-dropdown
                                                  split
                                                  @click="fieldsToImportClick(fieldDetailsToShow, importOption)"
                                                  :text="importOption[1].label"
                                                  :variant="model.schemaObj[fieldDetailsToShow].default.linkedFields.findIndex(i => i._id === importOption[0]) === -1
                                                    ? 'secondary-outline' : 'secondary'"
                                                  v-else-if="importOption[1].metaType === 'Address'">
                                                <template
                                                    v-slot:button-content>
                                                  {{importOption[1].label}}
                                                  <br />
                                                  <small
                                                      v-if="addressImportOptionsDisplay(fieldDetailsToShow, importOption[0], true)
                                                      && addressImportOptionsDisplay(fieldDetailsToShow, importOption[0], true) !== 'whole'"
                                                      v-text="addressImportOptionsDisplay(fieldDetailsToShow, importOption[0])"
                                                  />
                                                </template>
                                                <b-dropdown-item
                                                    @click="addressImportOptionsClick(fieldDetailsToShow, importOption[0], 'whole')">
                                                  <b-icon-check
                                                      v-if="addressImportOptionsFetch(fieldDetailsToShow, importOption[0]).addressDisplayType === 'whole'"
                                                  />
                                                    {{$t('collections.address.linkedChoice.whole')}}
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    @click="addressImportOptionsClick(fieldDetailsToShow, importOption[0], 'first')">
                                                  <b-icon-check
                                                      v-if="addressImportOptionsFetch(fieldDetailsToShow, importOption[0]).addressDisplayType === 'first'"
                                                  />
                                                    {{$t('collections.address.linkedChoice.first')}}
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    @click="addressImportOptionsClick(fieldDetailsToShow, importOption[0], 'cityState')">
                                                  <b-icon-check
                                                      v-if="addressImportOptionsFetch(fieldDetailsToShow, importOption[0]).addressDisplayType === 'cityState'"
                                                  />
                                                    {{$t('collections.address.linkedChoice.cityState')}}
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    @click="addressImportOptionsClick(fieldDetailsToShow, importOption[0], 'state')">
                                                  <b-icon-check
                                                      v-if="addressImportOptionsFetch(fieldDetailsToShow, importOption[0]).addressDisplayType === 'state'"
                                                  />
                                                    {{$t('collections.address.linkedChoice.state')}}
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    @click="addressImportOptionsClick(fieldDetailsToShow, importOption[0], 'zip')">
                                                  <b-icon-check
                                                      v-if="addressImportOptionsFetch(fieldDetailsToShow, importOption[0]).addressDisplayType === 'zip'"
                                                  />
                                                    {{$t('collections.address.linkedChoice.zip')}}
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    @click="addressImportOptionsClick(fieldDetailsToShow, importOption[0], 'country')">
                                                  <b-icon-check
                                                      v-if="addressImportOptionsFetch(fieldDetailsToShow, importOption[0]).addressDisplayType === 'country'"
                                                  />
                                                    {{$t('collections.address.linkedChoice.country')}}
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    @click="addressImportOptionsClick(fieldDetailsToShow, importOption[0], 'cityCountry')">
                                                  <b-icon-check
                                                      v-if="addressImportOptionsFetch(fieldDetailsToShow, importOption[0]).addressDisplayType === 'cityCountry'"
                                                  />
                                                    {{$t('collections.address.linkedChoice.cityCountry')}}
                                                </b-dropdown-item>
                                              </b-dropdown>
                                            </b-col>
                                          </b-row>
                                        </div>
                                        <div
                                            v-if="activeField.default.linkTo"
                                            class="form__header__small mt-5"
                                            key="allowNew">
                                          <b-row
                                              align-h="center">
                                            <b-col
                                                cols="auto"
                                                align="center">
                                              <label
                                                  class="form__label">
                                                {{$t('collections.allowNew')}}
                                              </label>
                                              <b-form-checkbox
                                                  v-model="activeField.default.allowNew"
                                                  name="allowNewEntries"
                                                  @input="saveRequest()"
                                                  switch
                                                  size="lg"
                                              />
                                            </b-col>
                                            <b-col
                                                v-if="activeField.default.allowNew"
                                                cols="*"
                                                class="mx-auto">
                                              <div>
                                                <span
                                                    class="form__label">
                                                  {{$t('collections.saveNew')}}
                                                </span>
                                                <br />
                                                <b-btn-group
                                                    class="mx-2">
                                                  <b-btn
                                                      :id="`saveDisable-${fieldDetailsToShow}`"
                                                      :variant="(!activeField.default.allowSave && !activeField.default.forceSave) ? 'secondary' : 'secondary-outline'"
                                                      @click="clickSaveDisable(fieldDetailsToShow); saveRequest()">
                                                    {{$t('collections.allowNewDisabled')}}
                                                  </b-btn>
                                                  <b-btn
                                                      :id="`saveOptional-${fieldDetailsToShow}`"
                                                      :variant="activeField.default.allowSave ? 'secondary' : 'secondary-outline'"
                                                      @click="clickSaveOptional(fieldDetailsToShow); saveRequest()">
                                                    {{$t('collections.allowNewOptional')}}
                                                  </b-btn>
                                                  <b-btn
                                                      :id="`saveRequired-${fieldDetailsToShow}`"
                                                      :variant="activeField.default.forceSave ? 'secondary' : 'secondary-outline'"
                                                      @click="clickSaveRequired(fieldDetailsToShow); saveRequest()">
                                                    {{$t('collections.allowNewRequired')}}
                                                  </b-btn>
                                                </b-btn-group>
                                              </div>
                                            </b-col>
                                          </b-row>
                                          <b-row
                                              class="mt-2">
                                            <b-col>
                                              <label
                                                  class="form__label">
                                                {{$t('collections.showLinked')}} {{$store.getters.collection(activeField.default.linkTo).name}}
                                              </label>
                                              <b-form-checkbox
                                                  v-model="activeField.displayLinked"
                                                  name="showLinkedItems"
                                                  @input="saveRequest()"
                                                  switch
                                                  size="lg"
                                              />
                                            </b-col>
                                            <b-col
                                                v-if="activeField.displayLinked"
                                                cols="*"
                                                class="mx-auto">
                                              <div>
                                                <span
                                                    class="form__label"
                                                    v-text="$t('collections.showLinkedWhich')"
                                                />
                                                <br />
                                                  <!-- {{collections.filter(i => i._id === activeField.default.linkTo)[0].schemaObj}} -->
                                                <b-list-group>
                                                  <b-list-group-item
                                                      v-for="fieldsToShow in Object.entries(model.schemaObj).filter(i => i[0] !== fieldDetailsToShow)"
                                                      :key="fieldsToShow[0]"
                                                      @click="showLinkedInButton(model._id, fieldDetailsToShow, fieldsToShow[0])"
                                                      :id="`showLinkedFieldOption-${fieldsToShow[0]}`"
                                                      :variant="model.schemaObj[fieldDetailsToShow].displayLinkedIn.indexOf(fieldsToShow[0]) > -1 ? 'primary' : 'light'"
                                                      class="mx-2">
                                                    {{fieldsToShow[1].label}}
                                                  </b-list-group-item>
                                                </b-list-group>
                                              </div>
                                            </b-col>
                                          </b-row>
                                        </div>
                                      </div>
                                    </transition>
                                  </div>
                                  <div
                                      v-if="activeField.metaType === 'MultiSelect'"
                                      class="mx-auto">
                                    <b-list-group>
                                      <b-list-group-item
                                          v-for="(item, id) in activeField.default"
                                          @mouseover="hoverSelectOption = id"
                                          @mouseleave="hoverSelectOption = null"
                                          :variant="hoverSelectOption === id ? 'warning' : 'light'"
                                          @click="delete activeField.default[id]; saveRequest()"
                                          :key="id">
                                          <b-icon-trash
                                              v-if="hoverSelectOption === id"
                                          />
                                          {{item.label}}
                                      </b-list-group-item>
                                    </b-list-group>
                                    <b-input-group>
                                      <label
                                          :for="`${fieldDetailsToShow}-addMultiSelect`"
                                          v-text="$t('collections.addSelectOption')"
                                          class="form__label"
                                      />
                                      <b-form-input
                                          :id="`${fieldDetailsToShow}-addMultiSelect`"
                                          v-model="multiSelectEntry"
                                      />
                                      <b-btn
                                          @click="multiSelectAdd(fieldDetailsToShow)">
                                        <b-icon-plus />
                                      </b-btn>
                                    </b-input-group>
                                  </div>
                                  <div
                                      v-if="activeField.metaType === 'Number'"
                                      class="mx-auto">
                                    <label
                                        :for="`${fieldDetailsToShow}-defaultValueInput`"
                                        v-text="$t('collections.defaultValue')"
                                        class="small"
                                    />
                                    <b-form-input
                                        :id="`${fieldDetailsToShow}-defaultValueInput`"
                                        type="number"
                                        v-model.number="activeField.default"
                                        @input="saveRequest()"
                                        class="form__input medium mt-auto mb-0"
                                    />
                                  </div>
                                  <b-form-select
                                      :id="`${fieldDetailsToShow}-phoneDefaultType`"
                                      v-if="activeField.metaType === 'Phone'"
                                      v-model="activeField.default.format"
                                      @change="saveRequest()"
                                      :options="phoneDefaultTypeOptions"
                                      class="form__input large mx-auto"
                                  />
                                  <div
                                      v-if="activeField.metaType === 'SelectOne'"
                                      class="mx-auto">
                                    <b-list-group>
                                      <b-list-group-item
                                          v-for="(item, id) in activeField.default"
                                          @mouseover="hoverSelectOption = id"
                                          @mouseleave="hoverSelectOption = null"
                                          :variant="hoverSelectOption === id ? 'warning' : 'light'"
                                          @click="delete activeField.default[id]; saveRequest()"
                                          :key="id">
                                          <b-icon-trash
                                              v-if="hoverSelectOption === id"
                                          />
                                          {{item.label}}
                                      </b-list-group-item>
                                    </b-list-group>
                                    <b-input-group>
                                      <label
                                          :for="`${fieldDetailsToShow}-addSelectOne`"
                                          v-text="$t('collections.addSelectOption')"
                                          class="form__label"
                                      />
                                      <b-form-input
                                          :id="`${fieldDetailsToShow}-addSelectOne`"
                                          v-model="selectOneEntry"
                                      />
                                      <b-btn
                                          @click="selectOneAdd(fieldDetailsToShow)">
                                        <b-icon-plus />
                                      </b-btn>
                                    </b-input-group>
                                  </div>
                                  <b-form-input
                                      v-if="activeField.metaType === 'String'"
                                      :id="`${fieldDetailsToShow}-defaultValueInput`"
                                      :placeholder="$t('collections.defaultValue')"
                                      type="text"
                                      v-model="activeField.default"
                                      @input="saveRequest()"
                                      class="form__input__scale mx-auto"
                                  />
                                  <b-form-textarea
                                      v-if="activeField.metaType === 'Textarea'"
                                      :id="`${fieldDetailsToShow}-defaultValueInput`"
                                      :placeholder="$t('collections.defaultValue')"
                                      type="text"
                                      v-model="activeField.default"
                                      @input="saveRequest()"
                                      class="form__input__scale mx-auto"
                                  />
                                  <div
                                      v-if="activeField.metaType === 'Toggle' && activeField.default"
                                      class="mx-auto">
                                    <b-form-input
                                        :id="`${fieldDetailsToShow}-defaultValueInput`"
                                        :placeholder="$t('collections.defaultValueOnPlaceholder')"
                                        type="text"
                                        v-model="activeField.default.labelOn"
                                        @input="saveRequest()"
                                        class="form__input large"
                                    />
                                    <b-form-input
                                        :id="`${fieldDetailsToShow}-defaultValueInput`"
                                        :placeholder="$t('collections.defaultValueOffPlaceholder')"
                                        type="text"
                                        v-model="activeField.default.labelOff"
                                        @input="saveRequest()"
                                        class="form__input large"
                                    />
                                    <b-btn
                                        @click="clickDefaultValueToggle(fieldDetailsToShow)"
                                        :variant="activeField.default.value ? 'secondary' : 'secondary-outline'"
                                        v-text="`${activeField.default.value
                                          ? (activeField.default.labelOn ? activeField.default.labelOn : $t('collections.toggleOn'))
                                          : (activeField.default.labelOff ? activeField.default.labelOff: $t('collections.toggleOff'))} ${$t('collections.byDefault')}`"
                                    />
                                  </div>
                                </b-input-group>
                              </div>
                              <b-input-group
                                  key="deleteDialog"
                                  class="form__container"
                                  v-else-if="deleteFieldConfirmation === field[0]">
                                <b-btn
                                    id="cancelDeleteFieldButton"
                                    class="moveCollectionFieldButton ml-auto"
                                    @click="deleteFieldConfirmation = null"
                                    variant="secondary">
                                  <b-icon-arrow-left
                                  />
                                  {{$t('collections.removeFieldCancel')}}
                                </b-btn>
                                <span
                                    v-text="$t('collections.removeFieldConfirmation')"
                                    class="mx-2 my-auto"
                                />
                                <b-btn
                                    id="deleteConfirmationFieldButton"
                                    variant="danger"
                                    @click.stop="removeField(model._id, field[0])"
                                    class="mr-auto moveCollectionFieldButton"
                                    :aria-label="$t('aria.deleteField')">
                                  <b-icon-trash
                                      size="sm"
                                  />
                                  <span
                                      v-text="$t('collections.removeFieldConfirm')"
                                  />
                                </b-btn>
                              </b-input-group>
                            </transition>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                    <b-row
                        class="mt-5">
                      <b-col
                          align="center">
                        <b-btn
                            id="addFieldButton"
                            size="lg"
                            @click="addingFieldButton">
                          <b-icon-plus
                              :scale="1.5"
                          />
                          <span
                              v-text="$t('collections.addField')"
                          />
                        </b-btn>
                      </b-col>
                    </b-row>
                  </div>
                  <div
                      id="addingFieldContainer"
                      key="addingField"
                      v-else>
                    <h2
                        class="text-center"
                        v-text="$t('collections.addFieldOptions')"
                    />
                    <b-row>
                      <b-col
                          v-for="i in Math.ceil(typeOptions.length / 5)"
                          :key="i.value">
                        <b-list-group>
                          <b-list-group-item
                              v-for="type in typeOptions.filter(o => !o.hideDisplay).slice((i - 1) * 5, i * 5)"
                              :key="type.value"
                              @click="typeSelection(type.value)"
                              class="collections__fields__option">
                            <b-row>
                              <b-col
                                  cols="*">
                                <h4
                                    class="ml-2"
                                    v-text="type.text"
                                />
                              </b-col>
                              <b-col
                                  cols="auto">
                                <span
                                    v-text="type.description"
                                    class="mt-0 my-auto"
                                />
                              </b-col>
                            </b-row>
                          </b-list-group-item>
                        </b-list-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                          align="center">
                        <b-btn
                            id="addFieldButton"
                            class="mt-4 px-5 py-2"
                            @click="addingField = !addingField"
                            v-text="!addingField ? $t('collections.addField') : $t('cancel')"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </transition>
              </b-card-body>
            </b-collapse>
          </b-card>
        </transition-group>
      </div>
    </div>
    <b-row
        v-if="collections.length < $_app.switch.collectionAdminLimit"
        class="mt-2">
      <b-col
          class="p-1 font-weight-bold mr-3"
          align="right">
        <b-button
            @click="addCollection()"
            variant="primary"
            class="px-4 py-2">
          <b-icon-plus
              size="lg"
          />
          <span
              v-text="$t('collections.addCollection')"
          />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable no-continue */
import moment from 'moment';
import totalCalculators from '@/mixins/totalCalculators';

export default {
  name: 'CollectionAdmin',
  mixins: [
    totalCalculators,
  ],
  title() {
    return `${this.$t('adminCollections')} | ${this.$t('project.brand')}`;
  },
  components: {
  },
  data() {
    return {
      addingField: false,
      autoSaveTiming: [
        1.25,
        800,
        1700,
      ],
      collectionId: null,
      deleteFieldConfirmation: null,
      deleteConfirmDialog: false,
      deleteConfirmText: null,
      fieldDetailsToShow: null,
      hoverField: null,
      hoverSelectOption: null,
      multiSelectAddTo: null,
      multiSelectEntry: null,
      selectOneAddTo: null,
      selectOneEntry: null,
      unsavedChanges: [],
    };
  },
  computed: {
    accountEmployees() {
      return this.$store.getters.account('employees');
    },
    accountPeople() {
      return this.$store.getters.account('people');
    },
    accountServices() {
      return this.$store.getters.account('services');
    },
    activeField() {
      if (this.fieldDetailsToShow) {
        return this.model.schemaObj[this.fieldDetailsToShow];
      }
      return [];
    },
    addressesInCollection() {
      const id = [];
      if (this.model.schemaObj) {
        const entries = Object.entries(this.model.schemaObj);
        entries.forEach((e) => {
          if (e[1].metaType === 'Address') {
            id.push(e[0]);
          }
        });
      }
      return id;
    },
    collections() {
      return this.$store.getters.collections;
    },
    collectionFields: {
      get() {
        if (this.model && this.model.schemaObj) {
          return Object.entries(this.model.schemaObj);
        }
        return [];
      },
    },
    collectionOptions() {
      const collections = [];
      this.collections.forEach((coll) => {
        if (coll._id !== this.model._id) {
          collections.push({
            value: coll._id,
            text: coll.name,
          });
        }
      });
      return collections;
    },
    model: {
      get() {
        if (this.collectionId) {
          return this.$store.getters.collection(this.collectionId);
        }
        return {};
      },
      set(newVal) {
        const index = this.collections.findIndex(coll => coll._id === this.collectionId);
        this.$store.state.collections[index] = newVal;
      },
    },
    phoneDefaultTypeOptions() {
      return [
        {
          value: null,
          text: this.$t('collections.phoneTypes.label'),
          disabled: true,
        },
        {
          value: 'usaFormat',
          text: this.$t('collections.phoneTypes.usaFormat'),
        },
        {
          value: 'noFormat',
          text: this.$t('collections.phoneTypes.noFormat'),
        },
      ];
    },
    systemFields() {
      // Determines whether a given field is used by the system. If so, user cannot delete the field.
      const fields = [];
      console.log(this.$store.state.account);
      const employeeInfo = this.$store.state.account.employeesFields;
      if (employeeInfo) {
        fields.push(employeeInfo.address);
        fields.push(employeeInfo.active);
        fields.push(employeeInfo.availability);
        fields.push(employeeInfo.email);
        fields.push(employeeInfo.employeeId);
        fields.push(employeeInfo.loginInfo);
        fields.push(employeeInfo.name);
        fields.push(employeeInfo.notes);
        fields.push(employeeInfo.phone);
        fields.push(employeeInfo.shiftsWorked);
      }
      return fields;
    },
    typeOptions() {
      return [
        { value: 'Address', text: this.$t('collections.types.Address'), description: this.$t('collections.types.addressDesc') },
        { value: 'Color', text: this.$t('collections.types.Color'), description: this.$t('collections.types.colorDesc') },
        { value: 'ColorBadge', text: this.$t('collections.types.ColorBadge'), description: this.$t('collections.types.colorBadgeDesc') },
        { value: 'Email', text: this.$t('collections.types.Email'), description: this.$t('collections.types.emailDesc') },
        { value: 'Inventory', text: this.$t('collections.types.Inventory'), description: this.$t('collections.types.inventoryDesc') },
        { value: 'Link', text: this.$t('collections.types.Link'), description: this.$t('collections.types.linkDesc') },
        { value: 'Notes', text: this.$t('collections.types.Notes'), description: this.$t('collections.types.notesDesc') },
        { value: 'Number', text: this.$t('collections.types.Number'), description: this.$t('collections.types.numberDesc') },
        { value: 'Phone', text: this.$t('collections.types.Phone'), description: this.$t('collections.types.phoneDesc') },
        { value: 'Price', text: this.$t('collections.types.Price'), description: this.$t('collections.types.priceDesc') },
        { value: 'SelectOne', text: this.$t('collections.types.SelectOne'), description: this.$t('collections.types.selectOneDesc') },
        { value: 'MultiSelect', text: this.$t('collections.types.MultiSelect'), description: this.$t('collections.types.multiSelectDesc') },
        { value: 'Status', text: this.$t('collections.types.Status'), description: this.$t('collections.types.statusDesc') },
        { value: 'Tags', text: this.$t('collections.types.Tags'), description: this.$t('collections.types.tagsDesc') },
        { value: 'Toggle', text: this.$t('collections.types.Toggle'), description: this.$t('collections.types.toggleDesc') },
        { value: 'String', text: this.$t('collections.types.String'), description: this.$t('collections.types.stringDesc') },
        { value: 'Textarea', text: this.$t('collections.types.Textarea'), description: this.$t('collections.types.textareaDesc') },
        // System Types
        { value: 'Timecard', text: this.$t('collections.types.Timecard'), hideDisplay: true },
        { value: 'Availability', text: this.$t('collections.types.Availability'), hideDisplay: true },
        { value: 'LoginInfo', text: this.$t('collections.types.LoginInfo'), hideDisplay: true },
      ];
    },
    typeData() {
      return [
        {
          metaType: 'Address',
          default: {
            type: 'usaFormat',
            address: {
              attn: '',
              street: '',
              streetSecond: null,
              streetSecondShow: false,
              city: null,
              state: null,
              zip: null,
              notes: null,
            },
            expanded: !(this.addressesInCollection.length > 1),
            labelSingle: this.$t('collections.address.labelSingle'),
            labelMulti: this.addressesInCollection && this.addressesInCollection.length > 1
              ? this.$t('collections.address.labelMultiAlt') : this.$t('collections.address.labelMulti'),
          },
          display: true,
          displayType: 'whole',
          download: true,
          label: this.fieldTypeDisplay('Address', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
          type: 'Object',
        },
        {
          metaType: 'Color',
          default: null,
          display: true,
          download: false,
          label: this.fieldTypeDisplay('Color', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
          type: 'String',
        },
        {
          metaType: 'ColorBadge',
          default: ['#ffffff', '#000000'],
          display: true,
          download: false,
          label: this.fieldTypeDisplay('Color', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
          type: ['String'],
        },
        {
          metaType: 'Email',
          default: null,
          display: true,
          download: true,
          label: this.fieldTypeDisplay('Email', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
          type: 'String',
        },
        {
          metaType: 'Inventory',
          type: 'Object',
          default: {
            counter: null,
            usedIn: [], // track which other collection items refer to this
            invoicePulls: [], // track which invoices have adjusted this qty
          },
          display: true,
          download: true,
          label: this.fieldTypeDisplay('Inventory', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
        },
        {
          metaType: 'Link',
          type: 'Object',
          default: {
            allowMany: false,
            allowNew: false,
            allowSave: false,
            dataOverride: {
              _id: null,
              humanId: null,
            },
            forceSave: false,
            linksChosen: [],
            linkTo: null,
            linkedFields: [],
            showLinked: false,
            showLinkedFields: [],
          },
          label: this.fieldTypeDisplay('Link', true),
          display: true,
          displayLinked: false,
          displayLinkedIn: [],
          download: true,
        },
        {
          metaType: 'MultiSelect',
          type: 'Object',
          default: {},
          display: true,
          download: true,
          label: this.fieldTypeDisplay('MultiSelect', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
        },
        {
          metaType: 'Notes',
          type: ['Object'],
          default: [],
          display: false,
          download: false,
          label: this.fieldTypeDisplay('Notes', true),
          notePreviewLength: 'sm',
          notesToShow: 3,
          sortable: false,
          sortByDefault: false,
          sortBySecondary: false,
        },
        {
          metaType: 'Number',
          default: null,
          display: true,
          download: true,
          label: this.fieldTypeDisplay('Number', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
          type: 'Number',
        },
        {
          metaType: 'Phone',
          type: 'Object',
          default: {
            format: 'usaFormat',
            number: null,
          },
          display: true,
          download: true,
          label: this.fieldTypeDisplay('Phone', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
        },
        {
          metaType: 'Price',
          type: 'Object',
          default: {
            price: null,
            type: 'usd',
          },
          display: true,
          download: true,
          label: this.fieldTypeDisplay('Price', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
        },
        {
          metaType: 'SelectOne',
          type: 'Object',
          default: {},
          display: true,
          download: true,
          label: this.fieldTypeDisplay('SelectOne', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
        },
        {
          metaType: 'Status',
          type: 'Object',
          default: {
            text: null,
            urgent: false,
            dateOfStatus: null,
          },
          display: true,
          download: false,
          label: this.fieldTypeDisplay('Status', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
        },
        {
          metaType: 'String',
          type: 'String',
          default: null,
          display: true,
          download: true,
          label: this.fieldTypeDisplay('String', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
        },
        {
          metaType: 'Tags',
          type: ['String'],
          default: null,
          display: true,
          download: true,
          label: this.fieldTypeDisplay('Tags', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
        },
        {
          metaType: 'Toggle',
          type: 'Object',
          default: {
            labelOn: this.$t('collections.defaultValueOn'),
            labelOff: null,
            value: false,
          },
          display: true,
          download: true,
          label: this.fieldTypeDisplay('Toggle', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
        },
        {
          metaType: 'Textarea',
          type: 'String',
          default: null,
          display: true,
          download: true,
          label: this.fieldTypeDisplay('Textarea', true),
          sortable: true,
          sortByDefault: false,
          sortBySecondary: false,
        },
        // Hidden/System MetaTypes
        {
          metaType: 'LoginInfo',
          type: 'Object',
          default: {
            accessType: 'user',
            username: null,
            idToken: null,
            signedUp: null,
            status: null,
            tempPassword: null,
            lastOnline: null,
          },
          display: false,
          download: false,
          label: this.$t('collections.types.LoginInfo'),
          sortable: false,
          sortByDefault: false,
          sortBySecondary: false,
        },
        {
          metaType: 'Timecard',
          type: ['Object'],
          default: [],
          display: false,
          download: false,
          label: this.$t('collections.types.Timecard'),
          sortable: false,
          sortByDefault: false,
          sortBySecondary: false,
        },
        {
          metaType: 'Availability',
          type: 'Object',
          default: {
            alwaysAvailabile: true,
          },
          display: false,
          download: false,
          label: this.$t('collections.types.Availability'),
          sortable: false,
          sortByDefault: false,
          sortBySecondary: false,
        },
      ];
    },
  },
  created() {
    this.$store.dispatch('initialLoad').catch((err) => {
      console.log(err);
      this.$router.go();
    });
    this.readyCheck();
  },
  methods: {
    accountServicesClick() {
      // Converts a blank Collection into a Scheduler asset
      this.$store.state.showSpinner = true;
      const newVal = this.$store.getters.account('services') === this.collectionId ? null : this.collectionId;
      if (newVal && !this.collectionFields.length) {
        const fieldsToAdd = [
          'Name',
          'Description',
          'Active',
          'Color',
          'Price',
          'Length',
        ];
        for (let a = 0; a < fieldsToAdd.length; a += 1) {
          let fieldType;
          let label;
          let activeToggle;
          switch (fieldsToAdd[a]) {
            case ('Active'): {
              activeToggle = true;
              fieldType = 'Toggle';
              label = this.$t('collections._servicesFields[0]');
              break;
            }
            case ('Color'): {
              fieldType = 'ColorBadge';
              label = this.$t('collections._servicesFields[1]');
              break;
            }
            case ('Description'): {
              fieldType = 'Textarea';
              label = this.$t('collections._servicesFields[2]');
              break;
            }
            case ('Length'): {
              fieldType = 'Number';
              label = this.$t('collections._servicesFields[3]');
              break;
            }
            case ('Price'): {
              fieldType = 'Price';
              label = this.$t('collections._servicesFields[4]');
              break;
            }
            case ('Name'): {
              fieldType = 'String';
              label = this.$t('collections._servicesFields[5]');
              break;
            }
            default: {
              break;
            }
          }
          let fieldData = this.typeData.find(data => data.metaType === fieldType);
          fieldData = JSON.parse(JSON.stringify(fieldData));
          const newField = {};
          const newId = this.$guid();
          newField[newId] = {
            ...fieldData,
            label,
          };
          const payload = {
            collection: this.model._id,
            fieldId: newId,
            system: fieldsToAdd[a],
            fieldData,
          };
          payload.fieldData.label = label;
          if (activeToggle) {
            payload.fieldData.default.labelOn = this.$t('collections._schedulerActive[0]');
            payload.fieldData.default.labelOff = this.$t('collections._schedulerActive[1]');
            payload.fieldData.default.value = true;
          }
          this.$store.commit('ADD_FIELD', payload);
        }
      }
      this.$store.dispatch('accountUpdate', {
        id: newVal,
        type: 'services',
      });
      this.saveNow();
    },
    accountEmployeesClick() {
      // Converts a blank Collection into a WorkDay asset
      const newVal = this.$store.getters.account('employees') === this.collectionId ? null : this.collectionId;
      this.$store.state.showSpinner = true;
      const fields = { // Stores field keys for later reference in template
        active: null,
        address: null,
        availability: null,
        email: null,
        employeeId: null,
        loginInfo: null,
        name: null,
        notes: null,
        phone: null,
        shiftsWorked: null,
      };
      if (newVal && !this.collectionFields.length) {
        const fieldsToAdd = [
          'EmployeeId',
          'Name',
          'Address',
          'Phone',
          'Email',
          'Active',
          'Notes',
          'ShiftsWorked',
          'LoginInfo',
          'Availability',
        ];
        for (let a = 0; a < fieldsToAdd.length; a += 1) {
          const newId = this.$guid();
          let fieldType;
          let label;
          let activeToggle;
          switch (fieldsToAdd[a]) {
            case ('Availability'): {
              fieldType = 'Availability';
              label = this.$t('collections._employeesFields.availability');
              fields.availability = newId;
              break;
            }
            case ('EmployeeId'): {
              fieldType = 'String';
              label = this.$t('collections._employeesFields.employeeId');
              fields.employeeId = newId;
              break;
            }
            case ('Active'): {
              fieldType = 'Toggle';
              label = this.$t('collections._employeesFields.active');
              activeToggle = true;
              fields.active = newId;
              break;
            }
            case ('Email'): {
              fieldType = 'Email';
              label = this.$t('collections._employeesFields.email');
              fields.email = newId;
              break;
            }
            case ('Notes'): {
              fieldType = 'Notes';
              label = this.$t('collections._employeesFields.notes');
              fields.notes = newId;
              break;
            }
            case ('ShiftsWorked'): {
              fieldType = 'Timecard';
              label = this.$t('collections._employeesFields.shiftsWorked');
              fields.shiftsWorked = newId;
              break;
            }
            case ('LoginInfo'): {
              fieldType = 'LoginInfo';
              label = this.$t('collections._employeesFields.loginInfo');
              fields.loginInfo = newId;
              break;
            }
            case ('Name'): {
              fieldType = 'String';
              label = this.$t('collections._employeesFields.name');
              fields.name = newId;
              break;
            }
            case ('Address'): {
              fieldType = 'Address';
              label = this.$t('collections._employeesFields.address');
              fields.address = newId;
              break;
            }
            case ('Phone'): {
              fieldType = 'Phone';
              label = this.$t('collections._employeesFields.phone');
              fields.phone = newId;
              break;
            }
            default: {
              break;
            }
          }
          let fieldData = this.typeData.find(data => data.metaType === fieldType);
          fieldData = JSON.parse(JSON.stringify(fieldData));
          const newField = {};
          newField[newId] = {
            ...fieldData,
          };
          const payload = {
            collection: this.model._id,
            fieldId: newId,
            fieldData,
          };
          payload.fieldData.label = label;
          if (activeToggle) {
            payload.fieldData.default.labelOn = this.$t('collections._schedulerActive[0]');
            payload.fieldData.default.labelOff = this.$t('collections._schedulerActive[1]');
            payload.fieldData.default.value = true;
          }
          this.$store.commit('ADD_FIELD', payload);
        }
      }
      this.$store.dispatch('accountUpdate', {
        id: newVal,
        type: 'employees',
        fields,
      });
      this.saveNow();
    },
    accountPeopleClick() {
      // Converts a blank Collection into a Scheduler asset
      const newVal = this.$store.getters.account('people') === this.collectionId ? null : this.collectionId;
      this.$store.state.showSpinner = true;
      if (newVal && !this.collectionFields.length) {
        const fieldsToAdd = [
          'Name',
          'Description',
          'Active',
          'Color',
        ];
        for (let a = 0; a < fieldsToAdd.length; a += 1) {
          let fieldType;
          let label;
          let activeToggle;
          switch (fieldsToAdd[a]) {
            case ('Active'): {
              fieldType = 'Toggle';
              label = this.$t('collections._peopleFields[0]');
              activeToggle = true;
              break;
            }
            case ('Color'): {
              fieldType = 'ColorBadge';
              label = this.$t('collections._peopleFields[1]');
              break;
            }
            case ('Description'): {
              fieldType = 'Textarea';
              label = this.$t('collections._peopleFields[2]');
              break;
            }
            case ('Name'): {
              fieldType = 'String';
              label = this.$t('collections._peopleFields[3]');
              break;
            }
            default: {
              break;
            }
          }
          let fieldData = this.typeData.find(data => data.metaType === fieldType);
          fieldData = JSON.parse(JSON.stringify(fieldData));
          const newField = {};
          const newId = this.$guid();
          newField[newId] = {
            ...fieldData,
          };
          const payload = {
            collection: this.model._id,
            fieldId: newId,
            fieldData,
          };
          payload.fieldData.label = label;
          if (activeToggle) {
            payload.fieldData.default.labelOn = this.$t('collections._schedulerActive[0]');
            payload.fieldData.default.labelOff = this.$t('collections._schedulerActive[1]');
            payload.fieldData.default.value = true;
          }
          this.$store.commit('ADD_FIELD', payload);
        }
      }
      this.$store.dispatch('accountUpdate', {
        id: newVal,
        type: 'people',
      });
      this.saveNow();
    },
    addCollection() {
      this.$store.dispatch('addCollection', {
        accessed: new Date(),
        created: new Date(),
        dateAddedDisplay: false,
        dateAddedDownload: false,
        dateUpdatedDisplay: true,
        dateUpdatedDownload: true,
        folders: [],
        hoverField: null,
        humanIdPrefix: null,
        idType: 'noId',
        idDisplay: false,
        idDownload: false,
        linkedBy: [],
        modified: new Date(),
        name: this.$t('collections.newName'),
        primaryKey: null,
        public: false,
        quickEntry: false,
        schemaObj: {},
        secondaryKey: null,
        structure: [],
        users: [],
      });
    },
    addingFieldButton() {
      this.addingField = true;
      if (this.fieldDetailsToShow) {
        this.fieldDetailsToShow = null;
      }
    },
    addField(fieldData) {
      const newField = {};
      const newId = this.$guid();
      newField[newId] = {
        ...fieldData,
      };
      const payload = {
        collection: this.model._id,
        fieldId: newId,
        fieldData,
      };
      this.$store.commit('ADD_FIELD', payload);
      // if (this.model.schemaObj) {
      //   this.model.schemaObj[newId] = newField;
      // } else {
      //   this.model.schemaObj = {};
      //   this.model.schemaObj[newId] = newField;
      // }
      this.$store.state.showSpinner = true;
      this.saveNow();
      this.addingField = false;
      this.fieldDetailsToShow = newId;
      setTimeout(() => {
        const el = document.getElementById(`labelInput-${newId}`);
        el.scrollIntoView();
        el.focus();
        el.select();
      }, 350);
    },
    addressImportOptionsClick(fieldKey, optionKey, selectedOption) {
      this.model.schemaObj[fieldKey].default.linkedFields.find(i => i._id === optionKey).addressDisplayType = selectedOption;
      this.saveRequest();
    },
    addressImportOptionsDisplay(fieldKey, optionKey, typeCheck) {
      const key = this.model.schemaObj[fieldKey].default.linkedFields.find(i => i._id === optionKey).addressDisplayType;
      let label = null;
      if (key) {
        if (typeCheck) {
          return key;
        }
        switch (key) {
          default: break;
          case ('whole'): {
            label = this.$t('collections.address.linkedChoice.whole');
            break;
          }
          case ('first'): {
            label = this.$t('collections.address.linkedChoice.first');
            break;
          }
          case ('cityState'): {
            label = this.$t('collections.address.linkedChoice.cityState');
            break;
          }
          case ('state'): {
            label = this.$t('collections.address.linkedChoice.state');
            break;
          }
          case ('cityCountry'): {
            label = this.$t('collections.address.linkedChoice.cityCountry');
            break;
          }
          case ('country'): {
            label = this.$t('collections.address.linkedChoice.country');
            break;
          }
          case ('zip'): {
            label = this.$t('collections.address.linkedChoice.zip');
            break;
          }
        }
      }
      return label;
    },
    addressImportOptionsFetch(fieldKey, optionKey) {
      if (this.model.schemaObj[fieldKey].default.linkedFields.find(i => i._id === optionKey)) {
        return this.model.schemaObj[fieldKey].default.linkedFields.find(i => i._id === optionKey);
      }
      return {};
    },
    clickDefaultValueToggle(fieldKey) {
      this.model.schemaObj[fieldKey].default.value = !this.model.schemaObj[fieldKey].default.value;
      this.saveRequest();
    },
    clickChangeAddressType(field) {
      this.model.schemaObj[field[0]].default.address = this.addressTemplates(field[1].default.type);
      this.saveRequest();
    },
    clickSaveDisable(fieldKey) {
      this.model.schemaObj[fieldKey].default.allowSave = false;
      this.model.schemaObj[fieldKey].default.forceSave = false;
      this.saveRequest();
    },
    clickSaveOptional(fieldKey) {
      this.model.schemaObj[fieldKey].default.allowSave = !this.model.schemaObj[fieldKey].default.allowSave;
      if (this.model.schemaObj[fieldKey].default.forceSave) {
        this.model.schemaObj[fieldKey].default.forceSave = false;
      }
      this.saveRequest();
    },
    clickSaveRequired(fieldKey) {
      this.model.schemaObj[fieldKey].default.forceSave = !this.model.schemaObj[fieldKey].default.forceSave;
      if (this.model.schemaObj[fieldKey].default.allowSave) {
        this.model.schemaObj[fieldKey].default.allowSave = false;
      }
      this.saveRequest();
    },
    fieldsToImport(collectionId) {
      return this.$store.getters.fields(collectionId, 'linked');
    },
    fieldsToImportClick(key, field) {
      const index = this.model.schemaObj[key].default.linkedFields.findIndex(i => i._id === field[0]);
      if (index === -1) {
        this.model.schemaObj[key].default.linkedFields.push({ _id: field[0], metaType: field[1].metaType });
      } else {
        this.model.schemaObj[key].default.linkedFields.splice(index, 1);
      }
      this.saveRequest();
    },
    fieldTypeDisplay(metaType, shortVersion) {
      const typeOption = this.typeOptions.find(type => type.value === metaType);
      if (shortVersion) {
        return typeOption.text;
      }
      return `${this.$t('collections.fieldType')}: ${typeOption.text}`;
    },
    moveField(id, up) {
      let schema = JSON.parse(JSON.stringify(this.model.schemaObj));
      schema = Object.entries(schema);
      let index;
      for (let a = 0; a < schema.length; a += 1) {
        if (schema[a][0] === id) {
          index = a;
        }
      }
      if (up) {
        const move = {
          up: schema[index],
          down: schema[index - 1],
        };
        schema.splice(index - 1, 2, move.up, move.down);
      } else {
        const move = {
          up: schema[index + 1],
          down: schema[index],
        };
        schema.splice(index, 2, move.up, move.down);
      }
      this.model.schemaObj = Object.fromEntries(schema);
      this.saveRequest();
    },
    multiSelectAdd(id) {
      if (this.multiSelectAddTo !== id) {
        this.multiSelectAddTo = id;
      } else {
        if (!this.model.schemaObj[id].default) {
          this.model.schemaObj[id].default = {};
        }
        this.model.schemaObj[id].default[this.$guid()] = {
          type: 'Boolean',
          value: false,
          label: this.multiSelectEntry,
        };
        this.multiSelectEntry = null;
        this.saveRequest();
      }
    },
    removeCollection(collId) {
      // Provides an 'enter collection name to confirm delete' dialog, unless we are in offline mode
      if (!this.$_app.offline) {
        this.deleteConfirmDialog = !this.deleteConfirmDialog;
      }
      if (this.deleteConfirmText === this.model.name || this.$_app.offline) {
        this.deleteConfirmText = null;
        if (this.model.schemaObj) {
          console.log('yup2');
          const schema = Object.entries(this.model.schemaObj);
          for (let a = 0; a < schema.length; a += 1) {
            console.log('yup3');
            if (schema[a][1].metaType === 'Link' && schema[a][1].default.linkTo) {
              console.log('yup4');
              this.$store.state.collectionsChangedRestart = true;
            }
          }
        }
        if (this.accountPeople === this.collectionId) {
          this.$store.dispatch('accountUpdate', { id: null, type: 'people' });
        }
        if (this.accountServices === this.collectionId) {
          this.$store.dispatch('accountUpdate', { id: null, type: 'services' });
        }
        if (this.accountEmployees === this.collectionId) {
          this.$store.dispatch('accountUpdate', { id: null, type: 'employees' });
        }
        this.collectionId = null;
        this.$store.dispatch('removeCollection', collId);
      }
      console.log('done');
    },
    removeField(collId, fieldId) {
      if (this.model.primaryKey === fieldId) {
        this.model.primaryKey = null;
      } else if (this.model.secondaryKey === fieldId) {
        this.model.secondaryKey = null;
      }
      if (this.model.schemaObj[fieldId].metaType === 'Link' && this.model.schemaObj[fieldId].default.linkTo) {
        this.$store.state.collectionsChangedRestart = true;
      }
      this.$store.state.showSpinner = true;
      delete this.model.schemaObj[fieldId];
      this.saveRequest();
    },
    removeSelectOption(fieldId, optionId) {
      delete this.model.schemaObj[fieldId].default[optionId];
      this.saveRequest();
    },
    saveRequest() {
      this.$store.commit('AUTOSAVE_REQUEST');
      this.lastChangedBefore = this.lastChanged;
      this.lastChanged = moment();
      if (this.unsavedChanges.indexOf(this.model._id) === -1) {
        this.unsavedChanges.push(this.model._id);
      }
    },
    saveNow() {
      this.$store.commit('AUTOSAVE_COMPLETE');
      if (this.unsavedChanges.length < 1) {
        this.$store.dispatch('updateCollection', this.model._id);
      } else {
        for (let a = 0; a < this.unsavedChanges.length; a += 1) {
          this.$store.dispatch('updateCollection', this.unsavedChanges[a]);
        }
      }
      this.unsavedChanges = [];
    },
    seeFieldDetails(field) {
      if (this.fieldDetailsToShow === field[0]) {
        this.fieldDetailsToShow = null;
      } else {
        this.fieldDetailsToShow = field[0];
      }
    },
    selectOneAdd(id) {
      const payload = {
        collection: this.model._id,
        fieldId: id,
        optionId: this.$guid(),
        label: this.selectOneEntry,
      };
      this.$store.commit('ADD_FIELD_SELECT_OPTION', payload);
      // if (!this.model.schemaObj[id].default) {
      //   this.model.schemaObj[id].default = {};
      // }
      // this.model.schemaObj[id].default = {
      //   ...this.model.schemaObj[id].default,
      //   [this.$guid()]: {
      //     type: 'Boolean',
      //     value: false,
      //     label: this.selectOneEntry,
      //   },
      // };
      this.selectOneEntry = null;
      this.saveRequest();
    },
    showLinkedFieldsOptions(collectionId, thisField) {
      return this.$store.getters.fields(collectionId).filter(field => field[0] !== thisField);
    },
    showLinkedInButton(coll, fieldKey, displayField) {
      const collection = this.collections.filter(c => c._id === coll)[0];
      const linkedIn = collection.schemaObj[fieldKey].displayLinkedIn;
      const index = linkedIn.indexOf(displayField);
      if (index === -1) {
        linkedIn.push(displayField);
      } else {
        linkedIn.splice(index, 1);
      }
      this.saveRequest();
    },
    /* starClick(fieldId, collectionId) {
    // Intended to be used as a primary/secondary sort key. Not properly implemented
       const coll = this.collections.find(c => c._id === collectionId);
       if (fieldId === coll.secondaryKey || fieldId === coll.primaryKey) {
         if (fieldId === coll.secondaryKey) {
           coll.secondaryKey = null;
         } else {
           coll.primaryKey = null;
         }
       } else if (!coll.primaryKey) {
         coll.primaryKey = fieldId;
       } else {
         coll.secondaryKey = fieldId;
       }
     }, */
    typeSelection(selectedType) {
      const fieldData = this.typeData.find(data => data.metaType === selectedType);
      this.addField(JSON.parse(JSON.stringify(fieldData)));
    },
    viewCollection(collId) {
      if (this.model._id !== collId) {
        this.collectionId = collId;
      } else {
        this.collectionId = null;
      }
      this.$nextTick(() => {
        this.$root.$emit('bv::toggle::collapse', collId);
      });
    },
  },
  watch: {
    collections(newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        if (newVal.length) {
          this.viewCollection(newVal[newVal.length - 1]._id);
        }
      }
    },
  },
};
</script>
